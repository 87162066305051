import React from 'react';
import { TabContainer } from './styled';

interface Props {
  active?: boolean;
  label: React.ReactElement | React.ReactNode;
  onClick: () => void;
}

export const Tab: React.FC<Props> = ({ active = false, label, onClick }) => {
  return (
    <TabContainer onClick={onClick} active={active}>
      {label}
    </TabContainer>
  );
};
