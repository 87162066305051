import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const OrganizationSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M1.6875 15.75H16.3125M2.8125 2.25V15.75M10.6875 2.25V15.75M15.1875 5.625V15.75M5.0625 5.0625H5.625M5.0625 7.3125H5.625M5.0625 9.5625H5.625M7.875 5.0625H8.4375M7.875 7.3125H8.4375M7.875 9.5625H8.4375M5.0625 15.75V13.2188C5.0625 12.753 5.4405 12.375 5.90625 12.375H7.59375C8.0595 12.375 8.4375 12.753 8.4375 13.2188V15.75M2.25 2.25H11.25M10.6875 5.625H15.75M12.9375 8.4375H12.9435V8.4435H12.9375V8.4375ZM12.9375 10.6875H12.9435V10.6935H12.9375V10.6875ZM12.9375 12.9375H12.9435V12.9435H12.9375V12.9375Z"
        stroke="#9EA9B1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Organization: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={OrganizationSVG} {...props} />;
};
