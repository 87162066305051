import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div``;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px 0px 0px;
  gap: 8px;
`;

export const Footer = styled.div`
  display: flex;
  padding: 24px 0px;
`;

export const TestAgainButton = styled(Button)`
  display: flex;
  padding: 7px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  color: #6a6a6a;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;
