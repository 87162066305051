import { Message } from '../../../redux/typings/recap';
import { createAccountFavouritesStore } from './account-favourites.store';

export interface AiItem {
  id: string;
  text: string;
  chunks: { id: string; from: number; to: number; messageId: string }[];
  hidden: boolean;
  excludedFromInsightsEngine: boolean;
}

export interface FavouritedLastMeeting {
  id: string;
  date: string;
  name: string;
  messages: Message[];
  actionItems: AiItem[];
}

export interface FavouritedAccount {
  id: string;
  name: string;
  provider: string | null;
  timeline: { date: string; story: string }[] | null;
  narrative: string | null;
  lastMeetings: FavouritedLastMeeting[];
  nextMeeting?: {
    date: string;
    name: string;
    record: {
      value: boolean;
      reason: string;
      canRecord: boolean;
    };
    id: string;
  } | null;
  lastGeneratedAt: Date | null;
  storyIsGenerating: boolean;
}

type TFavourites = {
  id: string;
  name: string;
  description?: string;
  accounts: FavouritedAccount[];
  loading: boolean;
};

export const AccountFavouritesInitialState: TFavourites = {
  id: '',
  name: '',
  accounts: [],
  loading: true,
};

export type AccountFavouritesStore = TFavourites & {
  dataLoaded: (store: AccountFavouritesStore) => void;
  removeFavourite: (id: string) => void;
  setRecord: (id: string, value: boolean) => void;
  setRegenerating: (id: string) => void;
};

export type AccountFavouritesContext = ReturnType<typeof createAccountFavouritesStore>;
