import React from 'react';

export const SuccessIndicator = () => {
  return (
    <svg
      width="221"
      height="155"
      viewBox="0 0 221 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="28.5" y="5" width="150" height="150" rx="75" fill="#EDFCF5" />
      <g clipPath="url(#clip0_1304_17489)">
        <path
          d="M198.487 1.2258C199.332 -0.4086 201.669 -0.4086 202.514 1.2258L207.895 11.6328C208.111 12.0499 208.451 12.3898 208.868 12.6054L219.275 17.9864C220.909 18.8314 220.909 21.1686 219.275 22.0136L208.868 27.3946C208.451 27.6102 208.111 27.95 207.895 28.3672L202.514 38.7742C201.669 40.4086 199.332 40.4086 198.487 38.7742L193.106 28.3672C192.891 27.9501 192.551 27.6102 192.134 27.3946L181.727 22.0136C180.092 21.1686 180.092 18.8314 181.727 17.9864L192.134 12.6054C192.551 12.3897 192.891 12.0499 193.106 11.6328L198.487 1.226V1.2258Z"
          fill="#D2F9E5"
        />
      </g>
      <g clipPath="url(#clip1_1304_17489)">
        <path
          d="M11.7421 120.766C12.2702 119.745 13.731 119.745 14.2591 120.766L17.6222 127.271C17.757 127.531 17.9694 127.744 18.2301 127.878L24.7345 131.241C25.756 131.77 25.756 133.23 24.7345 133.759L18.2301 137.122C17.9694 137.256 17.757 137.469 17.6222 137.729L14.2591 144.234C13.731 145.255 12.2702 145.255 11.7421 144.234L8.37898 137.729C8.24423 137.469 8.03182 137.256 7.77111 137.122L1.26686 133.759C0.245357 133.23 0.245357 131.77 1.26686 131.241L7.77123 127.878C8.03192 127.744 8.24433 127.531 8.37911 127.271L11.7421 120.766V120.766Z"
          fill="#D2F9E5"
        />
      </g>
      <g clipPath="url(#clip2_1304_17489)">
        <path
          d="M189.494 50.6129C189.916 49.7957 191.085 49.7957 191.507 50.6129L194.198 55.8164C194.305 56.025 194.475 56.1949 194.684 56.3027L199.887 58.9932C200.705 59.4157 200.705 60.5843 199.887 61.0068L194.684 63.6973C194.475 63.8051 194.305 63.975 194.198 64.1836L191.507 69.3871C191.085 70.2043 189.916 70.2043 189.494 69.3871L186.803 64.1836C186.695 63.975 186.525 63.8051 186.317 63.6973L181.113 61.0068C180.296 60.5843 180.296 59.4157 181.113 58.9932L186.317 56.3027C186.525 56.1949 186.695 56.025 186.803 55.8164L189.494 50.613V50.6129Z"
          fill="#D2F9E5"
        />
      </g>
      <circle cx="103" cy="80" r="60" fill="#D2F9E5" />
      <circle cx="103" cy="80" r="45" fill="#AAF0D1" />
      <path
        d="M122.167 66L96.4999 91.6667L84.8333 80"
        stroke="#16B380"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_1304_17489">
          <rect width="40" height="40" fill="white" transform="translate(180.5)" />
        </clipPath>
        <clipPath id="clip1_1304_17489">
          <rect width="25" height="25" fill="white" transform="translate(0.5 120)" />
        </clipPath>
        <clipPath id="clip2_1304_17489">
          <rect width="20" height="20" fill="white" transform="translate(180.5 50)" />
        </clipPath>
      </defs>
    </svg>
  );
};
