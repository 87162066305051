import { useContext } from 'react';
import { profileContext } from './profile.context';
import { useStore } from 'zustand';

export const useProfile = () => {
  const { store, isFetching } = useContext(profileContext);
  const profile = useStore(store, store => store);

  return {
    profile,
    isFetching,
  };
};
