import { useEffect } from 'react';
import { StoryTimeline } from '../../../../../componentsV2/StoryTimeline';
import { useAccountStory } from '../../../../../features/account/account-story/hooks/useAccountStory';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';

interface Props {
  accountName: string;
  onNotGeneratedStory: () => void;
}

export const AccountStory: React.FC<Props> = ({
  accountName,
  onNotGeneratedStory: onNotGeneratedStory,
}) => {
  const { storyNarrative, storyTimeline, isGenerating, lastGeneratedAt, load, generate } =
    useAccountStory();
  const { selectedAccount } = useAccountOverview();

  useEffect(() => {
    load([
      selectedAccount.story.timeline,
      selectedAccount.story.narrative,
      selectedAccount.story.isGenerating,
      selectedAccount.story.lastGeneratedAt
        ? new Date(selectedAccount.story.lastGeneratedAt)
        : null,
    ]);
  }, [selectedAccount]);

  useEffect(() => {
    if (!selectedAccount.story.timeline || !selectedAccount.story.narrative) {
      onNotGeneratedStory();
    }
  }, [selectedAccount.story.timeline, selectedAccount.story.narrative]);

  return (
    <StoryTimeline
      style={{ padding: '7px' }}
      storyNarrative={storyNarrative}
      storyTimeline={storyTimeline}
      accountName={accountName}
      isGenerating={isGenerating}
      lastGeneratedAt={lastGeneratedAt}
      onGenerate={generate}
    />
  );
};
