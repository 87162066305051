import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const CheckCircleSVG = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6667 10.3333C18.6667 14.9357 14.9357 18.6667 10.3333 18.6667C5.73096 18.6667 2 14.9357 2 10.3333C2 5.73096 5.73096 2 10.3333 2C14.9357 2 18.6667 5.73096 18.6667 10.3333Z"
        fill="#16B380"
        stroke="#16B380"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8L9 13.5L6.5 11"
        stroke="#EDFCF5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckCircle: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={CheckCircleSVG} {...props} />;
};
