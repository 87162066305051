import styled from 'styled-components';
import LabeledInput from '../../../_Misc/LabeledInput';
import { colors } from '../../../../../../theme/colors';

const DescriptionText = styled.div`
  color: var(--Gray-700, #6a6a6a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 0px 9px 13px;
  width: 320px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  gap: 8px;
  color: #282828;
  font-size: 20px;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const Label = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Link = styled.a`
  color: ${colors.primary[600]};
  text-decoration: underline;

  :hover {
    font-weight: 600;
  }
`;

export default {
  Label,
  Title,
  StyledInput,
  DescriptionText,
  Buttons,
  Container,
  Link,
};
