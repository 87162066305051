import React from 'react';
import { Button, Select } from 'antd';
import { CopyFilled, ReloadOutlined } from '@ant-design/icons';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../../componentsV2/UpgradeToPro';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/selectors';
import { Framework, FrameworkExecution } from '../../type';
import { ReactElement, useState } from 'react';
import { useToast } from '../../../../hooks/useToast';
import useFeatures from '../../../../hooks/useFeatures';

import Styles from './styles';
const { Container, NoFrameworks } = Styles;

interface Props {
  setLastExecution: (le: FrameworkExecution | null) => void;
  fetchLastExecution: (id: string) => Promise<FrameworkExecution | null>;
  onGenerate: (id: string) => Promise<FrameworkExecution | null>;
  onCopy: (name: string, updatedAt: string) => void;
  children: ReactElement;
  disabled: boolean;
  key: string;
}

export const FrameworksHeader: React.FC<Props> = ({
  setLastExecution,
  fetchLastExecution,
  onGenerate,
  onCopy,
  children,
  disabled,
  key,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<Framework | null | undefined>(undefined);
  const [refetch, setRefetch] = useState<NodeJS.Timeout | null>(null);

  const { error } = useToast();

  const user = useSelector(selectUser);
  const feature = useFeatures();
  const availableFeature = feature.meddicc;

  const handleSelect = async (f: Framework | null) => {
    if (!f?.id) return;

    if (f.id !== selected?.id && refetch) {
      clearTimeout(refetch);
      setRefetch(null);
    }

    setSelected(f);
    try {
      setLoading(true);
      const fe = await fetchLastExecution(f?.id);
      setLastExecution(fe);

      if (fe?.isProcessing) {
        setRefetch(
          setTimeout(() => {
            handleSelect(f);
          }, 5000),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = async () => {
    if (!selected) return;
    try {
      setLoading(true);
      await onGenerate(selected.id);
      setLastExecution({ isProcessing: true } as FrameworkExecution);
      setRefetch(
        setTimeout(() => {
          handleSelect({ ...selected });
        }, 2000),
      );
    } catch (e: any) {
      setLoading(false);
      error(e.response.data.message);
    }
  };

  const { data, isFetching } = useQuery({
    queryKey: ['frameworks', key],
    queryFn: async () => {
      if (!user?.membership.organization.id) return [];
      const d = await coreService.fetchFrameworksByWorkspaceId(user?.membership.organization.id);
      if (d.length > 0) {
        handleSelect(d[0]);
        return d;
      } else {
        setLoading(false);
        setLastExecution(null);
        return [];
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const options = data?.map(f => ({ label: f.name, value: f.id })) || [];
  const val = options.find(f => f.value === selected?.id);
  const noData = data?.length === 0 && !loading;

  document.addEventListener('stop-framework-polling', () => {
    if (refetch) clearTimeout(refetch);
  });

  return (
    <>
      <Container>
        <Select
          placeholder="Select a framework"
          options={options}
          disabled={isFetching || loading}
          value={val}
          style={{ width: 350 }}
          onSelect={(v, i) => handleSelect(data?.find(f => f.id === i.value) || null)}
        />

        <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            disabled={isFetching || loading || disabled || noData}
            onClick={() => handleGenerate()}
          >
            Regenerate
          </Button>
        </OptionalTooltipWrapper>

        <Button
          icon={<CopyFilled />}
          onClick={() => onCopy(selected?.name || '', selected?.createdAt || '')}
          style={{ borderRadius: '8px' }}
          disabled={isFetching || loading || disabled || noData}
        >
          Copy All
        </Button>
      </Container>

      {noData ? (
        <NoFrameworks
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
        >
          <span style={{ width: '500px', textAlign: 'center' }}>
            There are no frameworks created. Go to <a href="/settings?tab=framework"> frameworks</a>{' '}
            to create your own.
          </span>
        </NoFrameworks>
      ) : (
        React.cloneElement(children, {
          ...children.props,
          loading: isFetching || loading || children.props.loading,
          selectedFrameworkName: selected?.name,
          onGenerate: handleGenerate,
        })
      )}
    </>
  );
};
