import styled from 'styled-components';

export const ChatMessageContainer = styled.div<{ reversed: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reversed ? 'row-reverse' : 'row')};
  gap: 12px;
`;

export const Message = styled.div`
  max-width: 300px;
  background-color: blue;
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #eff6ff;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Speaker = styled.span`
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
`;

export const Timestamp = styled.span`
  font-size: 10px;
  line-height: 12px;
`;
