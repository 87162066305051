import styled from 'styled-components';

const TimeFrameList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
  overflow: auto;
`;

const UpcomingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredContent = styled.div`
  height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    color: gray;
    font-size: 17px;
  }

  & h1 {
    color: black;
    font-size: 22px;
  }
`;

export default {
  TimeFrameList,
  UpcomingList,
  LoaderContainer,
  CenteredContent,
};
