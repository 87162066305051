export const removeBullet = (text: string) => {
  if (!text) {
    return text;
  }

  return text.replace(/•\s/g, '');
};

export const getFirstLetter = (text: string) => {
  if (!text) {
    return '';
  }
  return text[0];
};

export const capitalize = (text: string) => {
  if (!text) {
    return '';
  }
  return text.toUpperCase();
};

export const isValidEmail = (email: string) => {
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailPattern.test(email);
};
