import React from 'react';
import { ButtonTabContainer } from './styles';

interface Props {
  active?: boolean;
  label: string;
  onClick: () => unknown;
}

export const ButtonTab: React.FC<Props> = ({ active = false, onClick, label }) => {
  return (
    <ButtonTabContainer active={active} onClick={onClick}>
      {label}
    </ButtonTabContainer>
  );
};
