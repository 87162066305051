import { useCallback } from 'react';
import { useVideoPlayer } from '../../video/hooks';
import { Utterance } from '../recap-management/types';
import { recapPlaybackStore } from './store';

export const useRecapPlayback = () => {
  const state = recapPlaybackStore();
  const { ready, play, isPlaying, currentTime, duration } = useVideoPlayer();

  const playMessage = useCallback((utterance: Utterance) => {
    play(utterance.startTime);
    state.messagePlayingChanged(utterance.id);
  }, []);

  return {
    ready,
    isPlaying,
    play,
    currentTime,
    duration,
    playMessage,
    messageIdPlaying: state.messageIdPlaying,
    changeMessagePlaying: state.messagePlayingChanged,
    autoScroll: state.autoScroll,
    changeAutoScroll: state.autoScrollingChanged,
  };
};
