import React from 'react';
import LogoSpinner from '../../assets/svg/logo/LogoSpinner';
import { Container, Loader, Text } from './style';

interface Props {
  text?: string;
  min?: boolean;
}

export const UpdateLoader: React.FC<Props> = ({ text = 'Loading', min = false }) => (
  <Container $min={min}>
    <Loader>
      <LogoSpinner />
      <Text>{text}...</Text>
    </Loader>
  </Container>
);
