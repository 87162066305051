import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { selectOnboardingError, selectOnboardingLoading } from '../../../../../redux/selectors';
import { Card } from '../Card';
import { Container, NavigationButtons, Status } from '../../style';
import { FramedLayout } from '../../../../../Layout/FramedLayout/FramedLayout';
import { CardHeader } from '../../CardHeader';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../../../types/commonTypes';
import { Progress } from '../../../Progress';

import * as Style from '../style';
const { CardFrame, Cards, Header } = Style;

const reasons = [
  {
    id: 1,
    title: 'To save time',
    info: '(i.e. Note taker and Automated Workflows)',
    key: 'Save Time',
  },
  {
    id: 2,
    title: 'To capture customer insights',
    info: '(i.e. Account Intelligence Dashboard & Account Pages)',
    key: 'Capture Customer Insights',
  },
];

interface Props {
  currentInternalStep: number;
  onBack: () => void;
  onNext: () => void;
  setReason: (s: string) => void;
}

export const PrimaryReason: React.FC<Props> = ({
  currentInternalStep,
  onBack,
  onNext,
  setReason,
}) => {
  const [selected, setSelected] = useState(1);

  const error = useSelector(selectOnboardingError);
  const loading = useSelector(selectOnboardingLoading);

  useEffect(() => {
    setReason(reasons.find(each => each.id === selected)!.key);
  }, [selected]);

  return (
    <FramedLayout error={error} blockLayout={loading} logOut>
      <Container>
        <Header>
          {' '}
          <CardHeader
            title={
              <>
                The <span>primary</span> reason I am joining <span>UpdateAI</span>
              </>
            }
          />
          ,
        </Header>

        <CardFrame>
          <Cards>
            {reasons.map(each => {
              return (
                <Card
                  title={each.title}
                  description={each.info}
                  key={'card-reasons' + each.id}
                  selected={selected === each.id}
                  onClick={() => setSelected(each.id)}
                />
              );
            })}
          </Cards>
        </CardFrame>

        <Status>
          <NavigationButtons>
            <Button onClick={() => onBack()} type="default" disabled={loading}>
              {'<< Back to ‘Tell Us About Yourself’'}
            </Button>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onNext();
              }}
            >
              Continue
            </Button>
          </NavigationButtons>

          <Progress total={ONBOARDING_TOTAL_STEPS_AMOUNT} current={currentInternalStep} />
        </Status>
      </Container>
    </FramedLayout>
  );
};
