import styled from 'styled-components';
import { CardStyled } from '../../../shared/components/Card/styles';
import { Tabs } from 'antd';

export const Card = styled(CardStyled)`
  height: 100%;
  overflow: hidden;
`;

export const VideoContainer = styled.div`
  padding: 16px;
`;

export const TabsContainer = styled(Tabs)`
  padding-left: 16px;
  padding-right: 16px;
`;
