import React from 'react';
import { CardStyled } from './styles';

interface Props {
  children?: React.ReactElement | React.ReactElement[];
  style?: React.CSSProperties;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Card: React.FC<Props> = ({
  children,
  style,
  className,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <CardStyled
      style={style}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </CardStyled>
  );
};
