import { createStore } from 'zustand';
import { ContactMetrics } from '../../API/types';

export interface Event {
  id: string;
  speakerLabel: string | null;
}

export interface ProfileKeyQuote {
  quote: string;
  event: {
    id: string;
    title: string;
  };
}

export interface Profile {
  role: {
    value: string;
  };
  personal: {
    value: string;
  };
  positiveFeedback: {
    value: string;
  };
  negativeFeedback: {
    value: string;
  };
  requests: {
    value: string;
  };
  communicationStyle: {
    type: string;
    value: string;
  };
  psychologicalStyle: {
    type: string;
    value: string;
  };
  decisionMakingStyle: {
    type: string;
    value: string;
  };
  keyQuotes: ProfileKeyQuote[];
}

export interface SpeakerLabels {
  [index: number]: string;
}

export interface ProfileStore {
  id: string;
  role: string | null;
  email: string;
  name: string | null;
  url: string | null;
  account: {
    id: string;
    name: string;
    provider: string;
  };
  speakerLabels: SpeakerLabels;
  events: Event[];
  profile: Profile;
  isGeneratingProfile: boolean;
  profileLastGeneratedAt: string | null;
  overview: ContactMetrics | null;
  profileProcessingRequested: () => void;
}

export const createProfileStore = () => {
  return createStore<ProfileStore>(set => ({
    id: '',
    role: null,
    email: '',
    name: null,
    url: null,
    account: {
      id: '',
      name: '',
      provider: '',
    },
    speakerLabels: {},
    events: [],
    profile: {
      role: {
        value: '',
      },
      personal: {
        value: '',
      },
      positiveFeedback: {
        value: '',
      },
      negativeFeedback: {
        value: '',
      },
      requests: {
        value: '',
      },
      communicationStyle: {
        type: '',
        value: '',
      },
      psychologicalStyle: {
        type: '',
        value: '',
      },
      decisionMakingStyle: {
        type: '',
        value: '',
      },
      keyQuotes: [],
    },
    isGeneratingProfile: false,
    profileLastGeneratedAt: null,
    overview: null,
    profileProcessingRequested: () => set({ isGeneratingProfile: true }),
  }));
};
