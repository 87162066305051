import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const LeftsideContainer = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    width: 35%;
    align-items: flex-start;
  }
`;

const RightsideContainer = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
    width: 65%;
    background: linear-gradient(132deg, #7230ff 0%, #ff6c54 100%);
    position: relative;
    overflow: hidden;
  }
`;

const Fingerprint = styled.img`
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  height: 500px;
  width: 500px;
`;

const Logo = styled.img`
  width: 161px;
  height: 40px;
`;

const AlertContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default {
  Container,
  LeftsideContainer,
  RightsideContainer,
  Logo,
  Fingerprint,
  AlertContainer,
};
