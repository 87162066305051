import React, { useState } from 'react';
import { secondsToTime } from '../../../../helpers/TextHelper';
import {
  DetailsContainer,
  Speaker,
  TextContainer,
  Timestamp,
  Transcript,
  TranscriptLineContainer,
} from './styles';
import { Avatar as AntAvatar, Space } from 'antd';
import Styles from './styles';
import { capitalize, getFirstLetter } from '../../../../utils/text';
import { PlayRound } from '../../../shared/components/Icons/PlayRound';
import { LinkOutlined } from '@ant-design/icons';
import { useToast } from '../../../../hooks/useToast';
import { useRecapAnalytics } from '../../../recapv2/recap-analytics/hooks/useRecapAnalytics';
import { useRecapPlayback } from '../../../recapv2/recap-playback/hook';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';

const { TextContent } = Styles;

interface Props {
  speaker: string;
  content: string;
  timestamp: string;
  id: string;
  chunks: {
    from: number;
    to: number;
    chunkIds: string[];
    itemIds: string[];
  }[];
}

interface AvatarProps {
  isPlaying: boolean;
  letter: string;
}
const Avatar: React.FC<AvatarProps> = ({ isPlaying, letter }) => {
  const styles: React.CSSProperties = isPlaying
    ? { color: '#BF513F', backgroundColor: '#FFDAD4' }
    : {};
  return (
    <AntAvatar style={styles} size="small">
      {letter}
    </AntAvatar>
  );
};

export const TranscriptLine: React.FC<Props> = ({ id, speaker, content, chunks, timestamp }) => {
  const { messageIdPlaying, playMessage } = useRecapPlayback();
  const { getMessageById, id: recapId } = useRecapManagement();
  const { success } = useToast();
  const { transcript } = useRecapAnalytics();
  const [isMessageHovered, setIsMessageHovered] = useState(false);
  const speakerLabel = speaker;

  const handlePlayMessage = () => {
    const message = getMessageById(id);
    if (message) {
      playMessage(message);
    }
  };

  const isPlaying = messageIdPlaying === id;
  const showPlayButton = isMessageHovered || isPlaying;

  const handleCopyMessage = () => {
    const url = `${window.location.origin}/recap/${recapId}?messageId=${id}`;
    navigator.clipboard.writeText(url);
    transcript.linkCopied(id);
    success(`Link to transcript copied to clipboard`);
  };

  return (
    <TranscriptLineContainer
      id={`transcript-row-${id}`}
      data-is-playing={String(isPlaying)}
      onMouseEnter={() => setIsMessageHovered(true)}
      onMouseLeave={() => setIsMessageHovered(false)}
    >
      <Avatar letter={capitalize(getFirstLetter(speakerLabel))} isPlaying={isPlaying} />
      <TextContent>
        <DetailsContainer title="Click to play" style={{ height: '24px' }}>
          <Speaker id="transcript-speaker">{speakerLabel}</Speaker>
          <Timestamp id="transcript-timestamp">{secondsToTime(timestamp)}</Timestamp>
          {showPlayButton && (
            <Space>
              <PlayRound
                style={{
                  cursor: 'pointer',
                  fill: isPlaying ? '#FF9887' : '#A6A6A6',
                }}
                onClick={handlePlayMessage}
              />
              <LinkOutlined style={{ color: '#A6A6A6' }} onClick={() => handleCopyMessage()} />
            </Space>
          )}
        </DetailsContainer>
        <Transcript id="transcript">
          {chunks.map((ch, i) => {
            const context = content.slice(ch.from, ch.to);
            return (
              <TextContainer
                id="transcript-chunk"
                key={'text-container-' + id + '-' + i}
                current={false}
                selected={false}
              >
                {context}
              </TextContainer>
            );
          })}
        </Transcript>
      </TextContent>
    </TranscriptLineContainer>
  );
};
