import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const DownloadSVG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.875C10.1658 1.875 10.3247 1.94085 10.4419 2.05806C10.5592 2.17527 10.625 2.33424 10.625 2.5V12.2417L13.3083 9.55833C13.3656 9.49693 13.4346 9.44768 13.5112 9.41352C13.5879 9.37936 13.6706 9.36099 13.7546 9.35951C13.8385 9.35803 13.9218 9.37346 13.9997 9.4049C14.0775 9.43633 14.1482 9.48312 14.2075 9.54247C14.2669 9.60182 14.3137 9.67251 14.3451 9.75034C14.3765 9.82816 14.392 9.91152 14.3905 9.99543C14.389 10.0794 14.3706 10.1621 14.3365 10.2388C14.3023 10.3154 14.2531 10.3844 14.1917 10.4417L10.4417 14.1917C10.3245 14.3087 10.1656 14.3744 10 14.3744C9.83437 14.3744 9.67552 14.3087 9.55833 14.1917L5.80833 10.4417C5.74693 10.3844 5.69768 10.3154 5.66352 10.2388C5.62936 10.1621 5.61099 10.0794 5.60951 9.99543C5.60803 9.91152 5.62346 9.82816 5.6549 9.75034C5.68633 9.67251 5.73312 9.60182 5.79247 9.54247C5.85182 9.48312 5.92251 9.43633 6.00034 9.4049C6.07816 9.37346 6.16152 9.35803 6.24544 9.35951C6.32935 9.36099 6.41212 9.37936 6.48878 9.41352C6.56545 9.44768 6.63445 9.49693 6.69167 9.55833L9.375 12.2417V2.5C9.375 2.33424 9.44085 2.17527 9.55806 2.05806C9.67527 1.94085 9.83424 1.875 10 1.875ZM2.5 13.125C2.66576 13.125 2.82473 13.1908 2.94194 13.3081C3.05915 13.4253 3.125 13.5842 3.125 13.75V15.625C3.125 15.9565 3.2567 16.2745 3.49112 16.5089C3.72554 16.7433 4.04348 16.875 4.375 16.875H15.625C15.9565 16.875 16.2745 16.7433 16.5089 16.5089C16.7433 16.2745 16.875 15.9565 16.875 15.625V13.75C16.875 13.5842 16.9408 13.4253 17.0581 13.3081C17.1753 13.1908 17.3342 13.125 17.5 13.125C17.6658 13.125 17.8247 13.1908 17.9419 13.3081C18.0592 13.4253 18.125 13.5842 18.125 13.75V15.625C18.125 16.288 17.8616 16.9239 17.3928 17.3928C16.9239 17.8616 16.288 18.125 15.625 18.125H4.375C3.71196 18.125 3.07607 17.8616 2.60723 17.3928C2.13839 16.9239 1.875 16.288 1.875 15.625V13.75C1.875 13.5842 1.94085 13.4253 2.05806 13.3081C2.17527 13.1908 2.33424 13.125 2.5 13.125Z"
        fill="#F3F4F5"
      />
    </svg>
  );
};
export const Download: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={DownloadSVG} {...props} />;
};
