import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { PlayVideo } from '../../../../shared/components/Icons/PlayVideo';
import { SkipBackward } from '../../../../shared/components/Icons/SkipBackwards';
import { SkipForward } from '../../../../shared/components/Icons/SkipForward';
import { Fullscreen } from '../../../../shared/components/Icons/Fullscreen';
import { Time } from './Time';
import { Speed } from './Speed';
import { PlayBar } from './PlayBar';
import { EditionBar } from './EditionBar';
import { VolumePanel } from './VolumePanel';
import { Download } from '../../../../shared/components/Icons/Download';
import { useVideoPlayer } from '../../../hooks';
import { Pause } from '../../../../shared/components/Icons/Pause';
import { Scissors } from './Scissors';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const {
  Container,
  LeftsideControls,
  RightsideControl,
  ControlsContainer,
  AllControls,
  VideoOverlay,
} = Styles;

interface Props {
  isEditable: boolean;
}

export const Controls: React.FC<Props> = ({ isEditable }) => {
  const {
    play,
    isPlaying,
    pause,
    skip,
    toggleFullscreen,
    fullscreen,
    download,
    markers,
    changeCropping,
    duration,
    togglePlay,
  } = useVideoPlayer();
  const [isEditing, setIsEditing] = useState(false);
  const [tempMarkers, setTempMarkers] = useState(markers);

  useEffect(() => {
    setTempMarkers(markers);
  }, [markers]);

  const handleTimeChanged = (time: number[]) => {
    setTempMarkers([time[0], time[1]]);
  };

  const markersEditionFinished = () => {
    const endTimeCropping = duration - tempMarkers[1];
    changeCropping({ start: tempMarkers[0], end: endTimeCropping });
    setIsEditing(false);
  };

  const markersEditionCanceled = () => {
    setTempMarkers([markers[0], markers[1]]);
    setIsEditing(false);
  };

  return (
    <Container id="controls-container">
      <VideoOverlay
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          togglePlay();
        }}
      />
      <AllControls>
        <div style={{ position: 'relative' }}>
          {isEditing ? (
            <EditionBar
              duration={duration}
              markers={tempMarkers}
              onTimeChanged={handleTimeChanged}
            />
          ) : (
            <PlayBar marks={isEditable ? markers : null} />
          )}
        </div>
        <ControlsContainer>
          <LeftsideControls>
            {isPlaying ? <Pause onClick={() => pause()} /> : <PlayVideo onClick={() => play()} />}
            <SkipBackward onClick={() => skip(-15)} />
            <SkipForward onClick={() => skip(15)} />
            <Time />
            {!isEditing && <Speed />}
            {isEditable && (
              <Scissors isEditing={isEditing} onEditionStarted={() => setIsEditing(true)} />
            )}
            {isEditable && !isEditing && <Download onClick={download} />}
            {isEditing && (
              <Button type="primary" size="small" onClick={markersEditionFinished}>
                Save
              </Button>
            )}
            {isEditing && (
              <Button size="small" onClick={markersEditionCanceled}>
                Cancel
              </Button>
            )}
          </LeftsideControls>
          <RightsideControl>
            <Fullscreen onClick={() => toggleFullscreen(!fullscreen)} />
            <VolumePanel />
          </RightsideControl>
        </ControlsContainer>
      </AllControls>
    </Container>
  );
};
