import { Button, Input, Tag } from 'antd';
import { LearnMore } from '../../../_Misc/Section/style';
import { useEffect, useMemo, useState } from 'react';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { coreService } from '../../../../../../services/core/core-service';
import { useToast } from '../../../../../../hooks/useToast';
import isValidDomain from 'is-valid-domain';
import Section from '../../../_Misc/Section';
import Style from './style';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import { doNotRecordListUpdated } from '../../../../../../stores/organization-details';
const { Content, Title, TagsList } = Style;

export const DNR: React.FC<{}> = () => {
  const { doNotRecordList, permissions } = useOrganizationDetails();
  const { success, error } = useToast();

  const [currentDomain, setCurrentDomain] = useState<string>('');
  const [domains, setDomains] = useState<string[]>(doNotRecordList);
  const [isLoading, setIsLoading] = useState(false);
  const [isListUpdated, setIsListUpdated] = useState(false);

  const isDisabled = useMemo(() => {
    return isLoading || !isValidDomain(currentDomain);
  }, [isLoading, currentDomain]);

  const sanitizedDomain = (domain: string) => {
    let d = domain.toLowerCase();
    d = domain.replace('www.', '');
    // Remove trailing dot. It is a valid domain with or without it.
    if (d.endsWith('.')) d = d.slice(0, -1);
    return d;
  };

  const handleAddDomain = () => {
    const domain = sanitizedDomain(currentDomain);

    if (isValidDomain(domain) && !domains.includes(domain) && permissions.manageDoNotRecordList) {
      setIsListUpdated(true);
      setDomains(l => {
        const list = [...l, domain];
        return list;
      });
      setCurrentDomain('');
    }
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      await coreService.updateDoNotRecordingList(domains);
      doNotRecordListUpdated(domains);
      success('Do not record list updated successfully.');
      setIsListUpdated(false);
    } catch {
      error('Failed to update do not record list.');
    } finally {
      setIsLoading(false);
    }
  };

  const tags = useMemo(() => {
    if (domains.length === 0) return <div style={{ fontSize: 11 }}>No email domains skipped</div>;

    return domains.map(d => (
      <Tag
        closable={!isLoading && permissions.manageDoNotRecordList}
        style={{ width: 'fit-content' }}
        key={'tag-' + d}
        onClose={() => {
          if (!isLoading) {
            setIsListUpdated(true);
            setDomains(l => {
              return l.filter(i => i !== d);
            });
          }
        }}
      >
        @{d}
      </Tag>
    ));
  }, [domains, isLoading]);

  useEffect(() => {
    setDomains(doNotRecordList);
  }, [doNotRecordList.length]);

  return (
    <Section
      title="'Do not record' List"
      description={
        <>
          We'll skip meetings with participants under these domains.
          <LearnMore
            href={
              'https://help.update.ai/en/articles/9012073-how-to-prevent-an-external-domain-or-account-from-being-recorded'
            }
            target="_blank"
          >
            Learn More
          </LearnMore>
        </>
      }
    >
      <Content>
        <Title>Domains that UpdateAI will not record across your entire workspace</Title>

        <TagsList>{tags}</TagsList>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OptionalTooltipWrapper>
            <div style={{ display: 'flex', gap: '10px', width: '50%' }}>
              <Input
                disabled={isLoading || !permissions.manageDoNotRecordList}
                onChange={e => {
                  setCurrentDomain(e.target.value.toLowerCase());
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') handleAddDomain();
                }}
                value={currentDomain}
                placeholder="Format should include domain name and the TLD (e.g. acme.com)"
              />
              <Button onClick={handleAddDomain} disabled={isDisabled}>
                Add
              </Button>
            </div>
          </OptionalTooltipWrapper>
        </div>

        <Button
          type="primary"
          onClick={handleSaveChanges}
          disabled={!isListUpdated || isLoading || !permissions.manageDoNotRecordList}
          loading={isLoading}
          style={{ width: '123px' }}
        >
          Save Changes
        </Button>
      </Content>
    </Section>
  );
};
