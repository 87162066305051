import { Progress } from 'antd';
import styled from 'styled-components';

export const ParticipantTalkDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Participant = styled.span`
  color: #445260;
  font-size: 14px;
  line-height: 18px;
`;

export const ProgressBar = styled(Progress)`
  flex: 1;
  > .ant-progress-outer {
    height: 10px !important;
  }

  > .ant-progress-outer > .ant-progress-inner {
    height: 10px !important;
  }

  > .ant-progress-outer > .ant-progress-inner > .ant-progress-bg {
    height: 10px !important;
  }
`;
