import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const Content = styled.div`
  margin-top: 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const BoundedContent = styled.div`
  width: 360px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Link = styled.a`
  color: #0675fc;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-end;
`;

export const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  background: ${colors.blue[600]};
`;

export const Item = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  color: ${colors.gray[600]};

  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  span {
    color: ${colors.gray[800]};
    font-family: Cera Pro;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 60px;
  height: 100%;
  width: 100%;
  gap: 30px;
`;

export const Setting = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  width: 500px;
`;

export const SettingTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.coolGray[500]};
  gap: 5px;

  h1 {
    font-family: Cera Pro;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 0;
    text-align: left;
  }

  h2 {
    font-family: Cera Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 0;
    text-align: left;
  }
`;
