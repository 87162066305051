import React from 'react';
import { Bar, Container } from './style';
import { colors } from '../../../theme/colors';

interface Props {
  total: number;
  current: number;
}

export const Progress: React.FC<Props> = ({ total, current }) => {
  return (
    <Container>
      {Array(total)
        .fill(0)
        .map((e, index) => {
          return (
            <Bar
              key={'bar-' + index}
              style={{ backgroundColor: index < current ? colors.primary[500] : '#E0E6EE' }}
            />
          );
        })}
    </Container>
  );
};
