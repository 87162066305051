import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;

  h1 {
    color: var(--Gray-950, #282828);
    font-family: 'Cera Pro Medium';
    font-size: 14px;
    font-style: normal;
    line-height: 20px; /* 142.857% */
    margin: 0;
    padding: 0;
  }

  h3 {
    color: var(--Gray-600, #475467);
    font-family: 'Cera Pro';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 200% */
    margin: 0;
    padding: 0;
  }
`;

export default {
  Container,
};
