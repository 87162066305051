import styled from 'styled-components';

const Warn = styled.div`
  display: flex;
  align-items: center;
  color: var(--Blue-700, #0675fc);

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
`;

export default { Warn };
