import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { Button, Space } from 'antd';
import { CopyOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useToast } from '../../../../hooks/useToast';
import { useCustomerIntelligenceData } from '../../../../features/insights/hooks/useCustomerIntelligenceData';
import { Magic } from '../../../../features/shared/components/Icons/Magic';
import { InsightSmartClip } from '../../../../features/insights/types';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';

const {
  Container,
  Summary: SummaryText,
  SummaryContainer,
  Title,
  ActionsContainer,
  SummaryTextActionsContainer,
} = Styles;

type GenerateSummaryFunction = () => Promise<string>;
type WatchItemsFn = () => Promise<InsightSmartClip[]>;

interface Props {
  trackGenerateClick: () => void;
  generateFn: GenerateSummaryFunction;
  watchItemsFn: WatchItemsFn;
  clearDependencies: any[];
}

export const Summary: React.FC<Props> = ({
  generateFn,
  watchItemsFn,
  trackGenerateClick,
  clearDependencies,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [summary, setSummary] = useState('');
  const { success } = useToast();
  const { group } = useCustomerIntelligenceFilters();
  const { fetchItems, groups } = useCustomerIntelligenceData();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  useEffect(() => {
    setSummary('');
  }, [clearDependencies]);

  const onGenerateSummary = () => {
    setIsGenerating(true);
    generateFn()
      .then(generatedSummary => setSummary(generatedSummary))
      .finally(() => setIsGenerating(false));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(summary);
    success('Summary copied to clipboard');
  };

  const handleShowWhatWasSaid = () => {
    fetchItems(watchItemsFn);
  };

  const handleGenerateClick = () => {
    onGenerateSummary();
    trackGenerateClick();
  };

  useEffect(() => {
    const hasGroup = groups.find(g => g.name === group);
    if (hasGroup && !initiallyLoaded) {
      onGenerateSummary();
      setInitiallyLoaded(true);
    }
  }, [groups, group]);

  return (
    <Container>
      <Title>Summary</Title>

      <SummaryContainer>
        <SummaryText>
          {summary ||
            (isGenerating
              ? 'Generating AI Summary...'
              : 'Select the “Regenerate AI Summary” button below to apply the new data.')}
        </SummaryText>
        <SummaryTextActionsContainer>
          <Button onClick={handleCopy} icon={<CopyOutlined />} disabled={!summary}>
            Copy all
          </Button>
        </SummaryTextActionsContainer>
      </SummaryContainer>

      <ActionsContainer>
        <Button loading={isGenerating} onClick={handleGenerateClick} type="primary">
          <Space>
            <Magic style={{ fill: 'white', marginTop: '4px' }} />
            <span>Re-generate AI Summary</span>
          </Space>
        </Button>
        <Button
          onClick={handleShowWhatWasSaid}
          disabled={isGenerating}
          icon={<VideoCameraOutlined />}
        >
          Watch what was said
        </Button>
      </ActionsContainer>
    </Container>
  );
};
