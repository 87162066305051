import React from 'react';
import { Participant, ParticipantTalkDataContainer, ProgressBar } from './styles';
import { Avatar, Space } from 'antd';

interface Props {
  speaker: string;
  ratio: number;
}

export const ParticipantTalkData: React.FC<Props> = props => {
  return (
    <ParticipantTalkDataContainer>
      <Space style={{ flex: 1 }}>
        <Avatar>{props.speaker[0].toUpperCase()}</Avatar>
        <Participant>{props.speaker}</Participant>
      </Space>
      <ProgressBar percent={props.ratio} />
    </ParticipantTalkDataContainer>
  );
};
