import React, { MouseEvent } from 'react';
import Styles, { OrgOptions, OtherOptions, RecapOptions, VisibilityOption } from './styles';
import { MoreOutlined, StarOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Flex, Space } from 'antd';
import { RemoveRecap } from '../RemoveRecap';
import { Link } from '../../../shared/components/Icons/Link';
import { useToast } from '../../../../hooks/useToast';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { EventVisibility } from '../../../recapv2/types';
import check from '../../../../assets/svg/green-check.svg';
import { useLibraries } from '../../../libraries/hooks/useLibraries';

const { CopyLink, MenuHeader, CopyContainer } = Styles;

export const VisibilityConfig: React.FC = () => {
  const { success } = useToast();
  const { manageInsight } = useLibraries();
  const {
    workspaceVisibility,
    privateLink,
    isBasic,
    permissions,
    changeWorkspaceVisibility,
    status,
    // excludeRecapFromInsights,
    // includeRecapFromInsights,
    id,
  } = useRecapManagement();

  const handleCopyOrganizationLink = (ev: MouseEvent<HTMLSpanElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    navigator.clipboard.writeText(privateLink);
    success('Organization share link copied to clipboard');
  };

  const handleOrganizationVisibilityClicked = (
    ev: MouseEvent<HTMLDivElement>,
    visibility: EventVisibility,
  ) => {
    ev.preventDefault();
    ev.stopPropagation();
    changeWorkspaceVisibility(visibility);
  };

  // const handleIncludeChange = (included: boolean) => {
  //   if (!included) {
  //     excludeRecapFromInsights();
  //   } else {
  //     includeRecapFromInsights();
  //   }
  // };

  const items: any[] = [
    {
      label: <RecapOptions>Recap options</RecapOptions>,
      key: 'recap-options',
      disabled: true,
    },
    {
      label: <Divider style={{ margin: '0px', cursor: 'default' }} />,
      key: 'org-div-vis-1',
      disabled: true,
    },
    {
      label: (
        <MenuHeader>
          <OtherOptions>Org. Workspace View</OtherOptions>
          <CopyContainer>
            <Link />
            <CopyLink
              data-testid="copy-organization-share-link"
              onClick={handleCopyOrganizationLink}
            >
              Copy internal link
            </CopyLink>
          </CopyContainer>
        </MenuHeader>
      ),
      key: 'org-vis',
      disabled: false,
    },
  ];

  if (permissions.canManageWorkspaceVisibility) {
    items.push({
      label: <Divider style={{ margin: '0px', cursor: 'default' }} />,
      key: 'org-div-vis-2',
      disabled: true,
    });

    items.push({
      label: (
        <VisibilityOption>
          <OrgOptions
            selected={workspaceVisibility === EventVisibility.Invitees}
            onClick={ev => handleOrganizationVisibilityClicked(ev, EventVisibility.Invitees)}
          >
            <>Invitees Only</>
            {workspaceVisibility === EventVisibility.Invitees && <img src={check} />}
          </OrgOptions>
        </VisibilityOption>
      ),
      key: 'org-inv',
    });

    items.push({
      label: (
        <VisibilityOption>
          <OrgOptions
            selected={workspaceVisibility === EventVisibility.Organization}
            onClick={ev => handleOrganizationVisibilityClicked(ev, EventVisibility.Organization)}
          >
            <>Organization-wide</>
            {workspaceVisibility === EventVisibility.Organization && <img src={check} />}
          </OrgOptions>
        </VisibilityOption>
      ),
      key: 'org-org',
    });
  }

  if (permissions.canFavoriteRecap && !isBasic) {
    items.push({
      label: <Divider style={{ margin: '0px', cursor: 'default' }} />,
      key: 'org-div-vis-x',
      disabled: true,
    });

    items.push({
      label: (
        <Flex gap={8} style={{ padding: '0px 8px' }} onClick={() => manageInsight(id, 'recap')}>
          <StarOutlined />
          Favorite Recap
        </Flex>
      ),
      key: 'favorite-recap',
      disabled: status !== 'READY',
    });
  }

  if (permissions.canDeleteRecap && !isBasic) {
    items.push({
      label: <Divider style={{ margin: '0px', cursor: 'default' }} />,
      key: 'org-div-vis-3',
      disabled: true,
    });

    items.push({
      label: <RemoveRecap />,
      key: 'delete-recap',
      disabled: false,
    });
  }

  return (
    <div id="appcues-recap-demo-step-5">
      <Dropdown
        overlayStyle={{ width: '360px' }}
        menu={{
          items: items,
        }}
      >
        <Button data-testid="recap-more-trigger" type="default" icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  );
};
