import { PropsWithChildren, ReactNode } from 'react';
import Styles from './styles';
const { Container, Content } = Styles;

interface Props {
  title: string;
  action?: ReactNode;
}

export const Box: React.FC<PropsWithChildren<Props>> = ({ title, children, action }) => {
  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
        {action}
      </div>
      <Content>{children}</Content>
    </Container>
  );
};
