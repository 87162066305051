import { useContext } from 'react';
import { analyticsContext } from '../analytics.context';
import { Settings } from '../../user/types';

export const useAnalytics = () => {
  const { sendEvent, alias, reset } = useContext(analyticsContext);
  return {
    sendEvent,
    alias,
    reset,
    page: {
      viewed: (pageName: string) => {
        sendEvent('Viewed', {
          event: {
            name: pageName,
          },
        });
      },
    },
    mobile: {
      viewed: () => {
        sendEvent('mobile_construction_view');
      },
    },
    signUp: {
      viewed: () => {
        sendEvent('sign_up_screen_view');
      },
    },
    settings: {
      updated: (settings: Settings) => {
        sendEvent('Settings Updated', {
          people: {
            recording_settings_internal_meetings_hosted: String(
              settings.internalMeetingsRecording.record,
            ),
            recording_settings_internal_meetings_not_hosted: String(
              settings.internalMeetingsRecording.sendBotIfNoHost,
            ),
            recording_settings_external_meetings_hosted: String(
              settings.externalMeetingsRecording.record,
            ),
            recording_settings_external_meetings_not_hosted: String(
              settings.externalMeetingsRecording.sendBotIfNoHost,
            ),
          },
        });
      },
    },
  };
};
