import { getDay, getHours, getMinutes, getMonth, getDate, getYear, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { twoDigitNumber } from './TextHelper';
import { format, parseISO } from 'date-fns';

export enum days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

const dateInfo = (date: Date) => {
  return {
    hour: `${twoDigitNumber(getHours(date).toString())}:${twoDigitNumber(
      getMinutes(date).toString(),
    )}`,
    monthDay: twoDigitNumber(getDate(date).toString()),
    weekDay: days[getDay(date)],
    month: months[getMonth(date)],
    year: getYear(date),
  };
};

export const to12Hour = (hourString: string) => {
  const hours = Number(hourString.split(':')[0]);
  const minutes = Number(hourString.split(':')[1]);

  const AmOrPm = hours >= 12 ? 'PM' : 'AM';
  return `${hours % 12}:${minutes}${AmOrPm}`;
};

export const recapFormatDate = (start: string, end?: string): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const started = utcToZonedTime(new Date(start), timeZone);
  const startedInfo = dateInfo(started);
  const { hour, monthDay, weekDay, month, year } = startedInfo;

  if (end) {
    const ended = utcToZonedTime(new Date(end), timeZone);
    const endedInfo = dateInfo(ended);

    if (isSameDay(started, ended)) {
      // Output format: Wednesday, August 04, 2021 09:30 - 10:30
      return `${weekDay}, ${month} ${monthDay}, ${year} ${to12Hour(hour)} - ${to12Hour(
        endedInfo.hour,
      )}`;
    } else {
      // Output format: Wed, August 04 09:30 - Thu, August 05, 2021 10:30
      return `${startedInfo.weekDay.slice(0, 3)}, ${startedInfo.month} ${startedInfo.monthDay} 
              ${to12Hour(startedInfo.hour)} - ${endedInfo.weekDay.slice(0, 3)}, ${endedInfo.month} 
              ${endedInfo.monthDay}, ${endedInfo.year} ${to12Hour(endedInfo.hour)}`;
    }
  } else {
    // Then it is a live event.
    // Output format: Wednesday, August 04, 2021 09:30 - Now
    return `${weekDay}, ${month} ${monthDay}, ${year} ${to12Hour(hour)} - Now`;
  }
};

export const recapFormatDateShort = (startTime: string, endTime: string): string => {
  const startDate = parseISO(startTime);
  const endDate = parseISO(endTime);

  const formattedStartDate = format(startDate, 'eeee, MMMM d, yyyy h:mma');
  const formattedEndDate = format(endDate, 'h:mma');

  // if it ends the next day (which will not happen that often) this will return something like Friday, June 23, 2023 11:00PM - 01:00AM (and it is implied that ended the next day)
  return `${formattedStartDate} - ${formattedEndDate}`;
};
