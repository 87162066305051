import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: relative;
  background-color: #eff2f7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FrameContainer = styled.div`
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: #ffffff;
  position: relative;
`;

export const Description = styled.p`
  font-style: normal;
  color: #445260;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Footer = styled.p`
  font-style: normal;
  color: #000000;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const MobileTitle = styled.p`
  font-style: normal;
  font-family: Cera Pro;
  color: #030c11;
  margin: 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  font-size: 20px;
  width: 100%;
  text-align: center;
`;

export const Logo = styled.img`
  height: 38px;
  width: 157px;
  left: 81px;
  top: 41px;
  position: absolute;
  cursor: pointer;
`;

export const MobileLogo = styled.img`
  max-width: 250px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogOut = styled.div`
  right: 81px;
  top: 41px;
  position: absolute;
  cursor: pointer;
`;

export const Error = styled.div<{ animationTime: number }>`
  background: #ffcaca;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c81a24;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 54px;
  bottom: 0;
  border-radius: 0px 0px 20px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  animation: fadeIn 500ms ease-in,
    fadeOut 500ms
      ${({ animationTime }) => {
        return animationTime - 500 + 'ms';
      }}
      ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate3d(0, 54px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0, 54px, 0);
    }
  }
`;

export const LockScreen = styled.span<{ show: boolean }>`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const MagicWand = styled.img`
  width: 106px;
  position: absolute;
  top: 0;
  left: 300px;
`;
