import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div<{ color: string; size?: string }>`

  display: flex;
  position: relative;
  width: ${props => (props.size === 'sm' ? '20px' : '30px')};
  height: ${props => (props.size === 'sm' ? '20px' : '30px')};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => (props.size === 'sm' ? '18px' : '32px')};
    height: ${props => (props.size === 'sm' ? '18px' : '32px')};
    border: ${props => (props.size === 'sm' ? '3px' : '8px')} solid #fff;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes Container {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;




