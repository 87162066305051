import { Badge } from 'antd';
import styled from 'styled-components';
import { mediaSize } from '../../../../../theme/media';

const ClearLabel = styled.span<{ disabled: boolean }>`
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
  font-size: 14px;
  line-height: 21px;
  color: ${props => (props.disabled ? '#cdd3db' : '#FF6C54')};
  transition: color 0.2s;
`;

const AccountsSelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 8px;
`;

const Count = styled(Badge)`
  color: white;
  margin-left: 8px;
`;

const CountBadge = styled.span`
  width: 20px;
  height: 20px;
  background: #ff6c54;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AccountContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Account = styled.span<{ isSet: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #0a61ca;
  padding: 4px;
  cursor: ${props => (props.isSet ? 'default' : 'pointer')};
  min-width: 96px;
  justify-content: center;

  @media (max-width: ${mediaSize.laptop}) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 20px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: var(--new-color-shades-gray-950, #282828);
  font-family: Cera Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
`;

const SubContent = styled.div`
  color: var(--new-color-shades-gray-600, #818181);
  /* Text sm/Regular */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Circle = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--new-color-shades-primary-100, #fff0ee);
`;

export default {
  Header,
  Circle,
  Content,
  SubContent,
  ClearLabel,
  AccountsSelectedContainer,
  Account,
  Count,
  CountBadge,
  AccountContainer,
};
