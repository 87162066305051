import styled from 'styled-components';

export const PublicVisibilityContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    & > * {
      color: black;
    }

    .anticon > svg > path {
      fill: black !important;
    }
  }
`;

export const VisibilityLabel = styled.span`
  color: var(--cool-gray-300, #9ea9b1);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
  text-transform: capitalize;
`;
