import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Styles from './styles';
import { Button, Input, Space, Tag, Tooltip } from 'antd';
import { Table } from '../../../../../../componentsV2/Table';
import { useInsightCategoriesManagement } from '../../../../../../features/insights/hooks/useInsightCategoriesManagement';
import { useToast } from '../../../../../../hooks/useToast';
import { useInsightCategories } from '../../../../../../features/insights/hooks/useInsightCategories';
import { EditableCell } from '../../../../../../componentsV2/Table/components/EditableCell';
import { categoriesMetadata } from './utils';
import { CategoryContext } from './components/CategoryContext';

const { ActionsContainer } = Styles;

export const CategoriesTable: React.FC = () => {
  const {
    categories,
    changeName,
    changeDescription,
    startDescriptionEditing,
    confirmNameChange,
    confirmDescriptionChange,
    startNameEditing,
    confirmChanges,
    remove,
    isSaving,
  } = useInsightCategoriesManagement();
  const { isFetching, type } = useInsightCategories();
  const { error } = useToast();

  const handleConfirm = (id: string) => {
    confirmChanges(id, valid => {
      if (!valid) {
        error(`Category must have a name`);
      }
    });
  };

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: '0px' }}>
      <Table
        loading={isFetching || isSaving}
        data={categories}
        pagination={false}
        columns={[
          {
            title: 'name',
            width: '180px',
            key: 'name',
            render: (value, record, index) => {
              const isEditing = (record.isEditingTitle || record.isNew) && record.mutable;
              const isMutable = record.mutable;
              const hasDefinedDescription =
                categoriesMetadata[type] && categoriesMetadata[type][record.name];
              const tooltip = hasDefinedDescription
                ? `Category Description\n\n${
                    categoriesMetadata[type][record.name].description
                  }\n\nRecommended Custom Context\n\n${
                    categoriesMetadata[type][record.name].customContext
                  }`
                : undefined;
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <EditableCell
                    inputType="input"
                    width={'180px'}
                    help={
                      hasDefinedDescription ? (
                        <CategoryContext type={type} category={record.name} />
                      ) : undefined
                    }
                    editing={isEditing}
                    isEditable={isMutable}
                    onChange={text => changeName(record.id, text)}
                    onEdit={() => startNameEditing(record.id)}
                    onEnter={() => confirmNameChange(record.id)}
                    value={record.name}
                  />
                </div>
              );
            },
          },
          {
            title: 'customized context',
            dataIndex: 'description',
            width: '250px',
            key: 'description',
            render: (value, record, index) => {
              const isEditing = record.isNew || record.isEditingDescription;
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <EditableCell
                    inputType="textarea"
                    width={'250px'}
                    editing={isEditing}
                    isEditable={true}
                    onChange={text => changeDescription(record.id, text)}
                    onEdit={() => startDescriptionEditing(record.id)}
                    onEnter={() => confirmDescriptionChange(record.id)}
                    noValueText="Add context to enhance detection"
                    value={record.description}
                  />
                </div>
              );
            },
          },
          {
            width: '80px',
            key: 'actions',
            render: (value, record, index) => {
              return (
                <ActionsContainer>
                  {!record.mutable ? (
                    <Tooltip title="Benchmark categories are industry standards and cannot be removed.">
                      <Tag color="volcano">Benchmark</Tag>
                    </Tooltip>
                  ) : (
                    <>
                      {' '}
                      {record.isNew && (
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => handleConfirm(record.id)}
                          icon={<CheckOutlined />}
                        />
                      )}
                      <Button
                        onClick={() => remove(record.id)}
                        size="small"
                        type="default"
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </>
                  )}
                </ActionsContainer>
              );
            },
          },
        ]}
      />
    </Space>
  );
};
