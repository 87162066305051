import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const InsightIconSVG = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 9C19.55 9 18.74 10.44 19.07 11.51L15.52 15.07C15.22 14.98 14.78 14.98 14.48 15.07L11.93 12.52C12.27 11.45 11.46 10 10 10C8.55 10 7.73 11.44 8.07 12.52L3.51 17.07C2.44 16.74 1 17.55 1 19C1 20.1 1.9 21 3 21C4.45 21 5.26 19.56 4.93 18.49L9.48 13.93C9.78 14.02 10.22 14.02 10.52 13.93L13.07 16.48C12.73 17.55 13.54 19 15 19C16.45 19 17.27 17.56 16.93 16.48L20.49 12.93C21.56 13.26 23 12.45 23 11C23 9.9 22.1 9 21 9Z"
        fill="#FF6C54"
      />
      <path
        d="M15 10L15.94 7.93L18 7L15.94 6.07L15 4L14.08 6.07L12 7L14.08 7.93L15 10Z"
        fill="#FF6C54"
      />
      <path d="M3.5 12L4 10L6 9.5L4 9L3.5 7L3 9L1 9.5L3 10L3.5 12Z" fill="#FF6C54" />
    </svg>
  );
};
export const InsightIcon: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={InsightIconSVG} {...props} />;
};
