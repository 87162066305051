import React, { KeyboardEvent, useEffect, useState } from 'react';
import Styles from './styles';
import Label from '../../../../features/shared/components/Label';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../redux/slices/sessionSlice';
import { selectSessionStatus } from '../../../../redux/selectors';
import { useToast } from '../../../../hooks/useToast';

const { Link, FormInputsContainer, RegisterContainer, FormItem, ForgotPasswordContainer } = Styles;

interface Props {
  onBack: () => unknown;
}

const LoginForm: React.FC<Props> = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { error: errorToast } = useToast();
  const { isLogin, error } = useSelector(selectSessionStatus);

  useEffect(() => {
    if (error) {
      errorToast('User or password incorrect');
    }
  }, [error]);

  const loginUser = () => {
    dispatch(login({ email, password }));
  };

  const onKeyPress = (ev: KeyboardEvent) => {
    // Still using keyCode on purpose for compatibility
    if (ev.key === 'Enter' || ev.keyCode === 13) loginUser();
  };

  return (
    <AuthenticationForm
      onBack={onBack}
      backText="Back to options"
      title="Login using email"
      description="Use update.ai using your own credentials"
    >
      <FormInputsContainer>
        <Form layout="vertical">
          <FormItem label="Email address">
            <Input onChange={ev => setEmail(ev.target.value)} />
          </FormItem>
          <FormItem label="Password">
            <Input
              style={{ letterSpacing: '3px' }}
              onChange={ev => setPassword(ev.target.value)}
              onKeyDown={onKeyPress}
              type="password"
            />
          </FormItem>
          <FormItem label="">
            <ForgotPasswordContainer>
              <Link href="/password/recover">Forgot your password?</Link>
            </ForgotPasswordContainer>
          </FormItem>
        </Form>
        <Button loading={isLogin} onClick={loginUser} type="primary" style={{ width: '100%' }}>
          Login
        </Button>
        <RegisterContainer>
          <Label>Don't have an account?</Label>
          <Link href="/signup">Register here.</Link>
        </RegisterContainer>
      </FormInputsContainer>
    </AuthenticationForm>
  );
};

export default LoginForm;
