import styled from 'styled-components';
import { tokens } from './config';
import { AlertVariant } from './types';

const Container = styled.div<{
  variant: AlertVariant;
  padding?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid ${props => tokens[props.variant].borderColor};
  background-color: ${props => tokens[props.variant].backgroundColor};
  padding: 9px 16px;
  gap: 16px;
  border-radius: 8px;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};

  .alert-title {
    color: ${props => tokens[props.variant].highlightColor};
  }

  .alert-message {
    color: ${props => tokens[props.variant].color};
  }

  .alert-secondary-action {
    color: ${props => tokens[props.variant].color};
  }

  .alert-primary-action {
    color: ${props => tokens[props.variant].highlightColor};
  }

  .close-icon {
    fill: ${props => tokens[props.variant].borderColor};
    color: ${props => tokens[props.variant].color};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  margin: 0px;
  padding: 0px;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const Action = styled.div`
  cursor: pointer;
  font-size: 14px;
`;

const PrimaryAction = styled(Action)`
  font-weight: 500;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 14px;
`;

const CloseContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export default {
  Container,
  Title,
  Message,
  PrimaryAction,
  Action,
  MessageContainer,
  ActionsContainer,
  CloseContainer,
};
