/* eslint-disable @typescript-eslint/no-explicit-any */
import 'reflect-metadata';
import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { initStore } from './redux/store';
import Hotjar from '@hotjar/browser';
import React from 'react';

declare global {
  interface Window {
    analytics: any;
    thena: any;
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-5J7VB55',
};

TagManager.initialize(tagManagerArgs);

const hotjarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = 6;

Hotjar.init(hotjarSiteId, hotjarVersion);

ReactDOM.render(
  <Provider store={initStore()}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
