/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren } from 'react';

import Styles from './styles';
import { Modal } from '../../../../componentsV2/Modal';
const { Container } = Styles;

interface Props {
  open: boolean;
  label: string;
  onClose: (v: boolean) => void;
}

export const ViewAllModal: React.FC<PropsWithChildren<Props>> = ({
  children,
  open,
  label,
  onClose,
}) => {
  return (
    <Modal hideFooter open={open} width={1296} title={label} onClose={() => onClose(false)}>
      <Container>{children}</Container>
    </Modal>
  );
};
