import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NoDataContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0px;

  background: #ffffff;
  border-radius: 8px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 0px;
`;

const Title = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
`;

const Detail = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6a6a6a;
`;

export default { Container, NoDataContainer, Message, Title, Detail };
