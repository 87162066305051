import { useContext, useMemo } from 'react';
import { summarizeInsightsContext } from './summarize-insights.context';
import { useStore } from 'zustand';

import { coreService } from '../../../services/core/core-service';
import { pick } from 'lodash';
import { SummarizeInsightsArgs } from './types';
import { format } from 'date-fns';

export const useSummarizeInsights = () => {
  const { store } = useContext(summarizeInsightsContext);
  const {
    isSummarizing,
    isSummarizingStatusChanged,
    resultGenerated,
    result,
    additionalNote,
    additionalNoteModified,
    reset,
    show,
    openModal,
    setDetails,
    addInsight,
    removeInsight,
    insights,
    details,
  } = useStore(store, store => store);

  const summarize = (args: SummarizeInsightsArgs) => {
    setDetails(pick(args, ['title', 'account', 'host', 'date']));
    openModal();
    isSummarizingStatusChanged(true);

    const payload = insights.reduce(
      (previousValue: { events: { id: string; insightIds: string[] }[] }, currentValue) => {
        let event = previousValue.events.find(event => event.id === currentValue.eventId);
        if (!event) {
          event = { id: currentValue.eventId, insightIds: [currentValue.id] };
          previousValue.events.push(event);
        } else {
          event.insightIds.push(currentValue.id);
        }
        return previousValue;
      },
      { events: [] },
    );

    coreService
      .summarizeInsights(payload)
      .then(result => resultGenerated(result))
      .finally(() => isSummarizingStatusChanged(false));
  };

  const close = () => {
    reset();
  };

  const resultParsed = useMemo(() => {
    if (!result) {
      return null;
    }

    const references = result.references.map((insightId, index) => {
      const insight = insights.find(insight => insight.id === insightId);
      return {
        text: `[${index + 1}] ${insight?.text}`,
        url: `https://portal.update.ai/recap/${insight?.eventId}?insightId=${insightId}`,
      };
    });

    return {
      summary: result.summary,
      references,
    };
  }, [result, insights]);

  const resultAsText = useMemo(() => {
    if (!details || !resultParsed) {
      return;
    }

    const { title, host, account, date } = details;

    let message = `Title: ${title} - ${format(new Date(date), 'MMMM dd, yyyy')}\n`;
    message += `Host: ${host}\n`;
    message += `Account: ${account}\n\npowered by www.update.ai\n\n~~\n`;
    message += `🧠 Smart Summary™️\n\n${resultParsed.summary}\n\n~~\n`;
    message += `📓 Reference Moments from the Smart Summary™️\n\n`;

    message += `${resultParsed.references
      .map(reference => `${reference.text}\n(${reference.url})`)
      .join('\n\n')}\n\n~~\n`;
    message += `📓 Additional Notes:\n${additionalNote}\n\n~~\n`;
    return message;
  }, [resultParsed, additionalNote]);

  const insightsCount = useMemo(() => insights.length, [insights]);

  return {
    summarize,
    isSummarizing,
    additionalNote,
    additionalNoteModified,
    insightsCount,
    show,
    close,
    resultAsText,
    addInsight,
    details,
    removeInsight,
    result,
    insights,
  };
};
