import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const IconSVG = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="11.5" stroke="#EE4E4E" />
      <circle cx="15" cy="15" r="14.5" stroke="#FBA6A6" />
      <g clipPath="url(#clip0_2_96)">
        <path
          d="M15 11.6667V15M15 18.3334H15.0084M23.3334 15C23.3334 19.6024 19.6024 23.3334 15 23.3334C10.3976 23.3334 6.66669 19.6024 6.66669 15C6.66669 10.3976 10.3976 6.66669 15 6.66669C19.6024 6.66669 23.3334 10.3976 23.3334 15Z"
          stroke="#B71E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_96">
          <rect width="20" height="20" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Error: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={IconSVG} {...props} />;
};
