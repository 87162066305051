import { UpgradeToPro } from '../../../../../componentsV2/UpgradeToPro';
import { Button } from 'antd';
import { ClearOutlined, CopyFilled, SendOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useToast } from '../../../../../hooks/useToast';
import { useAskMeAnything } from '../../../../askMeAnything/hooks/useAskMeAnything';
import { ChatItem } from '../../../../../services/types';
import { MessagesList } from './MessagesList';
import useFeatures from '../../../../../hooks/useFeatures';

import Styles from './styles';
const { ChatContainer, ChatInput, ChatActionsContainer, Chat, OneLinerContainer } = Styles;

interface Props {
  askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
  location: string;
}

export const AskMeAnythingChat2: React.FC<Props> = ({ askFunction, location }) => {
  const { success } = useToast();
  const { messages, isAsking, ask, clearMessages, copyAll } = useAskMeAnything();

  const noMessages = messages.length === 0;

  const features = useFeatures();
  const availableFeature = features.ama;

  const [question, setQuestion] = useState('');

  const sendMessage = () => {
    if (!isAsking) {
      ask({ question: question, askFunction: askFunction, location: location });
      setQuestion('');
    }
  };

  const handleCopyAll = () => {
    copyAll();
    success('Messages copied to clipboard');
  };

  return (
    <UpgradeToPro plan="pro" display={!availableFeature}>
      <Chat>
        <MessagesList messages={messages} />

        <ChatActionsContainer>
          <OneLinerContainer>
            <Button
              disabled={noMessages}
              icon={<CopyFilled />}
              onClick={handleCopyAll}
              style={{ borderRadius: '8px' }}
            >
              Copy All
            </Button>

            <Button
              disabled={noMessages}
              icon={<ClearOutlined />}
              onClick={clearMessages}
              style={{ borderRadius: '8px' }}
            >
              Clear Chat
            </Button>
          </OneLinerContainer>

          <ChatContainer>
            <ChatInput
              value={question}
              disabled={isAsking}
              onKeyDown={ev => {
                if (ev.key === 'Enter' && !ev.shiftKey) {
                  ev.stopPropagation();
                  ev.preventDefault();
                  sendMessage();
                }
              }}
              onChange={ev => {
                setQuestion(ev.target.value);
              }}
              placeholder="Enter your prompt..."
            />

            <Button
              onClick={sendMessage}
              icon={<SendOutlined />}
              size="small"
              style={{
                borderRadius: '8px',
                position: 'absolute',
                right: '16px',
                top: '8px',
                padding: '0',
              }}
              disabled={!question || isAsking}
              type="primary"
              loading={isAsking}
            />
          </ChatContainer>
        </ChatActionsContainer>
      </Chat>
    </UpgradeToPro>
  );
};
