import { useState } from 'react';
import { useToast } from '../../../../../../hooks/useToast';
import { ZoomTestResults } from '../../../../../../API/types';
import { coreAPI } from '../../../../../../API/core';

const lastTestFormat = (testResults?: ZoomTestResults) => {
  return `Last tested on:  ${
    testResults?.timestamp ? new Date(testResults.timestamp).toLocaleTimeString() : 'Not tested'
  }`;
};

export const useZoomTest = () => {
  const { error } = useToast();
  const [loading, setLoading] = useState(false);
  const [testResults, setTestResults] = useState<ZoomTestResults>();
  const [lastSync, setLastSync] = useState(lastTestFormat(testResults));

  const handleTestZoomIntegration = async () => {
    const api = new coreAPI();
    setLoading(true);
    try {
      const result = await api.testZoomIntegration();
      setTestResults(result);
      setLastSync(lastTestFormat(result));
    } catch (e) {
      error((e as Error).message);
    }
    setLoading(false);
  };

  return {
    loading,
    testResults,
    test: handleTestZoomIntegration,
    lastSync,
  };
};
