import styled from 'styled-components';
import { colors } from '../../theme/colors';

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
`;

const Heading = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #d2d2d2;
`;

const Header = styled.span`
  color: ${colors.coolGray['500']};
  font-family: 'Cera Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
`;

const Contents = styled.div`
  padding: 24px 16px;
`;

export default {
  Container,
  Heading,
  Header,
  Contents,
};
