import React, { CSSProperties, ReactNode } from 'react';
import Styles from './style';

const { ContainerHeader, Title, SubTitle } = Styles;

interface Props {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  titleStyle?: CSSProperties;
  subtitleStyle?: CSSProperties;
}

export const CardHeader: React.FC<Props> = ({ title, titleStyle, subtitle, subtitleStyle }) => {
  return (
    <ContainerHeader>
      {title && <Title style={{ ...titleStyle }}>{title}</Title>}
      {subtitle && <SubTitle style={{ ...subtitleStyle }}>{subtitle}</SubTitle>}
    </ContainerHeader>
  );
};
