import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';

const useThena = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    const init = async () => {
      if (user?.email) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.thena?.setEmail({
          email: user?.email,
          hashedEmail: user?.email, // the hashed email returned by the hmac function
        });
      }
    };

    init();
  }, []);
};

export default useThena;
