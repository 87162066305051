import React, { useEffect, useState } from 'react';
import { ButtonTabPaneContainer } from './styles';
import { ButtonTab } from './ButtonTab';

interface Props {
  defaultActiveKey?: string;
  selectedKey: string;
  onKeyChanged: (key: string) => unknown;
  items: {
    label: string;
    key: string;
  }[];
}

export const ButtonTabPane: React.FC<Props> = ({
  defaultActiveKey,
  selectedKey,
  items,
  onKeyChanged,
}) => {
  const [activeKey, setActiveKey] = useState<string>('');

  useEffect(() => {
    if (defaultActiveKey) {
      const existingItem = items.find(item => item.key === defaultActiveKey);
      if (existingItem) setActiveKey(defaultActiveKey);
    } else {
      const firstItem = items[0];
      setActiveKey(firstItem.key);
    }
  }, []);

  /*
  useEffect(() => {
    onKeyChanged(activeKey);
  }, [activeKey]);
  */

  const handleKeyChanged = (itemKey: string) => {
    onKeyChanged(itemKey);
  };

  useEffect(() => {
    setActiveKey(selectedKey);
  }, [selectedKey]);

  return (
    <ButtonTabPaneContainer>
      {items.map(item => {
        return (
          <ButtonTab
            key={item.key}
            active={item.key === activeKey}
            label={item.label}
            onClick={() => handleKeyChanged(item.key)}
          />
        );
      })}
    </ButtonTabPaneContainer>
  );
};
