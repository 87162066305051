import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const AccountContainer = styled.div`
  display: flex;
  gap: 1px;
  flex: 1;
`;

export default {
  Container,
  AccountContainer,
};
