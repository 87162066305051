import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 16px 0px 16px;
  width: 100%;
`;

const Title = styled.div`
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const TagsList = styled.div`
  display: flex;
  gap: 3px;
  width: 100%;
  flex-flow: wrap;
`;

export default { Content, Title, TagsList };
