import styled from 'styled-components';

const Label = styled.span<{ color?: string }>`
  font-size: 14px;
  color: ${props => (props.color ? props.color : '#282828')};
`;

export default {
  Label,
};
