import { CheckCircleFilled, ExclamationCircleFilled, SettingFilled } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { EventStatus } from '../../../../../../types/commonTypes';
import React from 'react';
import Styles from './styles';

const { Container } = Styles;

interface StatusConfiguration {
  color: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  backgroundColor: string;
}

const defaultStatus: StatusConfiguration = {
  icon: ExclamationCircleFilled,
  color: '#21A6FF',
  backgroundColor: '#cae9fd',
};

const statusConfig: { [k: string]: StatusConfiguration } = {
  READY: {
    icon: CheckCircleFilled,
    color: '#16B380',
    backgroundColor: '#EDFCF5',
  },
  DID_NOT_START: {
    icon: ExclamationCircleFilled,
    color: '#F77272',
    backgroundColor: '#FEF2F2',
  },
  NOT_AVAILABLE: {
    icon: ExclamationCircleFilled,
    color: '#F77272',
    backgroundColor: '#FEF2F2',
  },
  SKIPPED: {
    icon: ExclamationCircleFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  FINISHED: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  PROCESSING: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  LIVE: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
};

const statusLabel: { [k: string]: string } = {
  READY: 'Ready',
  SKIPPED: 'Skipped',
  DID_NOT_START: 'Not started',
  FINISHED: 'Processing',
  LIVE: 'Live',
};

const subStatusLabel: { [k: string]: string } = {
  TRANSCRIBED: 'Transcription Ready',
  LIMITED: 'Limited Dialogue',
  CLOUD_RECORDING_DISABLED: 'Recording disabled',
  WAITING_FILES: 'Processing',
  FILES_READY: 'Processing',
  AI_GENERATED: 'Processing',
  TRANSCRIPTION_ERROR: 'Processing Error',
  NOT_RECORDED: 'Not Recorded',
  PARTICIPANT_AUDIO_FILES_DISABLED: 'Audio Files N.A',
  NO_PARTICIPANT_FILES: 'Audio Files N.A.',
  PROCESSING_ERROR: 'Processing Error',
};

const tooltips: { [k: string]: string } = {
  TRANSCRIBED: 'The meeting transcription is available. The Smart Summary™️ is not yet available.',
  LIMITED: 'There was not enough dialogue to generate a Smart Summary™️ of this meeting.',
  CLOUD_RECORDING_DISABLED:
    "Unable to generate a meeting recap because host's Zoom cloud recording was disabled.",
  WAITING_FILES: 'The meeting recap is being processed.',
  FILES_READY: 'The meeting recap is being processed.',
  AI_GENERATED: 'The meeting recap is being processed.',
  TRANSCRIPTION_ERROR: 'There was an error processing this meeting recap.',
  NOT_RECORDED: 'This meeting was not recorded.',
  PARTICIPANT_AUDIO_FILES_DISABLED:
    'Unable to generate a meeting recap because Zoom participant audio files were not available.',
  NO_PARTICIPANT_FILES:
    'Unable to generate a meeting recap because Zoom participant audio files were not available.',
  PROCESSING_ERROR: 'There was an error processing this meeting recap.',
};

const getTooltip = (subStatus: string) => {
  const tooltipText = tooltips[subStatus];
  if (!tooltipText) {
    return undefined;
  }

  return tooltipText;
};

const getLabel = (status: string, subStatus: string) => {
  const labelStatus = statusLabel[status];
  const labelSubStatus = subStatusLabel[subStatus];
  return labelStatus || labelSubStatus || 'Unknown';
};

export const getStatusConfig = (
  status: string,
  subStatus: string,
): StatusConfiguration & { tooltip: string | undefined } => {
  const config = statusConfig[status];
  const tooltip = getTooltip(subStatus);

  if (config) {
    return { ...config, tooltip };
  }

  return {
    ...defaultStatus,
    tooltip,
  };
};

interface Props {
  status: string;
  subStatus: string;
}

const RecapStatus: React.FC<Props> = ({ status, subStatus }) => {
  const configuration = getStatusConfig(status, subStatus);

  const openHelp = () => {
    window.open(
      'https://help.update.ai/en/articles/6716426-what-is-the-status-of-my-meeting-recap-and-why-i-am-unable-to-access-it',
      '_blank',
    );
  };

  const tooltipTitle = configuration.tooltip ? (
    <Space direction="vertical">
      <span>{configuration.tooltip}</span>
      <a
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          openHelp();
        }}
      >
        Learn more
      </a>
    </Space>
  ) : status === EventStatus.DID_NOT_START ? (
    <>
      <a
        onClick={() => {
          window.open(
            'https://help.update.ai/en/articles/8288155-important-update-resolving-the-not-started-meeting-issue-in-your-dashboard',
            '_blank',
          );
        }}
      >
        Important announcement
      </a>{' '}
      around ‘Not Started’ meetings.
    </>
  ) : undefined;

  return (
    <Tooltip title={tooltipTitle}>
      <Container>
        <span style={{ color: configuration.color }}>{getLabel(status, subStatus)}</span>
      </Container>
    </Tooltip>
  );
};

export default RecapStatus;
