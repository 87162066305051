import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Label = styled.span`
  color: ${colors.coolGray[400]};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 350px;
  width: 100%;
  gap: 15px;
`;

export default {
  Label,
  Container,
};
