import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const LogoutSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.125 7.5V4.375C13.125 3.87772 12.9275 3.40081 12.5758 3.04917C12.2242 2.69754 11.7473 2.5 11.25 2.5H6.25C5.75272 2.5 5.27581 2.69754 4.92417 3.04917C4.57254 3.40081 4.375 3.87772 4.375 4.375V15.625C4.375 16.1223 4.57254 16.5992 4.92417 16.9508C5.27581 17.3025 5.75272 17.5 6.25 17.5H11.25C11.7473 17.5 12.2242 17.3025 12.5758 16.9508C12.9275 16.5992 13.125 16.1223 13.125 15.625V12.5M15.625 12.5L18.125 10M18.125 10L15.625 7.5M18.125 10H7.5"
        stroke="#9EA9B1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Logout: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={LogoutSVG} {...props} />;
};
