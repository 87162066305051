import React from 'react';
import { Container, Email, Name, NameAndEmail } from './style';
import Avatar from '../../../../../../../../features/shared/components/Avatar';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const NameCell: React.FC<Props> = ({ firstName, lastName, email }) => {
  const letter = firstName ? firstName[0].toUpperCase() : '';
  return (
    <Container>
      <Avatar size={'large'}>{letter}</Avatar>
      <NameAndEmail>
        <Name>{`${firstName} ${lastName}`}</Name>
        <Email>{email}</Email>
      </NameAndEmail>
    </Container>
  );
};

export default NameCell;
