import { useContext } from 'react';
import { analyticsContext } from '../../../analytics/analytics.context';

export interface ILearningCenter {
  home: {
    viewed: () => void;
  };
}

export const useLearningCenterAnalytics = (): ILearningCenter => {
  const { sendEvent } = useContext(analyticsContext);

  return {
    home: {
      viewed: () => {
        sendEvent('Learning Center Viewed');
      },
    },
  };
};
