import React from 'react';
import Styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import { Space, Tooltip } from 'antd';
import { AskMeAnythingMessage } from '../../../../../askMeAnything/types';
import { useMessageReferences } from '../../hooks/useMessageReferences';
import { CalendarOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

const {
  Container,
  PulsingDot,
  MessageContainer,
  Message,
  Sender,
  Timestamp,
  MessageBuble,
  TooltipContent,
} = Styles;

interface Props {
  messages: AskMeAnythingMessage[];
}

export const MessagesList: React.FC<Props> = ({ messages }) => {
  const { assignReferencesToMessages } = useMessageReferences();

  return (
    <Container>
      {assignReferencesToMessages(messages).map((message, messageIndex) => {
        const isUpdateSender = !message.isSenderUser;
        const content = ReactHtmlParser(message.content).map((element, idx) => {
          if (element.type === 'a') {
            return (
              <Tooltip
                overlayStyle={{ maxWidth: '600px' }}
                title={
                  <TooltipContent>
                    <h1>
                      <CalendarOutlined /> {decodeURI(element.props.title)}
                    </h1>

                    <h3>
                      @ {decodeURI(format(new Date(element.props.date), 'EEE, MMM dd - hh:mm aa'))}
                    </h3>

                    <h2>{decodeURI(element.props.text)}</h2>

                    <h4>
                      <a href={element.props.href} target="_blank">
                        Click for more
                      </a>
                    </h4>
                  </TooltipContent>
                }
                key={idx + '-tooltip-anchor-reference'}
              >
                {element}
              </Tooltip>
            );
          }
          return element;
        });

        return (
          <MessageContainer
            id={`ama-${messageIndex}`}
            key={messageIndex}
            isUpdateAI={isUpdateSender}
          >
            <Message isUpdateAI={isUpdateSender}>
              <Space>
                <Sender>{message.sender}</Sender>
                <Timestamp>{message.timestamp}</Timestamp>
              </Space>

              <MessageBuble isUpdateAI={isUpdateSender}>
                {message.isWaiting ? <PulsingDot /> : <div>{content}</div>}
              </MessageBuble>
            </Message>
          </MessageContainer>
        );
      })}
    </Container>
  );
};
