import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 16px;
  border-left: 2px solid ${colors.primary['400']};

  &:hover #thumbnail-overlay {
    visibility: visible;
    opacity: 0.5;
  }

  &:hover #folder-item-name {
    color: var(--primary-500, #ff6c54);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const Name = styled.span`
  color: ${colors.coolGray['500']};

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 105.263% */
  cursor: pointer;
`;

const StarredByLabel = styled.span`
  color: ${colors.coolGray['400']};
  /* Text Styles/Paragraph Small */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const StarredByName = styled.span`
  color: ${colors.coolGray['500']};
  /* Text Styles/Paragraph Small */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const DetailLabel = styled.span`
  color: ${colors.coolGray['400']};
  /* Text Styles/Paragraph Small */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const DetailDataContainer = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const DetailData = styled.div`
  display: flex;
  gap: 16px;
`;

const MenuTitle = styled.span`
  color: var(--cool-gray-300, #9ea9b1);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 9px 10px;
  align-items: center;
  gap: var(--spacing-lg, 12px);
  flex: 1 0 0;
  border-radius: 6px;

  & #menu-item:not(:last-child) {
    border-bottom: 1px solid ${colors.coolGray[100]};
  }
`;

const ItemLabel = styled.span`
  color: var(--cool-gray-700, #0a1e2c);

  /* Text sm/Medium */

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export default {
  Container,
  Details,
  Name,
  StarredByLabel,
  StarredByName,
  DetailLabel,
  DetailData,
  DetailDataContainer,
  MenuTitle,
  ItemContainer,
  ItemLabel,
};
