import { ReactElement } from 'react';

const hasDisplayName = (element: ReactElement, displayName: string) => {
  return (element.type as React.FC).displayName === displayName;
};

const filterElements = (elements: ReactElement[], displayName: string) => {
  return elements.filter(element => hasDisplayName(element, displayName));
};

const findElement = (elements: ReactElement[], displayName: string) => {
  const foundElement = elements.find(element => hasDisplayName(element, displayName));
  if (foundElement) {
    return foundElement;
  }
  return null;
};

export { filterElements, findElement, hasDisplayName };
