import React from 'react';
import h2c from 'html2canvas';
import { Button, Dropdown } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useToast } from '../../../../hooks/useToast';

interface Props {
  chartRef: HTMLDivElement | null;
  formatCsvFn: () => string;
}

export const ExportDropdown: React.FC<Props> = ({ chartRef, formatCsvFn }) => {
  const { success } = useToast();

  const handleExportPng = () => {
    if (chartRef) {
      h2c(chartRef, {
        scale: 3,
        width: 1280,
        height: 360,
        onclone: document => {
          const textLabels = document.querySelectorAll('.apexcharts-text');
          textLabels.forEach(element => {
            let currentStyle = element.getAttribute('style') || '';
            currentStyle += `font-family: 'Cera Pro' !important;`;
            element.setAttribute('style', currentStyle);
          });
        },
      }).then(canvas => {
        const data = canvas.toDataURL();
        const link = document.createElement('a');
        link.download = 'yourImage.png';
        link.href = 'data:' + data;
        link.click();
      });
    }
  };

  const handleExportCsv = () => {
    const csvData = formatCsvFn();
    navigator.clipboard.writeText(csvData);
    success('Data copied to clipboard');
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            label: 'Copy data',
            key: 'csv',
            onClick: handleExportCsv,
          },
          /*{ label: 'PNG', key: 'png', onClick: handleExportPng },*/
        ],
      }}
    >
      <Button icon={<DownloadOutlined />}>
        <span>Export</span>
      </Button>
    </Dropdown>
  );
};
