import React from 'react';
import Styles from './styles';
import { Tooltip } from 'antd';

const { Label } = Styles;

interface Props {
  name: string;
  onClick?: () => void;
}

export const TopicLabel: React.FC<Props> = ({ name, onClick }) => {
  return (
    <Tooltip title={name}>
      <Label onClick={onClick}>{name}</Label>
    </Tooltip>
  );
};
