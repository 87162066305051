import { Dropdown } from 'antd';
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Volume } from '../../../../../shared/components/Icons/Volume';
import Styles from './styles';
import { VolumeMuted } from '../../../../../shared/components/Icons/VolumeMuted';
import { useVideoPlayer } from '../../../../hooks';

const { Container, VolumeContainer } = Styles;

export const VolumePanel: React.FC = () => {
  const { volume, changeVolume, isMuted, mute, unmute } = useVideoPlayer();

  const handleVolumeChanged = (value: number | number[]) => {
    if (!Array.isArray(value)) {
      changeVolume(value / 100);
    }
  };

  const currentVolume = isMuted ? 0 : volume * 100;

  return (
    <Dropdown
      trigger={['hover']}
      placement={'top'}
      dropdownRender={() => {
        return (
          <Container>
            <VolumeContainer>
              <Slider
                key="volume-bar"
                min={0}
                max={100}
                value={currentVolume}
                dots={false}
                onChange={handleVolumeChanged}
                vertical
              />
            </VolumeContainer>
          </Container>
        );
      }}
    >
      {isMuted ? <VolumeMuted onClick={() => unmute()} /> : <Volume onClick={() => mute()} />}
    </Dropdown>
  );
};
