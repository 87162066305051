import styled from 'styled-components';

const Label = styled.span`
  overflow: hidden;
  color: #6a6a6a;
  text-overflow: ellipsis;
  font-family: 'Cera Pro Bold';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
`;

export default { Label };
