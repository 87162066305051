import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 180px);
  padding-bottom: 20px;
  padding-right: 10px;
  scroll-behavior: smooth;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 16px;
  height: 24px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
`;

export const ToggleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px 0px;
  width: 100%;
  height: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d1d1d1;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ByEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 16px 0px;
`;

export const BySlackContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 16px 0px;
`;
