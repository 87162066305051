import Styles from './style';

interface Props {
  title: string;
  text: string;
}

export const Quote: React.FC<Props> = ({ title, text }) => {
  const { Container, Text } = Styles;

  return (
    <Container>
      {title}
      <Text>{text}</Text>
    </Container>
  );
};
