import { Button, Modal, Select, SelectProps } from 'antd';
import React, { useState } from 'react';
import {
  Content,
  EmailInvite,
  EmailText,
  Footer,
  LabelText,
  NewMemberText,
  SelectWrapper,
  TitleContainer,
} from './style';
import { useToast } from '../../../../../../hooks/useToast';
import IconSvg from '../../../../../../components/common/IconSvg';
import { inviteEmails } from '../../../../../../features/organizations/organizations.service';

const options: SelectProps['options'] = [];

interface Props {
  shouldShow?: boolean;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

export const InviteEmailModal: React.FC<Props> = ({ shouldShow, onConfirm, onCancel }) => {
  const [invitationsToSend, setInvitationsToSend] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { success, error } = useToast();
  const [inputValues, setInputValues] = useState<string[]>([]);

  const handleChange = (values: string[]) => {
    setInputValues(values);
    if (values.length > 0) {
      setInvitationsToSend(values);
    }
  };

  const ModalTitle = (
    <TitleContainer>
      <IconSvg name="addNewMemberBlack" size="md" color="transparent" />
      <NewMemberText>Invite new member</NewMemberText>
    </TitleContainer>
  );

  const handleSendInvitations = async (emails: string[]) => {
    if (emails.length > 0) {
      setLoading(true);
      try {
        await inviteEmails(emails);
        success(`Invitation${invitationsToSend.length > 1 ? 's' : ''} sent successfully`);
      } catch (e) {
        error((e as Error).message);
      }
      setLoading(false);
      setInvitationsToSend([]);
      onConfirm();
    }
  };

  return (
    <Modal title={ModalTitle} open={shouldShow} closable={false} destroyOnClose footer={false}>
      <Content>
        <EmailInvite>
          <EmailText>Email</EmailText>
          <SelectWrapper>
            <div className="prefix-icon-wrapper">
              <IconSvg name="email" size="md" color="transparent" />
            </div>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder={
                <div style={{ paddingLeft: '32px' }}>
                  Separate multiple e-mails by pressing Enter↵
                </div>
              }
              onChange={handleChange}
              options={options}
              showArrow={false}
              open={false}
              ref={input => {
                if (input) setTimeout(() => input.focus());
              }}
            />
          </SelectWrapper>
          <LabelText>We’ll email them instructions and a link to join the workspace.</LabelText>
        </EmailInvite>

        <Footer>
          <Button
            type="default"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSendInvitations(invitationsToSend);
            }}
            loading={loading}
            disabled={inputValues.length === 0}
          >
            Invite new member{invitationsToSend.length > 1 ? 's' : ''}
          </Button>
        </Footer>
      </Content>
    </Modal>
  );
};
