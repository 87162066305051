import React, { PropsWithChildren, createContext, useState } from 'react';
import { UploadRecordingModal } from './components/UploadRecordingModal';

interface UploadRecordingContext {
  isOpen: boolean;
  show: () => unknown;
  close: () => unknown;
}

export const uploadRecordingContext = createContext<UploadRecordingContext>({
  isOpen: false,
  show: () => null,
  close: () => null,
});

export const UploadRecordingProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const show = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <uploadRecordingContext.Provider value={{ isOpen, show, close }}>
      <UploadRecordingModal />
      {children}
    </uploadRecordingContext.Provider>
  );
};
