import React, { useMemo } from 'react';
import { useCopyInsightType } from '../../../../hooks/useRecapCopy';
import { BulletList } from '../BulletList/BulletList';
import { SectionCard } from '../SectionCard/SectionCard';
import {
  getInsightTypeConfiguration,
  isExternal,
} from '../../../insights/insight-type-configuration/insight-type-configuration.service';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { NoDetectionMessage } from './components/NoDetectionMessage';

interface Props {
  category: string;
}

export const InsightTypeCard: React.FC<Props> = ({ category }) => {
  const {
    participantsType,
    insights,
    categories,
    publicVisibility,
    changePublicVisibility,
    createInsight,
    editInsight,
    removeInsight,
  } = useRecapManagement();
  const copyMoments = useCopyInsightType(category);
  const categoryConfig = getInsightTypeConfiguration(category);

  const isInternal = participantsType === 'internal';

  const items = useMemo(() => {
    return insights.filter(insight => insight.category === category);
  }, [insights, category]);

  const count = categories[category].count;

  const hasItems = items.length > 0;
  const isExternalCategory = isExternal(category);

  const handleVisibilityChanged = () => {
    const currentVisibility = { ...publicVisibility };
    if (categoryConfig.visibilityKey) {
      const currentValue = currentVisibility[categoryConfig.visibilityKey];
      currentVisibility[categoryConfig.visibilityKey] = !currentValue;
      changePublicVisibility(currentVisibility);
    }
  };

  const handleCreateInsight = () => {
    createInsight(category);
  };

  const handleUpdateInsight = (id: string, text: string) => {
    editInsight(id, category, text);
  };

  const handleRemoveInsight = (id: string) => {
    removeInsight(id, category);
  };

  const isPubliclyVisible = useMemo(() => {
    if (categoryConfig.visibilityKey) {
      return publicVisibility[categoryConfig.visibilityKey];
    }
    return false;
  }, [categoryConfig, category, publicVisibility]);

  return (
    <SectionCard
      showAdd={true}
      onCopy={copyMoments}
      onAdd={() => handleCreateInsight()}
      icon={categoryConfig.icon}
      color={categoryConfig.color}
      label={`${category} (${count})`}
      isPubliclyVisible={isPubliclyVisible}
      visibility={{
        disabled: false,
        onClick: () => handleVisibilityChanged(),
      }}
    >
      {!hasItems ? (
        <NoDetectionMessage
          category={category}
          isExternalCategory={isExternalCategory}
          isInternal={isInternal}
        />
      ) : (
        <BulletList
          bullets={items}
          color={categoryConfig.highlightColor}
          smartClipColor={categoryConfig.smartClipColor}
          hightlightColor={categoryConfig.color}
          onDelete={(id: string) => handleRemoveInsight(id)}
          category={category}
          onEdit={(id: string, text: string) => handleUpdateInsight(id, text)}
        />
      )}
    </SectionCard>
  );
};
