import styled from 'styled-components';
import { DateSelect as FilterDateSelect } from '../../../../pages/home/FilterBar/DateSelect';

const DateSelect = styled(FilterDateSelect)`
  padding: 0;
  font-family: 'Cera Pro' !important;

  ant-picker-input > input {
    font-family: 'Cera Pro' !important;
  }
`;

export default { DateSelect };
