import styled from 'styled-components';
import { Input } from 'antd';
import { colors } from '../../../../../theme/colors';

const { TextArea } = Input;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.gray[50]};
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
`;

const ChatContainer = styled.div`
  display: flex;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled(TextArea)`
  flex: 1;

  resize: none !important;
  height: 40px !important;
  overflow: hidden !important;
  align-items: center !important;

  & :focus {
    border: none;
    outline: none;
  }
`;

const ActionButtonsContainenr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChatActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 12px;
  padding-right: 24px;
  gap: 10px;
`;

export default {
  ChatContainer,
  ChatInput,
  ActionButtonsContainenr,
  ChatActionsContainer,
  BottomContainer,
};
