import styled from 'styled-components';

export const NavigationBarContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #e0e6ee;
  width: 100%;
  gap: 25px;
  height: 78px;
`;

export const LogoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6c54;
  cursor: pointer;
  height: 78px;
  width: 72px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const Details = styled.div`
  color: var(--new-color-shades-gray-700, #6a6a6a);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  display: flex;
  padding-left: 2px;
`;

export const Host = styled.div`
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
