import axios from 'axios';

const responseInterceptor = (response: { data: { statusCode: number }; status: number }) => {
  const statusCode = response.data.statusCode;
  const statusCodeFamily = Math.floor(statusCode / 100);

  if (statusCodeFamily === 4 || statusCodeFamily === 5) {
    response.status = statusCode;
    return Promise.reject(response);
  }

  return response;
};

export const createAxiosInstance = (BASE_URL: string) => {
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  });

  axiosInstance.interceptors.response.use(responseInterceptor);
  return axiosInstance;
};
