import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const CheckSVG = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 1L5.49992 10.1667L1.33325 6"
        stroke="#16B380"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Check: React.FC<
  Partial<CustomIconComponentProps> & {
    onClick?: (args: React.MouseEvent<HTMLSpanElement>) => unknown;
  }
> = props => {
  return <Icon component={CheckSVG} {...props} />;
};
