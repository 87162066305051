import styled from 'styled-components';
import { mediaSize } from '../../../../../../theme/media';
import { Button } from 'antd';

const ConnectAccountButton = styled(Button)`
  display: flex;
  padding: 9px 13px 9px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color: var(--new-color-shades-primary-600, #e6614c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background-color: white;
`;

const DropDownSection = styled.div`
  display: flex;
  padding: 8px 4px 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--gray-500, #a6a6a6);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: solid 1px #e4e4e4;
`;

const RemoveAccountButton = styled.div`
  display: flex;
  padding: 4px 4px 4px 4px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--red-700, #b71e1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const AccountTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const AccountText = styled.div`
  padding-right: 5px;
  color: var(--gray-500, #a6a6a6);

  @media (max-width: ${mediaSize.laptopL}) {
    display: none;
  }
`;

export default {
  ConnectAccountButton,
  DropDownSection,
  RemoveAccountButton,
  AccountTextContainer,
  AccountText,
};
