import styled from 'styled-components';

export const VisibilityOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
`;

export const RecapOptions = styled.div`
  color: var(--new-color-shades-gray-950, #282828);
  font-family: Cera Pro Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 16px;
  padding-top: 16px;
  flex: 1;
`;

export const OtherOptions = styled.div`
  color: var(--new-color-shades-gray-700, #6a6a6a);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex: 1;
  padding-left: 16px;
`;

export const OrgOptions = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ selected }) => (selected ? '#0A9168' : 'black')};
`;

const CopyLink = styled.span`
  color: var(--Primary-500, #ff6c54);

  /* text-sm/leading-5/font-medium */

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const MenuHeader = styled.div`
  display: flex;
`;

const CopyContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export default {
  CopyLink,
  MenuHeader,
  CopyContainer,
};
