import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const StepTitle = styled.div`
  display: flex;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 8px;
`;

export const StepDescription = styled.div`
  color: #6a6a6a;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 0px 0px 22px;
`;

export const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
`;
