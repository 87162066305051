import { ChangeEvent, useEffect, useState } from 'react';
import { updateUser } from '../redux/slices/sessionSlice';
import { coreAPI } from '../API/core';
import { useDispatch } from 'react-redux';
import { useToast } from './useToast';

export const useChangePassword = (firstName: string, lastName: string) => {
  const dispatch = useDispatch();
  const { success, error } = useToast();

  const [firstNameValue, setFirstNameValue] = useState(firstName);
  const [lastNameValue, setLastNameValue] = useState(lastName);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [status, setStatus] = useState<'' | 'error'>('');
  const [errorMessage, setErrorMessage] = useState('');

  const validatePassword = () => {
    if (newPassword.length < 8) {
      setErrorMessage('Password must be at least 8 characters long');
      setStatus('error');
      return false;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Password does not match');
      setStatus('error');
      return false;
    }

    setStatus('');
    return true;
  };

  const handleChangeFirstName = (e: ChangeEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setFirstNameValue(target.value);
  };

  const handleChangeLastName = (e: ChangeEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setLastNameValue(target.value);
  };

  const handleChangePassword = (e: ChangeEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setNewPassword(target.value);
  };

  const handleChangeConfirmPassword = (e: ChangeEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setConfirmPassword(target.value);
  };

  const resetFields = () => {
    setStatus('');
    setNewPassword('');
    setConfirmPassword('');
    setFirstNameValue(firstName);
    setLastNameValue(lastName);
  };

  useEffect(() => {
    if (validatePassword()) {
      setStatus('');
      setErrorMessage('');
    } else setStatus('error');
  }, [newPassword, confirmPassword]);

  const handleSavePassword = () => {
    const api = new coreAPI();
    try {
      api.updatePassword(newPassword);
      success('Password updated successfully');
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveUser = () => {
    dispatch(updateUser({ firstName: firstNameValue, lastName: lastNameValue }));
    success('User updated successfully');
  };

  return {
    firstNameValue,
    lastNameValue,
    status,
    errorMessage,
    newPassword,
    confirmPassword,
    resetFields,
    handleChangeFirstName,
    handleChangeLastName,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleSavePassword,
    handleSaveUser,
  };
};
