import { coreService } from '../../services/core/core-service';

const createZapierApiKey = async () => {
  const apiKeyData = await coreService.createZapierApiKey();
  return apiKeyData;
};

const removeIntegration = async (integrationId: string) => {
  await coreService.removeIntegration(integrationId);
};

export { createZapierApiKey, removeIntegration };
