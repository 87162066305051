import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Label = styled.span<{ color?: string }>`
  color: ${props => (props.color ? props.color : colors.green['500'])};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

const Value = styled.span<{ color?: string }>`
  color: ${props => (props.color ? props.color : colors.gray['700'])};

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default { Container, Label, Value };
