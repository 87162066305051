import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 22px;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--blue-50, #edf9ff);
`;

const Text = styled.div`
  color: var(--blue-700, #0675fc);
  font-variant-numeric: stacked-fractions;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default { Container, Text };
