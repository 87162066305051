import styled from 'styled-components';

const Label = styled.span`
  color: var(--gray-950, #282828);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex: 1;
`;

const Value = styled.span`
  color: var(--gray-500, #a6a6a6);
  text-align: right;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
  gap: 12px;
`;

export default { Container, Label, Value };
