import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../typings/session';
import { Organizations } from '../typings/organizations';

export const initialState: Organizations = {
  form: {
    isCreating: false,
    isUpdating: false,
  },
  editingOrgKey: undefined,
  editingOrgData: {
    memberships: [],
  },
  isOpenedInviteForm: false,
  isFetching: false,
  isDeleting: false,
  isRemovingMember: false,
  isCreating: false,
  createFormOpen: false,
  isUpdating: false,
  isCreatingInvite: false,
  isSettingPrimary: false,
  invitations: {
    error: undefined,
    isFetching: false,
    isAccepting: false,
    isDeclining: false,
    invitations: [],
  }
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: initialState,
  reducers: {
    fetchOrganizationData(state) {
      state.isFetching = true;
    },
    fetchOrganizationDataSuccess(state, action: PayloadAction<Organization>) {
      state.isFetching = false;
      state.editingOrgData = action.payload;
    },
    fetchOrganizationDataError(state) {
      state.isFetching = false;
    },
    openCreateForm(state){
      state.createFormOpen = true;
      state.editingOrgKey = undefined;
    },
    closeCreateForm(state){
      state.createFormOpen = false;
      state.editingOrgKey = undefined;
    },
    createOrganization(state) {
      state.isCreating = true;
    },
    createOrganizationSuccess(state, action) {
      state.isCreating = false;
      state.editingOrgKey = action.payload.name.toLowerCase();
      state.editingOrgData = action.payload;
      state.createFormOpen = false;
    },
    createOrganizationError(state) {
      state.isCreating = false;
    },
    updateOrganization(state) {
      state.isUpdating = true;
    },
    updateOrganizationSuccess(state, action) {
      state.editingOrgKey = action.payload.name.toLowerCase();
      if(state.editingOrgData){
        state.editingOrgData.name = action.payload.name;
      }
      state.isUpdating = false;
    },
    updateOrganizationError(state) {
      state.isUpdating = false;
    },
    openOrgForEdit(state, action) {
      state.editingOrgKey = action.payload.name.toLowerCase();
    },
    inviteMember(state) {
      state.isCreatingInvite = true;
      state.invitations.error = undefined;
    },
    inviteMemberSuccess(state) {
      state.isCreatingInvite = false;
      state.isOpenedInviteForm = false;
    },
    inviteMemberError(state, action) {
      state.isCreatingInvite = false;
      state.invitations.error = action.payload.error;
    },
    inviteMemberClear(state) {
      state.isCreatingInvite = false;
      state.invitations.error = undefined;
    },
    removeMember(state) {
      state.isRemovingMember = true;
    },
    removeMemberSuccess(state) {
      state.isRemovingMember = false;
    },
    removeMemberError(state, action) {
      state.isRemovingMember = false;
      state.invitations.error = action.payload.error;
    },
    setPrimary(state) {
      state.isSettingPrimary = true;
    },
    setPrimarySuccess(state) {
      state.isSettingPrimary = false;
    },
    setPrimaryError(state) {
      state.isSettingPrimary = false;
    },
    fetchInvitations(state) {
      state.invitations.isFetching = true;
    },
    fetchInvitationsSuccess(state, action) {
      state.invitations.isFetching = false;
      state.invitations.invitations = action.payload.invitations;
    },
    fetchInvitationsError(state) {
      state.invitations.isFetching = false;
    },
    acceptInvitation(state) {
      state.invitations.isAccepting = true;
    },
    acceptInvitationSuccess(state) {
      state.invitations.isAccepting = false;
    },
    acceptInvitationError(state) {
      state.invitations.isAccepting = false;
    },
    declineInvitation(state) {
      state.invitations.isDeclining = true;
    },
    declineInvitationSuccess(state) {
      state.invitations.isDeclining = false;
    },
    declineInvitationError(state) {
      state.invitations.isDeclining = false;
    },
    deleteOrganization(state) {
      state.isDeleting = true;
    },
    deleteOrganizationSuccess(state) {
      state.isDeleting = false;
    },
    deleteOrganizationError(state) {
      state.isDeleting = false;
    },
    openInviteUserForm(state) {
      state.isOpenedInviteForm = true;
    },
    closeInviteUserForm(state) {
      state.isOpenedInviteForm = false;
    },
  },
});

export const { createOrganization, createOrganizationSuccess, createOrganizationError } = organizationsSlice.actions;
export const { updateOrganization, updateOrganizationSuccess, updateOrganizationError } = organizationsSlice.actions;
export const { inviteMember, inviteMemberSuccess, inviteMemberError, inviteMemberClear } = organizationsSlice.actions;
export const { setPrimary, setPrimarySuccess, setPrimaryError } = organizationsSlice.actions;
export const { deleteOrganization, deleteOrganizationSuccess, deleteOrganizationError } = organizationsSlice.actions;
export const { fetchInvitations, fetchInvitationsSuccess, fetchInvitationsError } = organizationsSlice.actions;
export const { acceptInvitation, acceptInvitationSuccess, acceptInvitationError } = organizationsSlice.actions;
export const { removeMember, removeMemberSuccess, removeMemberError } = organizationsSlice.actions;
export const { declineInvitation, declineInvitationSuccess, declineInvitationError } = organizationsSlice.actions;
export const { fetchOrganizationData, fetchOrganizationDataSuccess, fetchOrganizationDataError } = organizationsSlice.actions;
export const { openOrgForEdit, openCreateForm, closeCreateForm } = organizationsSlice.actions;
export const { openInviteUserForm, closeInviteUserForm } = organizationsSlice.actions;
export const organizationsReducer = organizationsSlice.reducer;
