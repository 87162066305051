import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { Button, Form, Input } from 'antd';
import { coreAPI } from '../../../../API/core';
import RecoverPasswordSchema from '../../../../validations/RecoverPasswordSchema';
import { useToast } from '../../../../hooks/useToast';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';

const { FormInputsContainer } = Styles;

interface Props {
  onBack: () => unknown;
}

const ForgetPasswordForm: React.FC<Props> = ({ onBack }) => {
  const [isWorking, setIsWorking] = useState(false);
  const [email, setEmail] = useState('');
  const { error, success } = useToast();

  useEffect(() => {
    return () => {
      setEmail('');
      setIsWorking(false);
    };
  }, []);

  const recoverPassword = async () => {
    RecoverPasswordSchema.validate({ email })
      .then(() => {
        setIsWorking(true);
        const client = new coreAPI();
        client
          .recoverPassword({ email, from: 'portal' })
          .then(() => {
            success('Recovery e-mail sent successfully');
            setEmail('');
          })
          .catch(clientError => {
            if (clientError.response && clientError.response.data.message) {
              error(clientError.response.data.message);
            } else {
              error('Error recovering the e-mail.');
            }
          })
          .finally(() => setIsWorking(false));
      })
      .catch(error => {
        error('The e-mail is invalid.');
      });
  };

  return (
    <AuthenticationForm
      onBack={onBack}
      backText="Back to login"
      title="Forgot password?"
      description={`To reset your password, enter your email address below, and we'll send you a verification code.`}
    >
      <FormInputsContainer>
        <Form layout="vertical">
          <Form.Item style={{ marginBottom: '16px', fontSize: 'Inter' }} label="Email address">
            <Input value={email} disabled={isWorking} onChange={ev => setEmail(ev.target.value)} />
          </Form.Item>
        </Form>
        <Button
          onClick={recoverPassword}
          disabled={isWorking}
          type="primary"
          style={{ width: '100%' }}
        >
          Send recovery e-mail
        </Button>
      </FormInputsContainer>
    </AuthenticationForm>
  );
};

export default ForgetPasswordForm;
