import { ReactElement } from 'react';
import { Info } from '../../../../../../componentsV2/Alert/components/icons/Info';
import style from './styles';

export const OnlyOneConnected: React.FC<{ elem: ReactElement }> = ({ elem }) => {
  const { Warn } = style;
  return (
    <Warn>
      <Info style={{ scale: '0.6' }} />
      {elem}
    </Warn>
  );
};
