import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding-left: 24px;
gap: 16px;
width: 100%
align-self: stretch;
`;

export const Email = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
