import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0px 16px 0px 16px;
  color: #6a6a6a;
`;

export default {
  Content,
};
