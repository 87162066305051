import React, { useEffect, useState } from 'react';
import { Mentions, TopMentionsType } from '../../../../../../services/types';
import { Meeting } from './components/Meeting';
import { Pagination, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../../services/core/core-service';
import { useDashboardFilters } from '../../../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { DateRangeFilterStore } from '../../../../../../features/shared/dashboard-filters/types';

import Styles from './styles';
const { Container } = Styles;

interface Props {
  accountId: string[];
  sortBy: string;
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  topic: {
    id: string;
    name: string;
  };
}

export const MentionsContainer: React.FC<Props> = ({ sortBy, topic, accountId, filters }) => {
  const [elems, setElems] = useState<TopMentionsType | null>(null);
  const [pageSize, setPageSize] = useState<[number, number]>([1, 10]);

  const { data: topMentions, isFetching } = useQuery({
    queryKey: [
      'topic-modal-topics',
      filters.from,
      filters.to,
      filters.accountId,
      pageSize,
      accountId,
      topic.id,
    ],
    queryFn: async () => {
      return await coreService.getTopMentionsByTopicId(
        topic.id,
        filters.from,
        filters.to,
        1,
        10,
        sortBy,
        {
          accountIds: filters.accountId ? [filters.accountId] : undefined,
        },
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const handlePageChange = async (page: number, size: number) => {
    setPageSize([page, size]);
  };

  useEffect(() => {
    setElems(topMentions || null);
  }, [topMentions]);

  return (
    <Container>
      {isFetching ? (
        <Spin />
      ) : (
        (elems?.mentions || []).map((m: Mentions) => {
          return (
            <Meeting
              id={m.id}
              insights={m.mentions}
              account={m.account}
              title={m.title}
              scheduledStartDate={new Date(m.scheduledStartDate)}
            />
          );
        })
      )}
    </Container>
  );
};
