import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 354px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #ff6c54;
`;

const LoginLink = styled(Link)`
  margin-left: 4px;
`;

const Line = styled.span`
  border-top: 1px solid #d1d5db;
  flex: 1;
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RegisterContainer = styled.span`
  text-align: center;
`;

const ErrorMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: red;
  align-self: center;
`;

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export default {
  RegisterContainer,
  DividerContainer,
  FormContainer,
  ActionsContainer,
  Line,
  LoginLink,
  ErrorMessage,
  LoginOptions,
};
