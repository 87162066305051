import { Input, Popover, Space, Tooltip } from 'antd';
import Styles from '../../styles';
import React, { ReactNode } from 'react';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { CellLabel } = Styles;

interface Props {
  onChange: (value: string) => unknown;
  onEnter: () => unknown;
  onEdit: () => unknown;
  isEditable: boolean;
  editing: boolean;
  width?: string;
  value: string | null;
  noValueText?: string;
  inputType?: 'input' | 'textarea';
  help?: ReactNode;
}

export const EditableCell: React.FC<Props> = ({
  onChange,
  onEnter,
  onEdit,
  value,
  editing,
  inputType = 'input',
  isEditable,
  noValueText = '',
  width,
  help,
}) => {
  const ComponentInput = inputType === 'textarea' ? Input.TextArea : Input;

  return editing ? (
    <ComponentInput
      onBlur={() => onEnter()}
      style={{
        width: width || 'auto',
      }}
      onKeyDown={ev => {
        if (ev.key === 'Enter') {
          onEnter();
        }
      }}
      onChange={ev => onChange(ev.target.value)}
      value={value || ''}
    />
  ) : (
    <Space>
      {' '}
      <CellLabel width={width || 'auto'}>{value || noValueText}</CellLabel>
      {help && <Popover content={help}>{help && <QuestionCircleOutlined />}</Popover>}
      {isEditable && <EditOutlined onClick={() => onEdit()} />}
    </Space>
  );
};
