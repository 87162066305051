import React, { useEffect, useMemo, useState } from 'react';
import Styles from './styles';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { DateSelector } from '../../features/shared/components/DateSelector';
import { InsightsAskMeAnything } from './components/InsightsAskMeAnything';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Cascader, Dropdown, Space, Tag, Tooltip } from 'antd';
import { CustomerIntelligenceProvider } from '../../features/insights/context/CustomerIntelligenceProvider';
import { useCustomerIntelligenceFilters } from '../../features/insights/hooks/useCustomerIntelligenceFilters';
import { Alert } from '../../componentsV2/Alert/Alert';
import { CategoriesCard } from './components/CategoriesCard';
import { SubCategoriesCard } from './components/SubCategoriesCard';
import { SmartClipsDrawer } from './components/SmartClipsDrawer';
import { useCustomerIntelligenceData } from '../../features/insights/hooks/useCustomerIntelligenceData';
import { SelectAll } from './components/SelectAll';
import { AccountFilter } from './components/AccountFilter';
import { ManageInsightsFolderModal } from '../../features/libraries/components/ManageInsightFoldersModal';
import { ApiOutlined, DatabaseOutlined } from '@ant-design/icons';
import { useIntegrations } from '../../features/shared/hooks/useIntegrations';
import { ManageCategoriesModal } from './components/ManageCategoriesModal';
import { CategoryManagementProvider } from '../../features/insights/context/CategoryManagementProvider';
import { InsightCategoriesProvider } from '../../features/insights/context/InsightCategoriesProvider';
import { useInsightsAnalytics } from '../../features/insights/hooks/useInsightsAnalytics';
import { useLocation } from 'react-router-dom';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { StoredFilterType } from './components/StoredFilters/types';
import useFeatures from '../../hooks/useFeatures';

const { Contents, FiltersGroup, Header, HeaderRow } = Styles;

const CATEGORIES = [
  {
    label: 'Risks',
    value: 'Risks',
  },
  {
    label: 'Product Feedback',
    value: 'Product Feedback',
  },
  { label: 'Growth Opportunities', value: 'Growth Opportunities' },
  {
    label: 'Advocacy',
    value: 'Advocacy',
  },
  // {
  //   label: 'Marketing Moments',
  //   value: 'Marketing Moments',
  // },
];

export const Insights: React.FC = () => {
  useLocationChange();

  const {
    category,
    industries,
    dateRange,
    accounts,
    lifecycleStages,
    // priorities,
    // togglePriorities,
    resetFilters,
    selectAccounts,
    changeCategory,
    changeDateRange,
    toggleLifecycleStages,
    toggleIndustries,
  } = useCustomerIntelligenceFilters();

  const [showManageCategoriesModal, setShowManageCategoriesModal] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [showFilters, setShowFilters] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<StoredFilterType | undefined>(undefined);
  const [newOrUpdatedFilter, setNewFilter] = useState<StoredFilterType | undefined>(undefined);
  const [oldDR, setOldDR] = React.useState<(null | Date)[]>([null, null]);

  const { meetingsCount } = useCustomerIntelligenceData();
  const { hasCrmIntegration } = useIntegrations();
  const { portfolio } = useInsightsAnalytics();

  const { search } = useLocation();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const createFilter = (isNew: boolean = false) => {
    setNewFilter({
      id: isNew ? '' : selectedFilter?.id || '',
      name: isNew ? '' : selectedFilter?.name || '',
      config: {
        // accounts: accounts.map(a => JSON.stringify(a)),
        // industry: industries.filter(i => i.selected).map(i => i.id),
        // lifecycleStage: lifecycleStages.filter(l => l.selected).map(l => l.id),
      },
    });

    if (isNew) {
      setShowFiltersModal(true);
    }
  };

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  const handleShowModal = (value: boolean) => {
    setShowFiltersModal(value);
    setNewFilter(undefined);
  };

  const handleSaveFilter = (filter: StoredFilterType) => {
    handleShowModal(false);
    setSelectedFilter(filter);
  };

  const handleRemoveFilter = (filter: StoredFilterType) => {
    if (selectedFilter && selectedFilter.id === filter.id) {
      setSelectedFilter(undefined);
    }
  };

  const SelectedFitlerTag = useMemo(() => {
    return selectedFilter ? (
      <Tag
        style={{
          height: 'auto',
          fontSize: '13px',
          paddingLeft: '10px',
          paddingRight: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '8px',
        }}
        closeIcon
        onClose={() => {
          resetFilters();
          setSelectedFilter(undefined);
          portfolio.filtered('reset filters');
        }}
      >
        <Tooltip title={selectedFilter.name}>
          <div
            style={{
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {selectedFilter.name}
          </div>
        </Tooltip>
      </Tag>
    ) : (
      <></>
    );
  }, [selectedFilter?.name]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const type = params.get('type');
    const accountId = params.get('accountId');

    if (type) {
      changeCategory(type);
    }

    if (accountId) {
      const [id, name, provider] = accountId.split(',');
      selectAccounts([{ id, name, provider }]);
    }
  }, [search]);

  useEffect(() => {
    if (!oldDR[0] && !oldDR[1]) {
      setOldDR(dateRange);
    } else {
      portfolio.filtered('date range');
    }
  }, [dateRange]);

  // useEffect(() => {
  //   if (selectedFilter) {
  //     toggleIndustries(selectedFilter.config?.industry || []);
  //     toggleLifecycleStages(selectedFilter.config?.lifecycleStage || []);
  //     selectAccounts(
  //       (selectedFilter.config?.accounts?.map(a => JSON.parse(a)) as {
  //         id: string;
  //         provider: string;
  //         name: string;
  //       }[]) || [],
  //     );
  //   }
  // }, [selectedFilter]);

  return (
    <CustomerIntelligenceProvider>
      <InsightCategoriesProvider insightType={category}>
        <CategoryManagementProvider>
          <ManageCategoriesModal
            open={showManageCategoriesModal}
            onClose={() => setShowManageCategoriesModal(false)}
            onDiscard={() => setShowManageCategoriesModal(false)}
            onChangesSaved={() => null}
          />
          <TitledVerticalFlexLayout
            sider={true}
            title="Portfolio Trackers"
            titleDescription={
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ maxWidth: '600px' }}>
                  This tool lets you analyze data exclusively from your external meetings, providing
                  deeper insights into client interactions.
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Cascader
                    options={CATEGORIES}
                    changeOnSelect
                    defaultValue={[category || 'Risk']}
                    value={[category || 'Risk']}
                    multiple={false}
                    allowClear={false}
                    disabled={!availableFeature}
                    dropdownMenuColumnStyle={{
                      fontFamily: 'Cera Pro',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}
                    onChange={c => {
                      changeCategory(CATEGORIES.filter(cat => cat.value === c[0])[0].value);
                      portfolio.filtered('category');
                    }}
                  />
                </div>
              </div>
            }
          >
            <UpgradeToPro plan="business" display={!availableFeature}>
              <Contents>
                <ManageInsightsFolderModal />

                <Header $visible={showFilters}>
                  <HeaderRow>
                    <FiltersGroup>
                      <DateSelector
                        defaultValues={dateRange}
                        value="30d"
                        direction="past"
                        predefinedRanges={{
                          today: false,
                          '1d': false,
                          '7d': true,
                          '30d': true,
                          '3m': false,
                          '6m': true,
                          '12m': false,
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onDateOptionChanged={option => null}
                        onDateRangeChanged={e => {
                          changeDateRange(e);
                        }}
                      />

                      <AccountFilter />
                      {/*
                      <SelectAll
                        style={{ minWidth: '120px' }}
                        label="Industry"
                        onSelectionChanged={(values: string[]) => {
                          toggleIndustries(values);
                          portfolio.filtered('industry');
                        }}
                        showAll
                        options={[...industries.map(ind => ({ label: ind.id, id: ind.id }))]}
                        value={industries.filter(l => l.selected).map(l => l.id)}
                      />

                      <SelectAll
                        style={{ minWidth: '200px' }}
                        label="Lifecycle Stage"
                        onSelectionChanged={(values: string[]) => {
                          toggleLifecycleStages(values);
                          portfolio.filtered('maturity level');
                        }}
                        showAll
                        options={[...lifecycleStages.map(ind => ({ label: ind.id, id: ind.id }))]}
                        value={lifecycleStages.filter(l => l.selected).map(l => l.id)}
                      />
                      */}
                    </FiltersGroup>
                  </HeaderRow>

                  <HeaderRow>
                    <FiltersGroup>
                      {SelectedFitlerTag}
                      {/* 
                      <Dropdown.Button
                        style={{ width: 'auto' }}
                        type="primary"
                        menu={{
                          items: selectedFilter
                            ? [
                                {
                                  key: '1',
                                  label: 'Save as New Filter',
                                  onClick: () => createFilter(true),
                                },
                              ]
                            : [],
                        }}
                        onClick={() => createFilter(!!!selectedFilter)}
                      >
                        {selectedFilter ? 'Save changes' : 'Save as new Filter'}
                      </Dropdown.Button> */}
                    </FiltersGroup>
                  </HeaderRow>
                </Header>

                <SmartClipsDrawer />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '50px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <InsightsAskMeAnything category={category!} />

                    {!hasCrmIntegration && (
                      <Button type="link" onClick={handleConnectCrm}>
                        <Space>
                          Connect your CRM <ApiOutlined />
                        </Space>
                      </Button>
                    )}
                  </div>

                  <Alert
                    customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
                    message={`Insights come from ${meetingsCount} meeting recaps based on your applied filters.`}
                    variant="info"
                    title=""
                  />
                </div>

                <CategoriesCard onManageCategories={() => setShowManageCategoriesModal(true)} />

                <SubCategoriesCard />
              </Contents>
            </UpgradeToPro>
          </TitledVerticalFlexLayout>
        </CategoryManagementProvider>
      </InsightCategoriesProvider>
    </CustomerIntelligenceProvider>
  );
};
