import styled from 'styled-components';

interface ButtonTabContainerProps {
  active: boolean;
}

export const ButtonTabContainer = styled.span<ButtonTabContainerProps>`
  color: ${props => (props.active ? '#F25C3B' : '#596370;')};
  background-color: ${props => (props.active ? '#FFE6E2' : 'transparent')};
  border-radius: 6px;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  transition: color 0.1s ease-in;
  &:hover {
    background-color: ${props => (!props.active ? '#F8F8F8' : '#FFE6E2')};
  }
`;

export const ButtonTabPaneContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
