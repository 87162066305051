import React from 'react';
import Styles from './styles';
import { AccountIcon } from '../../AccountIcon';
import { ProviderIntegration } from '../../../../../redux/typings/store';

const { AccountAndDescription, AccountLabelGroup, Description, Label } = Styles;

interface Props {
  account: ProviderIntegration;
  prospectedAccounts?: ProviderIntegration[] | null;
  styles?: React.CSSProperties;
}

export const ResolveAccountLabel: React.FC<Props> = ({
  account,
  prospectedAccounts,
  styles = {},
}) => {
  if (account && account.id) {
    return (
      <AccountLabelGroup style={{ ...styles }}>
        <AccountIcon provider={account.provider} />
        <Label>{account.name}</Label>
      </AccountLabelGroup>
    );
  }

  if (!prospectedAccounts) {
    return <></>;
  } else if (prospectedAccounts!.length > 0) {
    return (
      <AccountAndDescription>
        <AccountLabelGroup>
          <AccountIcon provider={prospectedAccounts![0].provider} />
          <Label colored>{`${prospectedAccounts!.length} Accounts`}</Label>
        </AccountLabelGroup>
        <Description>Multiple accounts found</Description>
      </AccountAndDescription>
    );
  }

  return <Description style={{ ...styles }}>No accounts found</Description>;
};
