import React from 'react';
import Styles from './styles';

const { Container, Title, Subtitle, Contents } = Styles;

export const NoFolderItems: React.FC = () => {
  return (
    <Container>
      <Contents>
        <Title>Nothing marked as favorite</Title>
        <Subtitle>Smash the ⭐ next to any Smart Clip™️ to save it as favorite moment.</Subtitle>
      </Contents>
    </Container>
  );
};
