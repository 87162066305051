import React, { useState } from 'react';
import { coreService } from '../../../../../../../../services/core/core-service';
import { useToast } from '../../../../../../../../hooks/useToast';
import SaveInput from '../../../../../../../../componentsV2/SaveInput';

interface Props {
  userId: string;
  disableUserInteractions?: boolean;
  jobTitle: string;
}

const JobTitle: React.FC<Props> = ({ userId, disableUserInteractions, jobTitle }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { error, success } = useToast();

  const handleSave = async (val: string) => {
    setIsLoading(true);
    coreService
      .updateJobTitle({
        userId,
        jobTitle: val,
      })
      .then(() => {
        success('Job Title updated successfully.');
      })
      .catch(() => {
        error('Failed to update Job Title.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SaveInput
      placeholder="Write a Job Title..."
      value={jobTitle}
      loading={isLoading}
      disabled={disableUserInteractions}
      onSave={handleSave}
    />
  );
};

export default JobTitle;
