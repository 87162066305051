import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';
import { AccountFilterRender } from './AccountFilterRender';

export const AccountFilter = () => {
  const { accounts, selectAccounts } = useCustomerIntelligenceFilters();
  const { portfolio } = useInsightsAnalytics();

  return (
    <AccountFilterRender
      accounts={accounts}
      selectAccounts={e => {
        selectAccounts(e);
        portfolio.filtered('account');
      }}
    />
  );
};
