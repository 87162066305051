import React, { useState, useEffect, ReactElement } from 'react';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../services/core/core-service';
import { Summarization } from '../../../../../services/types';
import { Spin } from 'antd';
import { format } from 'date-fns'; //isSameDay, isSameMonth, isSameYear
import { MeetingTitleLink } from '../../../../../components/common/MeetingTitleLink';

import Styles from './style';
const { Container, EmptyState, Text } = Styles;

// const sameDay = (date1: string, date2: string): boolean => {
//   const d1 = new Date(date1);
//   const d2 = new Date(date2);
//   return isSameDay(d1, d2) && isSameMonth(d1, d2) && isSameYear(d1, d2);
// };

const Item: React.FC<{ summarization: Summarization }> = ({ summarization }) => {
  if (summarization.narrative?.length === 0) return <></>;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
      {format(new Date(summarization.lastRequestedAt), 'eeee, MMMM dd, YYY - HH:MM ')}
      {format(new Date(summarization.lastRequestedAt), 'aaa').toUpperCase()}
      <div style={{ display: 'flex', gap: '10px' }}>
        {summarization.events.map(e => (
          <MeetingTitleLink id={e.id} title={e.title} />
        ))}
      </div>
      <Text>
        {summarization.isProcessing ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            AI summary is being generated
            <Spin size="small" />
          </div>
        ) : (
          summarization.narrative
        )}
      </Text>
    </div>
  );
};

export const SummaryHistory: React.FC<{ active: boolean }> = ({ active }) => {
  const [summarization, setSummarization] = useState<Summarization[]>([]);
  const { selectedAccount } = useAccountOverview();

  const { data, isFetching, isRefetching } = useQuery({
    queryKey: ['user-event-summarization', selectedAccount.id, active],
    queryFn: () => {
      return coreService.getAccountEventSummarizations(selectedAccount.id);
    },
    refetchIntervalInBackground: true,
    refetchInterval: 15000,
  });

  // let date: string | undefined = undefined;
  const items: ReactElement[] = [];
  summarization.forEach(summarization => {
    // if (!date || !sameDay(date, summarization.lastRequestedAt)) {
    //   date = summarization.lastRequestedAt;
    //   items.push(
    //     <>
    //       <div>
    //         Generated at: {format(new Date(date), 'eeee, MMMM dd, YYY - HH:MM ')}
    //         {format(new Date(date), 'aaa').toUpperCase()}
    //       </div>
    //       <Item summarization={summarization} />
    //     </>,
    //   );
    // } else {
    //   items.push(<Item summarization={summarization} />);
    // }
    items.push(<Item summarization={summarization} />);
  });

  useEffect(() => {
    const sortedData = data ? [...data?.summarizations] : [];
    sortedData.sort(
      (a, b) => new Date(b.lastRequestedAt).getTime() - new Date(a.lastRequestedAt).getTime(),
    );
    setSummarization(sortedData);
  }, [data]);

  useEffect(() => {
    return;
  }, []);

  return (
    <Container>
      {isFetching && !isRefetching && (
        <Spin style={{ width: '100%', display: 'flex', justifyContent: 'center' }} />
      )}
      {summarization.length === 0 && !isFetching ? (
        <EmptyState>No AI summarizations generated</EmptyState>
      ) : (
        <>{items}</>
      )}
    </Container>
  );
};
