import { useContext } from 'react';
import { alertContext } from '../context/AlertContext';

export const useAlert = () => {
  const { alerts, addAlert, index } = useContext(alertContext);

  const currentAlert = alerts[index];

  return {
    alert: currentAlert,
    addAlert,
  };
};
