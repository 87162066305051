import styled from 'styled-components';
import { gray } from '../../../../../assets/colors';

const AccountAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const AccountLabelGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-style: normal;
  white-space: nowrap;
  color: ${gray};
`;

const Label = styled.span<{ colored?: boolean; underline?: boolean }>`
  font-size: 14px;
  color: ${props => (props.colored ? '#e6614c' : '#282828')};
  border-bottom: ${props => (props.underline ? '1px dashed #ffc4bb' : '')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  display: block;
`;

export default {
  AccountAndDescription,
  AccountLabelGroup,
  Description,
  Label,
};
