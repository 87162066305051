import React from 'react';
import { Container } from './style';

export interface SpinnerProps {
  animation: 'border' | 'grow';
  color?: string;
  size?: 'sm';
  variant?: string;
  style?: object;
  children?: React.ReactNode;
}

const Spinner: React.FC<SpinnerProps> = (props) => {

  const color = props.color ?? '#fff';
  const size = props.size ?? 'sm';

  return (
    <Container color={color} size={size}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
};

export default Spinner;
