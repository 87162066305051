import { Checkbox, Spin } from 'antd';
import { useOrganizationDetails } from '../../../../../features/organizations/hooks/useOrganizationDetails';
import ToggleCard from '../ToggleCard';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../redux/selectors';
import { OptionalTooltipWrapper } from '../../../../../componentsV2/OptionalTooltipWrapper';

export const Announcement: React.FC = () => {
  const org = useOrganizationDetails();

  const tooltipValue =
    'You do not have permission to manage bot announcement settings. Admins and Owners only.';

  return (
    <ToggleCard
      title="Make an announcement when the bot joins a meeting"
      description="When the virtual participant (bot) joins a meeting, an audio/text announcement will be made to all attendees. This setting applies to the entire workspace."
      recommendedSetting="ON (Text) | OFF (Voice)"
      hideToggle
    >
      {!org.isFetching ? (
        <div style={{ display: 'flex' }}>
          <OptionalTooltipWrapper
            display={!org.permissions.manageBotAnnouncement}
            value={tooltipValue}
          >
            <Checkbox
              disabled={!org.permissions.manageBotAnnouncement}
              onChange={e => org.changeBotAnnouncement({ text: e.target.checked })}
              checked={org.botTextAnnouncement}
            >
              Text
            </Checkbox>
          </OptionalTooltipWrapper>

          <OptionalTooltipWrapper
            display={!org.permissions.manageBotAnnouncement}
            value={tooltipValue}
          >
            <Checkbox
              disabled={!org.permissions.manageBotAnnouncement}
              style={{ margin: 0 }}
              onChange={e => org.changeBotAnnouncement({ voice: e.target.checked })}
              checked={org.botVoiceAnnouncement}
            >
              Audio
            </Checkbox>
          </OptionalTooltipWrapper>
        </div>
      ) : (
        <Spin />
      )}
    </ToggleCard>
  );
};
