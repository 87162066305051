import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 350px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.span`
  font-style: italic;
`;
const Text = styled.span``;

export default {
  Container,
  Section,
  Title,
  Text,
};
