import React from 'react';
import Styles from './styles';
import { InsightIcon } from '../../../../features/shared/components/Icons/InsightIcon';
import { InsightSwitchIcon } from '../../../../features/shared/components/Icons/InsightSwitchIcon';
import { useRecapManagement } from '../../../../features/recapv2/recap-management/hook';
import { useRecapAnalytics } from '../../../../features/recapv2/recap-analytics/hooks/useRecapAnalytics';

const { Container, Contents, Switch, Text, Link } = Styles;

export const InsightEngingeToggle: React.FC = () => {
  const { excludeRecapFromInsights, includeRecapFromInsights, excludedFromInsights, permissions } =
    useRecapManagement();
  const { meetingLevelInsightExclusionToggled } = useRecapAnalytics();

  const handleInsightToggleChanged = (value: boolean) => {
    const included = value;
    if (included) {
      includeRecapFromInsights();
    } else {
      excludeRecapFromInsights();
    }
    meetingLevelInsightExclusionToggled(included);
  };

  const preText = excludedFromInsights
    ? `Insights from this meeting recap are currently being excluded from `
    : `Insights from this meeting recap will be included in `;

  return (
    <Container>
      <Contents>
        <InsightIcon />
        <Text>
          {preText}
          <Link href="/accounts" target="_blank">
            Account Insights
          </Link>{' '}
          and{' '}
          <Link href="/insights" target="_blank">
            Portfolio Insights
          </Link>
        </Text>
        <Switch
          disabled={!permissions.canManageRecapExclusion}
          onChange={checked => handleInsightToggleChanged(checked)}
          checked={!excludedFromInsights}
          checkedChildren={<InsightSwitchIcon />}
          unCheckedChildren={<InsightSwitchIcon />}
        />
      </Contents>
    </Container>
  );
};
