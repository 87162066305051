import { IAuthorizationMechanism } from './types';
import { Globe } from '../../../shared/components/Icons/Globe';
import { Lock } from '../../../shared/components/Icons/Lock';

import Styled from './styles';
const { Item } = Styled;

export const authToLogo = (auth: IAuthorizationMechanism) => {
  switch (auth) {
    case IAuthorizationMechanism.public: {
      return (
        <Item>
          Public <Globe style={{ maxWidth: '15px' }} />
        </Item>
      );
    }
    case IAuthorizationMechanism.authorized_only: {
      return (
        <Item>
          User Specific
          <Lock style={{ maxWidth: '15px' }} />
        </Item>
      );
    }
    case IAuthorizationMechanism.requires_identification: {
      return (
        <Item>
          Private <Lock style={{ maxWidth: '15px' }} />
        </Item>
      );
    }
  }
};
