import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { PlusOutlined } from '@ant-design/icons';
import { Library } from './components/Library';
import { NewFolderModal } from './components/NewFolderModal';
import { useLibraries } from '../../features/libraries/hooks/useLibraries';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import useFeatures from '../../hooks/useFeatures';

const { LibraryHeaderActionsContainer } = Styles;

export const Libraries: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<string>('my-library');
  const { userLibrary, organizationLibrary, selectLibrary } = useLibraries();
  const [showNewFolderOptions, setShowNewFolderOptions] = useState({
    show: false,
    location: 'mine',
  });
  useLocationChange();

  const features = useFeatures();
  const availableFeature = features.favoritesFolder;

  const items = [
    {
      key: 'my-library',
      label: `My Library`,
      children: <Library mainFolderName="Starred (Private)" />,
    },
    {
      key: 'my-organization-library',
      label: `Workspace Library`,
      children: <Library mainFolderName="Starred (Workspace)" />,
    },
  ];

  useEffect(() => {
    if (currentTab === 'my-library') {
      if (userLibrary) {
        selectLibrary(userLibrary.id);
        return;
      }
    }

    if (organizationLibrary) {
      selectLibrary(organizationLibrary.id);
    }
  }, [currentTab, userLibrary, organizationLibrary]);

  const handleShowFolder = () => {
    const location = currentTab === 'my-library' ? 'mine' : 'my-organization';
    setShowNewFolderOptions({ show: true, location });
  };

  return (
    <TitledVerticalFlexLayout
      title="Smart Clips™ Favorites Library"
      extraTitle={
        availableFeature ? (
          <LibraryHeaderActionsContainer>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleShowFolder}>
              New folder
            </Button>
          </LibraryHeaderActionsContainer>
        ) : (
          <></>
        )
      }
    >
      <UpgradeToPro plan="startup" display={!availableFeature}>
        <>
          <NewFolderModal
            onCancel={() => setShowNewFolderOptions({ show: false, location: 'mine' })}
            open={showNewFolderOptions.show}
            location={showNewFolderOptions.location}
            onFolderCreated={() => setShowNewFolderOptions({ show: false, location: 'mine' })}
          />
          <Tabs
            activeKey={currentTab}
            onChange={setCurrentTab}
            items={items}
            style={{ width: '100%' }}
          />
        </>
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
