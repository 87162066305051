import React from 'react';
import styles from './styles';
import { ThunderboltFilled, ThunderboltOutlined } from '@ant-design/icons';

export const Tips: React.FC = () => {
  const { Container, Badge, Scroll, Card, Header, Content } = styles;

  return (
    <Container>
      <Badge>
        <ThunderboltFilled style={{ color: '#21A6FF' }} />
        Lightning tips
      </Badge>

      <Scroll>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Global Settings vs. Upcoming
            Meetings:
          </Header>
          <Content>
            This settings page pertains to your global recording rules. You can configure each
            upcoming meeting individually in your{' '}
            <a style={{ color: '#FF6C54' }} href="https://portal.update.ai/meetings/upcoming">
              Upcoming Meetings
            </a>{' '}
            dashboard.
          </Content>
        </Card>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Eligibility for Meeting Recaps:
          </Header>
          <Content>
            Only meetings conducted on Zoom, Microsoft Teams, or Google Meet are eligible for
            meeting recaps.
          </Content>
        </Card>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Zoom Account Connected:
          </Header>
          <Content>
            If your Zoom account is connected:<br></br>- Meetings you host on Zoom will be recorded
            natively by the Zoom cloud (no bot involved).<br></br>- You can pause, stop, and restart
            these meetings using the{' '}
            <a
              style={{ color: '#FF6C54' }}
              target="_blank"
              href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0062627#:~:text=after%2048%20hours.-,How%20to%20start%20a%20cloud%20recording,-Only%20hosts%20and"
            >
              Zoom player controls
            </a>{' '}
            providing you with control over what parts of the meeting to record or keep private.
            <br></br>- Meetings on Zoom that you do <b>not</b> host will be recorded by our bot.
          </Content>
        </Card>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Zoom Account Not Connected:
          </Header>
          <Content>
            If your Zoom account is not connected, all your Zoom meetings will be recorded by our
            bot.
          </Content>
        </Card>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Microsoft Teams and Google Meet:
          </Header>
          <Content>
            For all users, all meetings held on Microsoft Teams or Google Meet will be recorded by
            our bot.
          </Content>
        </Card>
        <Card>
          <Header>
            <ThunderboltOutlined style={{ color: '#21A6FF' }} /> Potential Settings Clash:
          </Header>
          <Content>
            In rare cases, your recording settings may conflict with those of other UpdateAI users
            in your workspace, resulting in potential overrides.
          </Content>
        </Card>
      </Scroll>
    </Container>
  );
};
