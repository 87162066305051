import { PropsWithChildren, createContext } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';
import isMobile from 'is-mobile';
import mixpanel from 'mixpanel-browser';

interface Optionals {
  people?: { [key: string]: string };
  event?: { [key: string]: string };
}

interface AnalyticsContext {
  sendEvent: (eventName: string, optionals?: Optionals) => Promise<null>;
  alias: (userId: string) => Promise<null>;
  reset: () => Promise<null>;
}

export const analyticsContext = createContext<AnalyticsContext>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendEvent: (a, b) => new Promise(() => null),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  alias: a => new Promise(() => null),
  reset: () => new Promise(() => null),
});

export const AnalyticsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const user = useSelector(selectUser);

  const sendEvent = async (eventName: string, optionals?: Optionals) => {
    const env = isMobile() ? 'mobile' : 'desktop';

    try {
      const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

      if (mixPanelToken) {
        mixpanel.init(mixPanelToken, {
          debug: process.env.REACT_APP_PORTAL_ENV === 'development',
          ignore_dnt: true,
        });

        if (user) {
          mixpanel.identify(user.id);

          if (optionals?.people) {
            mixpanel.people.set({ ...optionals.people });
          }

          mixpanel.track(eventName, {
            distinct_id: user.id,
            environment: env,
            ...optionals?.event,
          });
        } else {
          mixpanel.track(eventName, {
            environment: env,
            ...optionals?.event,
          });
        }
      }
    } catch (error) {}
    return null;
  };

  const alias = async (userId: string) => {
    try {
      const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

      if (mixPanelToken) {
        mixpanel.init(mixPanelToken, {
          debug: process.env.REACT_APP_PORTAL_ENV === 'development',
          ignore_dnt: true,
        });

        mixpanel.alias(userId);
      }
    } catch {}
    return null;
  };

  const reset = async () => {
    try {
      const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

      if (mixPanelToken) {
        mixpanel.init(mixPanelToken, {
          debug: process.env.REACT_APP_PORTAL_ENV === 'development',
          ignore_dnt: true,
        });

        mixpanel.reset();
      }
    } catch {}
    return null;
  };

  return (
    <analyticsContext.Provider value={{ sendEvent, alias, reset }}>
      {children}
    </analyticsContext.Provider>
  );
};
