import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ColleaguesSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.25 5.625V7.875M14.25 7.875V10.125M14.25 7.875H16.5M14.25 7.875H12M10.3125 4.78125C10.3125 5.11366 10.247 5.44281 10.1198 5.74992C9.99261 6.05702 9.80616 6.33607 9.57111 6.57111C9.33607 6.80616 9.05702 6.99261 8.74992 7.11982C8.44281 7.24703 8.11366 7.3125 7.78125 7.3125C7.44884 7.3125 7.11969 7.24703 6.81258 7.11982C6.50548 6.99261 6.22643 6.80616 5.99139 6.57111C5.75634 6.33607 5.56989 6.05702 5.44268 5.74992C5.31547 5.44281 5.25 5.11366 5.25 4.78125C5.25 4.10992 5.51668 3.46609 5.99139 2.99139C6.46609 2.51668 7.10992 2.25 7.78125 2.25C8.45258 2.25 9.09641 2.51668 9.57111 2.99139C10.0458 3.46609 10.3125 4.10992 10.3125 4.78125ZM3 14.4263V14.3438C3 13.0757 3.50374 11.8596 4.4004 10.9629C5.29705 10.0662 6.51318 9.5625 7.78125 9.5625C9.04932 9.5625 10.2654 10.0662 11.1621 10.9629C12.0588 11.8596 12.5625 13.0757 12.5625 14.3438V14.4255C11.1191 15.2948 9.46546 15.7528 7.7805 15.75C6.03225 15.75 4.3965 15.2662 3 14.4255V14.4263Z"
        stroke="#9EA9B1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const Colleagues: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={ColleaguesSVG} {...props} />;
};
