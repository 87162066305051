import styled from 'styled-components';

const Container = styled.div`
  height: 80px;
  width: 30px;
  display: flex;
  justify-content: center;
`;

const VolumeContainer = styled.div`
  height: 80px;
  width: 15px;
`;

export default {
  Container,
  VolumeContainer,
};
