import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { OnboardingRedirect } from './OnboardingRedirect';
import { NotLoggedInUser } from './NotLoggedInUser';
import { RequiresPasswordReset } from './RequiresPasswordReset';
import { LoggedInUser } from './LoggedInUser';
import { MobileRoute } from './MobileRoute';
import { Layout } from '@updateai/ui';
import { InvitationPage } from '../pages/invitations';
import { RecapIdResolver } from './RecapIdResolver';
import { RecapDemoResolver } from './RecapDemoResolver';
import { Insights } from '../pages/insights';
import { ErrorView } from '../componentsV2/ErrorView';
import { Settings } from '../pages/settings';
import { Mobile } from '../pages/mobile';
import { Home } from '../pages/home';
import { Onboarding } from '../pages/onboarding';
import { Libraries } from '../pages/libraries';
import { Share } from '../pages/share';
import { Profile } from '../pages/profile';
import { AccountFavourites } from '../pages/account-favourites';
import SuccessView from '../components/common/SuccessView';
import SignupPage from '../pages/signup';
import SigninPage from '../pages/signin';
import ResetPasswordPage from '../pages/reset-password';
import ForgotPasswordPage from '../pages/forgot-password';
import CombinedWrappers from './wrappers/CombinedWrappers';
import { PortfolioInsights } from '../pages/portfolio-insights';
import { Accounts2 } from '../pages/accounts2';

export const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CombinedWrappers />}>
          <Route element={<ProtectedRoute redirectPath={'login'} />}>
            <Route element={<MobileRoute />}>
              <Route element={<OnboardingRedirect />}>
                <Route path="home" element={<Home />} />
                <Route path="settings" element={<Settings />} />
                <Route path="/meetings/upcoming" element={<Home />} />
                <Route path="/meetings/recaps" element={<Home />} />
                <Route path="/libraries" element={<Libraries />} />
                <Route path="/accounts/:accountId" element={<Accounts2 />} />
                <Route path="/accounts" element={<Accounts2 />} />
                <Route path="/accounts/favorites" element={<AccountFavourites />} />
                <Route path="/profile/:id" element={<Profile />} />
                {/* Default private route*/}
                <Route path="*" element={<Home />} />
              </Route>

              <Route path="onboarding" element={<Onboarding />} />
            </Route>

            {/*<Route path="mobile/recap/:id" element={<Recap />} />*/}
            <Route path="mobile/*" element={<Mobile />} />
          </Route>

          <Route element={<LoggedInUser />}>
            <Route element={<RequiresPasswordReset />}>
              <Route path="password/reset" element={<ResetPasswordPage />} />
            </Route>
            <Route path="insights" element={<Insights />} />
            <Route path="portfolio-insights" element={<PortfolioInsights />} />
          </Route>

          <Route element={<NotLoggedInUser />}>
            <Route path="password/recover" element={<ForgotPasswordPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="login" element={<SigninPage />} />
          </Route>

          {/* Public routes */}
          <Route path="share/:id" element={<Share />} />
          <Route path="/invitations/:id/accept" element={<InvitationPage />} />
          <Route path="error" element={<ErrorView />} />
          <Route path="connection-success" element={<SuccessView />} />
          <Route path="recap/demo" element={<RecapDemoResolver />} />
          <Route path="recap/:id" element={<RecapIdResolver />} />

          <Route path="*" element={<Layout children={<SigninPage />} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
