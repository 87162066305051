import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ChevronDownSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4417 14.4459C10.3245 14.5629 10.1657 14.6286 10 14.6286C9.83442 14.6286 9.67556 14.5629 9.55837 14.4459L3.30837 8.19585C3.19797 8.07737 3.13787 7.92067 3.14073 7.75875C3.14359 7.59683 3.20918 7.44234 3.32369 7.32783C3.4382 7.21332 3.59269 7.14773 3.75461 7.14487C3.91652 7.14202 4.07323 7.20212 4.19171 7.31252L10 13.1209L15.8084 7.31252C15.9269 7.20212 16.0836 7.14202 16.2455 7.14487C16.4074 7.14773 16.5619 7.21332 16.6764 7.32783C16.7909 7.44234 16.8565 7.59683 16.8594 7.75875C16.8622 7.92067 16.8021 8.07737 16.6917 8.19585L10.4417 14.4459Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};

export const ChevronDown: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={ChevronDownSVG} {...props} />;
};
