import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const TLDRSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6668 10.4166V5.66663C16.6668 4.26649 16.6668 3.56643 16.3943 3.03165C16.1547 2.56124 15.7722 2.17879 15.3018 1.93911C14.767 1.66663 14.067 1.66663 12.6668 1.66663H7.3335C5.93336 1.66663 5.2333 1.66663 4.69852 1.93911C4.22811 2.17879 3.84566 2.56124 3.60598 3.03165C3.3335 3.56643 3.3335 4.26649 3.3335 5.66663V14.3333C3.3335 15.7334 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8211 4.69852 18.0608C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H10.0002M11.6668 9.16663H6.66683M8.3335 12.5H6.66683M13.3335 5.83329H6.66683M12.0835 15.8333L13.7502 17.5L17.5002 13.75"
        stroke="#5E16EB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TLDR: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon data-testid="tldr-icon" component={TLDRSvg} {...props} />;
};
