import styled from 'styled-components';

export const InsightListContainer = styled.ul`
  margin: 0;
  padding-inline-start: 0;
`;

const Blur = styled.div`
  background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0px;
  width: 100%;
  height: calc(100% - 55px);
  top: 0px;
  z-index: 2;
`;

const BlurMessage = styled.div`
  border-radius: 4px;
  border: 1px dashed var(--new-color-shades-yellow-500, #f9aa0b);
  background: var(--new-color-shades-yellow-50, #fffceb);
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;

  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  color: #b75a06;
`;

export default { Blur, BlurMessage };
