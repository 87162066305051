import React from 'react';
import { Framework, Question } from './type';
import { create } from 'zustand';
import { v4 } from 'uuid';

type FrameworkContextType = {
  frameworks: Framework[];
  actions: {
    setFrameworks: (frameworks: Framework[]) => void;
    addFramework: (framework: Framework) => void;
    removeFramework: (id: string) => void;
    updateFrameworkName: (frameworkId: string, name: string) => void;
    updateFrameworkMeetingType: (frameworkId: string, type: string[]) => void;
    addQuestion: (frameworkId: string, question: Question) => void;
    removeQuestion: (frameworkId: string, questionId: string) => void;
    updateQuestionContext: (
      frameworkId: string,
      questionId: string,
      context: string | null,
    ) => void;
    updateQuestionValue: (frameworkId: string, questionId: string, value: string) => void;
  };
};

export const FrameworkContext = React.createContext<FrameworkContextType>(
  {} as FrameworkContextType,
);

export const FrameworkProvider: React.FC = ({ children }) => {
  const [store] = React.useState(() =>
    create<FrameworkContextType>(set => ({
      frameworks: [],
      actions: {
        setFrameworks: (frameworks: Framework[]) => set({ frameworks }),
        addFramework: (framework: Framework) =>
          set(state => ({ frameworks: [...state.frameworks, framework] })),
        removeFramework: (id: string) =>
          set(state => ({ frameworks: state.frameworks.filter(f => f.id !== id) })),
        updateFrameworkName: (frameworkId: string, name: string) =>
          set(state => {
            const frameworks = state.frameworks.map(f =>
              f.id === frameworkId ? { ...f, name: name } : f,
            );
            return { frameworks };
          }),
        updateFrameworkMeetingType: (frameworkId: string, type: string[]) =>
          set(state => {
            const frameworks = state.frameworks.map(f =>
              f.id === frameworkId ? { ...f, meetingType: type } : f,
            );
            return { frameworks };
          }),
        addQuestion: (frameworkId: string, question: Question) =>
          set(state => ({
            frameworks: state.frameworks.map(f =>
              f.id === frameworkId
                ? {
                    ...f,
                    questions: [
                      ...f.questions,
                      { ...question, id: v4(), position: f.questions.length },
                    ],
                  }
                : f,
            ),
          })),
        removeQuestion: (frameworkId: string, questionId: string) =>
          set(state => ({
            frameworks: state.frameworks.map(f =>
              f.id === frameworkId
                ? { ...f, questions: f.questions.filter(q => q.id !== questionId) }
                : f,
            ),
          })),
        updateQuestionContext: (frameworkId: string, questionId: string, context: string | null) =>
          set(state => ({
            frameworks: state.frameworks.map(f =>
              f.id === frameworkId
                ? {
                    ...f,
                    questions: f.questions.map(q =>
                      q.id === questionId ? { ...q, context: context } : q,
                    ),
                  }
                : f,
            ),
          })),
        updateQuestionValue: (frameworkId: string, questionId: string, value: string) =>
          set(state => ({
            frameworks: state.frameworks.map(f =>
              f.id === frameworkId
                ? {
                    ...f,
                    questions: f.questions.map(q =>
                      q.id === questionId ? { ...q, value: value } : q,
                    ),
                  }
                : f,
            ),
          })),
      },
    })),
  );

  return <FrameworkContext.Provider value={store()}>{children}</FrameworkContext.Provider>;
};
