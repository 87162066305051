import { useMemo } from 'react';
import { Crop } from '../types';

/**
 * Handles markers for cropping. These markers dissapear
 * in public view but are displayed in private view.
 * @param crop Start and end of markers
 * @param duration Duration of the video
 * @returns
 */
export const useMarkers = (crop: Crop | null, duration: number) => {
  const markers = useMemo<[number, number]>(() => {
    if (!crop) {
      return [0, duration];
    }
    return [crop.start, duration - crop.end];
  }, [crop, duration]);

  return {
    markers,
  };
};
