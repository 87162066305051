import { ErrorIndicator } from './assets/ErrorIndicator';
import { SuccessIndicator } from './assets/SuccessIndicator';

const texts = {
  loading: {
    title: 'The invitation is being processed',
    subtitle: 'Hang in there',
    image: null,
  },
  error: {
    title: 'Oops! Something went wrong.',
    subtitle: 'Your invitation cannot be processed. It may have expired.',
    image: ErrorIndicator,
  },
  warning: {
    title: "Oops! We couldn't find your user.",
    subtitle:
      "If you already have an account, please sign in and try again. If you don't, please create one and try again.",
    image: ErrorIndicator,
  },
  success: {
    title: 'Invitation accepted!',
    subtitle: 'You are now part of the workspace',
    image: SuccessIndicator,
  },
};

export const getTexts = (status: 'loading' | 'error' | 'success' | 'warning') => {
  return texts[status];
};
