import React, { useState } from 'react';
import Styles from './styles';
import { Magic } from '../Icons/Magic';
import { AskMeAnythingModal } from './AskMeAnythingModal';
import { ChatItem } from '../../../../services/types';
import { Button, Space } from 'antd';
import useFeatures from '../../../../hooks/useFeatures';
import { colors } from '../../../../theme/colors';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../../componentsV2/UpgradeToPro';

const { Label } = Styles;

interface Props {
  askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
  disabled: boolean;
  location: string;
  customText?: string;
  description?: string;
  styles?: React.CSSProperties;
}

export const AskMeAnything: React.FC<Props> = ({
  disabled,
  askFunction,
  customText,
  styles,
  description,
  location,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const feature = useFeatures();
  const availableFeature = feature.ama;

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const handleClicked = () => {
    if (!disabled) {
      setShowModal(true);
    }
  };

  const isDisabled = disabled || !availableFeature;

  return (
    <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
      <Button
        type="primary"
        onClick={() => handleClicked()}
        disabled={isDisabled}
        style={{ ...styles }}
      >
        <AskMeAnythingModal
          open={showModal}
          description={description}
          onClose={handleModalClosed}
          askFunction={askFunction}
          location={location}
        />
        <Space>
          <Magic style={{ fill: isDisabled ? colors.gray[300] : 'white' }} />

          <Label style={{ flex: 1, color: isDisabled ? colors.gray[300] : 'white' }}>
            {customText || 'Ask me anything'}
          </Label>
        </Space>
      </Button>
    </OptionalTooltipWrapper>
  );
};
