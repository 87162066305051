import { Button } from 'antd';
import styled from 'styled-components';
import LabeledInput from '../_Misc/LabeledInput';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 180px);
  padding-bottom: 20px;
  padding-right: 10px;
  scroll-behavior: smooth;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
  gap: 14px;
`;

export const Buttons = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 20px;
  margin-top: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  align-self: stretch;
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  padding: 24px;
  gap: 4px;
`;

export const DeleteButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 130px;
  background: #fee2e2;
  border: 1px solid #fee2e2;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const LeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0px 16px 0px 16px;
`;
export const Title = styled.div`
  font-family: 'Cera Pro';
  color: #282828;
  flex: none;
  font-style: normal;
  align-self: stretch;
  font-weight: 500;
  height: 24px;
  font-size: 18px;
  line-height: 20px;
  order: 0;
  flex-grow: 0;
`;

export const Detail = styled.div`
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 20px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 320px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  letter-spacing: 0.01rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const PasswordInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 342.5px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  letter-spacing: 0.3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ChangePasswordButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 22px;
  gap: 8px;

  width: 52px;
  height: 20px;
`;

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6a6a6a;
`;

export const ErrorMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: red;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;
export const CompanyLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const Label = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const ChangeAvatarButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 13px;

  width: 100px;
  height: 34px;

  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
`;
export const OrganizationRoleLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
`;
export const CompanyLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 352.5px;
  height: 64px;
`;

export const RoleBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;

  height: 20px;
  background: #fff6c6;
  border-radius: 4px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #b75a06;
  text-transform: capitalize;
`;
