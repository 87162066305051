import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SVGComponent = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11V17M15 14H9M13.06 6.81L10.94 4.69C10.8007 4.55055 10.6353 4.43992 10.4533 4.36442C10.2712 4.28892 10.0761 4.25004 9.879 4.25H4.5C3.90326 4.25 3.33097 4.48705 2.90901 4.90901C2.48705 5.33097 2.25 5.90326 2.25 6.5V18.5C2.25 19.0967 2.48705 19.669 2.90901 20.091C3.33097 20.5129 3.90326 20.75 4.5 20.75H19.5C20.0967 20.75 20.669 20.5129 21.091 20.091C21.5129 19.669 21.75 19.0967 21.75 18.5V9.5C21.75 8.90326 21.5129 8.33097 21.091 7.90901C20.669 7.48705 20.0967 7.25 19.5 7.25H14.121C13.7233 7.24965 13.341 7.09138 13.06 6.81Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NewFolder: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={SVGComponent} {...props} />;
};
