import React from 'react';
import { PublicVisibilityContainer, VisibilityLabel } from './styles';
import { Eye } from '../../../shared/components/Icons/Eye';
import { EyeCrossed } from '../../../shared/components/Icons/EyeCrossed';
import { Earth } from '../../../shared/components/Icons/Earth';
import { EarthCrossed } from '../../../shared/components/Icons/EarthCrossed';

interface Props {
  visible: boolean;
  id?: string;
}

export const PublicVisibilityIndicator: React.FC<Props> = props => {
  const label = props.visible ? 'Publicly Visible' : 'Internal Only';

  return (
    <PublicVisibilityContainer id={props.id}>
      <VisibilityLabel>{label}</VisibilityLabel>
      {props.visible ? <Earth /> : <EarthCrossed />}
    </PublicVisibilityContainer>
  );
};
