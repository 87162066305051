import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const PencilSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M16.2982 1.70152C15.929 1.33238 15.4283 1.125 14.9062 1.125C14.3841 1.125 13.8834 1.33238 13.5142 1.70152L12.6464 2.56927L15.4304 5.35327L16.2982 4.48552C16.6673 4.11631 16.8747 3.61561 16.8747 3.09352C16.8747 2.57143 16.6673 2.07072 16.2982 1.70152ZM14.6347 6.14902L11.8507 3.36502L2.7382 12.4775C2.27534 12.9401 1.93508 13.5108 1.7482 14.138L1.1482 16.1518C1.11923 16.2489 1.11707 16.3521 1.14194 16.4504C1.16682 16.5488 1.21781 16.6385 1.28951 16.7102C1.36121 16.7819 1.45096 16.8329 1.54927 16.8578C1.64757 16.8826 1.75077 16.8805 1.84795 16.8515L3.8617 16.2515C4.48887 16.0646 5.05957 15.7244 5.5222 15.2615L14.6347 6.14902Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};
export const Pencil: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={PencilSVG} {...props} />;
};
