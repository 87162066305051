import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const IconSVG = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="11.5" stroke="#A6A6A6" />
      <circle cx="15" cy="15" r="14.5" stroke="#D1D1D1" />
      <g clipPath="url(#clip0_2_19)">
        <path
          d="M15 11.6667V15M15 18.3333H15.0084M23.3334 15C23.3334 19.6024 19.6024 23.3333 15 23.3333C10.3976 23.3333 6.66669 19.6024 6.66669 15C6.66669 10.3976 10.3976 6.66667 15 6.66667C19.6024 6.66667 23.3334 10.3976 23.3334 15Z"
          stroke="#282828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_19">
          <rect width="20" height="20" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Neutral: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={IconSVG} {...props} />;
};
