import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 378px;
  height: 38px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 11pt;
  font-weight: 500;
  gap: 15px;
  color: #5e5e5e;

  :hover {
    background-color: #4285f40a;
  }
`;

const Logo = styled.img`
  width: 32px;
  height: 32px;
`;

const MicrosoftLabel = styled.span`
  font-size: 15px;
  color: #5e5e5e;
`;

export default {
  Container,
  Logo,
  MicrosoftLabel,
};
