import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Tabs } from 'antd';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    padding-left: 4px;
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav {
    margin: 0px 0px 16px 0px;
  }
`;

const TabRawItem = styled(motion.span)<{ isActive?: boolean }>`
  display: flex;
  height: 40px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background-color: ${props => (props.isActive ? '#fff' : 'none')};
  box-shadow: ${props =>
    props.isActive
      ? '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)'
      : 'none'};
  color: #566976;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 30px;
  transition: background-color 300ms linear;
`;

export default { StyledTabs, TabRawItem };
