import React, { PropsWithChildren, useState } from 'react';
import { ProspectAccount, SelectedAccount } from '../../types';
import { Button, Dropdown, Spin, Tooltip } from 'antd';
import { AccountIcon } from '../../../../shared/components/AccountIcon';
import { Plus } from '../../../../shared/components/Icons/Plus';
import { DownOutlined } from '@ant-design/icons';
import { useAccountSelectionModal } from '../../hooks/useAccountSelectionModal';
import { coreAPI } from '../../../../../API/core';
import { useToast } from '../../../../../hooks/useToast';
import { colors } from '../../../../../theme/colors';
import { ProviderIntegration } from '../../../../../redux/typings/store';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';

import Styles from './styles';
const { AccountLabelContainer, Container } = Styles;

interface Props {
  hasModifyPermissions: boolean;
  prospectAccounts: ProspectAccount[];
  eventId: string;
  account: (SelectedAccount & { mappingId: string }) | null;
  onAccountRemoved: (id: string) => unknown;
  onAccountClicked: (accounts: ProviderIntegration[]) => unknown;
}

export const MeetingAccountSelector: React.FC<Props> = ({
  prospectAccounts,
  eventId,
  account,
  onAccountRemoved,
  onAccountClicked,
}) => {
  const { open } = useAccountSelectionModal();
  const [isRemoving, setIsRemoving] = useState(false);
  const { error: errorToast, success } = useToast();

  const handleChangeAccount = () => {
    open(eventId, prospectAccounts);
  };

  const handleAccountClicked = () => {
    if (onAccountClicked) {
      onAccountClicked(
        account?.id
          ? [{ id: account.mappingId, name: account.name, provider: account.provider }]
          : [],
      );
    }
  };

  const handleOpenAccountInsights = () => {
    if (account) {
      window.open(`/accounts/${account.id}`);
    }
  };

  const handleRemoveAccount = () => {
    const api = new coreAPI();
    setIsRemoving(true);
    api
      .removeRecapAccount(eventId)
      .then(() => {
        onAccountRemoved(eventId);
        success('Account removed');
      })
      .catch(() => {
        errorToast(`There was an error removing the account`);
      })
      .finally(() => setIsRemoving(false));
  };

  const ModalButton: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
      <Button
        type="text"
        size="small"
        style={{
          color: colors.primary[500],
          padding: 0,
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleChangeAccount();
        }}
      >
        {children}
      </Button>
    );
  };

  const ActionButton: React.FC<
    PropsWithChildren<{
      onMouseEnter?: React.MouseEventHandler<HTMLElement>;
      onMouseLeave?: React.MouseEventHandler<HTMLElement>;
    }>
  > = ({ children, onMouseEnter, onMouseLeave }) => {
    return (
      <Button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size="small"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <AccountLabelContainer>
          <AccountIcon provider={account?.provider || ''} styles={{ width: '12px' }} />
          <Tooltip title={account?.name || ''}>
            <span style={{ overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>
              {account?.name || ''}
            </span>
          </Tooltip>
        </AccountLabelContainer>

        {children}
      </Button>
    );
  };

  if (isRemoving) {
    return (
      <Container>
        <Spin size="small" spinning={isRemoving} />
      </Container>
    );
  }

  const dropDownMenuItems: MenuItemType[] = [
    {
      label: 'Filter by this account',
      key: 'filter-account',
      onClick: e => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        handleAccountClicked();
      },
    },
    {
      label: 'Open Account Insights',
      key: 'open-insights',
      onClick: e => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        handleOpenAccountInsights();
      },
    },
    {
      label: 'Change account',
      key: 'change-account',
      onClick: e => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        handleChangeAccount();
      },
    },
    {
      label: 'Remove account',
      key: 'remove-account',
      danger: true,
      onClick: e => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        handleRemoveAccount();
      },
    },
  ];

  if (account) {
    return (
      <Container>
        <Dropdown
          menu={{
            items: dropDownMenuItems,
          }}
        >
          <ActionButton>
            <DownOutlined />
          </ActionButton>
        </Dropdown>
      </Container>
    );
  }

  if (prospectAccounts.length) {
    const suffix = prospectAccounts.length > 1 ? 'accounts' : 'account';
    return (
      <ModalButton>
        {prospectAccounts.length} {suffix} found
      </ModalButton>
    );
  }

  return (
    <Container>
      <ModalButton>
        <div style={{ display: 'flex', alignContent: 'center', gap: '2px' }}>
          <Plus style={{ marginTop: '-2px' }} />
          <span>Link to CRM</span>
        </div>
      </ModalButton>
    </Container>
  );
};
