import React from 'react';
import Styles from './styles';
import { Checkbox } from 'antd';
import { AccountLabel } from '../../../AccountLabel';
import { ProviderIntegration } from '../../../../../../redux/typings/store';

const { AccountContainer, Container } = Styles;

interface Props {
  account: ProviderIntegration;
  checked: boolean;
  onCheck: (value: boolean) => unknown;
}

export const ResultItem: React.FC<Props> = ({ account, checked, onCheck }) => {
  return (
    <Container>
      <AccountContainer>
        <AccountLabel account={account} onClick={() => onCheck(!checked)} />
        {/*<Space onClick={() => onCheck(!checked)}>
          <AccountIcon provider={provider} />
          <span>{name}</span>
  </Space>*/}
      </AccountContainer>
      <Checkbox checked={checked} onChange={e => onCheck(e.target.checked)} />
    </Container>
  );
};
