import styled from 'styled-components';

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 500px;
  overflow: auto;
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default {
  Contents,
  ArticleContainer,
};
