import React from 'react';
import { CRMOrganizationSection } from './CRMOrganizationSection';
import { UpdateLoader } from '../../../../../../componentsV2/UpdateLoader';
import { PropertyMappingProvider } from '../../../../../../features/crm/property-mapping/components/PropertyMappingProvider';
import Section from '../../../_Misc/Section';
import Styled from './style';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';

const { Container } = Styled;

export const CRMSetupSection: React.FC<{} & { ref: React.Ref<HTMLDivElement> }> = React.forwardRef<
  HTMLDivElement,
  {}
>((props, ref) => {
  const { isFetching, permissions } = useOrganizationDetails();

  return (
    <Section
      title={'CRM Setup'}
      description="Organize your CRM integration by mapping the most important account properties to UpdateAI."
      isBeta
      learnMore="https://help.update.ai/en/articles/7211777-how-to-integrate-salesforce-or-hubspot-crm-into-updateai"
    >
      <Container>
        {isFetching ? (
          <UpdateLoader min />
        ) : (
          <PropertyMappingProvider>
            <CRMOrganizationSection disableUserInteractions={!permissions.connectPrimaryCrm} />
          </PropertyMappingProvider>
        )}
      </Container>
    </Section>
  );
});
