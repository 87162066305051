import { chunk } from 'lodash';
import { useMemo } from 'react';

const loadImage = (source: string) => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = img.onerror = () => {
      resolve(null);
    };
    img.src = source;
  });
};

export const useImagePreloader = (images: Array<string>, paralel?: number) => {
  useMemo(async () => {
    const paralelImages = paralel || images.length;
    const chunks = chunk(images, paralelImages);

    for (let i = 0; i < chunks.length; i++) {
      const currentChunk = chunks[i];
      await Promise.all(currentChunk.map(loadImage));
    }
  }, [images]);
};
