import React, { useEffect } from 'react';
import Styles from './styles';
import { Controls } from './Controls';
import { useVideoPlayer } from '../../hooks';

const { Container, Video } = Styles;

interface Props {
  src: string;
}

export const VideoPlayer: React.FC<Props> = ({ src }) => {
  const { setVideoPlayerRef, fullscreen, isEditable, toggleFullscreen } = useVideoPlayer();

  useEffect(() => {
    const eventListener = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape' && fullscreen) {
        toggleFullscreen(false);
      }
    };

    document.addEventListener('keydown', eventListener);

    return () => {
      document.removeEventListener('keydown', eventListener);
    };
  }, [fullscreen, toggleFullscreen]);

  return (
    <Container fullscreen={fullscreen}>
      <Video
        src={src}
        ref={videoPlayerRef => setVideoPlayerRef(videoPlayerRef)}
        poster="https://assets.update.ai/video_poster.png"
      />
      <Controls isEditable={isEditable} />
    </Container>
  );
};
