import { PlusOutlined } from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Button, Divider, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Copy } from '../../../shared/components/Icons/Copy';
import { PublicVisibilityIndicator } from '../PublicVisibilityIndicator/PublicVisibilityIndicator';
import Styles from './styles';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';

const { ButtonGroup, SectionCardContainer, SectionCardHeader, SectionCardTitle } = Styles;
type VisibilityConfig =
  | {
      disabled: true;
    }
  | { disabled: false; onClick: () => unknown };
interface CommonsProps {
  label: string;
  onCopy: () => unknown;
  icon: React.JSXElementConstructor<Partial<CustomIconComponentProps>>;
  color: string;
  isPubliclyVisible: boolean;
  visibility: VisibilityConfig;
  children: React.ReactElement | React.ReactElement[];
}

type PropsWithAddAction = {
  showAdd: true;
  onAdd: () => unknown;
} & CommonsProps;

type PropsWithoutAddAction = {
  showAdd: false;
  onAdd?: () => unknown;
} & CommonsProps;

type AppcuesId = {
  appcuesId?: string;
};

type Props = (PropsWithAddAction | PropsWithoutAddAction) & AppcuesId;

export const SectionCard: React.FC<Props> = ({
  label,
  icon: Icon,
  color,
  children,
  showAdd = true,
  onAdd,
  onCopy,
  isPubliclyVisible,
  visibility,
  appcuesId,
}) => {
  const { isPublic, isBasic } = useRecapManagement();

  const [shouldShowButtons, setShouldShowButtons] = useState(false);

  const onPublicVisibilityChange = () => {
    if (!visibility.disabled) {
      visibility.onClick();
    }
  };

  const onMouseEnterContainer = () => {
    setShouldShowButtons(true);
  };

  const onMouseLeaveContainer = () => {
    setShouldShowButtons(false);
  };

  return (
    <SectionCardContainer onMouseEnter={onMouseEnterContainer} onMouseLeave={onMouseLeaveContainer}>
      <SectionCardHeader>
        <Space style={{ color }}>
          {/*<Icon style={{ fontSize: '20px' }} />*/}

          <SectionCardTitle>{label}</SectionCardTitle>

          {shouldShowButtons && (
            <ButtonGroup>
              <Button onClick={onCopy} icon={<Copy />} type="text" />
              {!isPublic && !isBasic && showAdd && (
                <>
                  <Divider type="vertical" style={{ margin: '4px' }} />
                  <Button icon={<PlusOutlined />} onClick={onAdd} type="text" />
                </>
              )}
            </ButtonGroup>
          )}
        </Space>

        <Space size={'small'}>
          {!isPublic && !isBasic && (
            <Tooltip title={visibility.disabled ? 'This cannot be changed' : 'Click to toggle'}>
              <Space
                style={{ cursor: visibility.disabled ? 'default' : 'pointer' }}
                onClick={onPublicVisibilityChange}
              >
                <PublicVisibilityIndicator visible={isPubliclyVisible} id={appcuesId} />
              </Space>
            </Tooltip>
          )}
        </Space>
      </SectionCardHeader>

      <div>{children}</div>
    </SectionCardContainer>
  );
};
