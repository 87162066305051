import React, { useMemo, useState } from 'react';
import { AccountStoryProvider } from '../../../../features/account/account-story/context/account-story-context';
import { AccountStory } from './AccountStory';
import { useAccountOverview } from '../../../../features/account/account-overview/account-overview.hook';
import { AskMeAnythingProvider } from '../../../../features/askMeAnything/context/AskMeAnythingProvider';
import { Questions } from '../../../../features/askMeAnything/questions';
import { ChatItem } from '../../../../services/types';
import { useAskMeAnything } from '../../../../features/askMeAnything/hooks/useAskMeAnything';
import { AskMeAnythingChat2 } from '../../../../features/shared/components/AskMeAnything/AskMeAnythingChat2';
import TabComponent from '../../../../features/shared/components/TabV2';
import Styles from './style';

const { Container, Story } = Styles;
const { Tab, TabItem } = TabComponent;

export const ACCOUNT_DATE_FORMAT = 'MMM dd, yyyy';

export const RightTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('account-story');
  const { selectedAccount } = useAccountOverview();
  const { askAccounts } = useAskMeAnything();

  const handleSelectedTab = (selectedTab: string) => {
    setSelectedTab(selectedTab);
  };

  const items = useMemo(() => {
    if (!selectedAccount.id) return [];
    const partialTabs = [
      {
        key: 'account-story',
        label: <TabItem isActive={selectedTab === 'account-story'} label="Account Story" />,
        children: (
          <Story>
            <AccountStoryProvider accountId={selectedAccount.id}>
              <AccountStory
                accountName={selectedAccount.name || 'Account'}
                onNotGeneratedStory={() => setSelectedTab('ama')}
              />
            </AccountStoryProvider>
          </Story>
        ),
      },
      {
        key: 'ama',
        label: <TabItem isActive={selectedTab === 'ama'} label="AMA" />,
        children: (
          <AskMeAnythingProvider
            amaKey={selectedAccount.id}
            suggestedQuestions={{
              data: Questions.accounts,
              category: 'all',
              displayDropdown: false,
            }}
          >
            <AskMeAnythingChat2
              location="accounts"
              askFunction={(chatMessages: ChatItem[]) =>
                askAccounts(chatMessages, selectedAccount.id, null, null)
              }
            />
          </AskMeAnythingProvider>
        ),
      },
    ];

    return partialTabs;
  }, [selectedTab, selectedAccount]);

  if (!selectedAccount.id) return <></>;

  return (
    <Container>
      <Tab items={items} activeKey={selectedTab} onChange={handleSelectedTab} />
    </Container>
  );
};
