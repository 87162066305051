import { PropsWithChildren, useState } from 'react';
import { GenerativeAIItem } from '../../../../redux/typings/recap';
import { ChunkNavigationContext } from './ChunkNavigationContext';

export const ChunkNavigationProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [itemData, setItemData] = useState<{
    color: string;
    item: GenerativeAIItem;
    highlightColor: string;
    index: number;
    category: string;
  } | null>(null);
  const [messageIdPlaying, setMessageIdPlaying] = useState('');

  return (
    <ChunkNavigationContext.Provider
      value={{ itemData, setItemData, messageIdPlaying, setMessageIdPlaying }}
    >
      {children}
    </ChunkNavigationContext.Provider>
  );
};
