import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const IconSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78562 3.38559C10.1607 3.01047 10.6695 2.79973 11.2 2.79973C11.7305 2.79973 12.2393 3.01047 12.6144 3.38559C12.9895 3.76071 13.2003 4.26949 13.2003 4.79999C13.2003 5.33049 12.9895 5.83927 12.6144 6.21439L11.6344 7.19359C11.5252 7.3068 11.4648 7.45839 11.4662 7.61571C11.4677 7.77303 11.5308 7.92349 11.6421 8.03468C11.7534 8.14587 11.9039 8.2089 12.0613 8.2102C12.2186 8.21149 12.3701 8.15094 12.4832 8.04159L13.4624 7.06239C14.0453 6.45886 14.3679 5.65054 14.3606 4.81151C14.3533 3.97248 14.0167 3.16988 13.4234 2.57657C12.8301 1.98327 12.0275 1.64672 11.1885 1.63943C10.3495 1.63214 9.54115 1.95468 8.93762 2.53759L6.53762 4.93759C6.2259 5.24934 5.98195 5.62214 5.82109 6.03261C5.66023 6.44308 5.58592 6.88236 5.60282 7.32291C5.61972 7.76345 5.72746 8.19574 5.9193 8.59268C6.11114 8.98962 6.38293 9.34264 6.71762 9.62959C6.83868 9.73066 6.99466 9.78 7.15181 9.76695C7.30897 9.75389 7.45467 9.67949 7.55739 9.55984C7.66012 9.44019 7.71161 9.28491 7.70072 9.12759C7.68983 8.97026 7.61745 8.82355 7.49922 8.71919C7.28979 8.53992 7.11969 8.31928 6.99958 8.07113C6.87948 7.82299 6.81198 7.55269 6.80131 7.27722C6.79064 7.00174 6.83704 6.72703 6.9376 6.47034C7.03815 6.21365 7.19068 5.98052 7.38562 5.78559L9.78562 3.38559Z"
        fill="#FF9887"
      />
      <path
        d="M9.28239 6.3704C9.16133 6.26934 9.00536 6.22 8.8482 6.23305C8.69104 6.2461 8.54534 6.3205 8.44262 6.44015C8.3399 6.5598 8.28841 6.71509 8.29929 6.87241C8.31018 7.02973 8.38257 7.17644 8.50079 7.2808C8.71022 7.46008 8.88033 7.68072 9.00043 7.92886C9.12053 8.17701 9.18804 8.4473 9.1987 8.72278C9.20937 8.99825 9.16297 9.27296 9.06242 9.52965C8.96187 9.78634 8.80933 10.0195 8.61439 10.2144L6.21439 12.6144C5.83927 12.9895 5.33049 13.2003 4.79999 13.2003C4.26949 13.2003 3.76071 12.9895 3.38559 12.6144C3.01047 12.2393 2.79973 11.7305 2.79973 11.2C2.79973 10.6695 3.01047 10.1607 3.38559 9.7856L4.36559 8.8064C4.47483 8.69319 4.53524 8.5416 4.5338 8.38428C4.53236 8.22697 4.46919 8.07651 4.35789 7.96531C4.24659 7.85412 4.09607 7.79109 3.93875 7.7898C3.78143 7.78851 3.6299 7.84906 3.51679 7.9584L2.53759 8.9376C2.23196 9.23279 1.98818 9.5859 1.82047 9.97631C1.65276 10.3667 1.56448 10.7866 1.56079 11.2115C1.5571 11.6364 1.63806 12.0578 1.79896 12.4511C1.95986 12.8443 2.19747 13.2016 2.49793 13.5021C2.79838 13.8025 3.15567 14.0401 3.54894 14.201C3.94221 14.3619 4.36358 14.4429 4.78848 14.4392C5.21337 14.4355 5.63327 14.3472 6.02369 14.1795C6.4141 14.0118 6.7672 13.768 7.06239 13.4624L9.46239 11.0624C9.77412 10.7507 10.0181 10.3779 10.1789 9.96738C10.3398 9.55691 10.4141 9.11763 10.3972 8.67709C10.3803 8.23655 10.2725 7.80425 10.0807 7.40731C9.88887 7.01038 9.61708 6.65736 9.28239 6.3704Z"
        fill="#FF9887"
      />
    </svg>
  );
};
export const Link: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={IconSVG} {...props} />;
};
