export const timelineToObj = (
  timelineSeries: { name: string; data: { x: string; y: number }[] }[],
) => {
  const seriesObj: { name: string; date: string; value: number }[] = [];

  timelineSeries.forEach(v => {
    v.data.forEach(vd => {
      seriesObj.push({
        name: v.name,
        date: vd.x,
        value: vd.y,
      });
    });
  });

  return seriesObj;
};
