import styled from 'styled-components';

const Label = styled.span`
  overflow: hidden;
  color: #6a6a6a;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
`;

export default { Label };
