import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 1;
  padding: 16px;
  flex-shrink: 0;
  gap: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(13, 40, 59, 0.06), 0px 1px 3px 0px rgba(13, 40, 59, 0.1);
`;

const Title = styled.span`
  color: ${colors.coolGray['500']};
  /* Text Styles/Heading 6 */
  font-family: Cera Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 340px);
  overflow-y: auto;
`;

const SelectedItemsCount = styled.span`
  color: var(--blue-700, #0675fc);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const SelectedItemsActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const DeleteLabel = styled.span`
  color: var(--red-500, #ee4e4e);

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  cursor: pointer;
`;

const DeleteAction = styled(SelectedItemsActions)`
  gap: 8px;
`;

const FullHeihgtContainer = styled.div`
  height: calc(100vh - 340px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Container,
  Title,
  ItemsContainer,
  SelectedItemsActions,
  SelectedItemsCount,
  DeleteLabel,
  DeleteAction,
  FullHeihgtContainer,
};
