import { Button, Input } from 'antd';
import React, { useState } from 'react';
import Style from './style';
import { colors } from '../../theme/colors';
import { CloseOutlined } from '@ant-design/icons';
interface Props {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  onSave: (v: string) => Promise<void>;
}

const { Container, Actions } = Style;

const SaveInput: React.FC<Props> = ({ value, placeholder, disabled, loading, onSave }) => {
  const [val, setVal] = useState(value);
  const [updatedVal, setUpdatedVal] = useState(value);
  const [showActions, setShowActions] = useState(false);

  if (disabled)
    return (
      <div
        style={{
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {val}
      </div>
    );

  return (
    <Container onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}>
      <Input
        disabled={disabled || loading}
        placeholder={placeholder}
        value={updatedVal}
        suffix={showActions ? <div style={{ width: '75px' }} /> : <></>}
        onChange={e => setUpdatedVal(e.target.value)}
      />

      {(showActions || loading) && (
        <Actions>
          <Button
            shape="circle"
            size="small"
            style={{
              fontSize: '8px',
              color: colors.white,
              backgroundColor: colors.gray[200],
            }}
            loading={loading}
            disabled={disabled || loading}
            onClick={() => setUpdatedVal(val)}
            icon={<CloseOutlined />}
          />

          <Button
            type="primary"
            size="small"
            disabled={disabled || loading}
            loading={loading}
            onClick={() => {
              setShowActions(true);
              onSave(updatedVal).then(() => setVal(updatedVal));
            }}
          >
            Save
          </Button>
        </Actions>
      )}
    </Container>
  );
};

export default SaveInput;
