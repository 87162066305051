import { PropsWithChildren, createContext, useEffect, useRef } from 'react';
import { AccountSearchStore, createAccountSearchStore } from './account-search.store';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../services/core/core-service';
import { useStore } from 'zustand';

interface AccountSearchContext {
  store: AccountSearchStore;
}

export const accountSearchContext = createContext<AccountSearchContext>({
  store: createAccountSearchStore('home'),
});

interface Props {
  searchId: string;
}

export const AccountSearchProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  searchId,
}) => {
  const store = useRef(createAccountSearchStore('account')).current;

  const accountsSet = useStore(store, store => store.accountsSet);
  const fetchStatusChanged = useStore(store, store => store.fetchStatusChanged);

  const { data, isFetching } = useQuery({
    queryKey: ['accounts-initial-fetch', searchId],
    queryFn: async () => {
      const accounts = await coreService.getAccounts();
      return accounts;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      accountsSet(data.accounts);
    }
  }, [data]);

  return (
    <accountSearchContext.Provider value={{ store }}>{children}</accountSearchContext.Provider>
  );
};
