import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const PauseSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="#F3F4F5"
        fillRule="evenodd"
        d="M5.625 4.375a.625.625 0 0 1 .625-.625H7.5a.625.625 0 0 1 .625.625v11.25a.625.625 0 0 1-.625.625H6.25a.625.625 0 0 1-.625-.625V4.375Zm6.25 0a.625.625 0 0 1 .625-.625h1.25a.625.625 0 0 1 .625.625v11.25a.624.624 0 0 1-.625.625H12.5a.624.624 0 0 1-.625-.625V4.375Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export const Pause: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={PauseSVG} {...props} />;
};
