import { createContext } from 'react';
import { PastMeetingStore, createPastMeetingsStore } from './past-meetings.store';

interface PastMeetingsContext {
  store: PastMeetingStore;
}

export const pastMeetingsContext = createContext<PastMeetingsContext>({
  store: createPastMeetingsStore(),
});
