import React, { ReactElement, ReactNode, useMemo } from 'react';
import { ClearOptions } from '../types';
import { Panel } from '../Panel';
import { filterElements, hasDisplayName } from '../../../../../utils/react';
import Styles from './styles';

const { Contents } = Styles;

interface Props {
  clear?: ClearOptions;
  children: ReactNode[] | ReactNode;
  onPanelChange?: (id: string) => unknown;
}

export const DefaultPanel: React.FC<Props> = ({ children, clear, onPanelChange }) => {
  const childrens: ReactNode[] = useMemo(() => {
    if (Array.isArray(children)) return children;
    else return [children];
  }, [children]);

  const renderedChildrens = useMemo(() => {
    const elements: ReactElement[] = React.Children.toArray(childrens) as ReactElement[];
    const sections = filterElements(elements, 'FilterSection');

    return sections.map(section => {
      const sectionChildrens: ReactElement[] = React.Children.toArray(
        section.props.children,
      ) as ReactElement[];
      return React.cloneElement(
        section,
        section.props,
        sectionChildrens.map((element: ReactElement) => {
          if (hasDisplayName(element, 'PanelLink')) {
            return React.cloneElement(element, {
              ...element.props,
              onClick: () => onPanelChange && onPanelChange(element.props.panelId),
            });
          }
          return element;
        }),
      );
    });
  }, [childrens]);

  return (
    <Panel clear={clear} panelId="default" title="Filter options">
      <Contents>{renderedChildrens}</Contents>
    </Panel>
  );
};

DefaultPanel.displayName = 'DefaultPanel';
