import styled from 'styled-components';

const Container = styled.div<{ height?: string | number | undefined }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  height: ${({ height }) => height || 'auto'};
`;

export default {
  Container,
};
