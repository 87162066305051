import React from 'react';
import Styles from './styles';
import { Space } from 'antd';
import { AccountIcon } from '../../../../../../shared/components/AccountIcon';

const { AccountContainer, Container } = Styles;

interface Props {
  provider: string;
  name: string;
  selected: boolean;
  onClick: () => unknown;
}

export const ResultItem: React.FC<Props> = ({ provider, name, selected, onClick }) => {
  return (
    <Container selected={selected}>
      <AccountContainer onClick={onClick}>
        <Space>
          <AccountIcon provider={provider} />
          <span>{name}</span>
        </Space>
      </AccountContainer>
    </Container>
  );
};
