import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MeetingTitle = styled.span`
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const MeetingDate = styled.span`
  overflow: hidden;
  color: var(--N-900, #0d283b);
  text-overflow: ellipsis;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MeetingDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default {
  Container,
  MeetingTitle,
  MeetingDate,
  MeetingDetails,
};
