import React from 'react';
import Styles from './styles';
import { categoriesMetadata } from '../../utils';

const { Container, Section, Text, Title } = Styles;

interface Props {
  type: string;
  category: string;
}

export const CategoryContext: React.FC<Props> = ({ type, category }) => {
  const { description, customContext } = categoriesMetadata[type][category];
  return (
    <Container>
      <Section>
        <Title>Category Description</Title>
        <Text>{description}</Text>
      </Section>
      <Section>
        <Title>Recommended Custom Context</Title>
        <Text>{customContext}</Text>
      </Section>
    </Container>
  );
};
