import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { logoutUser } from '../../redux/slices/sessionSlice';
import { useAnalytics } from '../../features/analytics/hooks/useAnalytics';
import Styles from './style';
import UpdateLogo from '../../components/common/UpdateLogo';

const { Container, Disclaimer, Text } = Styles;

export const Mobile = () => {
  const dispatch = useDispatch();
  const { mobile } = useAnalytics();

  useEffect(() => {
    mobile.viewed();
  }, []);

  return (
    <Container>
      <UpdateLogo />
      <img src="https://assets.update.ai/mobile-building.png" style={{ height: '50vw' }} />
      <Text>Video playback is not currently available on mobile.</Text>
      <Disclaimer>Please visit this recap on your desktop.</Disclaimer>
      <Button onClick={() => dispatch(logoutUser())} icon={<LogoutOutlined />}>
        Logout
      </Button>
    </Container>
  );
};
