import React, { useState } from 'react';
import { LogoButton, NavigationBarContainer, Title, Details, Host } from './styles';
import { VisibilityConfig } from '../../../VisibilityConfig/VisibilityConfig';
import { Space } from 'antd';
import { ShareBox } from '../../../ShareBox';
import { AccountSelector } from '../../../AccountSelector/AccountSelector/AccountSelector';
import { useNavigate } from 'react-router-dom';
import { recapFormatDateShort } from '../../../../../../helpers/DateHelper';
import { PublicRecapBadge } from '../../../PublicRecapBadge';
import { EditableTitle } from '../EditableTitle/index';
import { useRecapManagement } from '../../../../../recapv2/recap-management/hook';
import { MeetingTypeSelector } from '../../../../../home/tabs/UpcomingsTab/components/MeetingTypeSelector';
import logo from '../../../../../../assets/svg/brandingLogo.svg';
import arrow from '../../../../../../assets/svg/arrow-left.svg';

export const NavigationBar: React.FC = () => {
  const { isPublic, type, started, ended, scheduledStartDate, host, id, permissions } =
    useRecapManagement();

  const navigate = useNavigate();

  const [hoveredLogo, setHoveredLogo] = useState(false);

  const startDate = scheduledStartDate || started || new Date();
  const endedDate = ended || new Date();

  const startTime = startDate.toISOString();
  const endTime = endedDate.toISOString();

  const goToRecaps = () => {
    navigate('/meetings/recaps');
  };

  return (
    <NavigationBarContainer>
      <Space>
        <LogoButton
          onClick={goToRecaps}
          onMouseEnter={() => setHoveredLogo(true)}
          onMouseLeave={() => setHoveredLogo(false)}
        >
          {hoveredLogo ? <img src={arrow} /> : <img src={logo} />}
        </LogoButton>

        <Title>
          <EditableTitle />
          <Details>
            <>{recapFormatDateShort(startTime, endTime)}</>
            <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>|</div>
            <div style={{ fontFamily: 'Cera Pro Medium' }}>Host</div>
            <Host>: {host}</Host>
          </Details>
        </Title>
      </Space>

      <Space style={{ paddingRight: '24px' }}>
        {isPublic ? (
          <Space>
            <PublicRecapBadge />
          </Space>
        ) : (
          <>
            <AccountSelector />
            <MeetingTypeSelector
              meetingType={type}
              eventId={id}
              size="middle"
              hasPermissions={permissions.canChangeMeetingTypes}
            />
            <ShareBox />
            <VisibilityConfig />
          </>
        )}
      </Space>
    </NavigationBarContainer>
  );
};
