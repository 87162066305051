import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';
import { colors } from '../../../../theme/colors';

const IconSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.79961 2.19998C2.79961 2.04085 2.7364 1.88823 2.62387 1.77571C2.51135 1.66319 2.35874 1.59998 2.19961 1.59998C2.04048 1.59998 1.88787 1.66319 1.77535 1.77571C1.66282 1.88823 1.59961 2.04085 1.59961 2.19998V13.8C1.59961 13.9591 1.66282 14.1117 1.77535 14.2242C1.88787 14.3368 2.04048 14.4 2.19961 14.4C2.35874 14.4 2.51135 14.3368 2.62387 14.2242C2.7364 14.1117 2.79961 13.9591 2.79961 13.8V10.2864L4.12521 10.008C5.28292 9.76423 6.48923 9.92579 7.54201 10.4656C9.0172 11.2221 10.7295 11.3725 12.314 10.8848L13.9764 10.3728C14.0989 10.335 14.206 10.259 14.2822 10.156C14.3583 10.0529 14.3995 9.92813 14.3996 9.79998V2.99998C14.3996 2.91073 14.3797 2.82261 14.3413 2.74203C14.303 2.66145 14.2471 2.59045 14.1778 2.5342C14.1085 2.47794 14.0276 2.43785 13.9408 2.41685C13.8541 2.39584 13.7638 2.39445 13.6764 2.41278L11.7724 2.81358C10.6519 3.04945 9.48674 2.94841 8.42361 2.52318L8.02201 2.36318C6.73877 1.84957 5.3322 1.72737 3.97961 2.01198L2.79961 2.25998V2.19998Z"
        fill={colors.primary[500]}
      />
    </svg>
  );
};
export const BlueFlag: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon data-testid="blue-flag" component={IconSVG} {...props} />;
};
