import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

interface Props {
  data: {
    series: {
      name: string;
      total: number;
    }[];
  };
}

export const CumulativeChart: React.FC<Props> = ({ data }) => {
  const sortedGroups = data.series.sort((a, b) => b.total - a.total);
  const categoriesData = sortedGroups.map(category => category.total);
  const categoriesSeries = sortedGroups.map(category => category.name);

  const categoriesTooltips = useMemo(() => {
    const total = categoriesData.reduce((a, b) => a + b, 0);
    const percentages = categoriesData.map(categoryValue =>
      Math.round((categoryValue / total) * 100),
    );
    return percentages;
  }, [categoriesData]);

  return (
    <Chart
      key={'bar'}
      series={[
        {
          data: categoriesData,
          type: 'bar',
        },
      ]}
      type="bar"
      width={'100%'}
      height={'320px'}
      options={{
        tooltip: {
          y: {
            formatter: (value, { dataPointIndex }) => {
              return `${value} detections | ${categoriesTooltips[dataPointIndex]}% of total`;
            },
            title: {
              formatter: () => '',
            },
          },
        },
        chart: {
          type: 'bar',
          fontFamily: 'Cera Pro',
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (clickEvent, chartConfig, dataPoint) => {
              // onCategorySelected(dataPoint.dataPointIndex);
            },
            xAxisLabelClick: (clickEvent, chart, dataPointOptions) => {
              // onCategorySelected(dataPointOptions.labelIndex);
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            opacity: 0.1,
          },
          column: {
            opacity: 0.1,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true,
            barHeight: '11px',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: categoriesSeries,
          labels: {
            style: {
              cssClass: 'chart-label',
            },
          },
          title: {
            text: 'Number of Mentions',
            style: {
              fontSize: '12px',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: '12px',
            },
          },
        },
      }}
    />
  );
};
