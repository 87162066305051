import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #eff6ff;
  gap: 30px;
  width: 100vw;
  height: 100vh;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #303b44;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: -20px;
  color: #303b44;
  text-align: center;
`;

const Disclaimer = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: black;
  text-align: center;
`;

export default { Container, Title, Text, Disclaimer };
