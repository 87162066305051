import styled from 'styled-components';

export const RecapBody = styled.div`
  max-height: 1080px;
  padding: 0px 32px 32px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
`;

export const Gradient = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 128px;
  padding: 0px 32px;
  pointer-events: none;
`;

export const GradientContainer = styled.div`
  background: linear-gradient(360deg, #f0f0f0 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  pointer-events: none;
`;

export const RecapContentsSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 16px;
  height: calc(100% - 32px);
  margin: auto;
`;

export const SmartSummaryContainer = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const VideoTranscriptContainer = styled.div`
  flex: 3;
  max-width: 600px;
`;

export const CardContainers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: auto;
`;

export const EmptyState = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px dashed #a6a6a6;
`;

export const EmptyStateCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  width: 125px;
  height: 125px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 65px;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MessageContent = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
`;

export const NoItemsTitle = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  align-items: center;
  color: #282828;
  text-align: center;
  font-size: 18px;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const NoItemsDescription = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  align-items: center;
  color: #6a6a6a;
  text-align: center;
  font-size: 14px;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const TabPane = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;
