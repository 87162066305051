import React from 'react';
import Styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import IconSvg from '../../../../../../components/common/IconSvg';
import { Avatar, Tooltip } from 'antd';
import { AskMeAnythingMessage } from '../../../../../askMeAnything/types';
import { useMessageReferences } from '../../hooks/useMessageReferences';
import { CalendarOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

const {
  Container,
  PulsingDot,
  MessageContainer,
  Message,
  Timestamp,
  MessageBuble,
  TooltipContent,
} = Styles;

interface Props {
  messages: AskMeAnythingMessage[];
}

export const MessagesList: React.FC<Props> = ({ messages }) => {
  const { assignReferencesToMessages } = useMessageReferences();

  return (
    <Container>
      {assignReferencesToMessages(messages).map((message, messageIndex) => {
        const isUpdateSender = !message.isSenderUser;
        const content = ReactHtmlParser(message.content).map((element, idx) => {
          if (element.type === 'a') {
            return (
              <Tooltip
                overlayStyle={{ maxWidth: '600px' }}
                title={
                  <TooltipContent>
                    <h1>
                      <CalendarOutlined /> {decodeURI(element.props.title)}
                    </h1>

                    <h3>
                      @ {decodeURI(format(new Date(element.props.date), 'EEE, MMM dd - hh:mm aa'))}
                    </h3>

                    <h2>{decodeURI(element.props.text)}</h2>

                    <h4>
                      <a href={element.props.href} target="_blank">
                        Click for more
                      </a>
                    </h4>
                  </TooltipContent>
                }
                key={idx + '-tooltip-anchor-reference'}
              >
                {element}
              </Tooltip>
            );
          }
          return element;
        });

        return (
          <MessageContainer
            id={`ama-${messageIndex}`}
            key={messageIndex}
            isUpdateAI={isUpdateSender}
          >
            <Message isUpdateAI={isUpdateSender}>
              <Avatar
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '32px',
                  height: '32px',
                  backgroundColor: '#FFE6E2',
                  color: '#FF6C54',
                }}
              >
                {isUpdateSender ? (
                  <div style={{ marginTop: '6px' }}>
                    <IconSvg
                      name="updateFull"
                      size="lg"
                      color="transparent"
                      width="24"
                      height="24"
                    />
                  </div>
                ) : (
                  message.sender.slice(0, 1)
                )}
              </Avatar>

              <MessageBuble isUpdateAI={isUpdateSender}>
                {message.isWaiting ? <PulsingDot /> : <div>{content}</div>}
                {!message.isWaiting ? (
                  <Timestamp isUpdateAI={isUpdateSender}>{message.timestamp}</Timestamp>
                ) : (
                  <></>
                )}
              </MessageBuble>
            </Message>
          </MessageContainer>
        );
      })}
    </Container>
  );
};
