import { create } from 'zustand';

interface AutomaticPlayState {
  played: boolean;
  itemPlayed: () => void;
}

export type AutomaticPlayStore = ReturnType<typeof createAutomaticPlayStore>;

export const createAutomaticPlayStore = () =>
  create<AutomaticPlayState>(set => {
    return {
      played: false,
      itemPlayed: () => set({ played: true }),
    };
  });
