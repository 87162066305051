import styled from 'styled-components';

const Container = styled.div``;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
`;

const Header = styled.div`
  padding: 12px 16px;
`;

const Title = styled.span`
  color: var(--gray-500, #a6a6a6);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export default {
  Container,
  Contents,
  Divider,
  Title,
  Header,
};
