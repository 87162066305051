import React from 'react';
import TopAccountsSection from './base';
import { AccountTopicClickHandler } from './types';
interface Props {
  onTopicClicked: AccountTopicClickHandler;
  onAccountClicked: (accountId: string) => unknown;
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
}

export const TopAccounts: React.FC<Props> = ({ filters, onTopicClicked, onAccountClicked }) => {
  return (
    <TopAccountsSection
      onAccountClicked={onAccountClicked}
      filters={filters}
      onTopicSelected={onTopicClicked}
    />
  );
};
