import { useSelector } from 'react-redux';
import { selectHubspotIntegration, selectSalesforceIntegration } from '../../../redux/selectors';

export const useIntegrations = () => {
  const hasSalesforce = useSelector(selectSalesforceIntegration);
  const hasHubspot = useSelector(selectHubspotIntegration);

  const hasCrmIntegration = Boolean(hasSalesforce?.id) || Boolean(hasHubspot?.id);

  const hasSalesforceIntegrated = Boolean(hasSalesforce?.id);
  const hasHubspotIntegrated = Boolean(hasHubspot?.id);

  return { hasCrmIntegration, hasSalesforceIntegrated, hasHubspotIntegrated };
};
