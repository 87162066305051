import styled from 'styled-components';
import { Input } from 'antd';

const Chat = styled.div`
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OneLinerContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ChatContainer = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ChatInput = styled(Input)`
  flex: 1;
  resize: none !important;
  height: 40px !important;
  overflow: hidden !important;
  align-items: center !important;

  & :focus {
    border: none;
    outline: none;
  }
`;

const ChatActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
  background: #fff;
`;

export default {
  ChatContainer,
  ChatInput,
  ChatActionsContainer,
  OneLinerContainer,
  Chat,
};
