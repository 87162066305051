import React, { useRef } from 'react';
import { Modal } from '../../../../componentsV2/Modal';
import Styles from './styles';
import { CategoriesTable } from './components/CategoriesTable';
import { useInsightCategoriesManagement } from '../../../../features/insights/hooks/useInsightCategoriesManagement';
import { Button } from 'antd';
import { useInsightCategories } from '../../../../features/insights/hooks/useInsightCategories';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';

const { TableContainer, Contents } = Styles;

const modalTitle: { [k: string]: string } = {
  Risks: 'Risk',
  Advocacy: 'Advocacy',
  'Product Feedback': 'Product Feedback',
};

interface Props {
  open: boolean;
  onClose: () => unknown;
  onChangesSaved: () => unknown;
  onDiscard: () => unknown;
}

export const ManageCategoriesModal: React.FC<Props> = ({ open, onClose, onDiscard }) => {
  const { discardChanges, saveChanges, categories, add, isSaving, hasModifications } =
    useInsightCategoriesManagement();
  const { isFetching, type } = useInsightCategories();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleCloseModal = () => {
    discardChanges();
    onClose();
  };

  const handleDiscardModal = () => {
    discardChanges();
    onDiscard();
  };

  const totalCustomCategories = categories.filter(cat => cat.mutable).length;
  const disableAddButton = totalCustomCategories >= 10;

  const editionInProgress = categories.some(category => {
    return category.isNew || category.isEditingDescription || category.isEditingTitle;
  });

  const title = modalTitle[type];

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      title={`Manage ${title} Categories`}
      helpArticle={{
        label: 'Learn more about custom AI insights',
        url: 'https://help.update.ai/en/articles/9058485-how-can-i-customize-my-ai-insights',
      }}
      primaryAction={{
        label: 'Save changes',
        loading: isSaving,
        onClick: saveChanges,
        disabled: editionInProgress || !hasModifications,
      }}
      secondaryAction={{
        label: 'Discard',
        disabled: isSaving || isFetching || !hasModifications,
        onClick: handleDiscardModal,
      }}
    >
      <Contents>
        <TableContainer>
          <CategoriesTable />
          <div ref={ref}></div>
        </TableContainer>
        <div>
          <OptionalTooltipWrapper
            display={disableAddButton}
            value="No more than 10 custom categories are allowed"
          >
            <Button
              style={{ width: '200px' }}
              disabled={isSaving || isFetching || disableAddButton}
              onClick={() => {
                add();
                setTimeout(() => {
                  if (ref.current) {
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                  }
                }, 50);
              }}
            >
              Add new category
            </Button>
          </OptionalTooltipWrapper>
          <p style={{ fontStyle: 'italic' }}>
            New categories are detected starting from the date they are created and will appear on
            the dashboard upon their first detection.
          </p>
        </div>
      </Contents>
    </Modal>
  );
};
