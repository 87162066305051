import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const UnlockSVG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 2C7.2387 2 5.00012 4.23858 5.00012 7V9C3.89555 9 3.00012 9.89543 3.00012 11V16C3.00012 17.1046 3.89555 18 5.00012 18H15.0001C16.1047 18 17.0001 17.1046 17.0001 16V11C17.0001 9.89543 16.1047 9 15.0001 9H7.00012V7C7.00012 5.34315 8.34327 4 10.0001 4C11.3966 4 12.5726 4.95512 12.9057 6.24926C13.0433 6.78411 13.5885 7.1061 14.1234 6.96844C14.6582 6.83078 14.9802 6.28559 14.8425 5.75074C14.2875 3.59442 12.3313 2 10.0001 2Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};
export const Unlock: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={UnlockSVG} {...props} />;
};
