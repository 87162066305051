import React from 'react';
import Styles from './styles';
import { Lock } from '../../../../../../../../features/shared/components/Icons/Lock';
import { Unlock } from '../../../../../../../../features/shared/components/Icons/Unlock';
import { EventVisibility } from '../../../../../../../../features/recapv2/types';

const { ChangeLabel, Container, Visibility } = Styles;

const options = [
  { value: EventVisibility.Invitees, label: 'Invitees Only' },
  { value: EventVisibility.Organization, label: 'Workspace' },
];

const getOptionLabel = (value: string): string | null => {
  return options.find(v => v.value === value)?.label || null;
};

interface Props {
  canManageMeetingVisibility: boolean;
  meetingsVisibilityLocked: boolean;
  meetingsVisibility: {
    externalMeetingsVisibility: string;
    internalMeetingsVisibility: string;
  };
  onChange: () => unknown;
}

export const MeetingsVisibility: React.FC<Props> = ({
  canManageMeetingVisibility,
  meetingsVisibility,
  meetingsVisibilityLocked,
  onChange,
}) => {
  const options = [];

  if (getOptionLabel(meetingsVisibility.internalMeetingsVisibility))
    options.push(getOptionLabel(meetingsVisibility.internalMeetingsVisibility));
  if (getOptionLabel(meetingsVisibility.externalMeetingsVisibility))
    options.push(getOptionLabel(meetingsVisibility.externalMeetingsVisibility));

  return (
    <Container>
      <Visibility>
        <div>{options.join(', ')}</div>
        {meetingsVisibilityLocked ? <Lock /> : <Unlock />}
      </Visibility>
      {canManageMeetingVisibility && <ChangeLabel onClick={onChange}>Change</ChangeLabel>}
    </Container>
  );
};
