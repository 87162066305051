import styled from 'styled-components';

export const ParicipantTalkRatioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FollowUpEmailContainer = styled.div`
  padding-top: 24px;
  position: relative;
  white-space: pre-wrap;
`;

export const FollowUpHeader = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ff6c54;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
`;
