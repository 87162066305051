/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as arrowDownDarker } from '../../assets/svg/arrowDownDarker.svg';
import { ReactComponent as arrowDownWhite } from '../../assets/svg/arrowDownWhite.svg';
import { ReactComponent as arrowUpDarker } from '../../assets/svg/arrowUpDarker.svg';
import { ReactComponent as arrowThinUpWhite } from '../../assets/svg/arrowThinUpWhite.svg';
import { ReactComponent as bookmark } from '../../assets/svg/bookmark.svg';
import { ReactComponent as boxArrowRight } from '../../assets/svg/boxArrowRight.svg';
import { ReactComponent as check } from '../../assets/svg/check.svg';
import { ReactComponent as chevronDoubleLeft } from '../../assets/svg/chevronDoubleLeft.svg';
import { ReactComponent as chevronDoubleRight } from '../../assets/svg/chevronDoubleRight.svg';
import { ReactComponent as chevronLeft } from '../../assets/svg/chevronLeft.svg';
import { ReactComponent as chevronRight } from '../../assets/svg/chevronRight.svg';
import { ReactComponent as close } from '../../assets/svg/close.svg';
import { ReactComponent as closex } from '../../assets/svg/actions/close.svg';
import { ReactComponent as copyCaptures } from '../../assets/svg/copyCaptures.svg';
import { ReactComponent as copy } from '../../assets/svg/actions/copy.svg';
import { ReactComponent as trash } from '../../assets/svg/actions/delete.svg';
import { ReactComponent as doubleDash } from '../../assets/svg/doubleDash.svg';
import { ReactComponent as edit } from '../../assets/svg/edit.svg';
import { ReactComponent as salesForce } from '../../assets/svg/salesForce.svg';
import { ReactComponent as signalFlag } from '../../assets/svg/signalFlag.svg';
import { ReactComponent as gear } from '../../assets/svg/gear.svg';
import { ReactComponent as googleLogo } from '../../assets/svg/googleLogo.svg';
import { ReactComponent as lightningCharge } from '../../assets/svg/lightningCharge.svg';
import { ReactComponent as minus } from '../../assets/svg/minus.svg';
import { ReactComponent as mic } from '../../assets/svg/objects/mic.svg';
import { ReactComponent as keyMomentGray } from '../../assets/svg/objects/bookmark.svg';
import { ReactComponent as actionItemGray } from '../../assets/svg/objects/flash.svg';
import { ReactComponent as plus } from '../../assets/svg/plus.svg';
import { ReactComponent as refresh } from '../../assets/svg/refresh.svg';
import { ReactComponent as search } from '../../assets/svg/search.svg';
import { ReactComponent as send } from '../../assets/svg/send.svg';
import { ReactComponent as zoomLogo } from '../../assets/svg/zoomLogo.svg';
import { ReactComponent as link } from '../../assets/svg/link.svg';
import { ReactComponent as salesforce } from '../../assets/svg/salesForce.svg';
import { ReactComponent as check2 } from '../../assets/svg/Input/Check.svg';
import { ReactComponent as cancel } from '../../assets/svg/actions/cancel.svg';
import { ReactComponent as grayEye } from '../../assets/svg/recap/hide.svg';
import { ReactComponent as lBlueEye } from '../../assets/svg/recap/viewAI.svg';
import { ReactComponent as blueEye } from '../../assets/svg/recap/viewKM.svg';
import { ReactComponent as logout } from '../../assets/svg/navigation/logout.svg';
import { ReactComponent as help } from '../../assets/svg/navigation/help.svg';
import { ReactComponent as house } from '../../assets/svg/navigation/home.svg';
import { ReactComponent as UpdateLogoBlue } from '../../assets/svg/updateLogoBlue.svg';
import { ReactComponent as salesForceLogo } from '../../assets/svg/logo/salesforce.svg';
import { ReactComponent as hubspotLogo } from '../../assets/svg/logo/hubspot.svg';
import { ReactComponent as salesForceLogoGray } from '../../assets/svg/logo/salesforceGray.svg';
import { ReactComponent as hubspotLogoGray } from '../../assets/svg/logo/hubspotGray.svg';
import { ReactComponent as hubspotBrand } from '../../assets/svg/hubspotLogo.svg';
import { ReactComponent as deleteRecap } from '../../assets/svg/recap/trash.svg';
import { ReactComponent as uploadImage } from '../../assets/svg/uploadImage.svg';
import { ReactComponent as integrationsCalendarLogo } from '../../assets/svg/integrations/calendarLogo.svg';
import { ReactComponent as integrationsCalendarLogoOutlook } from '../../assets/svg/integrations/calendarLogoOutlook.svg';
import { ReactComponent as integrationsZoomLogo } from '../../assets/svg/integrations/zoomLogo.svg';
import { ReactComponent as integrationsHubspotLogo } from '../../assets/svg/integrations/hubspotLogo.svg';
import { ReactComponent as integrationsSalesforceLogo } from '../../assets/svg/integrations/salesforceLogo.svg';
import { ReactComponent as integrationsSlackLogo } from '../../assets/svg/integrations/slackLogo.svg';
import { ReactComponent as integrationOk } from '../../assets/svg/integrations/integrationOk.svg';
import { ReactComponent as integrationAlert } from '../../assets/svg/integrations/integrationAlert.svg';
import { ReactComponent as addNewMember } from '../../assets/svg/addNewMember.svg';
import { ReactComponent as removeMember } from '../../assets/svg/removeMember.svg';
import { ReactComponent as addNewMemberBlack } from '../../assets/svg/addNewMemberBlack.svg';
import { ReactComponent as closeModal } from '../../assets/svg/closeModal.svg';
import { ReactComponent as email } from '../../assets/svg/email.svg';
import { ReactComponent as alertTriangle } from '../../assets/svg/alertTriangle.svg';
import { ReactComponent as users } from '../../assets/svg/users.svg';
import { ReactComponent as updateAiLogoStretched } from '../../assets/svg/updateAiLogoStretched.svg';
import { ReactComponent as support } from '../../assets/svg/support.svg';
import { ReactComponent as settings } from '../../assets/svg/settings.svg';
import { ReactComponent as home } from '../../assets/svg/home.svg';
import { ReactComponent as calendar } from '../../assets/svg/calendar.svg';
import { ReactComponent as updateFull } from '../../assets/svg/updateFull.svg';
import { ReactComponent as organization } from '../../assets/svg/building.svg';

import { black } from '../../assets/colors';

export const findMatchingIcon = (name: string) => {
  switch (name) {
    case 'arrowDownDarker':
      return arrowDownDarker;
    case 'arrowDownWhite':
      return arrowDownWhite;
    case 'arrowUpDarker':
      return arrowUpDarker;
    case 'arrowThinUpWhite':
      return arrowThinUpWhite;
    case 'bookmark':
      return bookmark;
    case 'cancel':
      return cancel;
    case 'chevronDoubleLeft':
      return chevronDoubleLeft;
    case 'chevronDoubleRight':
      return chevronDoubleRight;
    case 'chevronLeft':
      return chevronLeft;
    case 'chevronRight':
      return chevronRight;
    case 'copyCaptures':
      return copyCaptures;
    case 'copy':
      return copy;
    case 'delete':
      return trash;
    case 'doubleDash':
      return doubleDash;
    case 'edit':
      return edit;
    case 'salesForce':
      return salesForce;
    case 'signalFlag':
      return signalFlag;
    case 'googleLogo':
      return googleLogo;
    case 'refresh':
      return refresh;
    case 'search':
      return search;
    case 'send':
      return send;
    case 'zoomLogo':
      return zoomLogo;
    case 'gear':
      return gear;
    case 'boxArrowRight':
      return boxArrowRight;
    case 'check':
      return check;
    case 'lightningCharge':
      return lightningCharge;
    case 'minus':
      return minus;
    case 'mic':
      return mic;
    case 'plus':
      return plus;
    case 'close':
      return close;
    case 'close-x':
      return closex;
    case 'link':
      return link;
    case 'salesforce':
      return salesforce;
    case 'check2':
      return check2;
    case 'grayEye':
      return grayEye;
    case 'blueEye':
      return blueEye;
    case 'lightBlueEye':
      return lBlueEye;
    case 'logout':
      return logout;
    case 'help':
      return help;
    case 'house':
      return house;
    case 'updateLogoBlue':
      return UpdateLogoBlue;
    case 'salesforceLogo':
      return salesForceLogo;
    case 'hubspotLogo':
      return hubspotLogo;
    case 'salesforceLogoGray':
      return salesForceLogoGray;
    case 'hubspotLogoGray':
      return hubspotLogoGray;
    case 'hubspotBrand':
      return hubspotBrand;
    case 'actionItemGray':
      return actionItemGray;
    case 'keyMomentGray':
      return keyMomentGray;
    case 'deleteRecap':
      return deleteRecap;
    case 'uploadImage':
      return uploadImage;
    case 'integrationsCalendarLogo':
      return integrationsCalendarLogo;
    case 'integrationsCalendarLogoOutlook':
      return integrationsCalendarLogoOutlook;
    case 'integrationsZoomLogo':
      return integrationsZoomLogo;
    case 'integrationsHubspotLogo':
      return integrationsHubspotLogo;
    case 'integrationsSalesforceLogo':
      return integrationsSalesforceLogo;
    case 'integrationsSlackLogo':
      return integrationsSlackLogo;
    case 'integrationOk':
      return integrationOk;
    case 'integrationAlert':
      return integrationAlert;
    case 'addNewMember':
      return addNewMember;
    case 'removeMember':
      return removeMember;
    case 'addNewMemberBlack':
      return addNewMemberBlack;
    case 'closeModal':
      return closeModal;
    case 'email':
      return email;
    case 'alertTriangle':
      return alertTriangle;
    case 'users':
      return users;
    case 'updateAiLogoStretched':
      return updateAiLogoStretched;
    case 'home':
      return home;
    case 'support':
      return support;
    case 'calendar':
      return calendar;
    case 'settings':
      return settings;
    case 'updateFull':
      return updateFull;
    case 'organization':
      return organization;
    default:
      return arrowDownDarker;
  }
};

export interface Props {
  name: string;
  size: Size;
  id?: string;
  bgColor?: string;
  className?: string;
  clickable?: boolean;
  color?: string;
  height?: string;
  small?: boolean;
  width?: string;
  stroke?: string;
  tiny?: boolean;
  onClick?: () => void;
}

type Size = 'xs' | 'sm' | 'smd' | 'md' | 'mdl' | 'lg' | 'xl';

const sizes = {
  xs: '.55rem',
  sm: '.75rem',
  smd: '1rem',
  md: '1.25rem',
  mdl: '1.5rem',
  lg: '3.125rem',
  xl: '5rem',
};

const IconSvg = ({
  color = black,
  name,
  clickable,
  onClick,
  bgColor = '',
  size,
  height,
  width,
  stroke,
  id,
  ...props
}: Props) => {
  const IconComponent = findMatchingIcon(name);

  const innerContent = (
    <IconComponent
      id={id}
      height={height ?? sizes[size]}
      width={width ?? sizes[size]}
      onClick={() => {
        if (onClick) onClick();
      }}
      fill={color}
      cursor={clickable ? 'pointer' : 'default'}
      stroke={stroke}
      className="align-self-center"
      {...props}
    />
  );

  return bgColor ? (
    <RoundBackground backgroundColor={bgColor}>{innerContent}</RoundBackground>
  ) : (
    innerContent
  );
};

export default IconSvg;

const RoundBackground = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px 0 6px;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
