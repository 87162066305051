import { createContext } from 'react';
import { PastMeetingStore, createUpcomingMeetingsStore } from './upcoming-meetings.store';

interface UpcomingMeetingsContext {
  store: PastMeetingStore;
}

export const upcomingMeetingsContext = createContext<UpcomingMeetingsContext>({
  store: createUpcomingMeetingsStore(),
});
