import React from 'react';
import { Folder } from '../Folder';
import { StarredFolder as StarredFolderIcon } from '../../../../features/shared/components/Icons/StarredFolder';

interface Props {
  onClick: () => unknown;
  count: number;
  mainFolderName: string;
}

export const StarredFolder: React.FC<Props> = ({ onClick, count, mainFolderName }) => {
  return (
    <Folder
      onClick={onClick}
      icon={<StarredFolderIcon />}
      onRemove={() => null}
      onRename={() => null}
      name={mainFolderName}
      count={count}
      showMenu={false}
    />
  );
};
