import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './styles';
import {
  generateIntervalFromToday,
  setToLastSecond,
  setToZeroSeconds,
  sortDates,
} from '../../../../utils/dateUtils';

const { DateSelect } = Styles;

interface Props {
  value?: string;
  direction: 'future' | 'past';
  onDateRangeChanged: (value: [Date, Date] | [null, null]) => unknown;
  onDateOptionChanged: (value: string) => unknown;
  predefinedRanges?: {
    '3m'?: boolean;
    '6m'?: boolean;
    '12m'?: boolean;
    '18m'?: boolean;
  } & { [k: string]: boolean };
  defaultValues: [Date, Date] | [null, null];
  allowAll?: boolean;
}

interface DateInterval {
  label: string;
  generator: (multiplier: number) => [Date, Date];
}

const predefinedDateIntervals: { [k: string]: DateInterval } = {
  today: {
    label: 'Today',
    generator: () => generateIntervalFromToday(0),
  },
  '1d': {
    label: '1D',
    generator: multiplier => generateIntervalFromToday(multiplier * 1),
  },
  '7d': {
    label: '7D',
    generator: multiplier => generateIntervalFromToday(multiplier * 7),
  },
  '30d': {
    label: '30D',
    generator: multiplier => generateIntervalFromToday(multiplier * 30),
  },
  '60d': {
    label: '60D',
    generator: multiplier => generateIntervalFromToday(multiplier * 60),
  },
  '90d': {
    label: '90D',
    generator: multiplier => generateIntervalFromToday(multiplier * 90),
  },
  '3m': {
    label: '3M',
    generator: multiplier => generateIntervalFromToday(multiplier * 90),
  },
  '120d': {
    label: '120D',
    generator: multiplier => generateIntervalFromToday(multiplier * 120),
  },
  '6m': {
    label: '6M',
    generator: multiplier => generateIntervalFromToday(multiplier * 180),
  },
  '12m': {
    label: '12M',
    generator: multiplier => generateIntervalFromToday(multiplier * 365),
  },
};

export const DateSelector: React.FC<Props> = ({
  value = '',
  allowAll,
  direction,
  defaultValues,
  predefinedRanges,
  onDateRangeChanged,
  onDateOptionChanged,
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value);

  const multiplier = direction === 'past' ? -1 : 1;

  const changeIntervalData = (value: string) => {
    const dateInterval = predefinedDateIntervals[value];
    if (dateInterval) {
      const sortedDates = dateInterval.generator(multiplier);
      onDateRangeChanged(sortedDates);
    }
  };

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
      changeIntervalData(value);
    }
  }, [value]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setCurrentValue(e.target.value);
    onDateOptionChanged(e.target.value);
    if (e.target.value === 'all') {
      onDateRangeChanged([null, null]);
    }
    const dateInterval = predefinedDateIntervals[e.target.value];
    if (dateInterval) {
      const sortedDates = sortDates(dateInterval.generator(multiplier));
      onDateRangeChanged(sortedDates);
    }
  };

  const handleCalendarChange = (values: [Date, Date] | [null, null]) => {
    if (values[0] !== null && values[1] !== null) {
      const [firstDate, secondDate] = sortDates(values);
      onDateRangeChanged([setToZeroSeconds(firstDate), setToLastSecond(secondDate)]);
    }
  };

  const showDatePicker = currentValue === 'custom';

  const optionsToShow = useMemo(() => {
    return Object.entries(predefinedDateIntervals)
      .filter(([name]) => {
        if (typeof predefinedRanges !== 'undefined') {
          const value = predefinedRanges[name];
          if (value === false) {
            return false;
          }
          return true;
        }
      })
      .map(([name, interval]) => {
        return (
          <Radio.Button key={name} value={name}>
            {interval.label}
          </Radio.Button>
        );
      });
  }, [predefinedDateIntervals, predefinedRanges]);

  return (
    <Radio.Group onChange={handleRadioChange} value={currentValue}>
      <Radio.Button value="custom">
        {showDatePicker ? (
          <DateSelect
            style={{ padding: 0, fontFamily: 'Cera Pro' }}
            bordered={false}
            pastOnly={direction === 'past'}
            futureOnly={direction === 'future'}
            handleCalendarChange={handleCalendarChange}
            defaultFrom={defaultValues[0]}
            defaultTo={defaultValues[1]}
          />
        ) : (
          'Custom Dates'
        )}
      </Radio.Button>
      {allowAll && <Radio.Button value="all">All</Radio.Button>}
      {optionsToShow}
    </Radio.Group>
  );
};
