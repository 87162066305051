import { useState } from 'react';
import { FrameworksHeader } from '../../../frameworks/components/FrameworksHeader';
import { FrameworkExecution } from '../../../frameworks/type';
import { FrameworkCard } from '../../../frameworks/components/Card';
import { Button, Spin } from 'antd';
import { coreService } from '../../../../services/core/core-service';
import { useToast } from '../../../../hooks/useToast';
import { format } from 'date-fns';

import Styles from './styles';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { capitalize } from 'lodash';
const { Container, EmptyView, Cards, LastGeneratedLabel } = Styles;

interface InternalFrameworkProps {
  lastExecution: FrameworkExecution | null;
  selectedFrameworkName: string | null;
  loading: boolean;
  onGenerate: () => void;
  updateLastExecution: (args: { id: string; value: string }) => void;
}

const InternalFramework: React.FC<InternalFrameworkProps> = ({
  lastExecution,
  loading,
  selectedFrameworkName,
  onGenerate,
  updateLastExecution,
}) => {
  if (loading || lastExecution?.isProcessing) {
    return (
      <EmptyView
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}
      >
        {lastExecution?.isProcessing ? (
          <span style={{ width: '500px', textAlign: 'center' }}>
            Notes are being generated for framework: {selectedFrameworkName || ''}
          </span>
        ) : (
          <></>
        )}
        <Spin />
      </EmptyView>
    );
  }

  if (!lastExecution?.id)
    return (
      <EmptyView
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
      >
        <span style={{ width: '500px', textAlign: 'center' }}>
          Click the “Generate” button to automatically create notes for framework:{' '}
          {selectedFrameworkName || ''} from this meeting.
        </span>
        <Button onClick={onGenerate} type="primary">
          Generate
        </Button>
      </EmptyView>
    );

  return (
    <Cards>
      {lastExecution && (
        <LastGeneratedLabel>
          Last generated at{' '}
          {lastExecution.createdAt
            ? format(new Date(lastExecution.createdAt), 'MM/dd/yyy hh:mm aa')
            : 'Not generated yet'}
        </LastGeneratedLabel>
      )}
      {lastExecution &&
        lastExecution?.values.map(v => (
          <FrameworkCard
            key={'fw-card-qst-id' + v.questionId}
            id={v.questionId}
            title={v.question}
            value={v.answer}
            loading={loading}
            onSave={updateLastExecution}
          />
        ))}
    </Cards>
  );
};

export const Frameworks: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [lastExecution, setLastExecution] = useState<FrameworkExecution | null>(null);
  const id = window.location.pathname.replace('/recap/', '');

  const { success } = useToast();
  const { title } = useRecapManagement();

  const updateLastExecution = (args: { id: string; value: string }) => {
    if (!lastExecution) return;
    const updatedExecution = {
      ...lastExecution,
      values: lastExecution.values.map(v =>
        v.questionId === args.id ? { ...v, answer: args.value } : v,
      ),
    };
    setLastExecution(updatedExecution);
    setLoading(true);
    coreService.updateEventFrameworkExecution(id, updatedExecution).finally(() => {
      setLoading(false);
    });
  };

  if (lastExecution === undefined) {
    return (
      <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <FrameworksHeader
        setLastExecution={setLastExecution}
        fetchLastExecution={externalId => {
          return coreService.fetchEventFrameworksByExternalId(id, externalId);
        }}
        onGenerate={externalId => {
          return coreService.generateEventFrameworksByExternalId(id, externalId);
        }}
        onCopy={(name, createdAt) => {
          navigator.clipboard.writeText(
            `Recap: ${capitalize(title)}\n\n${name} - ${format(
              new Date(createdAt),
              'MMMM dd, yyyy hh:mm aa',
            )}\n\n${lastExecution?.values.map(v => `${v.question}:\n ${v.answer}`).join('\n\n')}`,
          );
          success('Copied to clipboard');
        }}
        disabled={!!lastExecution?.isProcessing || loading}
        key={'recap-frameworks-header'}
      >
        <InternalFramework
          loading={loading}
          selectedFrameworkName={null}
          lastExecution={lastExecution}
          onGenerate={() => 'generate'}
          updateLastExecution={updateLastExecution}
        />
      </FrameworksHeader>
    </Container>
  );
};
