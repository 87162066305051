import React, { useEffect, useState } from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { Button } from 'antd';
import { SuccessConnectionDataLabel } from '../../../Integrations/Components/SuccessConnectionDataLabel';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import { useIntegrationRequest } from '../../../../../../features/integrations/hooks/useIntegrationRequest';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import useFeatures from '../../../../../../hooks/useFeatures';
import {
  SubscribeToTeamsPlanLink,
  UpgradeToProBadge,
} from '../../../../../../componentsV2/UpgradeToPro';
import { coreService } from '../../../../../../services/core/core-service';
import { useIntegrationResult } from '../../../../../../features/integrations/hooks/useIntegrationResult';

export const SlackSection: React.FC<{}> = () => {
  const { Content } = Styled;
  const { slack, refetch, permissions, id } = useOrganizationDetails();
  const [isWorking, setIsWorking] = useState(false);

  const result = useIntegrationResult();

  const features = useFeatures();
  const hasIntegration = slack !== null;
  const availableFeature = features.slackIntegration;

  const removeWorkspaceIntegration = async () => {
    setIsWorking(true);
    coreService
      .removeWorkspaceSlackIntegration(id)
      .then(refetch)
      .finally(() => setIsWorking(false));
  };

  useEffect(() => {
    if (result && result.source === 'SLACK') {
      if (isWorking) {
        setIsWorking(false);
      }
      refetch();
    }
  }, [result]);

  const requestIntegrationUrl = async () => {
    setIsWorking(true);
    const url = await coreService.requestWorkspaceSlackIntegrationUrl();
    const popup = window.open(
      url,
      'popup_window',
      'height=800px,width=500px,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no',
    );
    if (popup) popup.moveTo(window.screen.width / 2 - 250, window.screen.height / 2 - 400);
  };

  return (
    <div id="slack-integration">
      <Section
        title={
          <div style={{ display: 'flex', gap: '10px' }}>
            Slack {!availableFeature && <UpgradeToProBadge />}
          </div>
        }
        description="Receive meeting summaries and action items right on Slack."
        learnMore="https://help.update.ai/en/articles/6405273-installing-and-using-the-updateai-app-for-slack"
      >
        <Content>
          This unified Slack integration ensures all workspace members are associated with the same
          Slack integration. This allows for a seamless experience when using the Slack integration.
          <div style={{ alignSelf: 'left' }}>
            {!!hasIntegration && (
              <SuccessConnectionDataLabel label="Connected" value={slack?.email || ''} />
            )}
          </div>
          <div>
            <OptionalTooltipWrapper
              display={!permissions.connectSlack || !availableFeature}
              value={
                !availableFeature ? (
                  <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to Slack." />
                ) : (
                  'Only Owners or Admins can manage slack integrations'
                )
              }
            >
              <Button
                type="primary"
                disabled={!permissions.connectSlack || !availableFeature}
                loading={isWorking}
                onClick={() => {
                  if (!!hasIntegration) {
                    removeWorkspaceIntegration();
                  } else {
                    requestIntegrationUrl();
                  }
                }}
              >
                {hasIntegration ? 'Remove Slack' : 'Connect Slack'}
              </Button>
            </OptionalTooltipWrapper>
          </div>
        </Content>
      </Section>
    </div>
  );
};
