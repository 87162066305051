import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { colors } from '../../../theme/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-style: normal;
  color: ${colors.coolGray[500]};
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.24px;
  margin-bottom: 16px;
  height: 100%;
`;

const LastGeneratedLabel = styled.span`
  display: flex;
  align-items: center;

  padding: 10px;
  color: #0675fc;

  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--4px, 8px);

  border: 1px solid #b6e8ff;

  background: #edf9ff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled(AntdButton)`
  & path {
    fill: ${colors.coolGray[500]};
  }
`;

export default { Container, Buttons, Button, LastGeneratedLabel };
