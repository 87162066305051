import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  background: #ffffff;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  flex-grow: 0;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const Description = styled.div`
  max-width: 700px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
  flex: none;
  flex-grow: 0;
`;

export const ExtraDescription = styled.div`
  max-width: 700px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #818181;
  flex: none;
  flex-grow: 0;
`;
export const RecommendedSetting = styled.div`
  display: flex;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a6a6a6;
  flex: none;
  flex-grow: 0;
`;

export const LearnMore = styled.a`
  font-style: normal;
  text-decoration: underline;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e6614c;
  flex: none;
  flex-grow: 0;
  padding-left: 4px;
`;

export const RecommendedSettingHref = styled.div`
  font-style: normal;
  text-decoration: underline;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  line-height: 20px;
  color: #e6614c;
  flex: none;
  flex-grow: 0;
  padding-left: 4px;

  :hover {
    color: #69b1ff;
    transition: color 0.3s;
  }
`;
