import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chunk, Event, KeyMoment, Message } from '../typings/recap';
import {
  AddActionItemAction,
  AddMomentAction,
  AddSummaryItemAction,
  EditMomentAction,
  EditSummaryItemAction,
  RemoveActionItemAction,
  RemoveMomentAction,
  RemoveSummaryItemAction,
  ReplaceActionItemId,
  ReplaceMomentId,
  ReplaceSummaryItemId,
  UpdateActionItemAction,
  UpdateEventTypeAction,
} from '../actions/recap';
import { ProviderIntegration, Recap } from '../typings/store';

const initialStateEventData: Event = {
  id: '',
  host: 'John Doe',
  purposeStatement: '',
  isHost: false,
  isOwner: false,
  participantsType: 'internal',
  note: '',
  actionItemsCount: 0,
  type: '',
  title: '',
  description: '',
  summaryItems: [],
  status: '',
  domain: '',
  source: '',
  started: new Date().toISOString(),
  ended: new Date().toISOString(),
  isDemo: null,
  scheduledEndDate: '',
  scheduledStartDate: '',
  participants: [],
  messages: [],
  isPublic: true,
  keyMoments: [],
  actionItems: [],
  hostUser: null,
  externalResource: null,
  categories: {},
  account: null,
  summary: [],
  speakerAnalytics: { speakers: [] },
  visibilityConfig: {
    showActionItems: false,
    showVideo: false,
    showTranscript: false,
    showRisks: false,
    showImportantNumbers: false,
    showMeetingDetails: false,
    showProductFeedback: false,
    showAdvocacy: false,
    showFeatureRequestFeedback: false,
    showTldr: false,
  },
  visibility: 'host',
  shouldBlur: false,
  prospectedAccounts: [],
  followUp: '',
  reports: [],
};

export const initialState: Recap = {
  status: {
    isFetching: false,
    isUpdating: false,
    error: undefined,
  },
  event: initialStateEventData,
  undo: initialStateEventData,
};

const recapSlice = createSlice({
  name: 'recap',
  initialState,
  reducers: {
    addActionItem(state, action: AddActionItemAction) {
      const itemsCopy = [...state.event.actionItems];
      itemsCopy.push({ ...action.payload, excludedFromInsightsEngine: true, chunks: [] });
      state.event.actionItems = itemsCopy;
    },
    addMoment(state, action: AddMomentAction) {
      const itemsCopy = [...state.event.keyMoments];
      const existingCategories = Object.assign({}, state.event.categories);
      if (!existingCategories[action.payload.category]) {
        existingCategories[action.payload.category] = {
          count: 0,
          items: [],
        };
      }
      existingCategories[action.payload.category].count++;
      existingCategories[action.payload.category].items.push({
        ...action.payload,
        excludedFromInsightsEngine: true,
        chunks: [],
      });
      itemsCopy.push({ ...action.payload, excludedFromInsightsEngine: true, chunks: [] });
      state.event.keyMoments = itemsCopy;
      state.event.categories = existingCategories;
    },
    addSummaryItem(state, action: AddSummaryItemAction) {
      const itemsCopy = [...state.event.summaryItems];
      itemsCopy.push({ ...action.payload, excludedFromInsightsEngine: true, chunks: [] });
      state.event.summaryItems = itemsCopy;
    },
    replaceSummaryItemId(state, action: ReplaceSummaryItemId) {
      const itemsCopy = [...state.event.summaryItems].map(summaryItem => {
        if (summaryItem.id === action.payload.oldId) {
          summaryItem.id = action.payload.newId;
        }
        return summaryItem;
      });
      state.event.summaryItems = itemsCopy;
    },
    replaceActionItemId(state, action: ReplaceActionItemId) {
      const itemsCopy = [...state.event.actionItems].map(actionItem => {
        if (actionItem.id === action.payload.oldId) {
          actionItem.id = action.payload.newId;
        }
        return actionItem;
      });
      state.event.actionItems = itemsCopy;
    },
    replaceMomentId(state, action: ReplaceMomentId) {
      const itemsCopy = [...state.event.keyMoments];
      const moment = itemsCopy.find(moment => moment.id === action.payload.oldId);
      if (moment) {
        moment.id = action.payload.newId;
        const categoriesCopies = Object.assign({}, state.event.categories);
        const existing = categoriesCopies[moment.category].items.find(
          mom => mom.id === action.payload.oldId,
        );
        if (existing) {
          existing.id = action.payload.newId;
          state.event.categories = categoriesCopies;
        }
        state.event.keyMoments = itemsCopy;
      }
    },
    removeMoment(state, action: RemoveMomentAction) {
      const categories = Object.assign({}, state.event.categories);
      categories[action.payload.category].items = categories[action.payload.category].items.filter(
        moment => moment.id !== action.payload.momentId,
      );
      state.event.categories = categories;
    },
    editMoment(state, action: EditMomentAction) {
      const categories = Object.assign({}, state.event.categories);
      const moment = categories[action.payload.category].items.find(
        m => m.id === action.payload.momentId,
      );
      if (moment) {
        moment.text = action.payload.text;
        state.event.categories = categories;
      }
    },
    removeSummaryItem(state, action: RemoveSummaryItemAction) {
      const summaryItems = [...state.event.summaryItems].filter(
        ai => ai.id !== action.payload.summaryItemId,
      );
      state.event.summaryItems = summaryItems;
    },
    editSummaryItem(state, action: EditSummaryItemAction) {
      const summaryItems = [...state.event.summaryItems];
      const matchedSummaryItem = summaryItems.find(ai => ai.id === action.payload.summaryItemId);
      if (matchedSummaryItem) {
        matchedSummaryItem.text = action.payload.text;
      }
      state.event.summaryItems = summaryItems;
    },
    removeActionItemBullet(state, action: RemoveActionItemAction) {
      const actionItems = [...state.event.actionItems].filter(
        ai => ai.id !== action.payload.actionItemId,
      );
      state.event.actionItems = actionItems;
    },
    updateActionItem(state, action: UpdateActionItemAction) {
      const actionItems = [...state.event.actionItems];
      const matchedActionItem = actionItems.find(ai => ai.id === action.payload.actionItemId);
      if (matchedActionItem) {
        matchedActionItem.text = action.payload.text;
      }
      state.event.actionItems = actionItems;
    },
    updateType(state, action: UpdateEventTypeAction) {
      state.event.type = action.payload.type;
    },
    updateNote(state, action: PayloadAction<{ note: string }>) {
      state.event.note = action.payload.note;
    },
    clearError(state) {
      state.status.error = undefined;
    },
    setRecapStatus(state, action: PayloadAction<{ status: string }>) {
      state.event.status = action.payload.status;
    },
    // Fetch Recaps.
    fetchRecap(state, action: PayloadAction<{ id: string; secret: string | null }>) {
      state.status = {
        ...state.status,
        isFetching: !!action,
        error: undefined,
      };
      state.event = initialStateEventData;
      state.undo = initialStateEventData;
    },
    fetchRecapSuccess(state, action: PayloadAction<{ eventData: Event }>) {
      state.status = {
        ...state.status,
        isFetching: false,
        error: undefined,
      };
      state.event = { ...action.payload.eventData };
      state.undo = { ...action.payload.eventData };
    },
    fetchRecapError(state, action: PayloadAction<{ error?: string }>) {
      state.status = {
        ...state.status,
        isFetching: false,
        error: action.payload.error,
      };
      state.event = initialStateEventData;
      state.undo = initialStateEventData;
    },
    updateRecap(state, action: PayloadAction<Partial<Event>>) {
      state.status = {
        ...state.status,
        isUpdating: !!action.payload,
        error: undefined,
      };
      state.undo = { ...state.event };
    },
    updateRecapSuccess(state, action: PayloadAction<Partial<Event>>) {
      state.status = {
        ...state.status,
        isUpdating: false,
        error: undefined,
      };
      state.event = { ...state.event, ...action.payload };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeRecapAccount(state) {
      state.event.account = null;
    },
    addRecapAccount(state, action: PayloadAction<ProviderIntegration>) {
      state.event.account = action.payload;
    },

    // Action Items.
    createActionItem(state, action: PayloadAction<Partial<Message>>) {
      state.status = {
        ...state.status,
        isUpdating: !!action.payload,
        error: undefined,
      };
      state.undo = state.event;
    },
    createActionItemSuccess(state, action: PayloadAction<{ messageId: string }>) {
      state.status = {
        ...state.status,
        isUpdating: false,
        error: undefined,
      };
      state.event.messages = state.event.messages.map(message => {
        if (message.id === action.payload.messageId) {
          const updatedMessage: Message = {
            ...message,
            actionItem: true,
          };
          return updatedMessage;
        }
        return message;
      });
    },
    removeActionItem(state, action: PayloadAction<{ messageId: string }>) {
      state.status = {
        ...state.status,
        isUpdating: !!action.payload,
        error: undefined,
      };
      state.undo = state.event;
    },
    removeActionItemSuccess(state, action: PayloadAction<{ messageId: string }>) {
      state.status = {
        ...state.status,
        isUpdating: false,
        error: undefined,
      };
      state.event.messages = state.event.messages.map(message => {
        if (message.id === action.payload.messageId) {
          state.event.actionItems.forEach(actionItem => {
            const chunkFound = actionItem.chunks.find(chunk => {
              return chunk.messageId === message.id;
            });
            if (chunkFound) {
              state.event.actionItems = state.event.actionItems.filter(ai => {
                return ai.id !== actionItem.id;
              });
            }
          });

          const updatedMessage: Message = {
            ...message,
            actionItem: false,
          };

          return updatedMessage;
        }
        return message;
      });
    },

    // Key Moments.
    createKeyMoment(state, action: PayloadAction<Chunk[]>) {
      state.status = {
        ...state.status,
        isUpdating: !!action.payload,
        error: undefined,
      };
      state.undo = state.event;
    },
    createKeyMomentSuccess(state, action: PayloadAction<KeyMoment>) {
      const list = action.payload.chunks;
      state.status = {
        ...state.status,
        isUpdating: false,
        error: undefined,
      };
      state.event.keyMoments = [...state.event.keyMoments, action.payload];
      state.event.messages = state.event.messages.map(message => {
        const chunkPerMessage = list.filter(each => each.messageId === message.id);
        if (chunkPerMessage.length > 0) {
          return {
            ...message,
            keyMoments: [...message.keyMoments, action.payload.id],
          };
        }
        return message;
      });
    },
    deleteKeyMoment(state, action: PayloadAction<string>) {
      state.status = {
        ...state.status,
        isUpdating: !!action.payload,
        error: undefined,
      };
      state.undo = state.event;
    },
    deleteKeyMomentSuccess(state, action: PayloadAction<string>) {
      state.status = {
        ...state.status,
        isUpdating: false,
        error: undefined,
      };
      state.event.keyMoments = state.event.keyMoments.filter(each => each.id !== action.payload);
      state.event.messages = state.event.messages.filter(each =>
        each.keyMoments.filter(each => each !== action.payload),
      );
    },

    // Error.
    error(state, action: PayloadAction<{ error?: string }>) {
      state.status = {
        ...state.status,
        isUpdating: false,
        error: action.payload.error,
      };
      state.event = state.undo;
    },

    // Restore.
    restoreInitialState(state) {
      state.event = { ...initialState.event };
      state.undo = { ...initialState.undo };
    },
  },
});

export const { clearError, fetchRecap, setRecapStatus, fetchRecapSuccess, fetchRecapError } =
  recapSlice.actions;
export const { updateRecap, updateRecapSuccess } = recapSlice.actions;
export const {
  createActionItem,
  createActionItemSuccess,
  removeActionItem,
  removeActionItemSuccess,
  removeActionItemBullet,
  removeSummaryItem,
  editSummaryItem,
  editMoment,
  removeMoment,
  addSummaryItem,
  addMoment,
  addActionItem,
  replaceSummaryItemId,
  replaceActionItemId,
  replaceMomentId,
} = recapSlice.actions;
export const { createKeyMoment, createKeyMomentSuccess, deleteKeyMoment, deleteKeyMomentSuccess } =
  recapSlice.actions;
export const { removeRecapAccount, addRecapAccount } = recapSlice.actions;
export const { restoreInitialState, error } = recapSlice.actions;
export const { updateNote, updateType, updateActionItem } = recapSlice.actions;
export const recapReducers = recapSlice.reducer;
