import React from 'react';
import Styles from './styles';
import { BlueFlag } from '../../../../features/shared/components/Icons/BlueFlag';
import { useEmail } from '../../../../features/email/useEmail';

const { Container, Link } = Styles;

export const ReportMissingMeeting: React.FC = () => {
  const { getEmailLink } = useEmail();

  return (
    <Container>
      <BlueFlag />
      <Link
        href={getEmailLink({
          subject:
            'Missing Recap: [Insert Date] + [Insert Meeting Title] hosted on [Select From: Zoom | Google Meet | Microsoft Teams]',
          to: ['support@update.ai'],
          body: 'Hello UpdateAI Support Team,\r\n\r\nI’m unable to locate a recap for the following past meeting:\r\n\r\n- [Insert Meeting Date]\r\n- [Insert Meeting Title]\r\n- Hosted on [Select From: Zoom | Google Meet | Microsoft Teams]\r\n- I was/was not the meeting host\r\n\r\nThank you for attempting to help me retrieve this recap. The importance of this meeting is [high | medium | low].',
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        Missing a meeting recap? Contact support
      </Link>
    </Container>
  );
};
