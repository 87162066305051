import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import VideoRecapPlayerContext from './VideoRecapPlayerContext';

export const VideoRecapPlayerProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [status, setStatus] = useState<'playing' | 'paused' | 'stopped'>('stopped');
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(0);
  const videoRef = useRef<HTMLVideoElement | HTMLAudioElement | null>(null);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isScrollingEnabled, setIsScrollingEnabled] = useState(false);
  const [containerRects, setContainerRects] = useState({ height: 0 });

  const timeUpdateListener = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  useEffect(() => {
    const updateRects = () => {
      if (containerRef) {
        setContainerRects({ height: containerRef.getBoundingClientRect().height });
      }
    };
    if (containerRef) {
      updateRects();
      window.addEventListener('resize', () => {
        updateRects();
      });
      containerRef.addEventListener('resize', () => {
        updateRects();
      });
    }

    return () => {
      window.removeEventListener('resize', updateRects);
      if (containerRef) {
        containerRef.removeEventListener('resize', updateRects);
      }
    };
  }, [containerRef]);

  const playListener = () => {
    setStatus('playing');
    setIsPlaying(true);
    setIsPaused(false);
  };

  const pauseListener = () => {
    setStatus('paused');
    setIsPaused(true);
    setIsPlaying(false);
  };

  const endedListener = () => {
    setStatus('stopped');
    setIsPaused(true);
    setIsPlaying(false);
  };

  const loadedMetadataListener = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      setDuration(duration);
    }
  };

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', timeUpdateListener);
        videoRef.current.removeEventListener('play', playListener);
        videoRef.current.removeEventListener('pause', pauseListener);
        videoRef.current.removeEventListener('ended', endedListener);
        videoRef.current.removeEventListener('loadedmetadata', loadedMetadataListener);
      }
    };
  }, []);

  const setVideoPlayerRef = (ref: HTMLVideoElement | HTMLAudioElement | null) => {
    if (!videoRef.current) {
      videoRef.current = ref;

      if (videoRef.current) {
        videoRef.current.addEventListener('timeupdate', timeUpdateListener);
        videoRef.current.addEventListener('play', playListener);
        videoRef.current.addEventListener('pause', pauseListener);
        videoRef.current.addEventListener('ended', endedListener);
        videoRef.current.addEventListener('loadedmetadata', loadedMetadataListener);

        setVolume(videoRef.current.volume);
        setIsMuted(videoRef.current.muted);
        setPlaybackRate(videoRef.current.playbackRate);
      }
    }
  };

  const skip = (seconds: number) => {
    if (videoRef.current) {
      const newTime = videoRef.current.currentTime + seconds;
      videoRef.current.currentTime = newTime;
    }
  };

  const changePlaybackRate = (rate: number) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  const seek = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
    }
  };

  const mute = () => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const unmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const play = (time?: number) => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        if (videoRef.current) {
          mute();
          videoRef.current.play().then(() => {
            unmute();
          });
        }
      });
      if (time) {
        videoRef.current.currentTime = time;
      }
      setIsScrollingEnabled(true);
    }
  };

  const changeVolume = (value: number) => {
    if (videoRef.current) {
      videoRef.current.volume = value;
      setVolume(value);
    }
  };

  return (
    <VideoRecapPlayerContext.Provider
      value={{
        changeVolume,
        currentTime,
        duration,
        isPaused,
        isPlaying,
        mute,
        playbackRate,
        volume,
        changePlaybackRate,
        pause,
        seek,
        play,
        isMuted,
        setVideoPlayerRef,
        setContainerRef,
        skip,
        status,
        unmute,
        isScrollingEnabled,
        changeScrollEnabled: setIsScrollingEnabled,
        containerRects,
      }}
    >
      {children}
    </VideoRecapPlayerContext.Provider>
  );
};
