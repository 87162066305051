import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';
import { Button } from 'antd';
import { motion } from 'framer-motion';

const ActionButton = styled(Button)`
  width: 110px;
  height: 34px;
`;

const Container = styled(motion.div)`
  display: flex;
  width: 412px;
  height: 190px;
  min-width: 320px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid ${colors.coolGray[100]};
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 24px 16px;
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const Logo = styled.img`
  height: 52px;
  width: 52px;
  align-self: flex-start;
  object-fit: contain;
`;

const Title = styled.span`
  color: var(--Cool-Gray-500, #0d283b);
  /* Text md/Semibold */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const Description = styled.span`
  color: ${colors.gray['700']};
  width: 100%;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Information = styled.span`
  color: ${colors.gray['700']};
  width: 100%;

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 142.857% */
`;

export default {
  Container,
  Logo,
  Title,
  Body,
  Description,
  TitleContainer,
  ActionButton,
  Header,
  Information,
};
