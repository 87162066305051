import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

const Input = styled(AntdInput)`
  font-family: Cera Pro;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px; /* 93.333% */
  padding: 0px;
  letter-spacing: -0.6px;
  width: 500px;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Title = styled.div`
  font-family: Cera Pro;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px; /* 93.333% */
  padding: 0px;
  letter-spacing: -0.6px;
  width: 500px;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export { Input, Title };
