import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
  scroll-behavior: smooth;
  padding-right: 5px;

  h2 {
    margin: 0px;
    color: gray;
    font-family: 'Cera Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea {
    resize: none;
  }
`;

const ItemContent = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 40px;
`;

export default { Container, ItemContent };
