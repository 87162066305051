import { useParams } from 'react-router-dom';
import { useShareLink } from './useShareLink';
import { UpdateLoader } from '../../componentsV2/UpdateLoader';
import { Button, Input, Modal } from 'antd';
import { useState } from 'react';

import Styles from './style';
const { Container, Content, Title, Form, Footer, Header } = Styles;

export const Share = () => {
  const [email, setEmail] = useState('');
  const { id } = useParams();
  const { link, alert, error, redirect, isLoading, isValidating, isExpired, handleValidation } =
    useShareLink(id!);

  if (redirect) window.location.replace(redirect);

  return (
    <Container isLoading={isLoading}>
      {isLoading || redirect ? (
        <UpdateLoader min />
      ) : (
        <Modal
          open
          centered
          destroyOnClose
          width={700}
          footer={false}
          closable={false}
          title={
            <Header>
              <Title>Access Meeting Recap</Title>
              <Button ghost onClick={() => window.open('https://update.ai')}>
                Visit www.update.ai
              </Button>
            </Header>
          }
          className={'noPaddingModal'}
        >
          <Content>
            {alert}

            <Form>
              Enter your email to receive access link
              <Input
                width={400}
                disabled={!link}
                status={error ? 'error' : ''}
                placeholder="Enter your email"
                onChange={e => setEmail(e.target.value)}
              />
            </Form>
          </Content>

          <Footer>
            <div></div>
            <Button
              type="primary"
              disabled={isExpired || (error && !link)}
              loading={isValidating}
              onClick={() => handleValidation(email)}
            >
              Send Access Link
            </Button>
          </Footer>
        </Modal>
      )}
    </Container>
  );
};
