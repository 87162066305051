import styled from 'styled-components';

const Option = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Label = styled.span`
  flex: 1;
  color: var(--gray-950, #282828);

  /* text-sm/leading-5/font-normal */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export default {
  Option,
  Label,
};
