import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 9px;
`;

const Content = styled.div`
  display: flex;
  gap: 30px;
`;

const Item = styled.div`
  font-family: Cera Pro;
  font-size: 13px;
  font-weight: 200;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.coolGray[300]};
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export default { Container, Title, Content, Data, Item };
