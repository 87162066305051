import { Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Insight } from '../../../../../../../../../features/recapv2/recap-management/types';
import { useState } from 'react';
import { useToast } from '../../../../../../../../../hooks/useToast';
import { coreService } from '../../../../../../../../../services/core/core-service';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

interface Props {
  insight: Insight;
  eventId: string;
  key: string;
}

export const AIItem: React.FC<Props> = ({ insight, key, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [val, setVal] = useState(!!insight.hidden);

  const { success, error } = useToast();

  const handleValueChange = async (actionItemId: string, value: boolean) => {
    setLoading(true);
    setVal(value);
    try {
      if (value) {
        await coreService.hideActionItem(eventId, actionItemId);
      } else {
        await coreService.showActionItem(eventId, actionItemId);
      }
    } catch {
      error('Failed to update action item');
      setVal(!value);
    } finally {
      success('Action item updated successfully');
      setLoading(false);
    }
  };

  return (
    <li key={key} style={{ textDecoration: val ? 'line-through' : 'none' }}>
      <Checkbox
        defaultChecked={insight.hidden}
        value={val}
        disabled={loading}
        onChange={v => handleValueChange(insight.id, !v.target.value)}
      />{' '}
      {insight.text}{' '}
      <FontAwesomeIcon
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          window.open(`/recap/${eventId}?insightId=${insight.id}`);
        }}
        size="xs"
        style={{ cursor: 'pointer' }}
        icon={faExternalLink}
      />
    </li>
  );
};
