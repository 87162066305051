import styled from 'styled-components';
import { Card } from '../../../../features/shared/components/Card/Card';

const Container = styled(Card)`
  width: 757px;
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 32px;
  gap: 24px;
`;

export const IndicatorContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  font-size: 34px;
  text-align: center;
  color: #282828;
`;

const Subtitle = styled.p`
  font-size: 18px;
  text-align: center;
  color: #818181;
`;

export default { Container, Title, Subtitle, IndicatorContainer };
