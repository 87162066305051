import React from 'react';
import { AskMeAnything } from '../../../../features/shared/components/AskMeAnything';
import { useAskMeAnything } from '../../../../features/askMeAnything/hooks/useAskMeAnything';
import { AskMeAnythingProvider } from '../../../../features/askMeAnything/context/AskMeAnythingProvider';
import { ChatItem } from '../../../../services/types';
import { Questions } from '../../../../features/askMeAnything/questions';

interface Props {
  category?: string;
}

export const InsightsAskMeAnything: React.FC<Props> = ({ category }) => {
  const { askInsights } = useAskMeAnything();

  return (
    <AskMeAnythingProvider
      amaKey="insights"
      suggestedQuestions={{
        data: Questions.iaDashboard,
        category: category,
        displayDropdown: true,
      }}
    >
      <AskMeAnything
        disabled={false}
        description="The bigger the meeting set, the more time the AI typically needs for processing."
        askFunction={(chatMessages: ChatItem[]) => askInsights(chatMessages)}
        customText={'Ask Me Anything'}
        location="insights"
      />
    </AskMeAnythingProvider>
  );
};
