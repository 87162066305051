import { userSettingsStore, settingsLoaded } from '../../../stores/user-settings';
import {
  updateExternalMeetingRecordingSetting,
  updateExternalSendBot,
  updateInternalMeetingRecordingSetting,
  updateInternalSendBot,
  updateMeetingRecordingSetting,
  updateSendBot,
  updateSettings,
} from '../user.service';
import { coreService } from '../../../services/core/core-service';
import { useEffect } from 'react';
import { Settings } from '../types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/selectors';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

export const useUserSettings = () => {
  const user = useSelector(selectUser);
  const { settings, isSaving, isFetching } = userSettingsStore();
  const { settings: settingsAnalytics } = useAnalytics();

  const isProUser = user?.userSubscription.type === 'paid';

  useEffect(() => {
    coreService.fetchSettings().then(data => {
      if (data) {
        settingsLoaded(data as Settings);
      }
    });
  }, []);

  useEffect(() => {
    settingsAnalytics.updated(settings);
  }, [settings]);

  return {
    settings,
    isFetching,
    isSaving,
    isProUser,
    updateExternalMeetingRecordingSetting,
    updateExternalSendBot,
    updateInternalMeetingRecordingSetting,
    updateInternalSendBot,
    updateMeetingRecordingSetting,
    updateSettings,
    updateSendBot,
  };
};
