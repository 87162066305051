import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 16px;
  aling-items: flex-start;
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 84px;
  border-radius: 8px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

const Title = styled.span`
  max-height: 48px;
  cursor: pointer;
  color: var(--Cool-Gray-500, #0d283b);
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  aling-items: flex-end;
  gap: 16px;
`;

const OptionLabel = styled.span`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const DetailLabel = styled.span`
  color: var(--Cool-Gray-400, #566976);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export default {
  Container,
  Thumbnail,
  Title,
  DetailsContainer,
  Details,
  OptionLabel,
  DetailLabel,
};
