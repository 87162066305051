import { useAnalytics } from '../../../analytics/hooks/useAnalytics';

export const useSummarizeInsightsAnalytics = () => {
  const { sendEvent } = useAnalytics();

  return {
    summaryGenerated: () => sendEvent('Meeting AI Summary Generated'),
    noteAdded: () => sendEvent('Meeting AI Summary Note Added'),
    textCopied: () => sendEvent('Meeting AI Summary Text Copied'),
  };
};
