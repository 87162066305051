/* eslint-disable @typescript-eslint/no-explicit-any */

export interface CreateBookmarkDTO {
  text: string;
  meetingId: string;
  date: string;
  from?: string;
  to?: string;
}

export interface EditBookmarkDTO {
  id?: string;
  from?: string;
  to?: string;
  text?: string;
}

export interface Bookmark {
  id: string;
  text: string;
  from: string;
  to: string;
  meetingId: string;
  userId: string;
  timeInMeeting: string;
  date?: Date;
}

export interface ActionItemExtraData {
  descriptionMessagesIds: string[];
  responseMessagesIds: string[];
}

export interface CreateActionItemType {
  id?: string;
  assignee: string;
  summary: string;
  topicId?: string | null;
  messageId?: string;
  dueDate?: string | null;
  insightId?: string | null;
  type?: string;
  meetingId?: string;
  timeInMeeting?: string;
  inMeetingDataId?: string;
}

export interface EditActionItemType {
  id: string;
  assignee?: string;
  summary?: string;
  dueDate?: string | null;
  extraData?: ActionItemExtraData | null;
  topicId?: string | null;
  category?: string | null;
}

export interface JoinResponse {
  meetingNum: string;
  id: string;
}

export interface SelectOption {
  value: string | number;
  label: string;
}

export interface Step {
  text: string;
  color: string;
}

export interface MetricMessage {
  id: string;
  messageId: string;
  meetingId: string;
  type: metricType;
  reason: string;
}

export enum metricType {
  FALSE_POSITIVES = 'FP',
  FALSE_NEGATIVES = 'FN',
  TRUE_POSITIVES = 'TP',
  TRUE_NEGATIVES = 'TN',
}

export interface UpdateMetricReasonDTO {
  id: string;
  reason: string;
}

export interface Notification {
  message: string;
  type: NotificationType;
  key: string;
  duration?: number;
}

type NotificationType = 'info' | 'error' | 'warning' | 'success';

export const ONBOARDING_TOTAL_STEPS_AMOUNT = 5;

export enum OnboardingStatus {
  Validation = 'VALIDATION',
  SelectOrganization = 'SELECT_ORGANIZATION',
  CalendarSync = 'CALENDAR_SYNC',
  ConferencingSync = 'CONFERENCING_SYNC',
  Completed = 'COMPLETED',
  RecordingSettings = 'RECORDING_SETTINGS',
}

export interface AccountDetails {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface HistoryMeeting {
  id: string;
  startedAt: string | Date;
  summary: string;
  endedAt: string;
  aiCount: number;
}

export interface SignupDetails {
  email?: string;
  company?: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
}

export interface OnboardingDetails {
  email?: string;
  password: string;
  repeatPassword: string;
}

export enum ModalOptionButtonId {
  ADD_TO = 'ADD_TO',
  MARK_AS = 'MARK_AS',
  REMOVE = 'REMOVE',
}

export interface ModalOption {
  label: string;
  buttons: ModalOptionButton[];
  id: ModalOptionButtonId;
}

export interface ModalOptionButton {
  text: string;
  iconName: string;
  function: any;
  separator?: boolean;
}

export enum UserOrganizationRole {
  OWNER = 'owner',
  MEMBER = 'member',
}

export enum OrganizationInviteStatus {
  ACTIVE = 'active',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
}

export enum EventStatus {
  READY = 'READY',
  FINISHED = 'FINISHED',
  SKIPPED = 'SKIPPED',
  SCHEDULED = 'SCHEDULED',
  DID_NOT_START = 'DID_NOT_START',
  LIMITED = 'LIMITED',
  NOT_AVAILABLE_BASIC = 'NOT_AVAILABLE_BASIC',
  BASIC = 'BASIC',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LIVE = 'LIVE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
}

export enum AccountIntegrations {
  ACCOUNT_PROVIDER_HUBSPOT = 'hubspot',
  ACCOUNT_PROVIDER_SALESFORCE = 'salesforce',
}
