import { Dropdown } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import Styled from './styles';
import { OptionalTooltipWrapper } from '../OptionalTooltipWrapper';

const { Container, Item, Label, Divider } = Styled;
interface DividerMenuItem {
  type: 'divider';
}

interface ItemMenuItem {
  type: 'item';
  label?: string | ReactNode;
  onClick?: () => unknown;
  key: string;
  icon?: ReactNode;
  render?: ReactNode;
  disabled?: {
    tooltip?: string;
    value: boolean;
  };
}

export type MenuItem = ItemMenuItem | DividerMenuItem;

interface Props {
  items: MenuItem[];
}

export const MenuLabel = Label;

export const Menu: React.FC<PropsWithChildren<Props>> = ({ items, children }) => {
  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => {
        return (
          <Container>
            {items.map((item, itemIndex) => {
              const isDisabled =
                item.type === 'item' && typeof item.disabled !== 'undefined'
                  ? item.disabled.value
                  : false;
              if (item.type === 'divider') {
                return <Divider key={`divider-${itemIndex}`} />;
              } else {
                return (
                  <OptionalTooltipWrapper
                    display={isDisabled}
                    value={typeof item.disabled !== 'boolean' ? item.disabled?.tooltip : undefined}
                  >
                    <Item
                      disabled={isDisabled}
                      key={item.key}
                      clickable={Boolean(item.onClick)}
                      onClick={item.onClick}
                    >
                      {item.render ? (
                        item.render
                      ) : (
                        <>
                          {item.icon}
                          {typeof item.label === 'string' ? (
                            <Label disabled={isDisabled}>{item.label}</Label>
                          ) : (
                            item.label
                          )}
                        </>
                      )}
                    </Item>
                  </OptionalTooltipWrapper>
                );
              }
            })}
          </Container>
        );
      }}
    >
      {children}
    </Dropdown>
  );
};
