import { Button, Input, InputNumber, Radio, Select, Space, Tag } from 'antd';
import { Box } from '../components/Box';
import { useMemo, useRef, useState } from 'react';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';
import { addDays } from 'date-fns';
import { IAuthorizationMechanism } from '../types';
import { ManageLinksComponent } from '../components/ManageLinksComponent';

import StylesGenerateLinks from './styles';
const { GeneralOptions, Item } = StylesGenerateLinks;

import Styles from '../styles';
import { initialState } from '..';
const { Title, Footer, Content, Description, Header } = Styles;

interface Props {
  toManageLinks: () => void;
  value: any;
  setValue: any;
  recapId: string;
}

export const GenerateShareLink: React.FC<Props> = ({ toManageLinks, setValue, value, recapId }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { success, error } = useToast();

  const handleCreateLink = () => {
    setIsLoading(true);
    coreService
      .createShareLink({
        eventId: recapId,
        expiresAt: addDays(new Date(), value.days).toISOString(),
        description: value.description,
        authorizationMechanism: value.mechanism,
        authorizedEmails:
          value.mechanism !== IAuthorizationMechanism.authorized_only ? [] : value.authorizedUsers,
      })
      .then(r => {
        navigator.clipboard.writeText(r.data.url);
        success('Link created and copied to clipboard.');
        toManageLinks();
        setValue(initialState);
      })
      .catch(e => error(e.message))
      .finally(() => setIsLoading(false));
  };

  const select = useMemo(() => {
    return (
      <Select
        mode="tags"
        style={{ width: '100%', marginTop: '16px' }}
        placeholder="Separate multiple e-mails by pressing enter"
        disabled={value.mechanism !== IAuthorizationMechanism.authorized_only || isLoading}
        onChange={e => {
          setValue((v: any) => {
            return { ...v, authorizedUsers: Array.from(new Set(e)) };
          });
        }}
        onFocus={() => {
          setTimeout(() => ref?.current?.focus());
        }}
        tagRender={tag => {
          return (
            <Tag closable={!isLoading} onClose={tag.onClose}>
              {tag.label}
            </Tag>
          );
        }}
        ref={ref}
        showArrow={false}
        open={false}
      />
    );
  }, [isLoading, value.mechanism]);

  return (
    <>
      <Title>Generate Share Link</Title>

      <Content>
        <Box title="General Options">
          <GeneralOptions>
            <Item>
              Expiration (Days from now)*
              <InputNumber
                min={1}
                max={365}
                value={value.days}
                style={{ width: '100%' }}
                disabled={isLoading}
                // formatter={value => `${value} ${value! > 1 ? 'days' : 'day'}`}
                onChange={e =>
                  setValue((v: any) => {
                    return { ...v, days: e || 1 };
                  })
                }
              />
            </Item>

            <Item>
              Share Link Description (Optional)
              <Input
                allowClear
                onChange={e => {
                  e.persist();
                  setValue((v: any) => {
                    return { ...v, description: e?.target?.value || '' };
                  });
                }}
                placeholder="Describe the use of the link"
                disabled={isLoading}
              />
            </Item>
          </GeneralOptions>
        </Box>

        <Box title="Shared Links Options">
          <Radio.Group
            onChange={e =>
              setValue((v: any) => {
                return { ...v, mechanism: e.target.value };
              })
            }
            value={value.mechanism}
            disabled={isLoading}
          >
            <Space direction="vertical">
              <Radio value={IAuthorizationMechanism.public}>
                <Header>Public</Header>
                <Description>
                  Anyone with this link can access the recap. Users in possession of this link will
                  not be prompted to authenticate or confirm their identity in any way.
                </Description>
              </Radio>

              <Radio value={IAuthorizationMechanism.requires_identification}>
                <Header>Private</Header>
                <Description>
                  Only people with this link can access the recap. When using the link, the user
                  will be asked to enter their email address. However, this is only for
                  identification purposes, and no user will be turned away.
                </Description>
              </Radio>

              <Radio value={IAuthorizationMechanism.authorized_only}>
                <Header>User Specific</Header>
                <Description>
                  Only people with this link AND added to this approved list of emails below can
                  access the recap. If the email address submitted is not on this approved list, the
                  user will see an error message.
                </Description>

                {select}
              </Radio>
            </Space>
          </Radio.Group>
        </Box>
      </Content>

      <Footer>
        <ManageLinksComponent />
        <div style={{ display: 'flex', gap: '16px' }}>
          <Button onClick={toManageLinks} disabled={isLoading}>
            Go to Manage Links
          </Button>
          <Button type="primary" loading={isLoading} onClick={handleCreateLink}>
            Generate Link
          </Button>
        </div>
      </Footer>
    </>
  );
};
