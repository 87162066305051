import React from 'react';

import LogoUpdateImage from '../../assets/svg/updateAI-logo.svg';

interface Props {
  height?: string;
  width?: string;
}

const UpdateLogo: React.FC<Props> = ({ height = '36px', width = '152px' }) => (
  <img alt="logo" src={LogoUpdateImage} height={height} width={width} />
);

export default UpdateLogo;
