import styled from 'styled-components';

const Container = styled.span`
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;

export default {
  Container,
};
