import { Button, Divider, Dropdown, Tooltip } from 'antd';
import { ILink } from '../../../../../../services/types';
import { CopyOutlined, MoreOutlined } from '@ant-design/icons';
import { formatLinkTime } from '../../../../../../utils/dateUtils';
import { IAuthorizationMechanism } from '../../types';
import { useCopyLink } from '../../../../../../hooks/useRecapCopy';

import Styles from './styles';
import { authToLogo } from '../../shareBoxUtils';
const { Container, Row, URL, Link } = Styles;

interface Props {
  selectLink: (link: ILink & { edit: boolean }) => void;
  cancelLink: (id: string) => void;
  list: ILink[] | null;
}

export const Rows: React.FC<Props> = ({ list, selectLink, cancelLink }) => {
  const copy = useCopyLink();

  return (
    <Container>
      <Row style={{ marginBottom: '10px' }}>
        <span>Share URL</span>
        <span>Type</span>
        {/* <span>Generated Date</span> */}
        <span>Expiration</span>
        <span>Description</span>
        <span></span>
      </Row>

      {list && list?.length > 0 ? (
        list.map(l => {
          return (
            <Row key={l.id}>
              <URL onClick={() => copy(l.url)}>
                <Link>{l.url.split('.ai')[1]}</Link>
                <CopyOutlined />
              </URL>

              <span>{authToLogo(l.type as IAuthorizationMechanism)}</span>

              <span>{formatLinkTime(new Date(l.expirationDate))}</span>

              <Tooltip title={l.description}>
                <Link>{l.description !== '' ? l.description : '-'}</Link>
              </Tooltip>

              <Dropdown
                menu={{
                  items: [
                    {
                      label: 'Copy Link',
                      disabled: false,
                      key: '0',
                      onClick: () => {
                        copy(l.url);
                      },
                    },
                    {
                      label: 'Change Expiration',
                      disabled: false,
                      key: '1',
                      onClick: () => {
                        selectLink({ ...l, edit: true });
                      },
                    },

                    {
                      label: 'Share Link Details',
                      disabled: false,
                      key: '2',
                      onClick: () => {
                        selectLink({ ...l, edit: false });
                      },
                    },
                    {
                      label: <Divider style={{ margin: '0px', cursor: 'default' }} />,
                      key: 'org-div-vis-3',
                      disabled: true,
                    },
                    {
                      label: <div style={{ color: 'red' }}>Deactivate Link</div>,
                      disabled: false,
                      key: '3',
                      onClick: () => {
                        cancelLink(l.id);
                      },
                    },
                  ],
                }}
              >
                <Button icon={<MoreOutlined />} size="small" />
              </Dropdown>
            </Row>
          );
        })
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 11,
          }}
        >
          No active Shared Links found
        </div>
      )}
    </Container>
  );
};
