import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { extractShareCode } from '../features/recap/utils/extractShareCode';
import { Recap } from '../pages/recap';
import { validate as uuidValidate } from 'uuid';
import { RecapManagementProvider } from '../features/recapv2/recap-management/context';

export const RecapIdResolver: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();

  const shareCode: string | null = useMemo(() => extractShareCode(search), [search]);

  if (!id) {
    navigate('/');
    return <></>;
  }

  if (shareCode) {
    if (uuidValidate(shareCode)) {
      window.location.href = `/share/${shareCode}`;
      return <></>;
    }
  }

  return (
    <RecapManagementProvider id={id} secret={shareCode || ''}>
      <Recap id={id} shareCode={shareCode || ''} />
    </RecapManagementProvider>
  );
};
