import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { InvitationCard } from './components/InvitationCard';
import { coreAPI } from '../../API/core';
import { useParams } from 'react-router-dom';
import { useRedirectNavigation } from '../../features/redirectNavigation/useRedirectNavigation';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';

const { Container } = Styles;
const coreClient = new coreAPI();

export const InvitationPage: React.FC = () => {
  const [status, setStatus] = useState<'success' | 'error' | 'loading' | 'warning'>('loading');

  const { id } = useParams();
  const user = useSelector(selectUser);
  const { setRedirect } = useRedirectNavigation();

  useEffect(() => {
    if (!user) {
      setRedirect();
      setStatus('warning');
    } else {
      if (id) {
        coreClient
          .acceptInvite(id)
          .then(() => setStatus('success'))
          .catch(() => setStatus('error'));
      }
    }
  }, [id, user]);

  return (
    <Container>
      <InvitationCard status={status} />
    </Container>
  );
};
