import styled from 'styled-components';

const Content = styled.div`
  display: grid;
  grid-template-columns: auto minmax(310px, 30%);
  border-radius: 8px;
  gap: 12px;
  overflow-x: hidden;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RightColumn = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 200px);
`;

const FilterAndActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default {
  Content,
  LeftColumn,
  RightColumn,
  DropdownOption,
  CenteredContent,
  FilterAndActionsContainer,
  ActionsContainer,
  FiltersContainer,
  Header,
  Body,
};
