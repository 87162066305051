import styled from 'styled-components';

export const TranscriptLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  &:hover {
    #transcript {
      background: var(--gray-200, #e4e4e4);
    }
  }

  &[data-is-playing='true'] {
    #transcript {
      background: var(--primary-50, #fff8f6);
    }
    #transcript-chunk {
      color: var(--primary-700, #bf513f);
      font-variant-numeric: stacked-fractions;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    #transcript-speaker {
      color: var(--primary-500, #ff6c54);
    }
    #transcript-timestamp {
      color: var(--primary-500, #ff6c54);
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const Transcript = styled.span`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
`;

export const Speaker = styled.span`
  color: var(--gray-950, #282828);
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const Timestamp = styled.span`
  color: var(--gray-600, #818181);
  text-align: right;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled.span<{
  selected: boolean;
  current: boolean;
}>`
  border-radius: ${props => (props.selected ? '4px' : '0px')};
  color: black;
  background-color: transparent;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export default { TextContent };
