import { Cascader } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { SuggestedQuestionsType } from '../../../../askMeAnything/questions';
import style from './style';

const { Containter, CardContainter, ActionContainer, HideButton, Card } = style;

interface Props {
  send: (value: string) => void;
  disabled: boolean;
  display: boolean;
  context: SuggestedQuestionsType;
  defaultCategory?: string;
}

const toCard = (
  question: string,
  category: string,
  send: (value: string) => void,
  disabled: boolean,
) => {
  return (
    <Card
      key={question.replace(' ', '').slice(0, 14)}
      onClick={() => {
        if (!disabled) send(question);
      }}
    >
      <h1>{category}</h1>
      <h2>{question}</h2>
    </Card>
  );
};

export const SuggestedQuestions: React.FC<Props> = ({
  send,
  disabled,
  display,
  context,
  defaultCategory = '',
}) => {
  const [show, setShow] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [questions] = useState(context.questions || []);

  useEffect(() => {
    setSelectedCategory(defaultCategory);
  }, [defaultCategory]);

  const showHideButton = useMemo(() => {
    return show ? (
      <>
        <EyeInvisibleOutlined />
        Hide Presets
      </>
    ) : (
      <>
        <EyeOutlined />
        Show Presets
      </>
    );
  }, [show]);

  return (
    <Containter>
      <ActionContainer>
        {selectedCategory !== '' && (
          <HideButton onClick={() => setShow(!show)}>{showHideButton}</HideButton>
        )}
        <div
          style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}
        >
          {display && show && context.type.length > 1 && (
            <>
              <div>Meeting type: </div>
              <Cascader
                value={[selectedCategory]}
                options={context.type as { value: string; label: string }[]}
                onChange={value => setSelectedCategory(value[0] as string)}
                placeholder="Please select"
                size="small"
              />
            </>
          )}
        </div>
      </ActionContainer>

      {show && (
        <CardContainter>
          {questions[selectedCategory]?.map(q => toCard(q.question, q.title, send, disabled)) || []}
        </CardContainter>
      )}
    </Containter>
  );
};
