import { useContext } from 'react';
import { categoryManagementContext } from '../context/CategoryManagementProvider';
import { useInsightCategories } from './useInsightCategories';

export const useInsightCategoriesManagement = () => {
  const {
    categories,
    add,
    changeDescription,
    changeName,
    discardChanges,
    confirmChanges,
    confirmNameChange,
    confirmDescriptionChange,
    startDescriptionEditing,
    startNameEditing,
    saveChanges,
    remove,
    isSaving,
    hasModifications,
  } = useContext(categoryManagementContext);
  const { refetch } = useInsightCategories();

  return {
    categories,
    isSaving,
    refetch,
    hasModifications,
    confirmNameChange,
    confirmDescriptionChange,
    remove,
    startDescriptionEditing,
    startNameEditing,
    saveChanges,
    changeName,
    discardChanges,
    changeDescription,
    confirmChanges,
    add,
  };
};
