import { DeleteOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../../../redux/typings/store';
import { coreAPI } from '../../../../API/core';
import { useToast } from '../../../../hooks/useToast';
import { Container } from './style';
import { useRecapAnalytics } from '../../../recapv2/recap-analytics/hooks/useRecapAnalytics';
import DeleteModal from '../../../../pages/settings/Tabs/Organizations/components/DeleteModal';

export const RemoveRecap: React.FC = () => {
  const [showModal, setshowModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const eventId = useSelector((store: Store) => store.recap.event.id);

  const recapAnalytics = useRecapAnalytics();
  const navigator = useNavigate();
  const { success, error } = useToast();

  const removeRecap = () => {
    setIsRemoving(true);
    const api = new coreAPI();
    api
      .removeRecap(eventId)
      .then(() => {
        recapAnalytics.deleted();
        success('Recap removed successfully');
        navigator('/');
      })
      .catch(() => {
        error('There was an error removing recap. Contact support.');
      })
      .finally(() => {
        setIsRemoving(false);
        setshowModal(false);
      });
  };

  return (
    <Container>
      <div onClick={() => setshowModal(true)}>
        <DeleteOutlined /> Delete this recap
      </div>
      <DeleteModal
        loading={isRemoving}
        shouldShow={showModal}
        closeModal={() => setshowModal(false)}
        heading="Delete this recap"
        detail={'Are you sure you want to delete this recap? It cannot be recovered.'}
        confirmButtonText="Yes, delete it now"
        onConfirm={removeRecap}
      />
    </Container>
  );
};
