import { useSelector } from 'react-redux';
import { selectUser } from '../redux/selectors';

const useFeatures = () => {
  const user = useSelector(selectUser);
  const isUpdateDeveloper = Boolean(user && user.email && user.email.includes('@update.ai'));

  return { ...user?.features, showCRMMapping: isUpdateDeveloper };
};

export default useFeatures;
