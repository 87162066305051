import React, { useMemo } from 'react';
import { useRecapManagement } from '../../features/recapv2/recap-management/hook';
import { InsightTypeCard } from '../../features/recap/components/InsightTypeCard';
import { getInsightTypeConfiguration } from '../../features/insights/insight-type-configuration/insight-type-configuration.service';

export const InsightCards: React.FC = () => {
  const { categories, publicVisibility, isPublic } = useRecapManagement();

  const categoriesShown = useMemo(() => {
    const allCatNames = Object.keys(categories);
    if (!isPublic) {
      return allCatNames;
    }

    const catNames = allCatNames.filter(category => {
      const config = getInsightTypeConfiguration(category);
      const visibilityKey = config.visibilityKey;
      if (!visibilityKey) {
        return false;
      }

      return publicVisibility[visibilityKey];
    });
    return catNames;
  }, [categories, publicVisibility, isPublic]);

  return (
    <>
      {categoriesShown.map(category => {
        return <InsightTypeCard category={category} key={category} />;
      })}
    </>
  );
};
