import { CopyOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React from 'react';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';

interface Props {
  children: React.ReactElement;
  onCopyInternal: () => unknown;
  onCopyPublic: () => unknown;
}

export const CopyAll: React.FC<Props> = ({ children, onCopyPublic, onCopyInternal }) => {
  const { isPublic } = useRecapManagement();

  if (isPublic) {
    return React.cloneElement(children, {
      onClick: () => {
        if (onCopyPublic) onCopyPublic();
      },
    });
  }

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            label: (
              <Space
                onClick={() => {
                  onCopyPublic();
                }}
              >
                <CopyOutlined />
                Copy to share publicly
              </Space>
            ),
            key: 'copy-publicly',
          },
          {
            label: (
              <Space
                onClick={() => {
                  onCopyInternal();
                }}
              >
                <CopyOutlined />
                Copy to share internally
              </Space>
            ),
            key: 'copy-internally',
          },
        ],
      }}
    >
      {children}
    </Dropdown>
  );
};
