import { CheckCircle } from '../../../shared/components/Icons/CheckCircle';
import style from './styles';

interface Props {
  title: string;
  icon?: string;
  description?: string;
  expand?: boolean;
  onClick?: () => void;
}

export const DropdownButton: React.FC<Props> = ({
  title,
  icon,
  description,
  expand = false,
  onClick,
}) => {
  const { Container, Title, Img, Description, Header } = style;

  return (
    <Container
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Header>
        {icon && <Img src={icon} />}
        <Title>{title}</Title>
        {expand && <CheckCircle />}
      </Header>
      {expand && description ? <Description>{description}</Description> : <></>}
    </Container>
  );
};
