import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Progress } from '../../Progress';
import { BoundedContent, Content, Status } from '../style';
import { useIntegrationResult } from '../../../../features/integrations/hooks/useIntegrationResult';
import { isIntegrationErrorMessage } from '../../../../helpers/integrations.helper';
import { FramedLayout } from '../../../../Layout/FramedLayout/FramedLayout';
import { TwoColumnLayout } from '../../../../Layout/FramedLayout/TwoColumnLayout';
import {
  selectOnboardingError,
  selectOnboardingLoading,
  selectZoomIntegration,
} from '../../../../redux/selectors';
import { setCurrentStep } from '../../../../redux/slices/onboardingSlice';
import { silentFetchUser, integrateZoom } from '../../../../redux/slices/sessionSlice';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../../types/commonTypes';
import { DropdownButton } from '../../../../features/onboarding/components/DropdownButton';
import { ZoomExtraInfo } from './ZoomExtraInfo';
import { MicrosoftIcon } from './MicrosoftIcon';
import { useOnboardingAnalytics } from '../../useOnboardingAnalytics';

export const Conferencing: React.FC = () => {
  const [showZoomExtraInfo, setShowZoomExtraInfo] = useState(false);
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const [providerSelected, setProviderSelected] = useState<'zoom' | 'teams' | 'meet' | 'none'>(
    'none',
  );

  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { video } = useOnboardingAnalytics();

  const error = useSelector(selectOnboardingError);
  const integration = useSelector(selectZoomIntegration);
  const isLoading = useSelector(selectOnboardingLoading);

  const integrationResult = useIntegrationResult();

  const nonZoomDescription =
    'Our custom bot will join your meetings and is configurable in your account.';

  const status = () => {
    const zoomCondition = ['zoom', 'none'].includes(providerSelected) && !showZoomExtraInfo;
    return (
      <Status>
        <Button
          type="primary"
          style={{ width: 'auto', alignSelf: 'flex-end' }}
          loading={isLoading}
          disabled={zoomCondition}
          onClick={() => dispatch(setCurrentStep())}
        >
          Next
        </Button>
        <Progress total={ONBOARDING_TOTAL_STEPS_AMOUNT} current={4} />
      </Status>
    );
  };

  useEffect(() => {
    if (integrationResult) {
      const { errorMessage, source } = integrationResult;
      if (source === 'ZOOM') {
        if (isIntegrationErrorMessage(errorMessage)) setGlobalError(errorMessage);

        dispatch(silentFetchUser());
      }
    }
  }, [integrationResult]);

  useEffect(() => {
    if (!!integration?.email) setShowZoomExtraInfo(true);
  }, [integration]);

  if (showZoomExtraInfo) {
    video.selected('Zoom');
    return <ZoomExtraInfo>{status()}</ZoomExtraInfo>;
  }

  return (
    <FramedLayout error={error || params.get('errorMessage') || globalError || undefined} logOut>
      <TwoColumnLayout
        title="Connect your primary meeting platform"
        image="https://assets.update.ai/meeting_integration.png"
        type="secondary"
        styles={{
          height: 'auto',
          width: 'auto',
        }}
      >
        <Content>
          <BoundedContent>
            <DropdownButton
              expand={providerSelected === 'zoom'}
              title="Connect Zoom account"
              icon="https://explore.zoom.us/media/rm-blog-1.svg"
              onClick={() => {
                dispatch(integrateZoom());
                setProviderSelected('zoom');
              }}
            />

            <DropdownButton
              expand={providerSelected === 'meet'}
              title="We use Google Meet"
              icon="https://assets.update.ai/google_calendar_logo.png"
              description={nonZoomDescription}
              onClick={() => {
                setProviderSelected('meet');
                video.selected('Meet');
              }}
            />

            <DropdownButton
              expand={providerSelected === 'teams'}
              title="We use Microsoft Teams"
              icon={MicrosoftIcon}
              description={nonZoomDescription}
              onClick={() => {
                setProviderSelected('teams');
                video.selected('Teams');
              }}
            />
          </BoundedContent>

          {status()}
        </Content>
      </TwoColumnLayout>
    </FramedLayout>
  );
};
