import styled from 'styled-components';

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 220px;
  flex-direction: row;
  min-width: fit-content;

  padding: 9px 13px;
  background: ${props =>
    props.disabled
      ? 'var(--cool-gray-400, #566976)'
      : 'var(--grst, linear-gradient(132deg, #07f 0%, #7b2bff 100%))'};
  gap: 4px;
  border-radius: 4px;
  height: 38px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    scale: 1.05;
  }
  transition: all 0.3s;
`;

const Label = styled.span`
  font-weight: 14px;
  color: var(--primary-50, #fff8f6);
  font-weight: 500;
  line-height: 20px;
`;

export default {
  Container,
  Label,
};
