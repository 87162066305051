import { QuestionCircleOutlined } from '@ant-design/icons';
import { colors } from '../../../../../../theme/colors';

export const ManageLinksComponent = () => {
  return (
    <div
      style={{ color: `${colors.gray[500]}`, cursor: 'pointer' }}
      onClick={() =>
        window.open(
          'https://help.update.ai/en/articles/6296112-how-to-create-a-publicly-shareable-view-of-your-meeting-recap',
        )
      }
    >
      <QuestionCircleOutlined /> How to share a recap outside of my UpdateAI workspace?
    </div>
  );
};
