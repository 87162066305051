import { create } from 'zustand';

interface OrganizationDetailsStore {
  id: string;
  name: string;
  isFetching: boolean;
  fetchError: string | null;
  allowJoinByDomain: boolean;
  doNotRecordList: string[];
  manageMeetingsVisibilityGlobally: boolean;
  botTextAnnouncement: boolean;
  botVoiceAnnouncement: boolean;
  globalMeetingsVisibility: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  };
  permissions: {
    connectPrimaryCrm: boolean;
    connectSlack: boolean;
    manageCustomInsightCategories: boolean;
    manageInvitations: boolean;
    manageMeetingsVisibilityGlobally: boolean;
    manageOrganizationDetails: boolean;
    manageDoNotRecordList: boolean;
    transferOwnership: boolean;
    manageBotAnnouncement: boolean;
  };
  crmData: {
    primaryProfile: string | null;
    pushToCrmWithPrimaryProfile: boolean;
    provider: 'salesforce' | 'hubspot' | null;
    properties: {
      isSynchronizing: boolean;
    };
    accountsList: {
      isSynchronizing: boolean;
      lastSynchronizedAt: string | null;
    };
  };
  slack: {
    id: string;
    email: string;
  } | null;
}

const organizationDetailsStore = create<OrganizationDetailsStore>(() => {
  return {
    id: '',
    name: '',
    isFetching: false,
    fetchError: null,
    allowJoinByDomain: false,
    doNotRecordList: [],
    manageMeetingsVisibilityGlobally: false,
    botTextAnnouncement: false,
    botVoiceAnnouncement: false,
    permissions: {
      connectPrimaryCrm: false,
      connectSlack: false,
      manageCustomInsightCategories: false,
      manageInvitations: false,
      manageMeetingsVisibilityGlobally: false,
      manageOrganizationDetails: false,
      transferOwnership: false,
      manageDoNotRecordList: false,
      manageBotAnnouncement: false,
    },
    globalMeetingsVisibility: {
      internalMeetingsVisibility: 'host',
      externalMeetingsVisibility: 'host',
    },
    crmData: {
      primaryProfile: null,
      pushToCrmWithPrimaryProfile: false,
      provider: null,
      properties: {
        isSynchronizing: false,
      },
      accountsList: {
        isSynchronizing: false,
        lastSynchronizedAt: null,
      },
    },
    slack: null,
  };
});

const fetchSucceeded = (args: {
  id: string;
  name: string;
  allowJoinByDomain: boolean;
  manageMeetingsVisibilityGlobally: boolean;
  botTextAnnouncement: boolean;
  botVoiceAnnouncement: boolean;
  doNotRecordList: string[];
  permissions: {
    manageMeetingsVisibilityGlobally: boolean;
    connectPrimaryCrm: boolean;
    connectSlack: boolean;
    manageCustomInsightCategories: boolean;
    manageInvitations: boolean;
    manageOrganizationDetails: boolean;
    transferOwnership: boolean;
    manageDoNotRecordList: boolean;
    manageBotAnnouncement: boolean;
  };
  globalMeetingsVisibility: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  };
  crmData: {
    primaryProfile: string | null;
    pushToCrmWithPrimaryProfile: boolean;
    provider: 'salesforce' | 'hubspot' | null;
    properties: {
      isSynchronizing: boolean;
    };
    accountsList: {
      isSynchronizing: boolean;
      lastSynchronizedAt: string | null;
    };
  };
  slack: {
    id: string;
    email: string;
  } | null;
}) => {
  organizationDetailsStore.setState(() => ({
    id: args.id,
    name: args.name,
    fetchError: null,
    isFetching: false,
    allowJoinByDomain: args.allowJoinByDomain,
    manageMeetingsVisibilityGlobally: args.manageMeetingsVisibilityGlobally,
    globalMeetingsVisibility: args.globalMeetingsVisibility,
    botTextAnnouncement: args.botTextAnnouncement,
    botVoiceAnnouncement: args.botVoiceAnnouncement,
    permissions: args.permissions,
    doNotRecordList: args.doNotRecordList,
    crmData: args.crmData,
    slack: args.slack,
  }));
};

const fetchFailed = (error: string) =>
  organizationDetailsStore.setState(() => ({ isFetching: false, fetchError: error }));

const fetchRequested = () =>
  organizationDetailsStore.setState(() => ({ isFetching: true, fetchError: null }));

const nameChanged = (name: string) => organizationDetailsStore.setState(() => ({ name }));

const crmDataChanged = (args: {
  pushToCrmWithPrimaryProfile: boolean;
  primaryProfile: string | null;
  provider: 'hubspot' | 'salesforce' | null;
  properties: {
    isSynchronizing: boolean;
  };
  accountsList: {
    isSynchronizing: boolean;
    lastSynchronizedAt: string | null;
  };
}) => organizationDetailsStore.setState(() => ({ crmData: args }));

const allowJoinByDomainToggled = (value: boolean) =>
  organizationDetailsStore.setState(() => ({ allowJoinByDomain: value }));

const doNotRecordListUpdated = (doNotRecordList: string[]) =>
  organizationDetailsStore.setState(() => ({ doNotRecordList }));

const manageGlobalMeetingsVisibilityDisabled = () =>
  organizationDetailsStore.setState(() => ({ manageMeetingsVisibilityGlobally: false }));
const manageGlobalMeetingsVisibilityEnabled = () =>
  organizationDetailsStore.setState(() => ({ manageMeetingsVisibilityGlobally: true }));

const globalMeetingsVisibilityModified = (args: {
  internalMeetingsVisibility: string;
  externalMeetingsVisibility: string;
}) => organizationDetailsStore.setState(() => ({ globalMeetingsVisibility: args }));

const changeBotAnnouncement = (args: {
  botTextAnnouncement?: boolean;
  botVoiceAnnouncement?: boolean;
}) => organizationDetailsStore.setState(() => args);

export {
  organizationDetailsStore,
  fetchSucceeded,
  fetchFailed,
  fetchRequested,
  doNotRecordListUpdated,
  nameChanged,
  allowJoinByDomainToggled,
  globalMeetingsVisibilityModified,
  manageGlobalMeetingsVisibilityDisabled,
  manageGlobalMeetingsVisibilityEnabled,
  crmDataChanged,
  changeBotAnnouncement,
};
