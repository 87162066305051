import {
  GetInsightsAggregatedDataArgs,
  GetInsightsAggregatedDataResult,
  InsightsArgs,
} from '../../../../services/types';
import { stripMils } from '../../../../utils/dateUtils';
import { Group } from '../../stores/customer-intelligence-data';
import { customerIntelligenceFiltersStore } from '../../stores/customer-intelligence-filters';

const isValidateDateFilter = (filter: [Date, Date] | [null, null]): filter is [Date, Date] => {
  return filter !== null && filter[0] !== null && filter[1] !== null;
};

export const storeToInsightArgs = (): null | InsightsArgs => {
  const {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    lifecycleStages,
    members,
    priorities,
    group,
    subGroup,
  } = customerIntelligenceFiltersStore.getState();

  if (!isValidateDateFilter(dateRange)) {
    return null;
  }

  const opts: InsightsArgs = {
    category,
    from: stripMils(dateRange[0].toISOString()),
    to: stripMils(dateRange[1].toISOString()),
    hostOnly: meetingsImInvitedOnly,
  };

  const accountIds = accounts.map(acc => acc.id);
  const memberIds = members.filter(mem => mem.selected).map(mem => mem.id);
  const lifecycleStagesIds = lifecycleStages.filter(ls => ls.selected).map(ls => ls.id);
  const industryIds = industries.filter(ind => ind.selected).map(ind => ind.id);
  const prioritiesIds = priorities
    .filter(priority => priority.selected)
    .map(priority => priority.id);

  if (accountIds.length) {
    opts['accounts'] = accountIds.join(',');
  }

  if (memberIds.length && memberIds.length !== members.length) {
    opts['users'] = memberIds.join(',');
  }

  if (lifecycleStagesIds.length && lifecycleStagesIds.length !== lifecycleStages.length) {
    opts['lifecycleStage'] = lifecycleStagesIds.join(',');
  }

  if (industryIds.length && industryIds.length !== industries.length) {
    opts['industry'] = industryIds.join(',');
  }

  if (prioritiesIds.length && prioritiesIds.length !== priorities.length) {
    opts['priority'] = prioritiesIds.join(',');
  }

  if (contractRenewalDateBefore) {
    opts['contractRenewalDateBefore'] = stripMils(contractRenewalDateBefore.toISOString());
  }

  if (contractValueMax) {
    opts['contractValueMax'] = contractValueMax;
  }

  if (contractValueMin) {
    opts['contractValueMin'] = contractValueMin;
  }

  if (group) {
    opts['group'] = group;
  }

  if (subGroup) {
    opts['subGroup'] = subGroup;
  }

  return opts;
};

export const storeToGetInsightAggregatedDataArgs = (): null | GetInsightsAggregatedDataArgs => {
  const opts = storeToInsightArgs();

  if (opts) {
    delete opts.group;
    delete opts.subGroup;
  }

  return opts;
};

export const extractGroupsFromAggregatedData = (
  aggregateData: GetInsightsAggregatedDataResult,
): Group[] => {
  const groupsDataMap = new Map<string, Group>();

  aggregateData.groups.forEach(group => {
    const existingGroup = groupsDataMap.get(group.name);
    if (!existingGroup) {
      groupsDataMap.set(group.name, {
        total: group.count,
        name: group.name,
        daily: [],
        subGroups: group.subgroups,
      });
    }
  });

  aggregateData.overview.forEach(overview => {
    const existingGroup = groupsDataMap.get(overview.name);
    if (existingGroup) {
      existingGroup.daily = overview.points.map(dailyPoint => {
        return {
          count: dailyPoint.value,
          date: dailyPoint.date,
        };
      });
      groupsDataMap.set(overview.name, existingGroup);
    }
  });

  const groups = Array.from(groupsDataMap.values());
  return groups;
};
