import { Button, Divider, Dropdown, Tooltip } from 'antd';
import { DownOutlined, FilterFilled, FilterOutlined, MenuOutlined } from '@ant-design/icons';
import { ManageFiltersModal } from './ManageFiltersModal';
import { StoredFilterType } from './types';
import { CreateFiltersModal } from './CreateFiltersModal';
import { useStoreFilter } from '../../../../features/insights/hooks/useStoreFilter';
import { useDashboardFilters } from '../../../../features/shared/dashboard-filters/dashboard-filters.hooks';

interface Props {
  disabled: boolean;
  label: string;
  showHideFilter: () => void;
}

const StoredFilters: React.FC<Props> & { Actions: () => JSX.Element } = ({
  disabled,
  label,
  showHideFilter,
}) => {
  const {
    loading,
    data,
    manageModal,
    createModal,
    showManageModal,
    hideManageModal,
    showCreateModal,
    hideCreateModal,
    selectFilter,
    removeFilter,
    createFilter,
    updateFilter,
  } = useStoreFilter();

  const { filters } = useDashboardFilters();

  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <Dropdown
        menu={{
          items:
            data?.length === 0
              ? [{ key: 'no-filters', label: 'No views saved', disabled: true }]
              : [
                  ...data?.map((d: StoredFilterType) => ({
                    key: d.id,
                    label: (
                      <Tooltip title={d.name}>
                        <div
                          style={{
                            width: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {d.name}
                        </div>
                      </Tooltip>
                    ),
                    icon: <FilterFilled />,
                  })),
                  {
                    key: 'divider',
                    label: <Divider style={{ margin: 0, padding: 0 }} />,
                    disabled: true,
                  },
                  { key: 'manage-filters', label: 'Manage Views', icon: <MenuOutlined /> },
                ],
          onClick: ({ key }) => {
            if (key === 'manage-filters') {
              showManageModal();
              return;
            }

            const found = data?.find(d => d.id === key) as StoredFilterType;
            if (!found) return;

            selectFilter(found);
          },
        }}
        disabled={disabled} //{data === undefined || data.length === 0}
      >
        {/* <Button icon={<DownOutlined />} loading={loading} disabled={loading}>
          Views
        </Button> */}
        <></>
      </Dropdown>

      <Button
        style={{ width: 'auto', display: 'flex', alignItems: 'center' }}
        onClick={showHideFilter}
        loading={loading}
      >
        <div
          style={{
            width: '100px',
            display: 'flex',
            gap: '10px',
          }}
        >
          {!loading && (
            <>
              <FilterOutlined />
              {label}
            </>
          )}
        </div>
      </Button>

      <ManageFiltersModal
        data={data}
        open={manageModal}
        loading={loading}
        onClose={hideManageModal}
        removeFilter={removeFilter}
        updateFilter={updateFilter}
      />

      <CreateFiltersModal
        open={createModal}
        loading={loading}
        onClose={hideCreateModal}
        saveFilter={(n: string) => createFilter({ name: n, config: filters })}
      />
    </div>
  );
};

StoredFilters.Actions = () => {
  return <></>;
  // return (
  //   <Dropdown.Button
  //     style={{ width: 'auto' }}
  //     menu={{
  //       items: [
  //         {
  //           key: '1',
  //           label: 'Save as new View',
  //           onClick: () => createFilter(true),
  //         },
  //       ],
  //     }}
  //     onClick={() => createFilter(!!!selectedFilter)}
  //   >
  //     {selectedFilter ? 'Save changes' : 'Save as new View'}
  //   </Dropdown.Button>
  // );
};

export default StoredFilters;
