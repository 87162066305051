import { createContext } from 'react';
import { AskMeAnythingMessage } from '../types';
import { Questions, SuggestedQuestionsType } from '../questions';

interface IAskMeAnythingContext {
  key: string;
  messages: AskMeAnythingMessage[];
  isAsking: boolean;
  suggestedQuestions: SuggestedQuestionsType;
  suggestedQuestionsDefaultCategory: string;
  suggestedQuestionsDisplayDropdown: boolean;
  addWordToMessage: (index: number, content: string) => unknown;
  askingStarted: () => void;
  askingFinished: () => void;
  clearMessages: () => void;
  removeLastMessage: () => void;
  addMessage: (message: AskMeAnythingMessage) => AskMeAnythingMessage[];
}

export const AskMeAnythingContext = createContext<IAskMeAnythingContext>({
  key: '',
  isAsking: false,
  messages: [],
  addWordToMessage: () => null,
  suggestedQuestions: Questions.recap,
  suggestedQuestionsDefaultCategory: '',
  suggestedQuestionsDisplayDropdown: false,
  askingStarted: () => {
    return;
  },
  askingFinished: () => {
    return;
  },
  clearMessages: () => {
    return;
  },
  addMessage: () => {
    return [];
  },
  removeLastMessage: () => {
    return;
  },
});
