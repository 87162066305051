import { Button, Modal } from 'antd';
import { PropsWithChildren } from 'react';
import style from './style';

interface Props {
  icon: string;
  title: string;
  description?: string;
  show: boolean;
  buttonTitle?: string;
  width?: number;
  showButton?: boolean;
  onCancel: () => void;
}

export const IntegrationModal: React.FC<PropsWithChildren<Props>> = ({
  icon,
  title,
  description,
  children,
  show,
  buttonTitle,
  width,
  showButton = true,
  onCancel,
}) => {
  const { Content, Icon, Header, Description } = style;

  return (
    <Modal
      visible={show}
      wrapClassName="vertical-center-modal"
      footer={null}
      onCancel={onCancel}
      width={width}
    >
      <Content>
        <Header>
          <Icon src={icon} />
          {title}
          {description && <Description>{description}</Description>}
          <div> {children}</div>
        </Header>

        {showButton ? (
          <Button
            type="primary"
            onClick={onCancel}
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              width: '185px',
              padding: '9px 27px',
              alignItems: 'center',
              scale: '1.2 !important',
            }}
          >
            {buttonTitle || 'Done'}
          </Button>
        ) : (
          <></>
        )}
      </Content>
    </Modal>
  );
};
