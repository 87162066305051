import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 300px);
  scroll-behavior: smooth;
`;

export default { Container };
