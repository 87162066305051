import React, { PropsWithChildren, ReactNode } from 'react';
import Styles from './styles';

const { Container, Heading, Header, Contents } = Styles;
interface Props {
  title?: string;
  extraHeader?: ReactNode;
  style?: React.CSSProperties;
}

export const Card: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  extraHeader,
  style = {},
}) => {
  return (
    <Container style={style}>
      {title && (
        <Heading>
          <Header>{title}</Header>
          {extraHeader}
        </Heading>
      )}
      <Contents>{children}</Contents>
    </Container>
  );
};
