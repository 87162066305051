import styled from 'styled-components';

const AccountsSelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 8px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const ClearLabel = styled.span<{ disabled: boolean }>`
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
  font-size: 14px;
  line-height: 21px;
  color: ${props => (props.disabled ? '#cdd3db' : '#FF6C54')};
  transition: color 0.2s;
`;

export default {
  AccountsSelectedContainer,
  Contents,
  ClearLabel,
};
