import React from 'react';
import { LayoutStyled } from './styles';

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  minusHeight?: number;
  sider?: boolean;
}

export const Layout: React.FC<Props> = ({ children, minusHeight = 48, sider = true }) => {
  return (
    <LayoutStyled style={{ overflow: 'auto' }} minusHeight={minusHeight} sider={sider}>
      {children}
    </LayoutStyled>
  );
};
