export const roleOptions = [
  {
    id: 'OWNER',
    name: 'Owner',
    description:
      'Admin + Can delete the workspace or transfer its ownership and manage other admins.',
  },
  {
    id: 'ADMIN',
    name: 'Admin',
    description: 'Member + Can invite people to the workspace and manage other members',
  },
  {
    id: 'MEMBER',
    name: 'Member',
    description: 'Can see workspace-wide content',
  },
];

export const getRoleName = (roleId: string) => {
  return roleOptions.find(role => role.id === roleId)?.name;
};
