import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 476px;
  height: 476px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--Cool-Gray-500, #0d283b);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  gap: 15px;
`;

const Icon = styled.img`
  height: 52px;
  width: 52px;
  align-self: flex-start;
  object-fit: contain;
`;

const Description = styled.div`
  color: var(--Cool-Gray-400, #566976);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: -12px;
`;

export default { Content, Icon, Header, Description };
