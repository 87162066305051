import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  padding: var(--spacing-lg, 12px) var(--spacing-lg, 12px) var(--spacing-lg, 12px)
    var(--spacing-md, 8px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  flex: 1 0 0;
  border-radius: 6px;
  background: ${colors.coolGray[100]};
`;

const Name = styled.span`
  color: var(--cool-gray-700, #0a1e2c);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  width: 144px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  cursor: pointer;
`;

const Count = styled.span`
  color: var(--cool-gray-500, #0d283b);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const ExtraContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExtraDataContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
`;

const MenuTitle = styled.span`
  color: var(--cool-gray-300, #9ea9b1);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 9px 10px;
  align-items: center;
  gap: var(--spacing-lg, 12px);
  flex: 1 0 0;
  border-radius: 6px;

  & #menu-item:not(:last-child) {
    border-bottom: 1px solid ${colors.coolGray[100]};
  }
`;

const ItemLabel = styled.span`
  color: var(--cool-gray-700, #0a1e2c);

  /* Text sm/Medium */

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
export default {
  Container,
  Name,
  Count,
  ExtraDataContainer,
  MenuTitle,
  ItemContainer,
  ItemLabel,
  ExtraContainer,
};
