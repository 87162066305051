import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { videoPlayerContext } from './VideoPlayerContext';
import { useMarkers } from '../../hooks/useMarkers';
import { Crop, CropCallback } from '../../types';
import { useVideo } from '../../hooks/useVideo';
import { useVideoTimeline } from '../../hooks/useVideoTimeline';

export const VideoPlayerProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [isScrollingEnabled, setIsScrollingEnabled] = useState(false);
  const [containerRects, setContainerRects] = useState({ height: 0 });
  const [fullscreen, toggleFullscreen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const cropCallback = useRef<null | CropCallback>(null);
  const [crop, setCrop] = useState<Crop | null>(null);

  const {
    changeVideoCurrentTime,
    isPaused,
    isPlaying,
    setVideoReference,
    videoCurrentTime,
    pause: pauseVideo,
    changeVolume,
    changePlaybackRate,
    play: playVideo,
    mute,
    unmute,
    isMuted,
    status,
    playbackRate,
    volume,
    videoRef,
    ready,
    duration,
  } = useVideo();

  const { markers } = useMarkers(crop, duration);
  const {
    duration: maskedDuration,
    userTimeToVideoTime,
    videoTimeToUserTime,
  } = useVideoTimeline(isEditable ? null : crop, duration);

  /**
   * If for some reason the user manages to scroll past the cropping
   * we pause the video and reset the time to 0.
   */
  useEffect(() => {
    const hasExceededMask = videoTimeToUserTime(videoCurrentTime) >= maskedDuration;
    if (hasExceededMask) {
      pauseVideo();
      changeVideoCurrentTime(userTimeToVideoTime(0));
    }
  }, [videoCurrentTime, maskedDuration]);

  const togglePlay = () => {
    if (isPlaying) pauseVideo();
    else playVideo();
  };

  const changeCurrentTimeInternal = (value: number) => {
    if (videoRef.current) {
      const videoTime = userTimeToVideoTime(value);
      changeVideoCurrentTime(videoTime);
    }
  };

  useEffect(() => {
    const updateRects = () => {
      if (containerRef) {
        setContainerRects({ height: containerRef.getBoundingClientRect().height });
      }
    };
    if (containerRef) {
      updateRects();
      window.addEventListener('resize', () => {
        updateRects();
      });
      containerRef.addEventListener('resize', () => {
        updateRects();
      });
    }

    return () => {
      window.removeEventListener('resize', updateRects);
      if (containerRef) {
        containerRef.removeEventListener('resize', updateRects);
      }
    };
  }, [containerRef]);

  const currentTime = useMemo(() => {
    return videoTimeToUserTime(videoCurrentTime);
  }, [videoCurrentTime]);

  const skip = (seconds: number) => {
    changeCurrentTimeInternal(currentTime + seconds);
  };

  const seek = (time: number) => {
    changeCurrentTimeInternal(time);
  };

  const play = (time?: number) => {
    playVideo();
    if (time) {
      changeCurrentTimeInternal(time);
    } else if (currentTime === 0) {
      /**
       * This check is to make sure to apply
       * the offset from the cropping
       * when the video is played from the beginning.
       */
      changeCurrentTimeInternal(0);
    }
    setIsScrollingEnabled(true);
  };

  const setCropping = (args: Crop) => {
    setCrop(args);
  };

  const changeCropping = (args: Crop) => {
    setCrop(args);
    if (cropCallback.current) {
      cropCallback.current({
        end: Math.round(args.end),
        start: Math.round(args.start),
      });
    }
  };

  const download = () => {
    if (videoRef.current) {
      window.open(videoRef.current.src, '_blank');
    }
  };

  const setCropCallback = (callback: CropCallback) => {
    cropCallback.current = callback;
  };

  return (
    <videoPlayerContext.Provider
      value={{
        changeVolume,
        currentTime,
        duration,
        maskedDuration,
        isPaused,
        isPlaying,
        mute,
        playbackRate,
        volume,
        changePlaybackRate,
        pause: pauseVideo,
        seek,
        play,
        isMuted,
        setVideoPlayerRef: setVideoReference,
        setContainerRef,
        skip,
        status,
        unmute,
        isScrollingEnabled,
        changeScrollEnabled: setIsScrollingEnabled,
        containerRects,
        cropping: crop,
        setCropping,
        fullscreen,
        ready,
        toggleFullscreen,
        download,
        isEditable,
        setIsEditable,
        markers,
        setCropCallback,
        changeCropping,
        togglePlay,
      }}
    >
      {children}
    </videoPlayerContext.Provider>
  );
};
