import React, { PropsWithChildren, useEffect } from 'react';
import { OnboardingStatus } from '../../../../../types/commonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnboardingStatus, selectUserMembership } from '../../../../../redux/selectors';
import { setCurrentStep } from '../../../../../redux/slices/onboardingSlice';
import { CardHeader } from '../../CardHeader';

interface Props {
  title: string;
  subtitle: string;
}

const JoinScreen: React.FC<PropsWithChildren<Props>> = ({ title, subtitle, children }) => {
  const membership = useSelector(selectUserMembership);
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (membership?.organization.id && onboardingStatus === OnboardingStatus.SelectOrganization)
      dispatch(setCurrentStep());
  }, [membership, onboardingStatus]);

  return (
    <>
      <CardHeader title={title} subtitle={subtitle} />
      {children}
    </>
  );
};

export default JoinScreen;
