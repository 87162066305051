import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ImportantNumbersSVG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.98065 13.0136L2.2666 17.7276M9.74539 5.53483L8.44465 6.83556C8.33854 6.94167 8.28549 6.99472 8.22505 7.03687C8.1714 7.07429 8.11353 7.10526 8.05264 7.12915C7.98404 7.15605 7.91047 7.17077 7.76333 7.2002L4.70961 7.81094C3.91602 7.96966 3.51923 8.04902 3.33359 8.25823C3.17187 8.44049 3.09802 8.68439 3.13148 8.92575C3.16989 9.20279 3.45602 9.48893 4.02829 10.0612L9.93307 15.966C10.5053 16.5382 10.7915 16.8244 11.0685 16.8628C11.3099 16.8962 11.5538 16.8224 11.736 16.6607C11.9452 16.475 12.0246 16.0782 12.1833 15.2846L12.7941 12.2309C12.8235 12.0838 12.8382 12.0102 12.8651 11.9416C12.889 11.8807 12.92 11.8229 12.9574 11.7692C12.9995 11.7088 13.0526 11.6557 13.1587 11.5496L14.4594 10.2489C14.5273 10.181 14.5612 10.1471 14.5985 10.1175C14.6316 10.0912 14.6667 10.0674 14.7034 10.0465C14.7447 10.0228 14.7888 10.0039 14.877 9.96615L16.9556 9.07531C17.5621 8.81542 17.8653 8.68547 18.003 8.47549C18.1234 8.29185 18.1665 8.0681 18.1229 7.85288C18.073 7.60676 17.8398 7.3735 17.3732 6.90697L13.0873 2.62103C12.6208 2.15451 12.3875 1.92124 12.1414 1.87135C11.9262 1.82772 11.7024 1.87081 11.5188 1.99126C11.3088 2.12899 11.1788 2.43219 10.9189 3.03861L10.0281 5.11724C9.99031 5.20542 9.97142 5.24951 9.9478 5.29086C9.92682 5.32758 9.90307 5.36266 9.87676 5.39578C9.84714 5.43307 9.81322 5.46699 9.74539 5.53483Z"
        stroke="#16B380"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ImportantNumbers: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon data-testid="important-number-icon" component={ImportantNumbersSVG} {...props} />;
};
