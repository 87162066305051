import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const IconSVG = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6.16668C13.0333 6.16668 12.4933 7.12668 12.7133 7.84001L10.3466 10.2133C10.1466 10.1533 9.85329 10.1533 9.65329 10.2133L7.95329 8.51334C8.17996 7.80001 7.63996 6.83334 6.66663 6.83334C5.69996 6.83334 5.15329 7.79334 5.37996 8.51334L2.33996 11.5467C1.62663 11.3267 0.666626 11.8667 0.666626 12.8333C0.666626 13.5667 1.26663 14.1667 1.99996 14.1667C2.96663 14.1667 3.50663 13.2067 3.28663 12.4933L6.31996 9.45334C6.51996 9.51334 6.81329 9.51334 7.01329 9.45334L8.71329 11.1533C8.48663 11.8667 9.02663 12.8333 9.99996 12.8333C10.9666 12.8333 11.5133 11.8733 11.2866 11.1533L13.66 8.78668C14.3733 9.00668 15.3333 8.46668 15.3333 7.50001C15.3333 6.76668 14.7333 6.16668 14 6.16668Z"
        fill="grey"
      />
      <path
        d="M9.99996 6.83334L10.6266 5.45334L12 4.83334L10.6266 4.21334L9.99996 2.83334L9.38663 4.21334L7.99996 4.83334L9.38663 5.45334L9.99996 6.83334Z"
        fill="grey"
      />
      <path
        d="M2.33329 8.16668L2.66663 6.83334L3.99996 6.50001L2.66663 6.16668L2.33329 4.83334L1.99996 6.16668L0.666626 6.50001L1.99996 6.83334L2.33329 8.16668Z"
        fill="grey"
      />
    </svg>
  );
};

export const InsightExcluded: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={IconSVG} {...props} onClick={props.onClick} />;
};
