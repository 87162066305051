import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 240px);
`;

export default {
  Container,
  Contents,
};
