import React from 'react';
import { Error } from './components/icons/Error';
import { Info } from './components/icons/Info';
import { Neutral } from './components/icons/Neutral';
import { Standout } from './components/icons/Standout';
import { Success } from './components/icons/Success';
import { Warning } from './components/icons/Warning';
import { AlertVariantsConfiguration } from './types';

const tokens: AlertVariantsConfiguration = {
  standout: {
    color: '#7C4DFF',
    highlightColor: '#5E16EB',
    backgroundColor: '#F3F2FF',
    borderColor: '#BCAEFF',
    icon: <Standout />,
  },
  error: {
    backgroundColor: '#FEF2F2',
    color: '#E6614C',
    highlightColor: '#B71E1E',
    borderColor: '#FBA6A6',
    icon: <Error />,
  },
  info: {
    backgroundColor: '#EDF9FF',
    color: '#0675FC',
    highlightColor: '#0A58C3',
    borderColor: '#84DBFF',
    icon: <Info />,
  },
  neutral: {
    backgroundColor: '#FFFFFF',
    color: '#6A6A6A',
    highlightColor: '#282828',
    borderColor: '#D1D1D1',
    icon: <Neutral />,
  },
  success: {
    backgroundColor: '#EDFCF5',
    color: '#16B380',
    highlightColor: '#087456',
    borderColor: '#72E3B7',
    icon: <Success />,
  },
  warning: {
    backgroundColor: '#FFFCEB',
    color: '#DD8002',
    highlightColor: '#7A390D',
    borderColor: '#FFDC4A',
    icon: <Warning />,
  },
};

export { tokens };
