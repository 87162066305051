import { queryStringToString } from '../../utils/query-string';
import { storeToInsightArgs } from '../insights/context/CustomerIntelligenceProvider/utils';

export const cleanupSSEMetadata = (sseMessage: string) => {
  const lines = sseMessage.split('\n').filter(line => line.trim() !== '');
  const words = lines.map(line => line.replace(/^data: /, ''));
  return words;
};

type EmitterFn = (word: string) => unknown;

/**
 * Sometimes the messages in SSE come one after the other very fast.
 * This causes that it's impossible to read because
 * a lot of words appear at the same time.
 * I created this class that acts as a "buffer" and emits 1 word every 100ms.
 */
export class WordEmitter {
  private words: string[] = [];
  private emitterFn: EmitterFn;
  private isDone = false;
  private intervalTimer: ReturnType<typeof setInterval> | null = null;

  constructor(onEmit: EmitterFn) {
    this.emitterFn = onEmit;
    this.intervalTimer = setInterval(() => {
      if (this.isDone && !this.words.length) {
        if (this.intervalTimer) {
          clearInterval(this.intervalTimer);
        }
      } else {
        const newWord = this.words.shift();
        if (newWord) {
          this.emitterFn(newWord);
        }
      }
    }, 100);
  }

  addWord(word: string) {
    this.words.push(word);
  }

  finish() {
    this.isDone = true;
  }
}

export const createQueryStringFromFilterValues = () => {
  const filterValues = storeToInsightArgs();
  const searchParams = new URLSearchParams();

  if (filterValues) {
    Object.entries(filterValues).forEach(([key, value]) => {
      if (typeof value !== 'undefined') {
        searchParams.append(key, value);
      }
    });
  }

  const queryString = queryStringToString(searchParams);
  return queryString;
};
