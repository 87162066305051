import React from 'react';
import Styles from './styles';
import { format, isToday, isYesterday } from 'date-fns';

const { Container } = Styles;

interface Props {
  date: string;
}

export const DateLabel: React.FC<Props> = ({ date }) => {
  const dataObject = new Date(date);

  return (
    <Container>
      {isToday(dataObject)
        ? 'TODAY'
        : isYesterday(dataObject)
        ? 'YESTERDAY'
        : format(dataObject, 'EEEE, MMM dd')}
    </Container>
  );
};
