import styled from 'styled-components';

const Container = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  padding-left: 12px;
  gap: 5px;
  color: #6a6a6a;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-left: 1px solid ${props => props.color || 'black'};

  h1 {
    color: #282828;
    font-family: 'Cera Pro';
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    margin: 0;
    padding: 0;
  }
`;

const Title = styled.div<{ isReady: boolean }>`
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ isReady }) => (isReady ? 'pointer' : 'auto')};
  :hover {
    text-decoration: ${({ isReady }) => (isReady ? 'underline' : 'none')};
  }
`;

export default { Container, Title };
