import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { VideoPlayer } from '../../../video/components/VideoPlayer';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { useRecapPlayback } from '../../../recapv2/recap-playback/hook';

export const Video: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { playback, getMessageAtTimestamp, getMessageById, insights } = useRecapManagement();
  const { changeMessagePlaying, ready, play, currentTime, duration } = useRecapPlayback();
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    const auto = searchParams.get('autoplay');
    const messageId = searchParams.get('messageId');
    const insightId = searchParams.get('insightId');

    if (played) {
      return;
    }

    if (!ready) {
      return;
    }

    if (auto) {
      play();
      setPlayed(true);
      return;
    }

    if (messageId) {
      const message = getMessageById(messageId);
      if (message) {
        const startTime = Number(message.startTime);
        changeMessagePlaying(messageId);
        play(startTime);
        setPlayed(true);
      }
      return;
    }

    if (insightId) {
      const insight = insights.find(insight => insight.id === insightId);
      if (insight) {
        const messageId = insight.chunks[0].messageId;
        const message = getMessageById(messageId);
        if (message) {
          const startTime = Number(message.startTime);
          changeMessagePlaying(messageId);
          play(startTime);
          setPlayed(true);
        }
      }
      return;
    }
  }, [searchParams, duration, ready, insights, played]);

  const scrollToRow = (id: string) => {
    changeMessagePlaying(id);
  };

  useEffect(() => {
    const findMessageBeingPlayed = () => {
      if (currentTime === 0) {
        return null;
      }

      const messagePlaying = getMessageAtTimestamp(currentTime);
      if (messagePlaying) {
        return messagePlaying;
      } else {
        return null;
      }
    };

    const messageResult = findMessageBeingPlayed();

    if (messageResult) {
      scrollToRow(messageResult.id);
      return;
    } else {
      /*
        We do this so the current selected
        message is unselected.
      */
      scrollToRow('');
    }
  }, [currentTime]);

  return <VideoPlayer src={playback ? playback.url : ''} />;
};
