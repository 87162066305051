import styled from 'styled-components';
import { colors } from '../../theme/colors';

const Container = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: ${({ isLoading }) =>
    !isLoading ? "url('https://assets.update.ai/recap_blur.png')" : 'none'};
  width: 100vw;
  height: 100vh;
`;

const Header = styled.div`
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: auto;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Label = styled.div`
  color: ${colors.black};
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
  margin-top: 25px;
`;

const Footer = styled.div`
  color: var(--New-Color-Shades-Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
`;

export default { Container, Content, Title, Label, Form, Footer, Header };
