import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  padding-top: 16px;
`;

const ResultCount = styled.span`
  color: #a6a6a6;
  font-size: 14px;
  line-height: 20px;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default { Container, ResultCount, ResultContainer, SpinnerContainer };
