import { useEffect, useState } from 'react';
import { integrationBroadcast } from '../../../App';
import { useSearchParams } from 'react-router-dom';

interface IntegrationResult {
  errorMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  source: string;
}

export const useIntegrationResult = () => {
  const [queryParams] = useSearchParams();
  const source = queryParams.get('source');
  const errorMessage = queryParams.get('message');
  const data = queryParams.get('data');
  const [integrationResult, setIntegrationResult] = useState<null | IntegrationResult>(null);

  useEffect(() => {
    if (source) {
      integrationBroadcast.postMessage({
        source,
        errorMessage,
        data,
      });

      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  }, []);

  useEffect(() => {
    new BroadcastChannel('integrations').onmessage = event => {
      const { source, errorMessage, data } = event.data;
      setIntegrationResult({ source, errorMessage, data });
    };
  }, []);

  return integrationResult;
};
