import { useContext } from 'react';
import { accountSearchContext } from './account-search.context';
import { useStore } from 'zustand';

export const useAccountSearch = () => {
  const { store } = useContext(accountSearchContext);

  const accounts = useStore(store, store => store.accounts);
  const isFetching = useStore(store, store => store.isFetching);

  return {
    accounts,
    isFetching,
  };
};
