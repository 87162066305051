import React from 'react';
import { AnimatedText, Container, Text } from './style';

export const Staging: React.FC = () => {
  return (
    <Container>
      <Text>Staging Environment</Text>
      <AnimatedText>Staging Environment</AnimatedText>
    </Container>
  );
};
