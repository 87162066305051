import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { filterElements, findElement } from '../../../../../utils/react';
import { FilterButton } from '../../FilterButton';
import { Dropdown } from 'antd';

interface Props {
  children: ReactNode[];
  count: number;
}

export const FilterPanel: React.FC<Props> = props => {
  const [currentPanelId, setCurrentPanelId] = useState('default');

  const { defaultPanel, panels } = useMemo(() => {
    const childrens = React.Children.toArray(props.children) as ReactElement[];

    let defaultPanel: ReactElement | null = findElement(childrens, 'DefaultPanel');

    if (defaultPanel) {
      defaultPanel = React.cloneElement(defaultPanel, {
        ...defaultPanel.props,
        onPanelChange: (id: string) => setCurrentPanelId(id),
      });
    }

    const panels: ReactElement[] = filterElements(childrens, 'Panel').map(children =>
      React.cloneElement(children, {
        ...children.props,
        onBack: () => setCurrentPanelId('default'),
      }),
    );

    return { defaultPanel, panels };
  }, [props.children]);

  if (!defaultPanel) {
    throw new Error('No default panel defined. Add <DefaultPanel/>');
  }

  const panelToDisplay = useMemo(() => {
    if (currentPanelId === 'default') {
      return defaultPanel;
    }

    const currentPanel = panels.find(panel => {
      return panel.props.panelId === currentPanelId;
    });

    if (currentPanel) {
      return currentPanel;
    }

    return null;
  }, [currentPanelId, defaultPanel, panels]);

  return (
    <Dropdown trigger={['click']} dropdownRender={() => panelToDisplay}>
      <FilterButton count={props.count} label="Filters" />
    </Dropdown>
  );
};
