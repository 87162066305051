import { useEffect, useState } from 'react';
import { coreService } from '../../../services/core/core-service';
import { useIntegrationResult } from './useIntegrationResult';
import { useToast } from '../../../hooks/useToast';
import { Store } from '../../../redux/typings/store';
import { useDispatch, useSelector } from 'react-redux';
import { extractError } from '../../../utils/api';
import { fetchUser } from '../../../redux/slices/sessionSlice';

const sourceToType: { [k: string]: string } = {
  ZOOM: 'zoom',
  NYLAS: 'calendar',
  SALESFORCE: 'salesforce',
  HUBSPOT: 'hubspot',
  SLACK: 'slack',
  ZAPIER: 'zapier',
};

export type IDSelectionFunction = (store: Store) => string | null | undefined;

export const useIntegrationRequest = (args: {
  source: string;
  id: IDSelectionFunction | string | null | undefined;
  refetch?: () => void;
}) => {
  const { source, id: idSelFunction, refetch } = args;

  const [isIntegrating, setIsIntegrating] = useState(false);
  const dispatch = useDispatch();

  const [isRemoving, setIsRemoving] = useState(false);

  const idOfFunction = useSelector(
    typeof idSelFunction === 'function' ? idSelFunction : () => null,
  );
  const idOfParam = typeof idSelFunction !== 'function' ? idSelFunction : null;
  const integrationId = idOfFunction || idOfParam;
  const hasIntegration = Boolean(integrationId);

  const [errorState, setErrorState] = useState<{ error: boolean; errorMessage: null | string }>({
    error: false,
    errorMessage: null,
  });
  const { error: errorToast } = useToast();
  const result = useIntegrationResult();
  const type = sourceToType[source];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestIntegration = async (params?: any) => {
    try {
      setIsIntegrating(true);
      const url = await coreService.requestIntegrationUrl(type, params);
      const popup = window.open(
        url,
        'popup_window',
        'height=800px,width=500px,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no',
      );
      if (popup) popup.moveTo(window.screen.width / 2 - 250, window.screen.height / 2 - 400);
    } catch (errorMessage) {
      if (!(errorMessage instanceof DOMException)) {
        errorToast('Error retrieving integration URL');
        setIsIntegrating(false);
      }
    }
  };

  useEffect(() => {
    if (result && result.source === source && isIntegrating) {
      const error = result.errorMessage && result.errorMessage !== 'undefined' ? true : false;
      const errorMessage = error ? result.errorMessage : null;
      if (errorMessage) {
        errorToast(errorMessage);
      }

      setErrorState({ error, errorMessage });
      if (refetch) refetch();
      setIsIntegrating(false);
    }
  }, [result, isIntegrating]);

  const removeInternalIntegration = async (fction: (id: string) => Promise<void>) => {
    try {
      if (integrationId) {
        setIsRemoving(true);
        await fction(integrationId);
        dispatch(fetchUser());
      }
    } catch (error) {
      const message = extractError(error);
      errorToast(message);
    } finally {
      if (refetch) refetch();
      setIsRemoving(false);
    }
  };

  const removeIntegration = async () => {
    await removeInternalIntegration((id: string) => coreService.removeIntegration(id));
  };

  const removeWorkspaceIntegration = async () => {
    await removeInternalIntegration((id: string) => coreService.removeWorkspaceIntegration(id));
  };

  const { error, errorMessage } = errorState;

  return {
    isRemoving,
    isIntegrating,
    hasIntegration,
    error,
    errorMessage,
    removeIntegration,
    requestIntegration,
    removeWorkspaceIntegration,
  };
};
