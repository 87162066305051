import React, { PropsWithChildren, ReactNode, useState } from 'react';
import {
  Container,
  CustomBodyCellComponent,
  CustomBodyRowComponent,
  CustomHeaderCellComponent,
  CustomHeaderComponent,
  Message,
  NoDataContainer,
  NoDataLogo,
  Shade,
  Title,
} from './style';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import EmailCell from './Components/Email';
import DateCell from './Components/Date';
import ActionCell from './Components/Action';
import { useToast } from '../../../../../../hooks/useToast';
import IconSvg from '../../../../../../components/common/IconSvg';
import { OrganizationInvitation } from '../../../../../../redux/typings/session';
import { useOrganizationInvites } from '../../../../../../features/organizations/hooks/useOrganizationInvites';

export interface InvitationDataType {
  email: string;
  date: string;
}

interface Props {
  disableUserInteractions?: boolean;
}

const InvitationsTable: React.FC<Props> = ({ disableUserInteractions }) => {
  const { invitations, revokeInvitation, resendInvitation } = useOrganizationInvites();
  const [loadingResend, setLoadingResend] = useState<string>('');
  const [loadingRevoke, setLoadingRevoke] = useState<string>('');
  const { success, error } = useToast();

  const handleRevokeInvitation = async (invitationId: string) => {
    setLoadingRevoke(invitationId);
    try {
      await revokeInvitation(invitationId);
      success('Invitation revoked successfully');
    } catch (e) {
      error((e as Error).message);
    }
    setLoadingRevoke('');
  };

  const handleResendInvitation = async (invitationId: string) => {
    setLoadingResend(invitationId);
    try {
      await resendInvitation(invitationId);
      success('Invitation resent successfully');
    } catch (e) {
      error((e as Error).message);
    }
    setLoadingResend('');
  };

  const RenderEmail = (email: string) => {
    return <EmailCell email={email} />;
  };

  const RenderDateInvited = (date: string) => {
    return <DateCell date={date} />;
  };

  const RenderAction = (invitation: OrganizationInvitation) => {
    return (
      <ActionCell
        onRevoke={() => handleRevokeInvitation(invitation?.id)}
        onResend={() => handleResendInvitation(invitation?.id)}
        loadingResend={loadingResend === invitation?.id}
        loadingRevoke={loadingRevoke === invitation?.id}
        disableUserInteractions={disableUserInteractions}
      />
    );
  };

  const columns: ColumnsType<InvitationDataType> = [
    {
      title: <div style={{ paddingLeft: '24px' }}>Name</div>,
      dataIndex: 'email',
      key: 'email',
      render: RenderEmail,
      width: '50%',
    },
    {
      title: 'Date Invited',
      dataIndex: 'date',
      key: 'date',
      render: RenderDateInvited,
      width: '20%',
    },
    {
      title: '',
      key: 'id',
      render: RenderAction,
      width: '30%',
    },
  ];

  const CustomHeader = (props: PropsWithChildren<ReactNode>) => {
    return <CustomHeaderComponent>{props.children}</CustomHeaderComponent>;
  };

  const CustomHeaderCell = (props: PropsWithChildren<ReactNode>) => {
    return (
      <CustomHeaderCellComponent>
        <div style={{ color: '#6A6A6A' }}>{props.children}</div>
      </CustomHeaderCellComponent>
    );
  };

  const CustomBodyRow = (props: PropsWithChildren<ReactNode>) => {
    return (
      <CustomBodyRowComponent key={props.children?.toString()}>
        {props.children}
      </CustomBodyRowComponent>
    );
  };

  const CustomBodyCell = (props: PropsWithChildren<ReactNode>) => {
    return <CustomBodyCellComponent>{props.children}</CustomBodyCellComponent>;
  };

  const components = {
    header: {
      row: CustomHeader,
      cell: CustomHeaderCell,
    },
    body: {
      row: CustomBodyRow,
      cell: CustomBodyCell,
      rowKey: 'email',
    },
  };

  return (
    <Container>
      {invitations.length > 0 ? (
        <Table
          columns={columns}
          pagination={false}
          dataSource={invitations}
          components={components}
          rowKey={'id'}
        />
      ) : (
        <NoDataContainer>
          <NoDataLogo>
            <Shade>
              <IconSvg name="users" size="mdl" color="transparent" />
            </Shade>
          </NoDataLogo>
          <Message>
            <Title>There are no invitations pending</Title>
          </Message>
        </NoDataContainer>
      )}
    </Container>
  );
};

export default InvitationsTable;
