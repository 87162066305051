import React, { useEffect } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { format } from 'date-fns';
import { Button, Checkbox, Spin, Tooltip } from 'antd';
import { useToast } from '../../../../../../hooks/useToast';
import { colors } from '../../../../../../theme/colors';
import Styles from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImportModal from '../ImportModal';
import RecapStatus from '../../../../../../features/home/tabs/RecapsTab/components/RecapStatus';

const { Container } = Styles;

interface CallsProps {
  hideModal: () => void;
  showModal: () => void;
}

interface CallProps {
  title: string;
  scheduled?: string;
  duration?: number;
  status?: string;
  subStatus?: string;
  recapId?: string;
  importedBy?: string;
}

export const Call: React.FC<CallProps> = ({
  title,
  scheduled,
  duration,
  status,
  subStatus,
  recapId,
  importedBy,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        lineHeight: 1.57,
        marginRight: '10px',
        width: '650px',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {status === 'READY' && recapId ? (
          <a
            style={{ fontSize: '14px', color: colors.primary[500] }}
            href={`/recap/${recapId}`}
            target="_blank"
          >
            {title}
          </a>
        ) : (
          <div style={{ fontSize: '14px' }}>{title}</div>
        )}

        {!['IMPORTING', ''].includes(status!.toUpperCase()) ? (
          <RecapStatus status={status!} subStatus={subStatus || ''} />
        ) : (
          <div style={{ fontSize: '12px' }}>{status}</div>
        )}
      </div>

      <div style={{ fontSize: '11px', display: 'flex', gap: '5px', alignItems: 'center' }}>
        <div>{format(new Date(scheduled || ''), 'MMM dd, yyyy hh:mm')}</div>
        <div>-</div>
        <div>{Math.floor((duration || 0) % 60)} minutes</div>
        {importedBy && <div>-</div>}
        {importedBy && (
          <Tooltip
            overlayInnerStyle={{ width: '400px', whiteSpace: 'pre-wrap' }}
            title={`If you don't have access to this meeting and you didn't import it, two things might be happening:\n1. The meeting might be set to "invitees only." Ask the person who imported it to change the visibility to "workspace".\n2. You might not be on the invitees list.`}
          >
            <div style={{ textDecoration: 'underline' }}>Imported by: {importedBy}</div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const Calls: React.FC<CallsProps> = ({ hideModal, showModal }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = React.useState<{ cursor?: string; calls: any[] }>({ calls: [] });
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [importing, setImporting] = React.useState(false);

  const hasMore = data.cursor !== null;
  const importLabel = `Import ${selected.length} ${selected.length === 1 ? 'call' : 'calls'}`;

  const { success, error } = useToast();

  const loadCalls = async () => {
    coreService.getGongMeetings(data?.cursor).then(res => {
      const newCalls = res?.calls || [];
      const newCallsIds = newCalls.map((c: { id: string }) => c.id);

      const sortedData = [
        ...[...data.calls].filter(c => !newCallsIds.includes(c.id)),
        ...newCalls,
      ].sort((a, b) => new Date(a.scheduled).getTime() - new Date(b.scheduled).getTime());

      setData({
        cursor: res?.records?.cursor || null,
        calls: sortedData,
      });
    });
  };

  const importCalls = async () => {
    setImporting(true);
    coreService
      .importGongCalls(selected)
      .then(() => {
        setData({
          cursor: data.cursor,
          calls: data.calls.map(c => {
            if (selected.includes(c.id)) {
              return { ...c, status: 'Importing' };
            }
            return c;
          }),
        });
        setSelected([]);
        success('Calls imported successfully');
      })
      .catch(() => {
        error('Failed to import calls');
      })
      .finally(() => setImporting(false));
  };

  useEffect(() => {
    loadCalls();
  }, []);

  return (
    <Container>
      <InfiniteScroll
        height={320}
        dataLength={data?.calls?.length || 0}
        next={() => loadCalls()}
        hasMore={hasMore}
        loader={
          <div
            style={{
              display: 'flex',
              width: '690px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        }
        scrollThreshold={0.5}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          {data?.calls?.map((call: any) =>
            !call?.status ? (
              <Checkbox
                key={call.id}
                disabled={importing}
                onChange={c =>
                  c.target.checked
                    ? setSelected([...selected, call.id])
                    : setSelected(selected.filter(s => s !== call.id))
                }
                checked={selected.includes(call.id)}
              >
                <Call
                  key={call.id + '-call'}
                  title={call?.title}
                  scheduled={call?.scheduled}
                  duration={call?.duration}
                  status={call?.status || ''}
                  importedBy={call?.importedBy}
                />
              </Checkbox>
            ) : (
              <Call
                key={call.id + '-call'}
                title={call?.title}
                scheduled={call?.scheduled}
                duration={call?.duration}
                status={call?.status}
                subStatus={call?.subStatus || ''}
                recapId={call?.recapId}
                importedBy={call?.importedBy}
              />
            ),
          )}
        </div>
      </InfiniteScroll>

      <Button
        type="default"
        disabled={selected.length === 0 || importing}
        style={{ width: '200px' }}
        loading={importing}
        onClick={() => {
          hideModal();
          setShowImportModal(true);
        }}
      >
        {importLabel}
      </Button>

      <ImportModal
        shouldShow={showImportModal}
        loading={importing}
        closeModal={() => {
          setShowImportModal(false);
          showModal();
        }}
        confirmButtonText={importLabel}
        onConfirm={() => {
          importCalls();
          setShowImportModal(false);
          showModal();
        }}
      />
    </Container>
  );
};
