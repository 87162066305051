import { AiItem } from '../features/account/account-favourites/account-favourites-types';
import { Message } from '../redux/typings/recap';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Item {
  id: string;
  text: string;
  chunks: {
    id: string;
    from: number;
    to: number;
    messageId: string;
  }[];
}

interface Point {
  position: number;
  chunkIds: string[];
  itemIds: string[];
}

interface MessageMapped {
  id: string;
  text: string;
  chunks: { from: number; to: number; id: string; itemId: string }[];
}

const isChunkBetweenInterval = (position: number, chunk: { from: number; to: number }) => {
  return position >= chunk.from && position <= chunk.to;
};

const createMessagesMap = (recap: any) => {
  const mapped = new Map<string, MessageMapped>();

  /**
   * We add the message to the map.
   * The first chunk is the whole text.
   */
  recap.messages.forEach((message: any) => {
    mapped.set(message.id, {
      ...message,
      chunks: [
        {
          from: 0,
          to: message.content.length,
          id: message.id,
          itemId: message.id,
        },
      ],
    });
  });

  return mapped;
};

const createChunks = (msg: MessageMapped) => {
  const positionPoints: Point[] = [];
  const points = new Set<number>();

  /**
   * For each chunk, we add a point in the rect.
   */
  msg.chunks.forEach(ch => {
    points.add(ch.from);
    points.add(ch.to);
  });

  const pointsArray = Array.from(points.values());

  /**
   * For each point, we check which chunks are involved in it.
   * We store their ids and itemIds.
   */
  pointsArray.forEach(p => {
    const matchingChunks = msg.chunks.filter(chunk => isChunkBetweenInterval(p, chunk));
    positionPoints.push({
      position: p,
      chunkIds: matchingChunks.map(mc => mc.id),
      itemIds: matchingChunks.map(mc => mc.itemId),
    });
  });

  const sortedPoints = positionPoints.sort((a, b) => a.position - b.position);

  const generatedChunks: any[] = [];

  for (let i = 0; i < sortedPoints.length - 1; i++) {
    const curPoint = sortedPoints[i];
    const nextPoint = sortedPoints[i + 1];
    /**
     * If the current point and nextpoint share Ids, then those ids
     * are involved in that segment.
     */
    const matchingChunkIds = curPoint.chunkIds.filter(cc =>
      Boolean(nextPoint.chunkIds.find(p => p === cc)),
    );
    const matchingItemIds = curPoint.itemIds.filter(cc =>
      Boolean(nextPoint.itemIds.find(p => p === cc)),
    );

    generatedChunks.push({
      from: curPoint.position,
      to: nextPoint.position,
      itemIds: matchingItemIds,
      chunkIds: matchingChunkIds,
    });
  }

  return generatedChunks;
};

const addChunksToMap = (item: Item, messagesMap: Map<string, MessageMapped>) => {
  item.chunks.forEach(chunk => {
    const message = messagesMap.get(chunk.messageId)!;
    if (message) {
      message.chunks.push({
        from: chunk.from,
        to: chunk.to,
        id: chunk.id,
        itemId: item.id,
      });
      messagesMap.set(chunk.messageId, message);
    }
  });
};

const mapChunksToMessage = (msg: MessageMapped) => {
  const chunks = createChunks(msg);
  return {
    ...msg,
    mapping: chunks,
  };
};

export const mapMessages = (recap: any) => {
  const messagesMap = createMessagesMap(recap);

  recap.actionItems.forEach((item: any) => addChunksToMap(item, messagesMap));
  recap.keyMoments.forEach((item: any) => addChunksToMap(item, messagesMap));
  recap.summaryItems.forEach((item: any) => addChunksToMap(item, messagesMap));

  const msgs = Array.from(messagesMap.values());

  return msgs.map(mapChunksToMessage);
};

const createMessagesMapFromList = (messages: any) => {
  const mapped = new Map<string, MessageMapped>();
  messages.forEach((message: any) => {
    mapped.set(message.id, {
      ...message,
      chunks: [
        {
          from: 0,
          to: message.content.length,
          id: message.id,
          itemId: message.id,
        },
      ],
    });
  });
  return mapped;
};

export const mapMessagesList = (messages: Message[], items: AiItem[]): any => {
  const messagesMap = createMessagesMapFromList(messages);

  items.forEach((item: AiItem) => addChunksToMap(item, messagesMap));

  const msgs = Array.from(messagesMap.values());
  return msgs.map(m => mapChunksToMessage(m));
};
