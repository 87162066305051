import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 460px;
  height: 100%;
  padding: 10px;
  padding-left: 16px;
  gap: 12px;
  background: #ffffff;
  margin-right: 20px;
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  scroll-behavior: auto;
  overflow-x: auto;
  gap: 10px;
  width: 100%;
`;

const Card = styled.div`
  width: 100%;
  padding: 5px;
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0a1e2c;
`;

const Content = styled.div`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0em;
  padding-left: 15px;
  color: #566976;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  width: 109px;
  height: 24px;
  padding: 3px 8px 3px 6px;
  border-radius: 9999px;
  gap: 2px;
  background: #edf9ff;
  border: 1px solid #b6e8ff;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #0a58c3;
`;

export default { Container, Badge, Scroll, Card, Header, Content };
