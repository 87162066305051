import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const PlayVideoSVG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4.71117C3.75 3.52284 5.02417 2.7695 6.06583 3.342L15.6825 8.632C16.7617 9.22533 16.7617 10.7762 15.6825 11.3695L6.06667 16.6595C5.025 17.232 3.75083 16.4787 3.75083 15.2903L3.75 4.71117Z"
        fill="#F3F4F5"
      />
    </svg>
  );
};
export const PlayVideo: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={PlayVideoSVG} {...props} />;
};
