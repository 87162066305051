import { useContext } from 'react';
import { accountStoryContext } from '../context/account-story-context';
import { useStore } from 'zustand';
import { coreService } from '../../../../services/core/core-service';
import { extractError } from '../../../../utils/api';
import { useToast } from '../../../../hooks/useToast';

export const useAccountStory = () => {
  const { store } = useContext(accountStoryContext);
  const accountId = useStore(store, state => state.accountId);
  const isGenerating = useStore(store, state => state.isGenerating);
  const lastGeneratedAt = useStore(store, state => state.lastGeneratedAt);
  const storyNarrative = useStore(store, state => state.storyNarrative);
  const storyTimeline = useStore(store, state => state.storyTimeline);
  const generationStarted = useStore(store, state => state.generationStarted);
  const loaded = useStore(store, state => state.loaded);
  const { error } = useToast();

  const load = (args: Parameters<typeof loaded>) => {
    loaded(...args);
  };

  const generate = () => {
    if (accountId) {
      coreService
        .generateAccountStory(accountId)
        .then(() => {
          generationStarted();
        })
        .catch(err => {
          const message = extractError(err);
          error(message);
        });
    }
  };

  return {
    isGenerating,
    lastGeneratedAt,
    storyNarrative,
    storyTimeline,
    accountId,
    load,
    generate,
  };
};
