import styled from 'styled-components';

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
  height: auto;
`;

const Header = styled.div<{ isOpen: boolean }>`
  flex: 1;
  display: flex;
  padding: 4px 8px;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-bottom: ${props => (props.isOpen ? '1px solid #d2d2d2' : 'none')};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: calc(100% - 16px);
`;

const HeaderContents = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Contents = styled.div`
  padding: 0px 0px 14px 12px;
  gap: 8px;
`;

export default { Container, Header, HeaderContents, Contents };
