import styled from 'styled-components';

const Container = styled.span`
  color: var(--cool-gray-50, #f3f4f5);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  font-variant-numeric: tabular-nums;
`;

export default {
  Container,
};
