/* eslint-disable @typescript-eslint/no-explicit-any */
export const setAccumulatedItems = (
  existingItems: any[],
  newItems: any[],
  invert = false,
): any[] => {
  const unique = existingItems.filter(s => {
    return !newItems.some(n => n.id === s.id);
  });

  const sorted = [...unique, ...newItems].sort((a, b) => {
    if (a.started && b.started) {
      return new Date(b.started).getTime() - new Date(a.started).getTime();
    } else if (a.scheduledStartDate && b.scheduledStartDate) {
      return new Date(b.scheduledStartDate).getTime() - new Date(a.scheduledStartDate).getTime();
    } else {
      return 0;
    }
  });

  if (invert) return sorted.reverse();

  return sorted;
};
