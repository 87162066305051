import React from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useIntegrationRequest } from '../../../../../features/integrations/hooks/useIntegrationRequest';
import { Store } from '../../../../../redux/typings/store';
import { useSelector } from 'react-redux';
import { selectSlackIntegration } from '../../../../../redux/selectors';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { Content } from '../style';
import plug from '../../../../../assets/svg/actions/plug.svg';
import unplug from '../../../../../assets/svg/actions/unplug.svg';
import { colors } from '../../../../../theme/colors';

const selectSlackIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.slack.id;

export const SlackIntegrationCard: React.FC = () => {
  const { isIntegrating, requestIntegration, hasIntegration, removeIntegration } =
    useIntegrationRequest({ source: 'SLACK', id: selectSlackIntegrationId });

  const slackIntegrationData = useSelector(selectSlackIntegration);

  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Disconnect',
          icon: <img src={plug} />,
          onClick: () => removeIntegration(),
          buttonProps: {},
        },
      ]
    : [
        {
          type: 'button',
          label: 'Connect',
          icon: <img src={unplug} />,
          onClick: () => requestIntegration(),
          buttonProps: {
            loading: isIntegrating,
            type: 'primary',
          },
        },
      ];

  return (
    <IntegrationCard
      title="Slack"
      description={
        <>
          Our Slack integration is available for paid team plans and operates at the Workspace
          level. To connect Slack to your Workspace, please have your Workspace Owner or Admin visit
          the{' '}
          <a style={{ color: colors.primary[500] }} href="/settings?tab=my-organization">
            My Workspace
          </a>{' '}
          settings tab.
        </>
      }
      logoSrc="https://assets.update.ai/slack.png"
      // actions={actions}
      // learnMore="https://help.update.ai/en/articles/6405273-installing-and-using-the-updateai-app-for-slack"
    >
      <Content>
        {hasIntegration && (
          <SuccessConnectionDataLabel label="Connected" value={slackIntegrationData?.email || ''} />
        )}
        {/* <IssueRecapMapAutomatic /> */}
      </Content>
    </IntegrationCard>
  );
};
