import React, { useState } from 'react';
import {
  AvatarAndLabel,
  AvatarContainer,
  Container,
  Email,
  LogoutContainer,
  Name,
  NameAndEmail,
} from './style';
import Avatar from '../../../features/shared/components/Avatar';
import { Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';
import { Profile } from '../../../features/shared/components/Icons/Profile';
import { Colleagues } from '../../../features/shared/components/Icons/Colleagues';
import { Organization } from '../../../features/shared/components/Icons/Organization';
import { Support } from '../../../features/shared/components/Icons/Support';
import { Logout } from '../../../features/shared/components/Icons/Logout';
import { ChevronUpDown } from '../../../features/shared/components/Icons/ChevronUpDown';
import { useNavigate } from 'react-router-dom';
interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
  collapsed?: boolean;
  logout?: () => void;
}

const MenuItemComponent = styled.div`
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 12px;
  color: var(--cool-gray-700, #0a1e2c);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const Account: React.FC<Props> = ({ firstName, lastName, email, collapsed, logout }) => {
  const firstLetter = firstName ? firstName[0].toUpperCase() : 'A';
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      label: (
        <MenuItemComponent>
          <Profile />
          View Profile
        </MenuItemComponent>
      ),
      key: 'profile',
      onClick: () => {
        navigate('/settings?tab=account');
      },
    },
    {
      type: 'divider',
    },
    {
      label: (
        <MenuItemComponent>
          <Organization />
          Workspace
        </MenuItemComponent>
      ),
      key: 'organization',
      onClick: () => {
        navigate('/settings?tab=my-organization');
      },
    },
    /* {
      label: (
        <MenuItemComponent>
          <Team />
          Team
        </MenuItemComponent>
      ),
      key: 'team',
    }, */
    {
      label: (
        <MenuItemComponent>
          <Colleagues />
          Invite Colleagues
        </MenuItemComponent>
      ),
      key: 'inviteColleagues',
      onClick: () => {
        navigate('/settings?tab=my-organization&invite=true');
        const inviteMemberButton = document.getElementById('invite-new-member-button');
        if (inviteMemberButton) inviteMemberButton.scrollIntoView({ behavior: 'smooth' });
      },
    },
    {
      type: 'divider',
    },
    /* {
      label: (
        <MenuItemComponent>
          <Changelog />
          Changelog
        </MenuItemComponent>
      ),
      key: 'changelog',
    }, */
    /* {
      label: (
        <MenuItemComponent>
          <Slack />
          Slack Community
        </MenuItemComponent>
      ),
      key: 'slackCommunity',
    }, */
    {
      label: (
        <MenuItemComponent>
          <Support />
          Support
        </MenuItemComponent>
      ),
      key: 'support',
      onClick: () => {
        window.open('https://help.update.ai', '_blank');
      },
    },
    {
      type: 'divider',
    },
    {
      label: (
        <MenuItemComponent>
          <Logout />
          Log out
        </MenuItemComponent>
      ),
      key: 'logout',
      onClick: logout,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onMouseLeave: () => setOpen(false),
      }}
      open={open}
    >
      <Container collapsed={collapsed} onClick={() => setOpen(!open)}>
        <AvatarAndLabel>
          <AvatarContainer>
            <Avatar size="large">{firstLetter}</Avatar>
          </AvatarContainer>
          {!collapsed && (
            <NameAndEmail>
              <Name>{`${firstName} ${lastName}`}</Name>
              <Email title={email}>{email}</Email>
            </NameAndEmail>
          )}
        </AvatarAndLabel>
        {!collapsed && (
          <LogoutContainer>
            <ChevronUpDown />
          </LogoutContainer>
        )}
      </Container>
    </Dropdown>
  );
};

export default Account;
