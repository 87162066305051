import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px;
  height: calc(100vh - 190px);
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  overflow-y: hidden;
`;

const Story = styled.div`
  display: flex;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

export default {
  Container,
  Story,
};
