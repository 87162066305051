import React from 'react';
import { Button, Dropdown, Input, MenuProps } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { colors } from '../../../../../../theme/colors';
import { Question } from '../../../../../../features/frameworks/type';

import Style from './style';
const { Container, ItemContent } = Style;

interface ItemProps {
  question: Question;
  removeQuestion: (id: string) => void;
  updateQuestion: (question: Question) => void;
}

const Item: React.FC<ItemProps> = ({ question, removeQuestion, updateQuestion }) => {
  const [editingContext, setEditingContext] = React.useState(question.context !== null);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          onClick={() => {
            if (editingContext) updateQuestion({ ...question, context: null });
            setEditingContext(!editingContext);
          }}
        >
          {editingContext ? 'Remove ' : 'Add/Edit '} Context
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          onClick={() => removeQuestion(question.id)}
          style={{ display: 'flex', alignItems: 'center', color: 'red', gap: '5px' }}
        >
          <DeleteOutlined /> Delete Question
        </a>
      ),
    },
  ];

  return (
    <ItemContent key={question.id}>
      <Input
        placeholder="Add a question or prompt"
        value={question.value}
        onChange={v => updateQuestion({ ...question, value: v.target.value })}
      />

      <Dropdown menu={{ items }}>
        <Button style={{ width: '100%' }} icon={<MoreOutlined />} />
      </Dropdown>

      {editingContext && (
        <div style={{ position: 'relative', justifySelf: 'end', width: '90%' }}>
          <Input.TextArea
            autoSize={false}
            maxLength={1000}
            style={{ height: '80px' }}
            onChange={v => updateQuestion({ ...question, context: v.target.value })}
            value={question.context || ''}
          />
          <div
            style={{
              left: '20px',
              position: 'absolute',
              top: '-6px',
              padding: '0px 5px',
              lineHeight: '10px',
              color: colors.gray[500],
              backgroundColor: colors.white,
            }}
          >
            Context
          </div>
          <div
            style={{
              right: '20px',
              position: 'absolute',
              bottom: '-7px',
              padding: '0px 5px',
              fontSize: '10px',
              color: colors.gray[500],
              backgroundColor: colors.white,
            }}
          >
            {1000 - (question.context || '').length}/1000 characters remaining
          </div>
        </div>
      )}
    </ItemContent>
  );
};

interface QuestionsProps {
  questions: Question[];
  removeQuestion: (id: string) => void;
  updateQuestion: (question: Question) => void;
}

export const Questions: React.FC<QuestionsProps> = ({
  questions,
  removeQuestion,
  updateQuestion,
}) => {
  return (
    <Container>
      {questions.length === 0 && <h2>No questions added yet.</h2>}
      {questions.map(i => (
        <Item
          key={i.id}
          question={i}
          removeQuestion={removeQuestion}
          updateQuestion={updateQuestion}
        />
      ))}
    </Container>
  );
};
