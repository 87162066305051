import styled from 'styled-components';

export const Container = styled.div`
  background-color: f0f0f0;
  height: calc(100vh - 3.75rem);
  margin: auto;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.h1`
  font-family: 'Cera Pro';
  font-style: normal;
  color: #9eaeba;
  width: 75%;
  text-align: center;
`;

export default { Container, Error };
