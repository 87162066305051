import { StoryTimelineEntry } from './types';
import { createStore } from 'zustand';

interface AccountStoryState {
  accountId: string | null;
  storyTimeline: StoryTimelineEntry[];
  storyNarrative: string | null;
  isGenerating: boolean;
  lastGeneratedAt: Date | null;
  loaded(
    storyTimeline: StoryTimelineEntry[],
    storyNarrative: string | null,
    isGenerating: boolean,
    lastGeneratedAt: Date | null,
  ): void;
  generationStarted(): void;
}

export type AccountStoryStore = ReturnType<typeof createAccountStoryStore>;

export const createAccountStoryStore = (accountId: string | null) => {
  return createStore<AccountStoryState>(set => {
    return {
      isGenerating: false,
      lastGeneratedAt: null,
      storyTimeline: [],
      storyNarrative: null,
      accountId,
      loaded: (
        storyTimeline: StoryTimelineEntry[],
        storyNarrative: string | null,
        isGenerating: boolean,
        lastGeneratedAt: Date | null,
      ) => {
        set({
          storyTimeline,
          isGenerating,
          storyNarrative,
          lastGeneratedAt,
        });
      },
      generationStarted: () => {
        set({ isGenerating: true });
      },
    };
  });
};
