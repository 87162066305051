import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const DisconnectSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M8.5125 2.08752H6.84583V3.65419L8.5125 5.32086V2.08752ZM13.5125 7.08752V10.3209L15.0125 11.8209L15.1792 11.6542V7.08752C15.1792 6.17086 14.4292 5.42086 13.5125 5.42086V2.08752H11.8458V5.42086H8.6125L10.2792 7.08752H13.5125ZM3.6125 2.78752L2.4375 3.96252L5.17917 6.70419V11.6709L8.09583 14.5875V17.0875H12.2625V14.5875L12.6625 14.1875L16.3875 17.9125L17.5625 16.7375L3.6125 2.78752ZM10.5958 13.8959V15.4209H9.7625V13.8959L6.84583 10.9625V8.37086L11.4875 13.0125L10.5958 13.8959Z"
        fill="black"
      />
    </svg>
  );
};
export const Disconnect: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={DisconnectSVG} {...props} />;
};
