import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/CeraProBold.otf';
import { ToastContainer } from 'react-toastify';
import { Routing } from './routes/Routes';
import { useImagePreloader } from './hooks/useImagePreloader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Staging } from './componentsV2/Staging';
import { AntProvider } from './Ant/AntProvider';
import { UserResolver } from './features/authentication/components/UserResolver';
import { LearningCenterProvider } from './features/help/learning-center/learning-center.context';
import { LearningCenter } from './features/help/learning-center/components/LearningCenter';
import { AnalyticsProvider } from './features/analytics/analytics.context';
import { InviteBotProvider } from './features/bot/invite-bot/invite-bot.context';
import { InviteBotModal } from './features/bot/invite-bot/components/InviteBotModal';
import { UploadRecordingProvider } from './features/recordings/upload-recording/upload-recording.context';

export const integrationBroadcast = new BroadcastChannel('integrations');
export const assetsDomain =
  process.env.REACT_APP_PORTAL_ENV === 'production'
    ? 'https://assets.update.ai'
    : 'https://assets.stg.update.ai';

const client = new QueryClient();

const App: React.FC = () => {
  useImagePreloader([
    'https://assets.update.ai/recap_blur.png',
    'https://assets.update.ai/calendar-step.png',
    'https://assets.update.ai/zoom-step.png',
    'https://assets.update.ai/sign-in.png',
    'https://assets.update.ai/continue-mobile.png',
    'https://assets.update.ai/get-started.png',
    'https://assets.update.ai/mobile-screen.png',
    'https://assets.update.ai/role-step.png',
    'https://assets.update.ai/ad_rocket.png',
  ]);

  const hostname = window.location.hostname;
  const isStaging = hostname.includes('stg');

  return (
    <>
      <AntProvider>
        <InviteBotProvider>
          <UploadRecordingProvider>
            <LearningCenterProvider>
              <LearningCenter />
              {isStaging && <Staging />}
              <AnalyticsProvider>
                <QueryClientProvider client={client}>
                  <UserResolver>
                    <Routing />
                  </UserResolver>
                  <ToastContainer />
                </QueryClientProvider>
              </AnalyticsProvider>
            </LearningCenterProvider>
          </UploadRecordingProvider>
          <InviteBotModal />
        </InviteBotProvider>
      </AntProvider>
    </>
  );
};

export default App;
