import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { AccountFilterPanel } from '../../../../features/shared/components/AccountFilterPanel';
import { Panel } from '../../../../features/shared/components/PanelFilters';
import { ProviderIntegration } from '../../../../redux/typings/store';
import React from 'react';

interface Props {
  accounts: ProviderIntegration[];
  selectAccounts: (e: ProviderIntegration[]) => void;
}

export const AccountFilterRender: React.FC<Props> = ({ selectAccounts, accounts }) => {
  return (
    <Dropdown
      dropdownRender={() => (
        <Panel panelId="accounts" title="Accounts">
          <AccountFilterPanel
            filter={value => value}
            onAccountsSelectionChange={e => {
              selectAccounts(e);
            }}
            placeholder="Search"
            selectedAccounts={accounts}
          />
        </Panel>
      )}
    >
      <Button>
        <Space>
          <span>Accounts</span>
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
