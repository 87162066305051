import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default { Container };
