import React, { useState } from 'react';
import Styled from './styles';
import { useRecapAccount } from '../../../../../hooks/useRecapAccount';
import { ProviderIntegration } from '../../../../../redux/typings/store';
import { useNavigate } from 'react-router-dom';
import { AccountResolver } from './AccountResolver';
import { AccountModal } from './AccountModal';

const { AccountContainer, Account } = Styled;

export const AccountSelector: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<ProviderIntegration | null>(null);

  const { isWorking, recapAccount, removeAccount, setAccount, prospectedAccounts } =
    useRecapAccount();

  const onSelect = async (account: ProviderIntegration) => {
    if (account) {
      setIsVisible(false);
      setAccount(account);
    }
  };

  const navigate = useNavigate();

  const canShowRemove = Boolean(recapAccount);
  const canShowSelector = !Boolean(recapAccount);

  return (
    <>
      <AccountContainer>
        <Account isSet={!canShowSelector} onClick={() => canShowSelector && setIsVisible(true)}>
          <AccountResolver
            canShowRemove={canShowRemove}
            canShowSelector={canShowSelector}
            isWorking={isWorking}
            recapAccount={recapAccount!}
            prospectedAccounts={prospectedAccounts!}
            onConnectClick={() => navigate('/settings?tab=integrations')}
            onDelete={removeAccount}
            onChangeClick={() => setIsVisible(true)}
          />
        </Account>
      </AccountContainer>
      <AccountModal
        isWorking={isWorking}
        onAccountSelection={onSelect}
        onCancel={() => {
          setIsVisible(false);
        }}
        onResultSelect={a => setSelectedAccount(a)}
        open={isVisible}
        selectedAccount={selectedAccount}
        prospectedAccounts={prospectedAccounts}
        centered
        destroyOnClose
        title={false}
        closable={false}
        footer={false}
      />
    </>
  );
};
