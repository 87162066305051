import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SVGComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 17.5C16.913 17.5 17.5489 17.2366 18.0178 16.7678C18.4866 16.2989 18.75 15.663 18.75 15V11.25C18.75 10.587 18.4866 9.95107 18.0178 9.48223C17.5489 9.01339 16.913 8.75 16.25 8.75H3.75C3.08696 8.75 2.45107 9.01339 1.98223 9.48223C1.51339 9.95107 1.25 10.587 1.25 11.25V15C1.25 15.663 1.51339 16.2989 1.98223 16.7678C2.45107 17.2366 3.08696 17.5 3.75 17.5H16.25ZM1.25 8.455V5C1.25 4.33696 1.51339 3.70107 1.98223 3.23223C2.45107 2.76339 3.08696 2.5 3.75 2.5H8.2325C8.72949 2.50022 9.20607 2.69774 9.5575 3.04917L11.3258 4.81667C11.4425 4.93417 11.6017 5 11.7675 5H16.25C16.913 5 17.5489 5.26339 18.0178 5.73223C18.4866 6.20107 18.75 6.83696 18.75 7.5V8.455C18.0632 7.83886 17.1727 7.49869 16.25 7.5H3.75C2.82733 7.49869 1.93681 7.83886 1.25 8.455Z"
        fill="#566976"
      />
    </svg>
  );
};

export const Folder: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={SVGComponent} {...props} />;
};
