import { accountSelectionStore } from '../stores/account-selection-modal';

export const useAccountSelectionModal = () => {
  const { show, close, prospectAccounts, open, eventId } = accountSelectionStore();

  return {
    eventId,
    show,
    close,
    prospectAccounts,
    open,
  };
};
