import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 180px);
  padding-bottom: 48px;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px;

  width: 100%;
  height: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const LineText = styled.div`
  gap: 4px;
  width: 100;
  padding: 8px 0px 0px 128px;

  font-size: 12px;
`;

export const CardHolder = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px var(--spacing-3xl, 24px);
  align-self: stretch;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: end;
  width: 100%;
`;
