import React from 'react';
import Styles from './styles';
import { Spin } from 'antd';

import { ResultItem } from './ResultItem/ResultItem';
import { ProviderIntegration } from '../../../../../redux/typings/store';

const { Container, ResultCount, ResultContainer, SpinnerContainer } = Styles;

interface Props {
  accounts: Array<{ item: ProviderIntegration; checked: boolean }>;
  loading: boolean;
  onCheck: (value: ProviderIntegration) => unknown;
  onUncheck: (value: ProviderIntegration) => unknown;
}

const Results: React.FC<Props> = ({ accounts, loading, onCheck, onUncheck }) => {
  const handleCheckChange = (value: boolean, provider: ProviderIntegration) => {
    if (value) {
      onCheck(provider);
    } else {
      onUncheck(provider);
    }
  };

  return (
    <Container>
      {loading ? (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      ) : (
        <>
          {' '}
          <ResultCount>Search results ({accounts.length})</ResultCount>
          <ResultContainer>
            {accounts.map(acc => (
              <ResultItem
                key={acc.item.id}
                checked={acc.checked}
                onCheck={val => handleCheckChange(val, acc.item)}
                account={acc.item}
              />
            ))}
          </ResultContainer>
        </>
      )}
    </Container>
  );
};

export { Results };
