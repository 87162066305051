import React, { ReactElement } from 'react';
import Styles from './styles';
import { Button, Dropdown, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Pencil } from '../../../../features/shared/components/Icons/Pencil';
import { Trash } from '../../../../features/shared/components/Icons/Trash';

const {
  Container,
  Count,
  Name,
  ExtraDataContainer,
  MenuTitle,
  ItemContainer,
  ItemLabel,
  ExtraContainer,
} = Styles;

interface Props {
  icon: ReactElement;
  name: string;
  onClick: () => unknown;
  onRename: () => unknown;
  onRemove: () => unknown;
  count?: number;
  showMenu?: boolean;
}

export const Folder: React.FC<Props> = ({
  icon,
  name,
  count,
  showMenu = true,
  onClick,
  onRemove,
  onRename,
}) => {
  const hasCount = typeof count !== 'undefined';
  return (
    <Container id="folder">
      {icon}
      <Tooltip title={name}>
        <Name onClick={() => onClick()}>{name}</Name>
      </Tooltip>
      <ExtraContainer>
        {hasCount && (
          <ExtraDataContainer>
            <Count id="folder-count">{count}</Count>
          </ExtraDataContainer>
        )}
        {showMenu && (
          <ExtraDataContainer>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'header',
                    disabled: true,
                    label: <MenuTitle>Folder options</MenuTitle>,
                  },
                  {
                    key: 'divider-1',
                    type: 'divider',
                  },
                  {
                    key: 'rename-folder',
                    label: (
                      <ItemContainer onClick={() => onRename()}>
                        <Pencil />
                        <ItemLabel>Rename folder</ItemLabel>
                      </ItemContainer>
                    ),
                  },
                  {
                    key: 'divider-2',
                    type: 'divider',
                  },
                  {
                    key: 'remove-folder',
                    label: (
                      <ItemContainer onClick={() => onRemove()}>
                        <Trash />
                        <ItemLabel>Delete</ItemLabel>
                      </ItemContainer>
                    ),
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button id="folder-menu" icon={<MoreOutlined />} type="text" />
            </Dropdown>
          </ExtraDataContainer>
        )}
      </ExtraContainer>
    </Container>
  );
};
