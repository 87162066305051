import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SVGComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.25C4.615 0.25 0.25 4.615 0.25 10C0.25 15.385 4.615 19.75 10 19.75C15.385 19.75 19.75 15.385 19.75 10C19.75 4.615 15.385 0.25 10 0.25ZM5.72 9.47C5.57955 9.61063 5.50066 9.80125 5.50066 10C5.50066 10.1988 5.57955 10.3894 5.72 10.53L8.72 13.53C8.78866 13.6037 8.87146 13.6628 8.96346 13.7038C9.05546 13.7448 9.15477 13.7668 9.25548 13.7686C9.35618 13.7704 9.45621 13.7518 9.5496 13.7141C9.64299 13.6764 9.72782 13.6203 9.79904 13.549C9.87026 13.4778 9.9264 13.393 9.96412 13.2996C10.0018 13.2062 10.0204 13.1062 10.0186 13.0055C10.0168 12.9048 9.99477 12.8055 9.95378 12.7135C9.91279 12.6215 9.85369 12.5387 9.78 12.47L8.06 10.75H13.75C13.9489 10.75 14.1397 10.671 14.2803 10.5303C14.421 10.3897 14.5 10.1989 14.5 10C14.5 9.80109 14.421 9.61032 14.2803 9.46967C14.1397 9.32902 13.9489 9.25 13.75 9.25H8.06L9.78 7.53C9.91248 7.38783 9.9846 7.19978 9.98118 7.00548C9.97775 6.81118 9.89903 6.62579 9.76162 6.48838C9.62421 6.35097 9.43882 6.27225 9.24452 6.26882C9.05022 6.2654 8.86217 6.33752 8.72 6.47L5.72 9.47Z"
        fill="#566976"
      />
    </svg>
  );
};

export const FolderBack: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={SVGComponent} {...props} />;
};
