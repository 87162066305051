import { useParams } from 'react-router-dom';
import { ProfileProvider } from '../../features/profile/profile.context';
import { ProfilePage } from './page';

export const Profile: React.FC = () => {
  const { id } = useParams();

  return (
    <ProfileProvider id={id || ''}>
      <ProfilePage />
    </ProfileProvider>
  );
};
