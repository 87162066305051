import { Form } from 'antd';
import styled from 'styled-components';

const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #ff6c54;
`;

const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ForgotPasswordContainer = styled.div`
  width: 100%;
  text-align: right;
`;

export default {
  Link,
  FormInputsContainer,
  RegisterContainer,
  ForgotPasswordContainer,
  FormItem,
};
