import { PropsWithChildren, createContext, useEffect, useRef } from 'react';
import { RecapManagementStore, createStore } from './store';
import { useQuery } from '@tanstack/react-query';
import { fetchRecap } from './service';

type RecapManagementContext = {
  store: RecapManagementStore;
};

export const recapManagementContext = createContext<RecapManagementContext>({
  store: createStore(),
});

interface Props {
  id: string;
  secret: string;
}

export const RecapManagementProvider: React.FC<PropsWithChildren<Props>> = ({
  id,
  secret,
  children,
}) => {
  const store = useRef(createStore()).current;
  const state = store();

  const { data, isFetching, error } = useQuery({
    queryKey: [id, secret],
    queryFn: async () => {
      const recap = await fetchRecap(id, secret);
      return recap;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (error instanceof Error) {
      state.fetchFailed(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (isFetching) state.fetchStarted();
    else state.fetchEnded();
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      state.recapLoaded(data);
    }
  }, [data]);

  return (
    <recapManagementContext.Provider value={{ store }}>{children}</recapManagementContext.Provider>
  );
};
