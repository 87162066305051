import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SkipBackwardSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99966 18.4828C8.94138 18.4828 7.94908 18.2851 7.02276 17.8895C6.09643 17.494 5.28717 16.9502 4.59499 16.258C3.90281 15.5658 3.35896 14.7574 2.96343 13.8327C2.56789 12.908 2.37012 11.9149 2.37012 10.8533C2.37012 10.6495 2.43745 10.4803 2.57212 10.3456C2.70678 10.2109 2.87602 10.1436 3.07983 10.1436C3.28363 10.1436 3.45286 10.2109 3.58751 10.3456C3.72218 10.4803 3.78951 10.6495 3.78951 10.8533C3.78951 12.5795 4.39383 14.0452 5.60247 15.2505C6.81111 16.4558 8.27684 17.0585 9.99966 17.0585C11.7225 17.0585 13.1882 16.455 14.3968 15.248C15.6055 14.041 16.2098 12.5761 16.2098 10.8533C16.2098 9.12716 15.6228 7.66142 14.4489 6.4561C13.275 5.2508 11.825 4.64815 10.0988 4.64815H9.64051L10.674 5.68165C10.8057 5.8133 10.8715 5.96578 10.8715 6.13908C10.8715 6.31239 10.8057 6.46486 10.674 6.5965C10.5424 6.72815 10.3891 6.79315 10.2141 6.79148C10.0391 6.78983 9.88583 6.72317 9.75418 6.59152L7.55672 4.39406C7.41179 4.25245 7.33932 4.08586 7.33932 3.89429C7.33932 3.70271 7.41179 3.53612 7.55672 3.39452L9.77501 1.17123C9.89278 1.0568 10.0426 0.998758 10.2245 0.997105C10.4064 0.995439 10.5563 1.05348 10.674 1.17123C10.7918 1.28899 10.8498 1.43966 10.8482 1.62323C10.8465 1.8068 10.7868 1.95581 10.669 2.07025L9.51551 3.22377H9.99468C11.0563 3.22377 12.0502 3.42153 12.9766 3.81706C13.9029 4.2126 14.7121 4.75647 15.4043 5.44865C16.0965 6.14083 16.6404 6.94925 17.0359 7.87392C17.4314 8.79858 17.6292 9.79171 17.6292 10.8533C17.6292 11.9116 17.4314 12.9039 17.0359 13.8302C16.6404 14.7565 16.0965 15.5658 15.4043 16.258C14.7121 16.9502 13.9029 17.494 12.9766 17.8895C12.0502 18.2851 11.0579 18.4828 9.99966 18.4828Z"
        fill="#F3F4F5"
      />
    </svg>
  );
};
export const SkipBackward: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={SkipBackwardSvg} {...props} />;
};
