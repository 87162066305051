import { coreAPI } from '../../../API/core';
import { coreService } from '../../../services/core/core-service';
import { Recap, Utterance } from './types';
import {
  createUtterances,
  dateOrNull,
  parseAccount,
  parseCategories,
  parseCropping,
  parseInsights,
  parsePlayback,
  parseSpeakerAnalytics,
} from './utils';
import { pick } from 'lodash';

export const fetchRecap = async (recapId: string, secret: string | null): Promise<Recap> => {
  const fetchRecapResponse = await coreService.fetchRecap(recapId, secret || '');

  const utterances: Utterance[] = createUtterances(fetchRecapResponse);
  const isPublic = Boolean(secret);
  const speakerAnalytics = parseSpeakerAnalytics(fetchRecapResponse);
  const insights = parseInsights(fetchRecapResponse);
  const account = parseAccount(fetchRecapResponse);
  const publicVisibilityConfig = pick(fetchRecapResponse.visibilityConfig, [
    'showActionItems',
    'showAdvocacy',
    'showTldr',
    'showProductFeedback',
    'showMeetingDetails',
    'showImportantNumbers',
    'showTranscript',
    'showVideo',
    'showRisks',
    'showFeatureRequestFeedback',
    'showGrowthOpportunities',
  ]);
  const playback = parsePlayback(fetchRecapResponse);
  const categories = parseCategories(fetchRecapResponse);
  const cropping = parseCropping(fetchRecapResponse);

  return {
    id: fetchRecapResponse.id,
    isDemo: fetchRecapResponse.isDemo,
    reports: fetchRecapResponse.reports,
    participantsType: fetchRecapResponse.participantsType,
    permissions: fetchRecapResponse.permissions,
    insights,
    followUp: fetchRecapResponse.followUp,
    participants: fetchRecapResponse.participants,
    account,
    isBasic: fetchRecapResponse.shouldBlur,
    isPublic,
    excludedFromInsightsEngine: fetchRecapResponse.excludedFromInsights,
    isOwner: fetchRecapResponse.isOwner,
    playback,
    cropping,
    publicVisibilityConfig,
    speakerAnalytics,
    scheduledStartDate: dateOrNull(fetchRecapResponse.scheduledStartDate),
    scheduledEndDate: dateOrNull(fetchRecapResponse.scheduledEndDate),
    started: dateOrNull(fetchRecapResponse.started),
    ended: dateOrNull(fetchRecapResponse.ended),
    type: fetchRecapResponse.type,
    workspaceVisibility: fetchRecapResponse.visibility,
    title: fetchRecapResponse.title,
    host: fetchRecapResponse.host,
    status: fetchRecapResponse.status,
    transcript: utterances,
    categories,
  };
};

const createSummaryItem = async (eventId: string): Promise<string> => {
  const api = new coreAPI();
  const createResult = await api.addSummaryItem(eventId);
  return createResult;
};

const createActionItem = async (eventId: string): Promise<string> => {
  const api = new coreAPI();
  const createResult = await api.addActionItem(eventId);
  return createResult;
};

const createMoment = async (eventId: string, category: string): Promise<string> => {
  const api = new coreAPI();
  const createResult = await api.addMoment(eventId, category);
  return createResult;
};

export const createInsight = (eventId: string, category: string) => {
  if (category === 'TL;DR') {
    return createSummaryItem(eventId);
  }

  if (category === 'Action Items') {
    return createActionItem(eventId);
  }

  return createMoment(eventId, category);
};

const updateSummaryItem = async (eventId: string, summaryItemId: string, text: string) => {
  const api = new coreAPI();
  await api.editSummaryItem(eventId, summaryItemId, text);
};

const updateActionItem = async (eventId: string, actionItemId: string, text: string) => {
  const api = new coreAPI();
  await api.editActionItem(eventId, actionItemId, text);
};

const updateMoment = async (eventId: string, momentId: string, category: string, text: string) => {
  const api = new coreAPI();
  await api.editMoment(eventId, momentId, category, text);
};

export const editInsight = (eventId: string, insightId: string, category: string, text: string) => {
  if (category === 'TL;DR') {
    return updateSummaryItem(eventId, insightId, text);
  }

  if (category === 'Action Items') {
    return updateActionItem(eventId, insightId, text);
  }

  return updateMoment(eventId, insightId, category, text);
};

const removeSummaryItem = async (eventId: string, summaryItemId: string) => {
  const api = new coreAPI();
  await api.removeSummaryItem(eventId, summaryItemId);
};

const removeActionItem = async (eventId: string, actionItemId: string) => {
  const api = new coreAPI();
  await api.removeActionItemBullet(eventId, actionItemId);
};

const removeMoment = async (eventId: string, momentId: string) => {
  const api = new coreAPI();
  await api.removeMoment(eventId, momentId);
};

export const removeInsight = (eventId: string, insightId: string, category: string) => {
  if (category === 'TL;DR') {
    return removeSummaryItem(eventId, insightId);
  }

  if (category === 'Action Items') {
    return removeActionItem(eventId, insightId);
  }

  return removeMoment(eventId, insightId);
};
