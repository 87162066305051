import { useMemo } from 'react';
import { roleOptions } from '../utils';

export const useMemberRoles = (role: string) => {
  const roles = useMemo(() => {
    if (role === 'OWNER') {
      return roleOptions;
    }
    return roleOptions.filter(({ id }) => id !== 'OWNER');
  }, [role]);

  return {
    roles,
  };
};
