import styled from 'styled-components';
import { mediaSize } from '../../../../theme/media';

const Container = styled.div`
  @media (max-width: ${mediaSize.tablet}) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 24px;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #e5e5e5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  min-height: 441px;

  & .ant-radio {
    align-self: start;
    padding-top: 3px;
  }
`;

const Footer = styled.div`
  color: var(--New-Color-Shades-Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
`;

const Description = styled.div`
  color: var(--Grey-900, #111b2b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.154px;
`;

const Header = styled.div`
  color: var(--Grey-900, #111b2b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.154px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default { Container, Title, Footer, Content, Description, Header, Item };
