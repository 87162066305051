import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { ViewAllModal } from '../../../AllTopicsModal';
import { Flex, Pagination, Select } from 'antd';
import { TopTopicsTable } from '../TopTopicsTable';

interface Props {
  open: boolean;
  insightType?: string;
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  onTopicClicked: (args: { id: string; name: string }) => unknown;
  sortBy: string;
  onClose: () => unknown;
}

export const ViewAllTopicsModal: React.FC<Props> = ({
  filters,
  onTopicClicked,
  sortBy,
  open,
  onClose,
  insightType,
}) => {
  const [selectedSortBy, setSelectedSortBy] = useState(sortBy);
  const [page, setSelectedPage] = useState(1);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { data, isFetching } = useQuery({
    queryKey: [
      'top-topics',
      filters.from,
      filters.to,
      page,
      filters.accountId,
      selectedSortBy,
      insightType,
      isOpen,
    ],
    queryFn: async args => {
      if (!isOpen) {
        return null;
      }

      const options: any = {};
      if (filters.accountId) {
        options['accountIds'] = [filters.accountId];
      }

      if (insightType) {
        options['insightType'] = insightType;
      }

      const data = await coreService.getTopTopics(
        filters.from,
        filters.to,
        page,
        10,
        selectedSortBy,
        options,
      );
      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSortByChanged = (sortBy: string) => {
    setSelectedSortBy(sortBy);
    setSelectedPage(1);
  };
  return (
    <ViewAllModal open={isOpen} label="View All Topics" onClose={handleClose}>
      <Flex vertical gap={'16px'}>
        <Flex>
          <Select
            disabled={isFetching}
            value={selectedSortBy}
            onChange={value => handleSortByChanged(value)}
            options={[
              { value: 'mentions', label: '# of Mentions' },
              { value: 'meetings', label: '# of Meetings' },
              { value: 'accounts', label: '# of Accounts' },
            ]}
          />
        </Flex>
        <TopTopicsTable
          loading={isFetching}
          data={data?.topics || []}
          onTopicClicked={onTopicClicked}
        />
        <Flex justify="flex-end">
          {data && (
            <Pagination
              onChange={page => setSelectedPage(page)}
              current={page}
              total={data.pagination.items.total}
            />
          )}
        </Flex>
      </Flex>
    </ViewAllModal>
  );
};
