export const capitalize = (text: string): string =>
  `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

export const capitalizeSentence = (sentence: string, stopword = ' '): string => {
  return sentence
    .split(stopword)
    .map(word => {
      return capitalize(word.trim());
    })
    .join(stopword);
};

export const twoDigitNumber = (number: string) => {
  if (Number(number) < 10 && number.length < 2) {
    return '0' + number.toString();
  }
  return number;
};

export const secondsToTime = (timestamp: number | string) => {
  const time = Number.parseFloat(timestamp.toString());
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) - hours * 60;
  const seconds = Math.floor(time % 60);

  let formattedTime = '';

  if (hours !== 0) {
    formattedTime = hours.toString().padStart(2, '0') + ':';
  }

  formattedTime =
    formattedTime + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

  return formattedTime;
};
