import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;
`;

const CenteredContent = styled.div`
  height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    color: gray;
    font-size: 17px;
  }

  & h1 {
    color: black;
    font-size: 22px;
  }
`;

export default {
  Container,
  CenteredContent,
};
