import { AskMeAnythingMessage } from '../../../../askMeAnything/types';

export const useMessageReferences = () => {
  const assignReferencesToMessages = (messages: AskMeAnythingMessage[]): AskMeAnythingMessage[] => {
    return messages.map(m => {
      if (m.references?.length > 0) {
        let newContent = m.content;

        m.references.forEach((ref, idx) => {
          newContent = newContent.replace(
            `[${idx + 1}]`,
            `<a href="${window.location.origin}/recap/${ref.eventId}?messageId=${
              ref.messageId
            }&autoplay=true" target="_blank" title="${encodeURI(ref.eventTitle)}" text="${encodeURI(
              ref.referenceText,
            )}" date="${encodeURI(ref.scheduledStartDate)}">[${idx + 1}]</a>`,
          );
        });

        return { ...m, content: newContent };
      } else {
        return m;
      }
    });
  };

  return {
    assignReferencesToMessages,
  };
};
