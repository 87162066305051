import { useContext } from 'react';
import { uploadRecordingContext } from './upload-recording.context';

export const useUploadRecording = () => {
  const { isOpen, show, close } = useContext(uploadRecordingContext);
  return {
    isOpen,
    show,
    close,
  };
};
