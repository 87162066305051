import React from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ImportantNumbers } from '../../shared/components/Icons/ImportantNumbers';
import { TLDR } from '../../shared/components/Icons/TL-DR';
import { ActionItem } from '../../shared/components/Icons/ActionItem';
import { Moment } from '../../shared/components/Icons/Moment';

interface InsightTypeConfiguration {
  color: string;
  highlightColor: string;
  visibilityKey?: string;
  smartClipColor: string;
  icon: React.FC<Partial<CustomIconComponentProps>>;
  canExcludeInsights: boolean;
}

const defaultInsightTypeConfiguration: InsightTypeConfiguration = {
  color: '#087456',
  highlightColor: '#D2F9E5',
  smartClipColor: '#EFF6FF',
  icon: ImportantNumbers,
  canExcludeInsights: false,
};

const actionItemConfig: InsightTypeConfiguration = {
  color: '#0675FC',
  highlightColor: '#D6F1FF',
  icon: ActionItem,
  visibilityKey: 'showActionItems',
  smartClipColor: '#EFF6FF',
  canExcludeInsights: false,
};

const summaryItemsConfig: InsightTypeConfiguration = {
  color: '#5E16EB',
  highlightColor: '#EBE7FF',
  icon: TLDR,
  visibilityKey: 'showTldr',
  smartClipColor: '#F4EFFF',
  canExcludeInsights: false,
};

const risksItemsConfig: InsightTypeConfiguration = {
  color: '#B75A06',
  highlightColor: '#FFF6C6',
  icon: Moment,
  visibilityKey: 'showRisks',
  smartClipColor: '#FCF2E6',
  canExcludeInsights: true,
};

const importantNumbersItemsConfig: InsightTypeConfiguration = {
  color: '#087456',
  highlightColor: '#D2F9E5',
  icon: ImportantNumbers,
  visibilityKey: 'showImportantNumbers',
  smartClipColor: '#DEFAEE',
  canExcludeInsights: true,
};

const productItemsConfig: InsightTypeConfiguration = {
  color: '#0675FC',
  highlightColor: '#D6F1FF',
  icon: ActionItem,
  visibilityKey: 'showProductFeedback',
  smartClipColor: '#EFF6FF',
  canExcludeInsights: true,
};

const advocacyItemsConfig: InsightTypeConfiguration = {
  color: '#E74F20',
  highlightColor: '#D6F1FF',
  smartClipColor: '',
  icon: ActionItem,
  visibilityKey: 'showAdvocacy',
  canExcludeInsights: true,
};

const growthOpportunitiesConfig: InsightTypeConfiguration = {
  color: '#E74F20',
  highlightColor: '#D6F1FF',
  smartClipColor: '',
  icon: ActionItem,
  visibilityKey: 'showGrowthOpportunities',
  canExcludeInsights: true,
};

const insightTypeConfigs: { [k: string]: InsightTypeConfiguration } = {
  'Action Items': actionItemConfig,
  'TL;DR': summaryItemsConfig,
  Risks: risksItemsConfig,
  'Important Numbers': importantNumbersItemsConfig,
  Advocacy: advocacyItemsConfig,
  'Product Feedback': productItemsConfig,
  'Growth Opportunities': growthOpportunitiesConfig,
};

export const getInsightTypeConfiguration = (category: string): InsightTypeConfiguration => {
  const config = insightTypeConfigs[category];
  return config || defaultInsightTypeConfiguration;
};

const externalInsightTypes = ['Risks', 'Product Feedback', 'Advocacy'];

export const isExternal = (insightType: string) => externalInsightTypes.includes(insightType);
export const isInternal = (insightType: string) => !isExternal(insightType);
