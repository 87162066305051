import { addDays, format, isAfter } from 'date-fns';
import dayjs from 'dayjs';

const formatDateToDayMonthYear = (date: string | Date) => dayjs(date).format('ddd - MMM D, YYYY');

const formatDateToMonthDayYear = (date: string | Date) => dayjs(date).format('dddd, MMMM D, YYYY');

const formatCustomDate = (date: string | Date, format: string) => dayjs(date).format(format);

const formatHours = (date: string | Date) => dayjs(date).format('hh:mma');

const formatInsightTime = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'EEE, MMM dd @ hh:mm aa');

const formatInsightTimeWithYear = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'EEE, MMM dd yyyy @ hh:mm aa');

const formatLinkTime = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'MMMM dd, yyyy');

const getEndTime = (date: string | Date, duration: number) =>
  dayjs(date).add(duration, 'minutes').toString();

const validateDate = (date: string, invalidDateMessage: string) => {
  if (date.toLowerCase() === 'invalid date') {
    return invalidDateMessage;
  }

  return date;
};

const formatMonthShort = (date: string | Date) =>
  validateDate(dayjs(date).format('MMM').toUpperCase(), 'No date');

const formatDayNumber = (date: string | Date) => validateDate(dayjs(date).format('D'), '0');

const getDayOfWeekName = (dayOfWeek: string) => {
  switch (dayOfWeek) {
    case '1':
      return 'Sunday';
    case '2':
      return 'Monday';
    case '3':
      return 'Tuesday';
    case '4':
      return 'Wednesday';
    case '5':
      return 'Thursday';
    case '6':
      return 'Friday';
    case '7':
      return 'Saturday';
    default:
      return 'Day of week not found';
  }
};

export const setToZeroSeconds = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setToLastSecond = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date;
};

const sortDates = (args: [Date, Date]): [Date, Date] => {
  const [firstDate, secondDate] = args;
  if (isAfter(firstDate, secondDate)) {
    return [secondDate, firstDate];
  }

  return [firstDate, secondDate];
};

export const generateIntervalFromToday = (days: number): [Date, Date] => {
  const lower = new Date();
  const upper = addDays(lower, days);
  const [firstDate, secondDate] = sortDates([lower, upper]);
  return [setToZeroSeconds(firstDate), setToLastSecond(secondDate)];
};

const getTodayDateRange = () => {
  const startToday = new Date();
  startToday.setUTCHours(0, 0, 0, 0);
  const endToday = new Date();
  endToday.setUTCHours(23, 59, 59, 999);
  return {
    from: startToday.toISOString(),
    to: endToday.toISOString(),
  };
};

const getWeekDateRange = () => {
  const curr = new Date();
  const first = curr.getDate() - curr.getDay();
  const firstDayWeek = new Date(curr.setDate(first));
  firstDayWeek.setUTCHours(0, 0, 0, 0);

  const last = first + 6;
  const lastDayWeek = new Date(curr.setDate(last));
  lastDayWeek.setUTCHours(23, 59, 59, 999);

  return {
    from: firstDayWeek.toISOString(),
    to: lastDayWeek.toISOString(),
  };
};

const stripMils = (timeString: string) => {
  const time = timeString.split('.');
  return `${time[0]}Z`;
};

const secondsToHms = (d: number) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '00:';
  const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : '00:';
  const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
  return hDisplay + mDisplay + sDisplay;
};

export {
  secondsToHms,
  getDayOfWeekName,
  sortDates,
  formatDayNumber,
  formatCustomDate,
  formatDateToMonthDayYear,
  formatInsightTime,
  formatMonthShort,
  formatDateToDayMonthYear,
  formatHours,
  getEndTime,
  getTodayDateRange,
  getWeekDateRange,
  stripMils,
  formatLinkTime,
  formatInsightTimeWithYear,
};
