import React from 'react';
import { PropsWithChildren } from 'react';
import Styles from './styles';

const Header = (props: PropsWithChildren<React.ReactNode>) => {
  return <Styles.Header>{props.children}</Styles.Header>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeaderCell = (props: any) => {
  return (
    <Styles.HeaderCell>
      <div style={{ color: '#6A6A6A' }}>{props.children}</div>
    </Styles.HeaderCell>
  );
};

const Row = (props: PropsWithChildren<React.ReactNode>) => {
  return <Styles.Row>{props.children}</Styles.Row>;
};

const Cell = (props: PropsWithChildren<React.ReactNode>) => {
  return <Styles.Cell>{props.children}</Styles.Cell>;
};

export { Header, HeaderCell, Row, Cell };
