import { Button, Modal } from 'antd';
import { GenerateShareLink } from './GenerateShareLink';
import { ManageShareLink } from './ManageShareLink';
import { EditShareLink } from './EditShareLink';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { IAuthorizationMechanism } from './types';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { ILink } from '../../../../services/types';
import React, { useState } from 'react';
import share from '../../../../assets/svg/shareFrame.svg';

import Styled from './styles';
const { Container } = Styled;

enum Tabs {
  generate = 'GENERATE',
  manage = 'MANAGE',
  edit = 'EDIT',
}

export const initialState = {
  days: 100,
  description: '',
  authorizedUsers: [],
  mechanism: IAuthorizationMechanism.public,
};

export const ShareBox: React.FC = () => {
  const [value, setValue] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.generate);
  const [selectedLink, setSelectedLink] = useState<(ILink & { edit: boolean }) | null>(null);
  const { id, participantsType, permissions } = useRecapManagement();

  const modalContent = () => {
    switch (selectedTab) {
      case Tabs.generate:
        return (
          <GenerateShareLink
            toManageLinks={() => setSelectedTab(Tabs.manage)}
            recapId={id}
            value={value}
            setValue={setValue}
          />
        );
      case Tabs.manage:
        return (
          <ManageShareLink
            toGenerateLinks={() => setSelectedTab(Tabs.generate)}
            toLinksDetails={() => setSelectedTab(Tabs.edit)}
            selectLink={link => setSelectedLink(link)}
            isExternal={participantsType === 'external'}
            recapId={id}
          />
        );
      case Tabs.edit:
        return (
          <EditShareLink
            link={selectedLink}
            toManageLinks={() => setSelectedTab(Tabs.manage)}
            updateLink={setSelectedLink}
          />
        );
    }
  };

  return (
    <>
      <Container>
        <OptionalTooltipWrapper
          value="Only owners can share public links"
          display={!permissions.canManageShareLinks}
        >
          <Button
            type="default"
            onClick={() => setShowModal(true)}
            disabled={!permissions.canManageShareLinks}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <img src={share} /> Public Share
            </div>
          </Button>
        </OptionalTooltipWrapper>
      </Container>

      <Modal
        centered
        destroyOnClose
        width={700}
        footer={false}
        open={showModal}
        className={'noPaddingModal'}
        onCancel={() => {
          setShowModal(false);
          setSelectedTab(Tabs.generate);
          setSelectedLink(null);
          setValue(initialState);
        }}
      >
        {modalContent()}
      </Modal>
    </>
  );
};
