import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Link = styled.a`
  color: var(--Primary-500, #ff6c54);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.06px;
  text-decoration-line: underline;
`;

export default {
  Container,
  Link,
};
