export const useRedirectNavigation = () => {
  const setRedirect = () => {
    localStorage.setItem('update-redirect', window.location.pathname);
  };

  const cleanRedirect = () => {
    localStorage.removeItem('update-redirect');
  };

  const getRedirect = () => {
    return localStorage.getItem('update-redirect');
  };

  const hasRedirect = () => {
    return getRedirect() !== null;
  };

  const redirect = (): string => {
    const redirectPath = getRedirect();
    cleanRedirect();

    return redirectPath || '/';
  };

  return {
    setRedirect,
    hasRedirect,
    redirect,
  };
};
