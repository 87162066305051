import { PropsWithChildren, createContext, useRef } from 'react';
import { AutomaticPlayStore, createAutomaticPlayStore } from './automatic-play.store';

interface AutomaticPlayContext {
  store: AutomaticPlayStore;
}

export const automaticPlayContext = createContext<AutomaticPlayContext>({
  store: createAutomaticPlayStore(),
});

export const AutomaticPlayProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createAutomaticPlayStore()).current;

  return (
    <automaticPlayContext.Provider value={{ store }}>{children}</automaticPlayContext.Provider>
  );
};
