import './logo.css';

const frames = [
  { path: null, rule: false },
  {
    path: 'M24.3098 112.108L24 111.931V32L10 40.0346V119.965L10.2207 120.092L24.3098 112.108Z',
  },
  {
    path: 'M80 160V143.931L24 111.931V32L10 40.0346V119.965L80 160Z',
  },
  {
    path: 'M149.779 120.092L135.69 112.108L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L149.779 120.092Z',
  },
  {
    path: 'M150 46.85L136 70.65V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.85Z',
  },
  {
    path: 'M110.8 68.9533L124.8 76.8867V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V68.9533Z',
  },
  {
    path: 'M110.8 97.45L124.8 105.325V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.45Z',
  },
  {
    path: 'M80 115.117V131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117Z',
  },
  {
    path: 'M49.3589 97.6146L35.2 105.241V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117L49.3589 97.6146Z',
  },
  {
    path: 'M49.2 35.6587L35.2893 25.5767L35.2 25.6277V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0347V119.965L80 160L150 119.965V46.407L110.8 68.8485V97.5238L80 115.117L49.2 97.5238V35.6587Z',
  },
  {
    path: 'M79.9998 0.00012207L35.2 25.6277V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117L49.2 97.5238V33.5238L79.9999 15.9308L79.9998 0.00012207Z',
  },
  {
    path: 'M119.097 22.5H91.0977L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L119.097 22.5Z',
  },
  {
    path: 'M60.4 40.1612V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.7101 47.7543L60.4 40.1612Z',
  },
  {
    path: 'M60.4702 91.1917L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.093L60.4702 91.1917Z',
  },
  {
    path: 'M99.6 90.9938V91.1515L80 102.372L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.1169L80 86.4416L85.5984 83.1178L99.6 90.9938Z',
  },
  {
    path: 'M99.6 62.5783V91.1515L80 102.372L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.1169L80 86.4416L85.6 83.1169V54.6652L99.6 62.5783Z',
  },
  {
    path: 'M99.6 62.3377L119.2 51.2554L105.2 43.2208L85.6 54.4416V83.1169L80 86.4416L74.4 83.1169V47.9307L119.2 22.4416L80 0L35.2 25.6277V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117L49.2 97.5238V33.5238L80 15.9307L91.2 22.4416L60.4 40.0346V91.1515L80 102.372L99.6 91.1515V62.3377Z',
  },
  {
    path: 'M119.2 51.2554L99.6 62.3377V91.1515L80 102.372L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.1169L80 86.4416L85.6 83.1169V54.4416L105.2 43.2208L119.2 51.2554ZM144.4 36.8486L130.4 44.7447L116.4 36.8486L130.4 28.814L144.4 36.8486Z',
  },
  {
    path: 'M99.6 62.5783V91.1515L80 102.372L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.1169L80 86.4416L85.6 83.1169V54.6652L99.6 62.5783Z',
    rule: false,
  },
  {
    path: 'M99.6 90.9938V91.1515L80 102.372L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.1169L80 86.4416L85.5984 83.1178L99.6 90.9938Z',
    rule: false,
  },
  {
    path: 'M60.4702 91.1917L60.4 91.1515V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.4 47.9307V83.093L60.4702 91.1917Z',
    rule: false,
  },
  {
    path: 'M60.4 40.1612V40.0346L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L74.7101 47.7543L60.4 40.1612Z',
    rule: false,
  },
  {
    path: 'M119.097 22.5H91.0977L91.2 22.4416L80 15.9307L49.2 33.5238V97.5238L80 115.117L110.8 97.5238V68.8485L150 46.4069V119.965L80 160L10 119.965V40.0346L24 32V111.931L80 143.931L136 111.931V70.3723L124.8 76.7446V105.558L80 131.186L35.2 105.558V25.6277L80 0L119.2 22.4416L119.097 22.5Z',
    rule: false,
  },
  {
    path: 'M79.9998 0.00012207L35.2 25.6277V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117L49.2 97.5238V33.5238L79.9999 15.9308L79.9998 0.00012207Z',
    rule: false,
  },
  {
    path: 'M49.2 35.6587L35.2893 25.5767L35.2 25.6277V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0347V119.965L80 160L150 119.965V46.407L110.8 68.8485V97.5238L80 115.117L49.2 97.5238V35.6587Z',
    rule: false,
  },
  {
    path: 'M49.3589 97.6146L35.2 105.241V105.558L80 131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117L49.3589 97.6146Z',
    rule: false,
  },
  {
    path: 'M80 115.117V131.186L124.8 105.558V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.5238L80 115.117Z',
    rule: false,
  },
  {
    path: 'M110.8 97.45L124.8 105.325V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V97.45Z',
    rule: false,
  },
  {
    path: 'M110.8 68.9533L124.8 76.8867V76.7446L136 70.3723V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.4069L110.8 68.8485V68.9533Z',
    rule: false,
  },
  {
    path: 'M150 46.85L136 70.65V111.931L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L150 119.965V46.85Z',
    rule: false,
  },
  {
    path: 'M149.779 120.092L135.69 112.108L80 143.931L24 111.931V32L10 40.0346V119.965L80 160L149.779 120.092Z',
    rule: false,
  },
  {
    path: 'M80 160V143.931L24 111.931V32L10 40.0346V119.965L80 160Z',
    rule: false,
  },
  {
    path: 'M24.3098 112.108L24 111.931V32L10 40.0346V119.965L10.2207 120.092L24.3098 112.108Z',
    rule: false,
  },
  { path: null, rule: false },
];

const LogoSpinner = () => (
  <svg
    version="1.1"
    id="logosvg"
    viewBox="0 0 160 160"
    className="animated-logo"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {frames.map(({ path, rule = true }, index) => (
      <g id={`frame${index + 1}`} key={index}>
        {path && <path
          {...rule && {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
          }}
          d={path}
          fill="#FF6C54"
        />}
      </g>
    ))}
  </svg>
);

export default LogoSpinner;
