import { useEffect, useMemo, useRef, useState } from 'react';

export const useVideo = () => {
  const [status, setStatus] = useState<'playing' | 'paused' | 'stopped'>('stopped');
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(0);
  const videoRef = useRef<HTMLVideoElement | HTMLAudioElement | null>(null);
  const [duration, setDuration] = useState(0);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [ready, setReady] = useState(false);

  const mute = () => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const unmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const changeVideoCurrentTime = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      setVideoCurrentTime(time);
    }
  };

  const play = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        if (videoRef.current) {
          mute();
          videoRef.current.play().then(() => {
            /**
             * We don't try to unmute it anymore because if
             * it fails then Chrome pauses the video, leading
             * to an undesired UX.
             */
            // unmute();
          });
        }
      });
    }
  };

  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const isPaused = useMemo(() => {
    return status === 'paused' || status === 'stopped';
  }, [status]);

  const isPlaying = useMemo(() => {
    return status === 'playing';
  }, [status]);

  const setVideoReference = (ref: HTMLVideoElement | HTMLAudioElement | null) => {
    videoRef.current = ref;
  };

  const playListener = () => {
    setStatus('playing');
  };

  const pauseListener = () => {
    setStatus('paused');
  };

  const endedListener = () => {
    setStatus('stopped');
  };

  const loadedMetadataListener = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      setDuration(duration);
    }
  };

  const timeUpdateListener = () => {
    if (videoRef.current) {
      setVideoCurrentTime(videoRef.current.currentTime);
    }
  };

  const canPlayListener = () => {
    if (videoRef.current) {
      setReady(true);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', timeUpdateListener);
      videoRef.current.addEventListener('play', playListener);
      videoRef.current.addEventListener('pause', pauseListener);
      videoRef.current.addEventListener('ended', endedListener);
      videoRef.current.addEventListener('loadedmetadata', loadedMetadataListener);
      videoRef.current.addEventListener('canplay', canPlayListener);

      setVolume(videoRef.current.volume);
      setIsMuted(videoRef.current.muted);
      setPlaybackRate(videoRef.current.playbackRate);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', timeUpdateListener);
        videoRef.current.removeEventListener('play', playListener);
        videoRef.current.removeEventListener('pause', pauseListener);
        videoRef.current.removeEventListener('ended', endedListener);
        videoRef.current.removeEventListener('loadedmetadata', loadedMetadataListener);
        videoRef.current.removeEventListener('canplay', canPlayListener);
      }
    };
  }, [videoRef.current]);

  const changePlaybackRate = (rate: number) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  const changeVolume = (volume: number) => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
      setVolume(volume);
    }
  };

  return {
    changeVideoCurrentTime,
    videoCurrentTime,
    setVideoReference,
    changePlaybackRate,
    isPaused,
    changeVolume,
    isPlaying,
    play,
    pause,
    isMuted,
    mute,
    ready,
    unmute,
    duration,
    playbackRate,
    volume,
    status,
    videoRef,
  };
};
