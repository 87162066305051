import React, { PropsWithChildren } from 'react';
import Styles from './styles';
import { LeftOutlined } from '@ant-design/icons';
import { ClearOptions } from '../types';

const { ClearAll, Container, Header, Title, Contents } = Styles;

interface Props {
  clear?: ClearOptions;
  title: string;
  panelId: string;
  onBack?: () => unknown;
}

export const Panel: React.FC<PropsWithChildren<Props>> = ({
  clear,
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  panelId,
  onBack,
  children,
}) => {
  const showBack = typeof onBack !== 'undefined';
  const showClear = typeof clear !== 'undefined';

  const onClearClicked = () => {
    if (clear && !clear.disabled) {
      clear.onClear();
    }
  };

  return (
    <Container>
      <Header>
        {showBack && <LeftOutlined onClick={onBack} />}
        <Title>{title}</Title>
        {showClear && <ClearAll disabled={clear.disabled} onClick={onClearClicked} />}
      </Header>
      <Contents>{children}</Contents>
    </Container>
  );
};

Panel.displayName = 'Panel';
