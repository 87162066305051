import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 250px);
  width: 100%;
  gap: 20px;
`;

const PaginationFooter = styled.span`
  color: var(--gray-600, #818181);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const PaginationFooterBold = styled.span`
  color: var(--gray-950, #282828);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

export default { Container, PaginationFooter, PaginationFooterBold, Filters };
