import React from 'react';
import { Avatar as AntAvatar, AvatarProps } from 'antd';

interface Props {
  background?: string;
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformProps = (props: any): AvatarProps => {
  const copy = { ...props };
  delete copy.inverted;
  return copy;
};

const Avatar: React.FC<Props & AvatarProps> = props => {
  const background = props.background || '#EBE7FF';
  const color = props.color || '#5E16EB';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const avatarProps = transformProps(props);
  return <AntAvatar {...avatarProps} style={{ backgroundColor: background, color }} />;
};

export default Avatar;
