import React from 'react';
import Styles from './styles';
import { FolderContents } from '../FolderContents';
import { FolderNavigation } from '../FolderNavigation';
import { useLibraries } from '../../../../features/libraries/hooks/useLibraries';

const { Container } = Styles;

interface Props {
  mainFolderName: string;
}

export const Library: React.FC<Props> = ({ mainFolderName }) => {
  const { folder } = useLibraries();
  const title = folder ? folder.name : 'Recent clips';

  return (
    <Container>
      <FolderNavigation mainFolderName={mainFolderName} />
      <FolderContents title={title} />
    </Container>
  );
};
