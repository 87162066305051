import { Button } from 'antd';
import { AskMeAnythingProvider } from '../../../../features/askMeAnything/context/AskMeAnythingProvider';
import { AskMeAnything } from '../../../../features/shared/components/AskMeAnything';
import { useAskMeAnything } from '../../../../features/askMeAnything/hooks/useAskMeAnything';
import { ChatItem } from '../../../../services/types';
import { Questions } from '../../../../features/askMeAnything/questions';
import { useFavouriteAccount } from '../../../../features/account/hooks/useFavouriteAccount';
import { useContext } from 'react';
import { accountFavouritesContext } from '../../../../features/account/account-favourites/account-favourites.context';

interface Props {
  id?: string;
  selected: { id: string; type: 'ai' | 'views' } | null;
  handleExpand: (id: string, type: 'ai' | 'views') => void;
}

export const FavouritedStatus: React.FC<Props> = ({ id, selected, handleExpand }) => {
  const { askAccounts } = useAskMeAnything();
  const useStore = useContext(accountFavouritesContext);
  const favourited = useStore();
  const { favouriteButton } = useFavouriteAccount(id || ' ', true, () =>
    favourited.removeFavourite(id || ''),
  );

  if (!id) return <></>;

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <Button
        onClick={() => {
          handleExpand(id, 'views');
        }}
        style={{ width: '110px' }}
      >
        {selected?.id === id && selected?.type === 'views' ? 'Hide Story' : 'Show Story'}
      </Button>

      <Button
        type="primary"
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '50px',
          justifyContent: 'center',
        }}
      >
        <AskMeAnythingProvider
          amaKey={id}
          suggestedQuestions={{
            data: Questions.accounts,
            category: 'all',
            displayDropdown: false,
          }}
        >
          <AskMeAnything
            customText=" "
            location="accounts"
            askFunction={(chatMessages: ChatItem[]) => askAccounts(chatMessages, id, null, null)}
            styles={{ width: '50px' }}
            disabled={false}
          />
        </AskMeAnythingProvider>
      </Button>

      {favouriteButton}
    </div>
  );
};
