import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const PublicRecapSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.2446 4.32958C12.5433 3.32891 11.5712 2.54907 10.4422 2.08158C11.075 3.1949 11.5242 4.40292 11.7726 5.65918C12.3253 5.28989 12.8212 4.84198 13.2446 4.32958ZM10.6614 6.25998C10.3905 4.59 9.72911 3.00759 8.731 1.64158C8.24563 1.58643 7.75557 1.58643 7.2702 1.64158C6.27209 3.00759 5.61067 4.59 5.3398 6.25998C6.18124 6.61758 7.08633 6.80127 8.0006 6.79998C8.9446 6.79998 9.8438 6.60798 10.6614 6.25998ZM5.2118 7.50078C6.10421 7.8321 7.04867 8.00117 8.0006 7.99998C8.9814 7.99998 9.9206 7.82398 10.7894 7.50078C10.8367 8.51138 10.7422 9.52361 10.5086 10.508C9.68684 10.7028 8.84513 10.8007 8.0006 10.8C7.1374 10.8 6.2982 10.6984 5.4934 10.5072C5.25961 9.52311 5.16482 8.51115 5.2118 7.50078ZM4.2286 5.65918C4.4771 4.4032 4.92632 3.19546 5.559 2.08238C4.43004 2.54987 3.45786 3.32971 2.7566 4.33038C3.1798 4.84078 3.6758 5.29038 4.2286 5.65918ZM13.8678 5.43838C14.32 6.4729 14.4895 7.60891 14.359 8.73038C13.5772 9.30116 12.722 9.76399 11.8166 10.1064C12.0006 9.06659 12.0474 8.00715 11.9558 6.95518C12.6667 6.5499 13.3114 6.0384 13.8678 5.43838ZM2.1334 5.43838C2.68977 6.03841 3.33454 6.54991 4.0454 6.95518C3.95468 8.00743 4.00148 9.06702 4.1846 10.1072C3.27919 9.76477 2.42403 9.30194 1.6422 8.73118C1.51194 7.60964 1.68172 6.47363 2.1342 5.43918L2.1334 5.43838ZM8.0006 12C8.719 12 9.423 11.9368 10.107 11.816C9.76458 12.7214 9.30175 13.5765 8.731 14.3584C8.24562 14.4134 7.75558 14.4134 7.2702 14.3584C6.69942 13.5766 6.23659 12.7214 5.8942 11.816C6.5782 11.9368 7.2822 12 8.0006 12ZM11.4862 11.4856C11.2295 12.3325 10.8793 13.1481 10.4422 13.9176C11.2215 13.5949 11.9296 13.1218 12.526 12.5254C13.1224 11.9289 13.5955 11.2209 13.9182 10.4416C13.1558 10.8752 12.3414 11.2264 11.4862 11.4856ZM5.559 13.9176C4.77972 13.5949 4.07164 13.1218 3.47522 12.5254C2.8788 11.9289 2.40572 11.2209 2.083 10.4416C2.8454 10.8752 3.6598 11.2264 4.515 11.4856C4.77172 12.3325 5.12188 13.1481 5.559 13.9176Z"
        fill="#21A6FF"
      />
    </svg>
  );
};
export const PublicRecap: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={PublicRecapSVG} {...props} />;
};
