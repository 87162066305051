import styled from 'styled-components';

const Container = styled.div`
  padding: 32px 32px 0px 32px;
  height: 100%;
`;

const Title = styled.h1`
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  color: #282828;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const Contents = styled.div<{ hasDescription: boolean }>`
  margin-top: 24px;
  margin-bottom: 24px;
  height: ${({ hasDescription }) => (hasDescription ? 'calc(100% - 125px)' : 'calc(100% - 100px)')};
`;

const LayoutBody = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  height: 30px;
`;

export const TitleDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  align-self: stretch;
  padding: 4px 0px;
  height: 45px;
`;

export default {
  Container,
  Title,
  Contents,
  LayoutBody,
  Header,
  TitleDescription,
};
