import React from 'react';
import AuthenticationLayout from '../../componentsV2/Layouts/AuthenticationLayout';
import ForgetPasswordForm from './components/ForgotPasswordForm';
import { useNavigate } from 'react-router-dom';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  useLocationChange();

  return (
    <AuthenticationLayout
      leftSide={<ForgetPasswordForm onBack={() => navigate('/signin')} />}
      rightSide={<p></p>}
    />
  );
};

export default ForgotPasswordPage;
