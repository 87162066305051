import { useEffect, useMemo, useState } from 'react';
import { organizationMembersStore } from '../../../stores/organization-members';
import { useOrganizationDetails } from './useOrganizationDetails';
import {
  changeMemberRole,
  changeMemberType,
  removeMember,
  fetchMembers,
} from '../organizations.service';
import { Member } from '../types';

export const useOrganizationMembers = () => {
  const { isFetching, fetchError } = useOrganizationDetails();
  const { members: allMembers } = organizationMembersStore();

  const [members, setInternalMembers] = useState(allMembers);

  const admins = useMemo(() => {
    const sortedMembers: Member[] = [];
    const owner = members.find(member => member.role === 'OWNER');
    const admins = members.filter(member => member.role === 'ADMIN');

    if (owner) {
      sortedMembers.push(owner);
    }

    sortedMembers.push(...admins);
    return sortedMembers;
  }, [members]);

  const setMembers = (members: Member[]) => {
    setInternalMembers(
      [...members].sort((a, b) => {
        const result = a.user.firstName.localeCompare(b.user.firstName);
        if (result === 0) return a.user.lastName.localeCompare(b.user.lastName);
        return result;
      }),
    );
  };

  const filterMembers = (s: string | null) => {
    if (!s || s === '') {
      setMembers(allMembers);
    } else {
      setMembers(
        allMembers.filter(m =>
          (m.user.firstName + ' ' + m.user.lastName).toUpperCase().includes(s.toUpperCase()),
        ),
      );
    }
  };

  const membersCount = members.length;

  useEffect(() => {
    setMembers(allMembers);
  }, [allMembers]);

  return {
    isFetching,
    members,
    admins,
    membersCount,
    fetchError,
    fetchMembers,
    changeMemberRole,
    changeMemberType,
    removeMember,
    filterMembers,
  };
};
