import { MessageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { TabDefinition } from '../../../shared/components/Tab/Tabs';
import { Chat } from '../Chat';
import { Space } from 'antd';
import { Transcript } from '../Transcript';

export const chatTab: TabDefinition = {
  children: <Chat />,
  key: 'chat',
  label: (
    <Space>
      <MessageOutlined style={{ fontSize: '16px' }} />
      Chat text from zoom
    </Space>
  ),
};

export const transcriptTab: TabDefinition = {
  children: <Transcript />,
  key: 'transcript',
  label: (
    <Space>
      <VideoCameraOutlined style={{ fontSize: '16px' }} />
      Video transcript
    </Space>
  ),
};
