import styled from 'styled-components';
import { colors } from '../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background-color: ${colors.white};
  border-radius: 12px;
  gap: 8px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  justify-content: center;
  color: ${colors.coolGray[400]};

  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 8px;
  margin-bottom: 10px;
  white-space: pre-wrap;

  & span {
    color: ${colors.coolGray[700]};
    font-weight: 500;
    margin-top: 2.5px;
  }
`;

const Fade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${colors.white} 79%);
  height: 100px;
  width: calc(100% - 5px);
  bottom: 0;
  left: 0;
  border-radius: 0 0 12px 12px;
`;

const EmptyView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    text-align: center;
    font-size: 12px;
    color: var(--Cool-Gray-400, #566976);
  }
`;

export default { Container, Content, Fade, EmptyView };
