type QuestionType = {
  title: string;
  question: string;
};

type CategoryType = {
  [k: string]: QuestionType[];
};

type DropdownType = {
  value: string;
  label: string;
}[];

export type SuggestedQuestionsType = {
  type: DropdownType;
  questions: CategoryType;
};

export const Questions: {
  recap: SuggestedQuestionsType;
  iaDashboard: SuggestedQuestionsType;
  accounts: SuggestedQuestionsType;
} = {
  recap: {
    type: [
      { value: 'none', label: 'None' },
      { value: 'sales', label: 'Sales / Pre Kickoff' },
      { value: 'onboarding', label: 'Onboarding & Implementation' },
      { value: 'review', label: 'Strategic Review' },
      { value: 'touchbase', label: 'General Touchbase' },
      { value: 'issue', label: 'Issue or Escalation' },
    ],
    questions: {
      sales: [
        {
          title: 'Business Needs',
          question: 'What are the key business challenges or needs the client expressed?',
        },
        {
          title: 'Value Proposition',
          question:
            'How did we position our product/service as a solution to their needs? Any specific features or benefits highlighted?',
        },
        {
          title: 'Interest Level',
          question:
            'How interested did the client seem in our offering? What were their initial reactions or feedback?',
        },
        {
          title: 'Objections or Concerns',
          question: 'What objections or concerns did the client raise? How did we address them?',
        },
      ],
      onboarding: [
        {
          title: 'Adoption',
          question: 'What is preventing users from using the product?',
        },
        {
          title: 'Expansion',
          question: 'Who seem to be our biggest advocates?',
        },
        {
          title: 'Feedback',
          question: 'What is the client’s early impression of our product?',
        },
        {
          title: 'Next Steps',
          question: 'What are the agreed upon next steps?',
        },
      ],
      review: [
        {
          title: 'Goal Setting',
          question: 'Are the client’s goals and objectives being met?',
        },
        {
          title: 'Risk Factors',
          question:
            'Are there any potential risk factors or signs of dissatisfaction that we need to mitigate?',
        },
        {
          title: 'Expansion',
          question:
            'Did we identify any opportunities for upselling or cross-selling during the conversation?',
        },
        {
          title: 'New Challenges',
          question:
            'Did the client mention any new challenges or changes in their business that we need to address?',
        },
      ],
      touchbase: [
        {
          title: 'Product Feedback',
          question: 'Did the client have any product feedback or requests?',
        },
        {
          title: 'Service Delivery',
          question: 'Did the client provide any feedback about our team or service delivery?',
        },
        {
          title: 'Competition',
          question: 'Did the client mention any other competitive product or service?',
        },
        {
          title: 'Next Steps',
          question: 'What are the next steps in terms of follow-up or ongoing support?',
        },
      ],
      issue: [
        {
          title: 'Issue Details',
          question: 'Can you summarize the specific issue or complaint raised by the client?',
        },
        {
          title: 'Client Follow-up',
          question:
            "What's the plan for following up with the client? Any specific commitments made?",
        },
        {
          title: 'Client Disposition',
          question:
            'What was the client’s emotional tone and how did it change throughout the course of the conversation?',
        },
        {
          title: 'Next Steps',
          question: 'What are the next steps in terms of follow-up or ongoing support?',
        },
      ],
      none: [
        {
          title: 'Risk Factors',
          question:
            'Are there any potential risk factors or signs of dissatisfaction that we need to mitigate?',
        },
        {
          title: 'Product Feedback',
          question: 'Did the client have any product feedback or requests?',
        },
        {
          title: 'Expansion',
          question:
            'Did we identify any opportunities for upselling or cross-selling during the conversation?',
        },
        {
          title: 'Next Steps',
          question: 'What are the agreed upon next steps?',
        },
      ],
    },
  },
  iaDashboard: {
    type: [
      { value: 'Risks', label: 'Risks' },
      { value: 'Product Feedback', label: 'Product Feedback' },
    ],
    questions: {
      Risks: [
        {
          title: 'Emotional Tone',
          question: 'What was the emotional tone of these conversations?',
        },
        {
          title: 'Learning and Adaptation',
          question:
            'What can we learn from these conversations to improve our product, service, or client communication strategies?',
        },
        {
          title: 'Suggestions and Requests',
          question:
            'Did clients make any specific suggestions or requests for changes, improvements, or additional support?',
        },
        {
          title: 'Perception of Value',
          question:
            'How do clients perceive the value of our offerings? Are there gaps between their expectations and what we deliver?',
        },
      ],
      'Product Feedback': [
        {
          title: 'Themes: ',
          question:
            'Are there specific features, functionalities, or aspects that are consistently highlighted, either positively or negatively?',
        },
        {
          title: 'Client Experience',
          question: 'How is the current product design impacting the overall client experience?',
        },
        {
          title: 'Suggestions and Requests',
          question:
            'Did clients make any specific suggestions or requests for changes, improvements, or additional support?',
        },
        {
          title: 'Competition',
          question: 'What references did the client make about the products of our competitors?',
        },
      ],
    },
  },
  accounts: {
    type: [],
    questions: {
      all: [
        {
          title: 'Success Stories',
          question: 'Are there any key milestones or success stories?',
        },
        {
          title: 'Client Needs',
          question: 'Have the client’s needs or business priorities shifted over this period?',
        },
        {
          title: 'Upsell or Expansion',
          question:
            'Are there opportunities for upselling or expanding the client’s use of our services?',
        },
        {
          title: 'Product Improvement',
          question:
            'Are there any suggestions or requests from the client that could inform our product development or feature roadmap?',
        },
      ],
    },
  },
};
