import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  .ant-input {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Label = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
`;
export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6a6a6a;
`;
