import React from 'react';
import { AccountFavouritesProvider } from '../../features/account/account-favourites/account-favourites.context';
import { AccountFavouritesPage } from './page';

export const AccountFavourites: React.FC = () => {
  return (
    <AccountFavouritesProvider>
      <AccountFavouritesPage />
    </AccountFavouritesProvider>
  );
};
