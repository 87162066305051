import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
`;

export const Bar = styled.div`
  height: 6px;
  border-radius: 4px;
  flex: 1;
`;
