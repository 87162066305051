import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { Category } from '../../types';
import { insightCategoriesStore } from '../../stores/insights-categories';
import { v4 } from 'uuid';
import { useInsightCategories } from '../../hooks/useInsightCategories';
import { useQuery } from '@tanstack/react-query';
import { useToast } from '../../../../hooks/useToast';
import { coreService } from '../../../../services/core/core-service';
import { success } from '../../../../assets/colors';

interface CategoryToManage extends Category {
  isNew: boolean;
  isEditingTitle: boolean;
  isEditingDescription: boolean;
}

interface CategoryManagementContext {
  categories: CategoryToManage[];
  add: () => unknown;
  hasModifications: boolean;
  startDescriptionEditing: (id: string) => unknown;
  startNameEditing: (id: string) => unknown;
  confirmChanges: (id: string, callback: (valid: boolean) => unknown) => unknown;
  confirmNameChange: (id: string) => unknown;
  confirmDescriptionChange: (id: string) => unknown;
  changeName: (id: string, name: string) => unknown;
  changeDescription: (id: string, name: string) => unknown;
  discardChanges: () => unknown;
  saveChanges: () => unknown;
  remove: (id: string) => unknown;
  isSaving: boolean;
}

export const categoryManagementContext = createContext<CategoryManagementContext>({
  categories: [],
  add: () => null,
  remove: () => null,
  startDescriptionEditing: () => null,
  confirmNameChange: () => null,
  confirmDescriptionChange: () => null,
  confirmChanges: () => null,
  startNameEditing: () => null,
  changeName: () => null,
  changeDescription: () => null,
  discardChanges: () => null,
  saveChanges: () => null,
  isSaving: false,
  hasModifications: false,
});

export const CategoryManagementProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [categoriesToManage, setCategoriesToManage] = useState<CategoryToManage[]>([]);
  const { categories, refetch, type } = useInsightCategories();
  const [isSaving, setIsSaving] = useState(false);
  const { error, success } = useToast();

  useEffect(() => {
    if (categories) {
      setCategoriesToManage(
        categories.map(category => ({
          ...category,
          isEditingDescription: false,
          isEditingTitle: false,
          isNew: false,
        })),
      );
    }
  }, [categories]);

  const startDescriptionEditing = (id: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        category.isEditingDescription = true;
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const startNameEditing = (id: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        category.isEditingTitle = true;
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const changeName = (id: string, name: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        category.name = name;
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const changeDescription = (id: string, description: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        category.description = description;
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const discardChanges = () => {
    setCategoriesToManage(
      categories.map(value => {
        return {
          ...value,
          isEditingDescription: false,
          isEditingTitle: false,
          isNew: false,
        };
      }),
    );
  };

  const add = () => {
    const newCat = [...categoriesToManage];
    newCat.push({
      id: v4(),
      description: 'No description',
      isEditingDescription: false,
      isEditingTitle: false,
      isNew: true,
      mutable: true,
      name: 'Default name',
    });
    setCategoriesToManage(newCat);
  };

  const confirmChanges = (id: string, callback: (valid: boolean) => unknown) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        if (category.isNew) {
          if (!category.name) {
            callback(false);
          } else {
            category.isNew = false;
            callback(true);
          }
        }
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const saveChanges = async () => {
    setIsSaving(true);
    const categories = categoriesToManage.map(catMan => {
      return {
        id: catMan.id,
        description: catMan.description,
        name: catMan.name,
      };
    });
    await coreService
      .updateInsightCategories({ type, categories })
      .then(() => {
        success('Categories saved successfully');
        refetch();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const remove = (id: string) => {
    setCategoriesToManage(categoriesToManage.filter(category => category.id !== id));
  };

  const confirmNameChange = (id: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        if (!category.name) {
          error('Name cannot be empty');
        } else {
          category.isEditingTitle = false;
        }
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const confirmDescriptionChange = (id: string) => {
    const newCat = [...categoriesToManage].map(category => {
      if (category.id === id) {
        category.isEditingDescription = false;
      }
      return category;
    });
    setCategoriesToManage(newCat);
  };

  const hasModifications = useMemo(() => {
    const newOrModifiedCount = categoriesToManage.filter(cat => {
      const exists = categories.find(c => c.id === cat.id);
      return (
        !Boolean(exists) || exists?.name !== cat.name || exists?.description !== cat.description
      );
    }).length;
    const removed = categories.filter(cat => {
      const exists = categoriesToManage.find(c => c.id === cat.id);
      return !Boolean(exists);
    }).length;
    return newOrModifiedCount + removed > 0;
  }, [categoriesToManage, categories]);

  return (
    <categoryManagementContext.Provider
      value={{
        categories: categoriesToManage,
        changeDescription,
        confirmDescriptionChange,
        confirmNameChange,
        changeName,
        discardChanges,
        remove,
        hasModifications,
        add,
        isSaving,
        saveChanges,
        startDescriptionEditing,
        startNameEditing,
        confirmChanges,
      }}
    >
      {children}
    </categoryManagementContext.Provider>
  );
};
