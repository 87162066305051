import React, { useEffect, useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useAskMeAnything } from '../../../../askMeAnything/hooks/useAskMeAnything';
import { ChatItem } from '../../../../../services/types';
import { AskMeAnythingChat } from '../AskMeAnythingChat';

import Styles from './styles';
const { MagicIconHeader, Header, Modal, HeaderContainer, Description } = Styles;

interface Props {
  open: boolean;
  location: string;
  description?: string;
  askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
  onClose?: () => unknown;
}

export const AskMeAnythingModal: React.FC<Props> = ({
  open,
  onClose,
  location,
  askFunction,
  description,
}) => {
  const { messages } = useAskMeAnything();
  const messageCount = useRef(0);

  useEffect(() => {
    const totalMessages = messages.length;
    if (totalMessages !== messageCount.current) {
      const lastMessagePosition = messages.length - 1;
      const lastMessageKey = `ama-${lastMessagePosition}`;
      document.getElementById(lastMessageKey)?.scrollIntoView();
      messageCount.current = totalMessages;
    }
  }, [messages]);

  return (
    <Modal
      centered
      width={'770px'}
      closable={false}
      bodyStyle={{ padding: '0px', minHeight: '430px' }}
      style={{ borderRadius: '8px', height: '730px' }}
      title={
        <HeaderContainer>
          <Header>
            <MagicIconHeader />
            <span style={{ flex: 1 }}>Ask me anything</span>
            <CloseOutlined
              onClick={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                if (onClose) onClose();
              }}
            />
          </Header>
          <Description>{description}</Description>
        </HeaderContainer>
      }
      footer={null}
      open={open}
    >
      <AskMeAnythingChat askFunction={askFunction} location={location} />
    </Modal>
  );
};
