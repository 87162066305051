import { produce } from 'immer';
import { Insight, PublicVisibilityConfig, Recap } from './types';
import { create } from 'zustand';
import { boolean } from 'yup/lib/locale';

export type RecapManagementState = {
  recap: Recap;
  isFetching: boolean;
  fetchError: string | null;
  fetchStarted: () => void;
  fetchEnded: () => void;
  fetchFailed: (reason: string) => void;
  recapLoaded: (recap: Recap) => void;
  titleChanged: (title: string) => void;
  workspaceVisibilityChanged: (visibility: string) => void;
  publicVisibilityChanged: (visibility: Partial<PublicVisibilityConfig>) => void;
  typeChanged: (meetingType: string) => void;
  insightAdded: (insight: Insight) => void;
  insightRemoved: (insight: Insight) => void;
  insightTextModified: (insightId: string, text: string) => void;
  insightIdReplaced: (oldId: string, newId: string) => void;
  exclusionFromInsightsEngineChanged: (excluded: boolean) => void;
  insightExclusionFromInsightsEngineChanged: (insightId: string, excluded: boolean) => void;
  insightRecategorized: (insightId: string, category: string) => void;
  reportProcessingStatusChanged: (reportId: string, isProcessing: boolean) => void;
};

export type RecapManagementStore = ReturnType<typeof createStore>;

export const createStore = () => {
  return create<RecapManagementState>(set => {
    return {
      isFetching: false,
      fetchError: null,
      fetchFailed: reason => set({ fetchError: reason }),
      recap: {
        isDemo: false,
        id: '',
        reports: [],
        participantsType: '',
        participants: [],
        followUp: null,
        isBasic: false,
        insights: [],
        transcript: [],
        playback: null,
        cropping: {
          start: 0,
          end: 0,
        },
        categories: {},
        title: '',
        type: '',
        account: null,
        speakerAnalytics: { speakers: [] },
        host: '',
        isOwner: false,
        isPublic: false,
        excludedFromInsightsEngine: false,
        scheduledEndDate: null,
        scheduledStartDate: null,
        started: null,
        ended: null,
        status: '',
        publicVisibilityConfig: {
          showActionItems: false,
          showAdvocacy: false,
          showImportantNumbers: false,
          showFeatureRequestFeedback: false,
          showProductFeedback: false,
          showRisks: false,
          showMeetingDetails: false,
          showTldr: false,
          showTranscript: false,
          showVideo: false,
        },
        workspaceVisibility: '',
        permissions: {
          canChangeMeetingTitle: false,
          canChangeMeetingTypes: false,
          canDeleteRecap: false,
          canGenerateReports: false,
          canManageInsightExclusion: false,
          canManageMeetingAccount: false,
          canManagePurposeStatement: false,
          canManageRecapExclusion: false,
          canManageShareLinks: false,
          canManageWorkspaceVisibility: false,
          canFavoriteRecap: false,
        },
      },
      fetchStarted: () => set({ isFetching: true }),
      fetchEnded: () => set({ isFetching: false }),
      recapLoaded: recap => set({ recap }),
      titleChanged: title => {
        set(state =>
          produce(state, draft => {
            draft.recap.title = title;
          }),
        );
      },
      workspaceVisibilityChanged: visibility => {
        set(state =>
          produce(state, draft => {
            draft.recap.workspaceVisibility = visibility;
          }),
        );
      },
      publicVisibilityChanged: visibility => {
        set(state =>
          produce(state, draft => {
            draft.recap.publicVisibilityConfig = Object.assign(
              {},
              draft.recap.publicVisibilityConfig,
              visibility,
            );
          }),
        );
      },
      typeChanged: type => {
        set(state =>
          produce(state, draft => {
            draft.recap.type = type;
          }),
        );
      },
      insightAdded: insight => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights.push(insight);
            draft.recap.categories[insight.category].count++;
          }),
        );
      },
      insightRemoved: insight => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights = draft.recap.insights.filter(ins => ins.id !== insight.id);
            draft.recap.categories[insight.category].count--;
          }),
        );
      },
      insightTextModified: (insightId, text) => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights = draft.recap.insights.map(ins => {
              if (ins.id === insightId) {
                ins.text = text;
              }
              return ins;
            });
          }),
        );
      },
      insightIdReplaced: (oldId, newId) => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights = draft.recap.insights.map(ins => {
              if (ins.id === oldId) {
                ins.id = newId;
              }
              return ins;
            });
          }),
        );
      },
      exclusionFromInsightsEngineChanged: excluded => {
        set(state =>
          produce(state, draft => {
            draft.recap.excludedFromInsightsEngine = excluded;
            draft.recap.insights = draft.recap.insights.map(insight => {
              insight.excludedFromInsightsEngine = excluded;
              return insight;
            });
          }),
        );
      },
      insightExclusionFromInsightsEngineChanged: (insightId, excluded) => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights = draft.recap.insights.map(ins => {
              if (ins.id === insightId) {
                ins.excludedFromInsightsEngine = excluded;
              }
              return ins;
            });
          }),
        );
      },
      insightRecategorized: (insightId, category) => {
        set(state =>
          produce(state, draft => {
            draft.recap.insights = draft.recap.insights.map(ins => {
              if (ins.id === insightId) {
                draft.recap.categories[ins.category].count--;
                draft.recap.categories[category].count++;
                ins.category = category;
              }
              return ins;
            });
          }),
        );
      },
      reportProcessingStatusChanged: (reportId, isProcessing) => {
        set(state =>
          produce(state, draft => {
            draft.recap.reports = draft.recap.reports.map(report => {
              if (report.id === reportId) {
                report.isProcessing = true;
              }
              return report;
            });
          }),
        );
      },
    };
  });
};
