import React from 'react';
import { Container, Email } from './style';
import Avatar from '../../../../../../../../features/shared/components/Avatar';

interface Props {
  email?: string;
  className?: string;
}

const EmailCell: React.FC<Props> = ({ email, className }) => {
  const firstLetter = email ? email[0] : '';
  return (
    <Container className={className}>
      <Avatar size={'large'}>{firstLetter}</Avatar>
      <Email>{email}</Email>
    </Container>
  );
};

export default EmailCell;
