import { PayloadAction } from '@reduxjs/toolkit';
import { put, putResolve, retry, select, takeLatest } from 'redux-saga/effects';
import { Credentials, IntegrationData, User } from '../typings/session';
import {
  login as loginAction,
  loginSuccess as loginSuccessAction,
  loginError as loginErrorAction,
  logoutUser as logoutAction,
  recoverPassword as recoverPasswordAction,
  recoverPasswordSuccess as recoverPasswordSuccessAction,
  resetPassword as resetPasswordAction,
  resetPasswordSuccess as resetPasswordSuccessAction,
  resetPasswordError as resetPasswordErrorAction,
  updateUser as updateUserAction,
  updateUserSuccess as updateUserSuccessAction,
  fetchUser as fetchUserAction,
  silentFetchUser as silentFetchUserAction,
  fetchUserSuccess as fetchUserSuccessAction,
  integrateZoom as integrateZoomAction,
  removeZoom as removeZoomAction,
  integrateZoomResponse as integrateZoomResponseAction,
  error as sessionErrorAction,
  integrateCalendar as integrateCalendarAction,
  removeCalendar as removeCalendarAction,
  integrateCalendarResponse as integrateCalendarResponseAction,
  integrateSlack as integrateSlackAction,
  removeSlack as removeSlackAction,
  integrateSlackResponse as integrateSlackResponseAction,
  integrateSalesforce as integrateSalesforceAction,
  removeSalesforce as removeSalesforceAction,
  integrateSalesforceResponse as integrateSalesforceResponseAction,
  integrateHubspot as integrateHubspotAction,
  removeHubspot as removeHubspotAction,
  integrateHubspotResponse as integrateHubspotResponseAction,
  updateFirstTimeUserSuccess,
  updateFirstTimeUser as updateFirstTimeUserAction,
} from '../slices/sessionSlice';
import { restoreInitialState as restoreRecapInitialState } from '../slices/recapSlice';
import { AxiosError } from 'axios';
import { coreAPI } from '../../API/core';
import {
  selectCalendarIntegration,
  selectSalesforceIntegration,
  selectSlackIntegration,
  selectUser,
  selectZoomIntegration,
  selectHubspotIntegration,
} from '../selectors';

const RETRY_TIMES = 2;
const INTERVAL_HALF_A_SECOND = 500;

function openWindow(url: string) {
  const popup = window.open(
    url,
    'popup_window',
    'height=800px,width=500px,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no',
  );
  if (popup) popup.moveTo(window.screen.width / 2 - 250, window.screen.height / 2 - 400);
}

// Login/Logout.
function* login(action: PayloadAction<Credentials>) {
  try {
    const API = new coreAPI();

    const { user } = yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.login, action.payload);
    yield put(loginSuccessAction({ user }));
  } catch (error) {
    yield put(loginErrorAction({ error: 'Invalid email or password.' }));
  }
}

function* logout() {
  try {
    const API = new coreAPI();
    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.logout);
    localStorage.removeItem('uai_home_upcoming_filters');
    localStorage.removeItem('uai_home_past_meetings_filters');
    yield put(restoreRecapInitialState());
  } catch (error) {}
}

// Password.
function* recoverPassword(action: PayloadAction<{ email: string; from: string }>) {
  try {
    const user: User = yield select(selectUser);
    const API = new coreAPI();

    if (user?.email === action.payload.email) {
      yield put(logoutAction());
    }

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.recoverPassword, action.payload);
    yield put(recoverPasswordSuccessAction());
  } catch {
    yield put(
      sessionErrorAction({
        error: `Error while trying password recovery with email:\n${action.payload.email}`,
      }),
    );
  }
}

function* resetPassword(action: PayloadAction<{ token: string; password: string }>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.resetPassword, {
      password: action.payload.password,
    });
    yield put(resetPasswordSuccessAction());
    yield put(logoutAction());
  } catch (error) {
    const err = error as AxiosError;
    yield put(
      resetPasswordErrorAction({
        error: err.response?.data.message,
      }),
    );
  }
}

function* fetchUserCallback(action: PayloadAction<{ callback?: () => void }>) {
  try {
    const API = new coreAPI();
    const updatedUser: User = yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.fetchUser);
    yield put(fetchUserSuccessAction(updatedUser));
    if (action.payload.callback) {
      action.payload.callback();
    }
  } catch (error) {
    yield put(sessionErrorAction({ error: 'Error while fetching user.' }));
  }
}

// User.
function* fetchUser() {
  try {
    const API = new coreAPI();
    const updatedUser: User = yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.fetchUser);

    yield put(fetchUserSuccessAction(updatedUser));
  } catch (error) {
    yield put(sessionErrorAction({ error: 'Error while fetching user.' }));
  }
}

function* updateUser(action: PayloadAction<Partial<User>>) {
  try {
    const API = new coreAPI();

    yield put(updateUserSuccessAction(action.payload));
    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.updateUser, action.payload);
  } catch (error) {
    yield put(sessionErrorAction({ error: 'Error while trying to update the user.' }));
  }
}

function* updateUserSuccess() {
  // ver que onda esto
}

function* integrateZoom() {
  try {
    const integration: IntegrationData = yield select(selectZoomIntegration);
    const API = new coreAPI();

    if (!integration.id) {
      const zoomURL: string | undefined = yield retry(
        RETRY_TIMES,
        INTERVAL_HALF_A_SECOND,
        API.integrateZoom,
      );

      if (zoomURL) openWindow(zoomURL.toString());
      yield put(integrateZoomResponseAction({}));
    } else {
      yield put(integrateZoomResponseAction({ error: 'Zoom already in sync.' }));
    }
  } catch (error) {
    yield put(integrateZoomResponseAction({ error: `Error while trying sync zoom. ${error}` }));
  }
}

function* removeZoom() {
  try {
    const integration: IntegrationData = yield select(selectZoomIntegration);
    const API = new coreAPI();

    if (integration.id) {
      yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeIntegration, {
        integrationId: integration.id,
      });
      yield putResolve(fetchUserAction());
      yield putResolve(integrateZoomResponseAction({}));
    }
  } catch (error) {
    yield put(integrateZoomResponseAction({ error: `Error while trying remove zoom. ${error}` }));
  }
}

function* integrateCalendar(action: PayloadAction<{ provider?: string }>) {
  try {
    const integration: IntegrationData = yield select(selectCalendarIntegration);
    const API = new coreAPI();

    if (!integration.id) {
      const url: string | undefined = yield retry(
        RETRY_TIMES,
        INTERVAL_HALF_A_SECOND,
        API.integrateCalendar,
        { provider: action.payload.provider },
      );

      if (url) openWindow(url.toString());
      yield put(integrateCalendarResponseAction({}));
    } else {
      yield put(integrateCalendarResponseAction({ error: 'Calendar already in sync.' }));
    }
  } catch (error) {
    yield put(
      integrateCalendarResponseAction({ error: `Error while trying sync calendar. ${error}` }),
    );
  }
}

function* removeCalendar() {
  try {
    const integration: IntegrationData = yield select(selectCalendarIntegration);
    const API = new coreAPI();

    if (integration.id) {
      yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeIntegration, {
        integrationId: integration.id,
      });
      yield putResolve(fetchUserAction());
      yield putResolve(integrateCalendarResponseAction({}));
    }
  } catch (error) {
    yield put(
      integrateZoomResponseAction({ error: `Error while trying remove calendar. ${error}` }),
    );
  }
}

function* integrateSlack() {
  try {
    const integration: IntegrationData = yield select(selectSlackIntegration);
    const API = new coreAPI();

    if (!integration.id) {
      const slackUrl: string | undefined = yield retry(
        RETRY_TIMES,
        INTERVAL_HALF_A_SECOND,
        API.integrateSlack,
      );

      if (slackUrl) openWindow(slackUrl.toString());
    } else {
      yield put(integrateSlackResponseAction({ error: 'Slack already installed.' }));
    }
  } catch (error) {
    yield put(
      integrateSlackResponseAction({ error: `Error while trying to install slack. ${error}` }),
    );
  }
}

function* removeSlack() {
  try {
    const integration: IntegrationData = yield select(selectSlackIntegration);
    const API = new coreAPI();

    if (integration.id) {
      yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeIntegration, {
        integrationId: integration.id,
      });
      yield putResolve(fetchUserAction());
      yield putResolve(integrateSlackResponseAction({}));
    }
  } catch (error) {
    yield put(integrateSlackResponseAction({ error: `Error while trying remove slack. ${error}` }));
  }
}

function* integrateSalesforce() {
  try {
    const integration: IntegrationData = yield select(selectSalesforceIntegration);
    const API = new coreAPI();

    if (!integration.id) {
      const salesforceUrl: string | undefined = yield retry(
        RETRY_TIMES,
        INTERVAL_HALF_A_SECOND,
        API.integrateSalesforce,
      );

      if (salesforceUrl) openWindow(salesforceUrl.toString());
      yield put(integrateSalesforceResponseAction({}));
    } else {
      yield put(integrateSalesforceResponseAction({ error: 'Salesforce already installed.' }));
    }
  } catch (error) {
    yield put(
      integrateSalesforceResponseAction({
        error: `Error while trying to install salesforce. ${error}`,
      }),
    );
  }
}

function* removeSalesforce() {
  try {
    const integration: IntegrationData = yield select(selectSalesforceIntegration);
    const API = new coreAPI();

    if (integration.id) {
      yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeIntegration, {
        integrationId: integration.id,
      });
      yield putResolve(fetchUserAction());
      yield putResolve(integrateSalesforceResponseAction({}));
    }
  } catch (error) {
    yield put(
      integrateSalesforceResponseAction({
        error: `Error while trying remove salesforce. ${error}`,
      }),
    );
  }
}

function* integrateHubspot() {
  try {
    const integration: IntegrationData = yield select(selectHubspotIntegration);
    const API = new coreAPI();

    if (!integration.id) {
      const hubspotUrl: string | undefined = yield retry(
        RETRY_TIMES,
        INTERVAL_HALF_A_SECOND,
        API.integrateHubspot,
      );

      if (hubspotUrl) openWindow(hubspotUrl.toString());
      yield put(integrateHubspotResponseAction({}));
    } else {
      yield put(integrateHubspotResponseAction({ error: 'HubSpot already installed.' }));
    }
  } catch (error) {
    yield put(
      integrateHubspotResponseAction({ error: `Error while trying to install hubspot. ${error}` }),
    );
  }
}

function* removeHubspot() {
  try {
    const integration: IntegrationData = yield select(selectHubspotIntegration);
    const API = new coreAPI();

    if (integration.id) {
      yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeIntegration, {
        integrationId: integration.id,
      });
      yield putResolve(fetchUserAction());
      yield putResolve(integrateHubspotResponseAction({}));
    }
  } catch (error) {
    yield put(
      integrateHubspotResponseAction({ error: `Error while trying remove hubspot. ${error}` }),
    );
  }
}

function* updateFirstTimeUse() {
  try {
    const API = new coreAPI();
    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.putEndToFirstUse);
    yield put(updateFirstTimeUserSuccess());
  } finally {
    yield put(fetchUserAction());
  }
}

export function* sessionSaga() {
  yield takeLatest(loginAction.type, login);
  yield takeLatest(logoutAction.type, logout);

  yield takeLatest(recoverPasswordAction.type, recoverPassword);
  yield takeLatest(resetPasswordAction.type, resetPassword);

  yield takeLatest(updateUserAction.type, updateUser);
  yield takeLatest(updateUserSuccessAction.type, updateUserSuccess);
  yield takeLatest(fetchUserAction.type, fetchUser);
  yield takeLatest('FETCH_CALLBACK', fetchUserCallback);
  yield takeLatest(silentFetchUserAction.type, fetchUser);

  yield takeLatest(integrateZoomAction.type, integrateZoom);
  yield takeLatest(removeZoomAction.type, removeZoom);

  yield takeLatest(integrateCalendarAction.type, integrateCalendar);
  yield takeLatest(removeCalendarAction.type, removeCalendar);

  yield takeLatest(integrateSlackAction.type, integrateSlack);
  yield takeLatest(removeSlackAction.type, removeSlack);

  yield takeLatest(integrateSalesforceAction.type, integrateSalesforce);
  yield takeLatest(removeSalesforceAction.type, removeSalesforce);

  yield takeLatest(integrateHubspotAction.type, integrateHubspot);
  yield takeLatest(removeHubspotAction.type, removeHubspot);

  yield takeLatest(updateFirstTimeUserAction.type, updateFirstTimeUse);
}
