import { PropsWithChildren, createContext, useEffect } from 'react';
import { ProfileStore, createProfileStore } from './profile.store';
import { StoreApi } from 'zustand';
import { coreService } from '../../services/core/core-service';
import { useQuery } from '@tanstack/react-query';

interface ProfileContext {
  store: StoreApi<ProfileStore>;
  isFetching: boolean;
}

export const profileContext = createContext<ProfileContext>({
  store: createProfileStore(),
  isFetching: true,
});

export const ProfileProvider: React.FC<PropsWithChildren<{ id: string }>> = ({ children, id }) => {
  const store = createProfileStore();

  const { data, isFetching } = useQuery({
    queryKey: ['profile-initial-fetch', id],
    queryFn: async () => {
      const profile = await coreService.getContact(id);
      return profile;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      store.setState(data);
    }
  }, [data]);

  return (
    <profileContext.Provider value={{ store, isFetching }}>{children}</profileContext.Provider>
  );
};
