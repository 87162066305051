import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const PlayRoundSVG = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10ZM8.39 7.092C8.50831 7.0272 8.6419 6.99551 8.77671 7.00026C8.91152 7.00501 9.04255 7.04603 9.156 7.119L12.656 9.369C12.7617 9.4369 12.8486 9.53026 12.9088 9.64051C12.9691 9.75077 13.0006 9.87438 13.0006 10C13.0006 10.1256 12.9691 10.2492 12.9088 10.3595C12.8486 10.4697 12.7617 10.5631 12.656 10.631L9.156 12.881C9.04269 12.954 8.91181 12.995 8.77713 12.9999C8.64246 13.0048 8.50895 12.9733 8.39066 12.9087C8.27237 12.8441 8.17366 12.7489 8.10492 12.633C8.03617 12.5171 7.99993 12.3848 8 12.25V7.75C7.99999 7.61542 8.03619 7.48332 8.10481 7.36755C8.17343 7.25177 8.27194 7.1566 8.39 7.092Z"
      />
    </svg>
  );
};

export const PlayRound: React.FC<
  Partial<CustomIconComponentProps> & { onClick: () => unknown }
> = props => {
  return <Icon component={PlayRoundSVG} {...props} onClick={props.onClick} />;
};
