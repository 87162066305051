import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s, opacity 0.25s linear;
  height: 100%;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: var(--GR-TP, linear-gradient(180deg, transparent 0%, black 100%));
`;

const VideoOverlay = styled.div`
  flex: 1;
`;

const AllControls = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftsideControls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RightsideControl = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 16px;
  flex: 1;
`;

export default {
  Container,
  LeftsideControls,
  ControlsContainer,
  RightsideControl,
  AllControls,
  VideoOverlay,
};
