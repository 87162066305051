import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.css';
import { Dragger } from '../../../../../shared/components/Icons/Dragger';
import Styles from './styles';
import { secondsToHms } from '../../../../../../utils/dateUtils';

const { Container } = Styles;

interface Props {
  duration: number;
  markers: [number, number];
  onTimeChanged: (time: number[]) => unknown;
}

export const EditionBar: React.FC<Props> = ({ duration, markers, onTimeChanged }) => {
  const [m, setm] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    setm(markers);
  }, [markers]);

  const handleTimeChanged = (time: number | number[]) => {
    if (Array.isArray(time)) {
      onTimeChanged(time);
    }
  };

  return (
    <Container id="edition-bar-container">
      <p style={{ color: '#ff6c54' }}>
        Keep selection from {secondsToHms(m[0])} to {secondsToHms(m[1])}
      </p>
      <Slider
        key={'edition-bar'}
        min={0}
        max={duration}
        value={m}
        range={true}
        dots={false}
        railStyle={{ backgroundColor: 'var(--cool-gray-400, #566976)' }}
        onChange={handleTimeChanged}
        handleRender={props => {
          return <Dragger {...props.props} />;
        }}
      />
    </Container>
  );
};
