import styled from 'styled-components';
import { colors } from '../../../../../theme/colors';

const Container = styled.a`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: ${colors.violet[300]};
  cursor: pointer;
  width: fit-content;
  height: 20px;

  &:hover {
    background: ${colors.violet[200]};
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
`;

const Label = styled.span`
  color: ${colors.violet[500]};
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
`;

export default {
  Container,
  Label,
};
