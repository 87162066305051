import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  gap: 5px;
`;

export default {
  Container,
  Row,
};
