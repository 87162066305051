import React, { PropsWithChildren } from 'react';
import { ButtonProps } from 'antd';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import Styled from './styles';

const { Container, Logo, Title, Description, Body, TitleContainer, ActionButton, Header } = Styled;

export interface ButtonAction {
  label: string;
  icon?: React.ReactNode;
  type: 'button';
  tooltip?: string | React.ReactElement;
  onClick: () => unknown;
  buttonProps: ButtonProps & React.RefAttributes<HTMLButtonElement>;
}

export interface CustomAction {
  type: 'custom';
  render: () => JSX.Element;
}

interface Props {
  beta?: boolean;
  actions?: (ButtonAction | CustomAction)[];
  title: string;
  logoSrc: string;
  description: string | React.ReactElement;
}

export const IntegrationCard: React.FC<PropsWithChildren<Props>> = ({
  actions,
  description,
  title,
  logoSrc,
  children,
}) => {
  const actionsToRender = actions ? (
    actions.map(action => {
      if (action.type === 'button') {
        return (
          <OptionalTooltipWrapper display={action.buttonProps.disabled} value={action.tooltip}>
            <ActionButton onClick={action.onClick} {...action.buttonProps} type="default">
              {action.buttonProps.loading ? (
                <></>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                  }}
                >
                  {action.icon}
                  {action.label}
                </div>
              )}
            </ActionButton>
          </OptionalTooltipWrapper>
        );
      }

      return action.render();
    })
  ) : (
    <></>
  );

  return (
    <Container
      initial={{ scale: 0, opacity: 0, rotate: 300 }}
      animate={{ rotate: 360, scale: 1, opacity: 1 }}
      transition={{
        stiffness: 260,
        damping: 20,
      }}
    >
      <Header>
        <TitleContainer>
          <Logo src={logoSrc} />
          <Title>{title}</Title>
        </TitleContainer>
        {actionsToRender}
      </Header>

      <Description>{description}</Description>

      <Body>{children}</Body>
    </Container>
  );
};
