import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Styles from './style';
import { NewFolder } from '../../../../features/shared/components/Icons/NewFolder';

const {
  CancelButton,
  ConfirmDeleteButton,
  Container,
  Content,
  Footer,
  Heading,
  Header,
  MainContent,
  Column,
  InputLabel,
} = Styles;

interface Props {
  open?: boolean;
  name: string;
  onCloseModal: () => void;
  onConfirm: (newName: string) => void;
  loading?: boolean;
}

export const RenameFolderModal: React.FC<Props> = ({
  open,
  onCloseModal,
  onConfirm,
  name,
  loading,
}) => {
  const [folderName, setFolderName] = useState(name);

  useEffect(() => {
    setFolderName(name);
  }, [name]);

  const handleConfirm = () => {
    if (onConfirm) onConfirm(folderName);
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Modal title={false} open={open} closable={false} destroyOnClose footer={false} centered>
      <Container>
        <Content>
          <Header>
            <NewFolder />
            <Heading>Rename folder</Heading>
          </Header>
        </Content>
        <MainContent>
          <Column>
            <InputLabel>Folder name</InputLabel>
            <Input value={folderName} onChange={ev => setFolderName(ev.target.value)} />
          </Column>
        </MainContent>
        <Footer>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ConfirmDeleteButton
            onClick={handleConfirm}
            loading={loading}
            style={{ width: 'fit-content' }}
          >
            Save
          </ConfirmDeleteButton>
        </Footer>
      </Container>
    </Modal>
  );
};
