import styled from 'styled-components';
import { grayDarker } from '../../assets/colors';

export const Container = styled.div<{ $min?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.$min ? '' : '100vw')};
  height: ${props => (props.$min ? '' : 'calc(100vh - 76px)')};
`;

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  font-family: 'Cera Pro Bold';
  color: ${grayDarker};
  padding-top: 20px;
`;
