import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FoldersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`;

const FoldersTitle = styled.span`
  color: var(--cool-gray-500, #0d283b);

  /* Text Styles/Heading 6 */
  font-family: Cera Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export default {
  Container,
  FoldersContainer,
  FoldersTitle,
};
