import { create } from 'zustand';
import { InsightSmartClip } from '../types';

export interface Group {
  name: string;
  total: number;
  daily: { date: string; count: number }[];
  subGroups: { name: string; count: number }[];
}

export interface CurrentSubGroup {
  name: string;
  count: number;
}

interface CustomerIntelligenceDataStore {
  isFetching: boolean;
  isFetchingItems: boolean;
  meetingsCount: number;
  groups: Group[];
  currentSubGroups: CurrentSubGroup[];
  items: string[];
  showWatchItems: boolean;
  watchItems: InsightSmartClip[];
}

const customerIntelligenceDataStore = create<CustomerIntelligenceDataStore>(() => {
  return {
    isFetching: true,
    isFetchingItems: false,
    meetingsCount: 0,
    groups: [],
    currentSubGroups: [],
    items: [],
    watchItems: [],
    showWatchItems: false,
  };
});

const fetchingStatusChanged = (isFetching: boolean) => {
  customerIntelligenceDataStore.setState(() => ({ isFetching }));
};

const dataFetched = (data: Pick<CustomerIntelligenceDataStore, 'groups' | 'meetingsCount'>) => {
  customerIntelligenceDataStore.setState(() => ({ ...data }));
};

const groupSelected = (groupName: string) => {
  const selectedGroup = customerIntelligenceDataStore
    .getState()
    .groups.find(group => group.name === groupName);

  if (selectedGroup) {
    customerIntelligenceDataStore.setState(() => ({ currentSubGroups: selectedGroup.subGroups }));
  }
};

const watchItemsFetched = (watchItems: InsightSmartClip[]) => {
  customerIntelligenceDataStore.setState(() => ({ watchItems }));
};

const watchItemsFetchStarted = () =>
  customerIntelligenceDataStore.setState(() => ({ isFetchingItems: true }));
const watchItemsFetchEnded = () =>
  customerIntelligenceDataStore.setState(() => ({ isFetchingItems: false }));

const watchItemsShowToggled = (show: boolean) =>
  customerIntelligenceDataStore.setState(() => ({ showWatchItems: show }));

const reducers = {
  dataFetched,
  fetchingStatusChanged,
  groupSelected,
  watchItemsFetched,
  watchItemsFetchEnded,
  watchItemsFetchStarted,
  watchItemsShowToggled,
};

export { reducers, customerIntelligenceDataStore, CustomerIntelligenceDataStore };
