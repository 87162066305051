import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SalesforceSvg = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93417 2.37234C10.706 1.56882 11.7803 1.07124 12.9671 1.07124C14.5496 1.07124 15.921 1.95036 16.6595 3.26025C17.315 2.96722 18.025 2.81593 18.743 2.8163C21.5913 2.8163 23.9034 5.14597 23.9034 8.02069C23.9034 10.8954 21.5913 13.2251 18.743 13.2251C18.3948 13.2251 18.0555 13.1901 17.7232 13.124C17.077 14.2756 15.8419 15.0581 14.4353 15.0581C13.8626 15.0592 13.2973 14.9293 12.7825 14.6783C12.1276 16.2167 10.6023 17.298 8.82648 17.298C6.97153 17.298 5.39791 16.1288 4.79131 14.4849C4.52133 14.5418 4.24614 14.5705 3.97021 14.5703C1.76362 14.5703 -0.0209961 12.7593 -0.0209961 10.5352C-0.0209961 9.04065 0.782521 7.73955 1.97461 7.03625C1.72171 6.45366 1.59153 5.82521 1.59219 5.1901C1.59219 2.62306 3.67571 0.54834 6.24274 0.54834C7.74604 0.54834 9.09109 1.26482 9.93505 2.37691"
        fill="#00A1E0"
      />
    </svg>
  );
};
export const Salesforce: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={SalesforceSvg} {...props} />;
};
