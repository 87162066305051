import React, { PropsWithChildren } from 'react';
import Label from '../../../shared/components/Label';
import Styles from './styles';

const {
  Container,
  Title,
  FormContainer,
  TitleContainer,
  Link,
  HeaderContainer,
  BackButton,
  BackContainer,
} = Styles;

interface Props {
  onBack?: () => unknown;
  title: string;
  footer?: React.ReactElement;
  description: string;
  backText?: string;
}

const AuthenticationForm: React.FC<PropsWithChildren<Props>> = ({
  onBack,
  title,
  children,
  footer,
  description,
  backText,
}) => {
  return (
    <Container>
      <FormContainer>
        <HeaderContainer>
          {backText ? (
            <BackContainer>
              <BackButton />
              <Link onClick={() => onBack && onBack()}>{backText}</Link>
            </BackContainer>
          ) : null}

          <TitleContainer>
            <Title>{title}</Title>
            <Label>{description}</Label>
          </TitleContainer>
        </HeaderContainer>
        {children}
      </FormContainer>
      {footer}
    </Container>
  );
};

export default AuthenticationForm;
