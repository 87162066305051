import { Button } from 'antd';
import styled from 'styled-components';
import LabeledInput from '../../../_Misc/LabeledInput';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 180px);
  padding-bottom: 48px;
`;

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #818181;
  align-self: stretch;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 24px 24px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const UploadLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 4px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;
export const UploadLogoFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 24px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const UploadImageButtonFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const UploadImageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;

  width: 75px;
  height: 75px;
  border: 2px dashed #d1d1d1;
  border-radius: 6px;
`;

export const HelpText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #818181;
  white-space: pre-line;
`;

export const UploadButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 11px;
  width: 94px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5a5a5a;
`;

export const StyledInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 0px 9px 13px;
  width: 375px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const MembersContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px;
  border-radius: 8px;
  background: #ffffff;
  width: 100%;
  flex: none;
  flex-grow: 0;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  gap: 24px;
  height: 78px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  height: 38px;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  gap: 24px;
`;

export const Details = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a6a6a6;
`;
export const PageButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

export const BackPageButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 9px 7px 11px;
  gap: 8px;

  width: 36px;
  height: 30px;

  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6a6a6a;
`;

export const ForwardPageButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 9px 7px 11px;
  gap: 8px;

  width: 36px;
  height: 30px;

  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6a6a6a;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 24px 0px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  gap: 8px;
  color: #282828;
  font-size: 20px;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const ToggleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0px 24px 0px;
`;

export const MultipleOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 36px;
  padding: 16px 0px;
`;
export const OptionDetail = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: center;
`;
export const OptionDetailText = styled.div`
  width: 152px;

  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
`;

export const DashedLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 70%;
  border: 1px dashed #d1d1d1;
  border-style: none none dashed;
  color: #d1d1d1;
`;
export const Description = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6a6a6a;
  flex: none;
  flex-grow: 0;
  padding: 8px 0px 12px 0px;
`;

export const GroupOption = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
