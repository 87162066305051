import styled from 'styled-components';

const Container = styled.div`
  gap: 16px;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const EmptyView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Cool-Gray-400, #566976);
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  height: calc(100vh - 385px);
  padding-right: 5px;
`;

export default { Container, EmptyView, Cards };
