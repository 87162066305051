import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { propertyMappingContext } from '../../property-mapping.context';
import { createPropertyMappingStore } from '../../property-mapping.store';
import { useSession } from '../../../../../hooks/useSession';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../services/core/core-service';

export const PropertyMappingProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createPropertyMappingStore()).current;
  const user = useSession();
  const { fetchStatusChanged, propertiesLoaded, properties, mapping } = store();

  const organizationId = useMemo(() => {
    if (user.user) {
      return user.user.membership.organization.id;
    }
  }, [user.user]);

  const { data, isFetching } = useQuery({
    queryKey: ['property-mapping', organizationId],
    queryFn: () => {
      if (!organizationId) {
        return null;
      }
      return coreService.getCrmMapping(organizationId);
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      propertiesLoaded(data.properties, data.mapping);
    }
  }, [data]);

  return (
    <propertyMappingContext.Provider value={{ store }}>{children}</propertyMappingContext.Provider>
  );
};
