import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ActionItemSVG = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83354 1.66663L2.41142 10.5732C2.12075 10.922 1.97541 11.0964 1.97319 11.2437C1.97126 11.3717 2.02832 11.4935 2.12792 11.574C2.2425 11.6666 2.46952 11.6666 2.92357 11.6666H9.00021L8.16688 18.3333L15.589 9.42675C15.8797 9.07794 16.025 8.90354 16.0272 8.75624C16.0292 8.62819 15.9721 8.50637 15.8725 8.42588C15.7579 8.33329 15.5309 8.33329 15.0768 8.33329H9.00021L9.83354 1.66663Z"
        stroke="#0675FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ActionItem: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon data-testid="action-item-icon" component={ActionItemSVG} {...props} />;
};
