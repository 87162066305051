import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 60px;
  height: 100%;
  width: 100%;
  gap: 30px;
`;

export default { Container };
