import styled from 'styled-components';
import { Button } from 'antd';

export const TitleContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 0px 16px;
gap: 16px;
isolation: isolate;
width: 100%
height: 40px;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
`;

export const NewMemberText = styled.div`
  display: flex;
  flex-direction: row;
  order: 1;
  flex-grow: 2;

  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #282828;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
`;

export const EmailInvite = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const EmailText = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #282828;
  flex: none;
  flex-grow: 0;
`;
export const LabelText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0px 2px 1px;
  gap: 8px;
  flex: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6a6a6a;
  align-self: stretch;
  flex-grow: 0;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 2.8rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 12px);
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #a6a6a6;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;

export const SendInviteButton = styled(Button)`
  padding: 9px 17px;
  width: 129px;
  height: 38px;
`;
