import { organizationDetailsStore } from '../../../stores/organization-details';
import { useEffect } from 'react';
import {
  changeName,
  fetch,
  toggleAllowJoinByDomain,
  changeGlobalMeetingsVisibility,
  disableManageMeetingsVisibilityGlobally,
  enableManageMeetingsVisibilityGlobally,
  updateCrmSettings,
  changeBotAnnouncement as changeBotAnnouncementService,
} from '../organizations.service';
import { useToast } from '../../../hooks/useToast';

export const useOrganizationDetails = () => {
  const {
    name,
    id,
    isFetching,
    fetchError,
    allowJoinByDomain,
    globalMeetingsVisibility,
    manageMeetingsVisibilityGlobally,
    permissions,
    doNotRecordList,
    crmData,
    slack,
    botTextAnnouncement,
    botVoiceAnnouncement,
  } = organizationDetailsStore();

  const { success, error } = useToast();

  const changeBotAnnouncement = async (args: { text?: boolean; voice?: boolean }) => {
    try {
      await changeBotAnnouncementService(args);
      success('Bot announcement settings updated successfully');
    } catch (e) {
      error('Failed to update bot announcement settings');
    }
  };

  useEffect(() => {
    if (!id) {
      fetch();
    }
  }, [id]);

  return {
    fetchError,
    id,
    name,
    crmData,
    isFetching,
    allowJoinByDomain,
    permissions,
    globalMeetingsVisibility,
    manageMeetingsVisibilityGlobally,
    doNotRecordList,
    slack,
    botTextAnnouncement,
    botVoiceAnnouncement,
    changeName,
    fetch,
    toggleAllowJoinByDomain,
    changeGlobalMeetingsVisibility,
    disableManageMeetingsVisibilityGlobally,
    enableManageMeetingsVisibilityGlobally,
    updateCrmSettings,
    refetch: fetch,
    changeBotAnnouncement,
  };
};
