import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import Style from './style';

interface Props {
  title: string;
  maxLength: number;
  value: string;
  disable?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

const { Container } = Style;

export const ContextTextarea: React.FC<Props> = ({
  maxLength,
  title,
  placeholder,
  value,
  disable = false,
  onChange,
}) => {
  return (
    <Container>
      <h1>{title}</h1>
      <TextArea
        rows={4}
        placeholder={placeholder}
        maxLength={maxLength}
        style={{ width: '700px' }}
        disabled={disable}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
      <h3>{`${maxLength - value.length}/${maxLength} characters remaining.`}</h3>
    </Container>
  );
};
