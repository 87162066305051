import React, { useMemo, useState } from 'react';
import Styles from './styles';
import { Folder } from '../Folder';
import { StarredFolder } from '../StarredFolder';
import { BackFolder } from '../BackFolder';
import { useLibraries } from '../../../../features/libraries/hooks/useLibraries';
import { Folder as FolderIcon } from '../../../../features/shared/components/Icons/Folder';
import { DeleteFolderModal } from '../DeleteFolderModal';
import { RenameFolderModal } from '../RenameFolderModal';
import { useToast } from '../../../../hooks/useToast';
import { extractError } from '../../../../utils/api';

const { Container, FoldersContainer } = Styles;

interface FolderToRemoveData {
  id: string;
  name: string;
}

interface FolderToRenameData {
  id: string;
  name: string;
}

interface Props {
  mainFolderName: string;
}

export const FolderNavigation: React.FC<Props> = ({ mainFolderName }) => {
  const {
    folders,
    selectFolder,
    selectedFolderId,
    selectedLibraryId,
    removeFolder,
    renameFolder,
    refetchLibraries,
  } = useLibraries();

  const [folderToRemove, setFolderToRemove] = useState<FolderToRemoveData | null>(null);
  const [folderToRename, setFolderToRename] = useState<FolderToRenameData | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const { success, error: errorToast } = useToast();

  const handleFolderSelected = (id: string) => {
    selectFolder(id);
  };

  const goToRoot = () => {
    selectFolder('');
  };

  const isRoot = !Boolean(selectedFolderId);
  const isInsideFolder = !isRoot;

  const foldersToShow = useMemo(() => {
    const starredFolder = folders.find(folder => folder.isStarred);
    const otherFolders = folders.filter(folder => !folder.isStarred);

    return (
      <>
        <StarredFolder
          key={starredFolder?.id || 'starred-folder'}
          count={starredFolder?.itemsCount || 0}
          onClick={() => starredFolder && handleFolderSelected(starredFolder.id)}
          mainFolderName={mainFolderName}
        />
        {otherFolders.map(folder => (
          <Folder
            key={folder.id}
            onClick={() => handleFolderSelected(folder.id)}
            onRemove={() => setFolderToRemove({ id: folder.id, name: folder.name })}
            onRename={() => setFolderToRename({ id: folder.id, name: folder.name })}
            count={folder.itemsCount}
            icon={<FolderIcon />}
            name={folder.name}
          />
        ))}
      </>
    );
  }, [selectedFolderId, folders]);

  const handleRemoveFolder = async () => {
    try {
      setIsRemoving(true);
      if (folderToRemove && selectedLibraryId) {
        await removeFolder(selectedLibraryId, folderToRemove.id);
        setFolderToRemove(null);
        success('Folder removed successfully.');
      }
    } catch (error) {
      const message = extractError(error);
      errorToast(message);
    } finally {
      setIsRemoving(false);
      refetchLibraries();
    }
  };

  const handleRenameFolder = async (newName: string) => {
    try {
      setIsRenaming(true);
      if (folderToRename && selectedLibraryId) {
        await renameFolder(selectedLibraryId, folderToRename.id, newName);
        setFolderToRename(null);
        success('Folder renamed successfully.');
      }
    } catch (error) {
      const message = extractError(error);
      errorToast(message);
    } finally {
      setIsRenaming(false);
      refetchLibraries();
    }
  };

  return (
    <Container>
      {folderToRemove && (
        <DeleteFolderModal
          isRemoving={isRemoving}
          name={folderToRemove.name}
          onCancel={() => setFolderToRemove(null)}
          onConfirm={() => handleRemoveFolder()}
          show={Boolean(folderToRemove)}
        />
      )}
      {folderToRename && (
        <RenameFolderModal
          loading={isRenaming}
          name={folderToRename.name}
          onCloseModal={() => setFolderToRename(null)}
          onConfirm={newName => handleRenameFolder(newName)}
          open={Boolean(folderToRename)}
        />
      )}
      <FoldersContainer>
        {isInsideFolder && <BackFolder onClick={() => goToRoot()} />}
        {isRoot && foldersToShow}
      </FoldersContainer>
    </Container>
  );
};
