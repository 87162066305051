import React from 'react';
import Styles from './styles';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const {
  Container,
  Contents,
  HighlightLabel,
  RegularLabel,
  TextContents,
  UnderlineLabel,
  // CloseButton,
  JoshFace,
} = Styles;

const JoinBanner: React.FC = () => {
  // const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const goToSignup = () => {
    navigate('/signup/?utm_source=public_recap&utm_medium=banner');
  };

  /*
  if (!show) {
    return null;
  }
  */

  return (
    <Container>
      <Contents>
        <JoshFace src="https://assets.update.ai/josh.png" />
        <TextContents>
          <HighlightLabel>Save hours of busywork.</HighlightLabel>
          <RegularLabel>
            Create a free account, <UnderlineLabel>no credit card required</UnderlineLabel>
          </RegularLabel>
          <Button
            type="default"
            onClick={() => goToSignup()}
            style={{ height: '25px', display: 'flex', alignItems: 'center' }}
          >
            Register now!
          </Button>
        </TextContents>
      </Contents>
      {/*<CloseButton onClick={() => setShow(false)} />*/}
    </Container>
  );
};

export default JoinBanner;
