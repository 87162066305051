import styled from 'styled-components';

const Header = styled.tr`
  align-items: center;
  padding: 12px 24px;
  height: 40px;
  background: #f8f8f8;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #6a6a6a;
`;

const HeaderCell = styled.td`
  padding-left: 18px;
  color: #6a6a6a;
`;

const Row = styled.tr`
  height: 54px;
`;

const Cell = styled.td`
  padding: 8px 8px 8px 12px !important;
  text-overflow: ellipsis;
  white-space: pre;
`;

export default {
  Header,
  HeaderCell,
  Row,
  Cell,
};
