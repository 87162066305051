import { Button, Dropdown, MenuProps, Space, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { coreService } from '../../services/core/core-service';
import { useToast } from '../../hooks/useToast';

interface Props {
  visibility: string;
  id: string;
  hasPermissions: boolean;
}

export const DropdownVisibility: React.FC<Props> = ({ visibility, id, hasPermissions }) => {
  const [loading, setLoading] = useState(false);
  const [label, _setLabel] = useState('');

  const { success, error } = useToast();

  const setLabel = (label: string) => {
    _setLabel(label === 'invitees' ? 'Invitees Only' : 'Organization-wide');
  };

  const handleVisibilityChange = async (visibility: string) => {
    setLoading(true);
    try {
      await coreService.updateWorkspaceVisibility(id, visibility);
      setLabel(visibility);
      success('Visibility updated');
    } catch {
      error('Error updating visibility');
    } finally {
      setLoading(false);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Meeting Visibility',
      key: '0',
      disabled: true,
    },
    {
      label: 'Invitees Only',
      key: '1',
      onClick: () => handleVisibilityChange('invitees'),
    },
    {
      label: 'Organization-wide',
      key: '2',
      onClick: () => handleVisibilityChange('organization'),
    },
  ];

  useEffect(() => {
    setLabel(visibility);
  }, []);

  return (
    <Dropdown menu={{ items }} disabled={loading || !hasPermissions}>
      <Button
        size="small"
        style={{ width: '160px' }}
        onClick={e => e.stopPropagation()}
        disabled={loading || !hasPermissions}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          {loading ? <Spin size="small" /> : label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
