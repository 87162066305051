import styled from 'styled-components';

const Container = styled.div`
  color: #282828;
  font-family: 'Cera Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export default {
  Container,
};
