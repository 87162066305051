import styled from 'styled-components';

const Container = styled.div`
  height: 175px;
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  & span {
    color: var(--N-900, #0d283b);
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
  }

  & div {
    color: var(--N-900, #0d283b);
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 25% 115px 20% 110px auto;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Link = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100%);
`;

const URL = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 2.5px 3px;
  border-radius: 2px;
  max-width: 160px;
  background: var(--N-200, #eff2f7);
`;

export default { Container, Row, URL, Link, Item };
