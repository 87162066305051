import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: inherit;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  flex: 4;
  padding: 40px;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 30px;
  flex: 1;
  justify-content: space-between;
`;

export const Right = styled.div<{ background: string }>`
  flex: 3;
  border-radius: 0 20px 20px 0px;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
`;
