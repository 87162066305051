import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const GlobeSVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2436 4.32995C12.5424 3.32928 11.5702 2.54943 10.4412 2.08195C11.074 3.19527 11.5232 4.40329 11.7716 5.65955C12.3243 5.29025 12.8202 4.84234 13.2436 4.32995ZM10.6604 6.26035C10.3896 4.59037 9.72814 3.00796 8.73002 1.64195C8.24465 1.5868 7.7546 1.5868 7.26922 1.64195C6.27111 3.00796 5.6097 4.59037 5.33882 6.26035C6.18026 6.61795 7.08535 6.80163 7.99962 6.80035C8.94362 6.80035 9.84282 6.60835 10.6604 6.26035ZM5.21082 7.50115C6.10323 7.83247 7.0477 8.00153 7.99962 8.00035C8.98042 8.00035 9.91962 7.82435 10.7884 7.50115C10.8357 8.51174 10.7412 9.52398 10.5076 10.5083C9.68587 10.7031 8.84415 10.8011 7.99962 10.8003C7.13642 10.8003 6.29722 10.6987 5.49242 10.5075C5.25863 9.52348 5.16385 8.51151 5.21082 7.50115ZM4.22762 5.65955C4.47612 4.40357 4.92534 3.19583 5.55802 2.08275C4.42906 2.55023 3.45688 3.33008 2.75562 4.33075C3.17882 4.84115 3.67482 5.29075 4.22762 5.65955ZM13.8668 5.43875C14.319 6.47327 14.4885 7.60928 14.358 8.73075C13.5762 9.30152 12.721 9.76435 11.8156 10.1067C11.9996 9.06695 12.0464 8.00752 11.9548 6.95555C12.6657 6.55026 13.3104 6.03876 13.8668 5.43875ZM2.13242 5.43875C2.68879 6.03877 3.33356 6.55027 4.04442 6.95555C3.95371 8.0078 4.0005 9.06739 4.18362 10.1075C3.27821 9.76513 2.42305 9.30231 1.64122 8.73155C1.51097 7.61001 1.68075 6.47399 2.13322 5.43955L2.13242 5.43875ZM7.99962 12.0003C8.71802 12.0003 9.42202 11.9371 10.106 11.8163C9.7636 12.7218 9.30078 13.5769 8.73002 14.3587C8.24465 14.4138 7.7546 14.4138 7.26922 14.3587C6.69845 13.5769 6.23562 12.7218 5.89322 11.8163C6.57722 11.9371 7.28122 12.0003 7.99962 12.0003ZM11.4852 11.4859C11.2285 12.3328 10.8783 13.1485 10.4412 13.9179C11.2205 13.5952 11.9286 13.1221 12.525 12.5257C13.1214 11.9293 13.5945 11.2212 13.9172 10.4419C13.1548 10.8755 12.3404 11.2267 11.4852 11.4859ZM5.55802 13.9179C4.77874 13.5952 4.07066 13.1221 3.47424 12.5257C2.87782 11.9293 2.40474 11.2212 2.08202 10.4419C2.84442 10.8755 3.65882 11.2267 4.51402 11.4859C4.77074 12.3328 5.1209 13.1485 5.55802 13.9179Z"
        fill="#C3C9CE"
      />
    </svg>
  );
};
export const Globe: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={GlobeSVG} {...props} />;
};
