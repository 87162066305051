import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const BetaSVG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M1.12339 9.98423C0.652658 9.98423 0.318069 9.77425 0.119624 9.35429C-0.0788218 8.93432 -0.0303642 8.54435 0.264997 8.18438L3.3386 4.44622V1.12341H2.78479C2.62788 1.12341 2.49636 1.07034 2.39021 0.964193C2.28406 0.858048 2.23099 0.72652 2.23099 0.56961C2.23099 0.412699 2.28406 0.281171 2.39021 0.175026C2.49636 0.0688807 2.62788 0.0158081 2.78479 0.0158081H7.21521C7.37212 0.0158081 7.50364 0.0688807 7.60979 0.175026C7.71594 0.281171 7.76901 0.412699 7.76901 0.56961C7.76901 0.72652 7.71594 0.858048 7.60979 0.964193C7.50364 1.07034 7.37212 1.12341 7.21521 1.12341H6.6614V4.44622L9.735 8.18438C10.0304 8.54435 10.0788 8.93432 9.88038 9.35429C9.68193 9.77425 9.34734 9.98423 8.87661 9.98423H1.12339ZM1.12339 8.87663H8.87661L5.5538 4.83388V1.12341H4.4462V4.83388L1.12339 8.87663Z"
        fill="#7C4DFF"
      />
    </svg>
  );
};

export const Beta: React.FC<Partial<CustomIconComponentProps>> = ({ ...props }) => {
  return <Icon component={BetaSVG} {...props} />;
};
