import React, { useState } from 'react';
import Styles from './styles';

const { EllipsisButton } = Styles;

interface Props {
  text: string;
}

export const Ellipsis: React.FC<Props> = ({ text }) => {
  const [isOpen, setIsOpen] = useState(true);

  return isOpen ? (
    <span>{text}</span>
  ) : (
    <EllipsisButton onClick={() => setIsOpen(true)}>(...)</EllipsisButton>
  );
};
