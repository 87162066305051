import React from 'react';
import IconSvg from '../../../../../../components/common/IconSvg';
import Styles from './styles';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  label: string;
  value: string;
  isSyncing?: boolean;
}

const { Container, Label, Value } = Styles;

export const SuccessConnectionDataLabel: React.FC<Props> = ({ label, value, isSyncing }) => {
  return (
    <Container>
      {isSyncing ? (
        <LoadingOutlined style={{ color: '#4BC5FF' }} />
      ) : (
        <IconSvg name="integrationOk" size="sm" color="transparent" />
      )}
      <Label>{label}</Label>
      {isSyncing ? <Value color="#4BC5FF">{'Sync in progress...'}</Value> : <Value>{value}</Value>}
    </Container>
  );
};
