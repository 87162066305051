import React from 'react';

export const ErrorIndicator: React.FC = () => {
  return (
    <svg
      width="151"
      height="151"
      viewBox="0 0 151 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="150" height="150" rx="75" fill="#FEF2F2" />
      <circle cx="75" cy="75.5" r="60" fill="#FEE2E2" />
      <circle cx="75" cy="75.5" r="45" fill="#FDCBCB" />
      <path
        d="M75.5283 56.5L75.4241 82.5M75.5283 94.5H75.5758"
        stroke="#EE4E4E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
