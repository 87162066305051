import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 24px;
`;

export const AvatarImage = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  & > span {
    color: white;
    font-size: 20px;
    width: 30px;
    height: 30px;
    color: black;
  }
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #00489f;
`;

export const NameAndEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
`;
export const Name = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #282828;
  order: 1;
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  order: 2;
`;
