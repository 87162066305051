import styled from 'styled-components';

export const TabContainer = styled.span<{ active: boolean }>`
  padding: 4px 8px;
  border-bottom: ${props => (props.active ? '1px solid #FF6C54' : '1px solid transparent')};
  font-weight: ${props => (props.active ? '700' : '400')};
  display: flex;
  align-items: center;
  cursor: default;
  color: ${props => (props.active ? '#FF6C54' : 'grey')};
`;

export const TabsBarContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0px 12px;
`;
