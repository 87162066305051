import { Button } from 'antd';
import styled from 'styled-components';

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100% - 380px);
  overflow: auto;
  padding: 16px;
  position: relative;
`;

export const NoMessagesFoundContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #445260;
`;

const TranscriptContainer = styled.div`
  height: 100%;
`;

const AutoscrollContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  padding: 4px;
  height: 36px;
`;

const AutoscrollButton = styled(Button)`
  box-shadow: 0px 4px 15px 0px rgba(86, 105, 118, 0.6);
`;

export default {
  AutoscrollContainer,
  AutoscrollButton,
  TranscriptContainer,
};
