import React from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import InvitationsTable from '../InvitationsTable';
import { UpdateLoader } from '../../../../../../componentsV2/UpdateLoader';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';

const { LoaderWrapper } = Styled;

export const InvitationsSection: React.FC<{}> = () => {
  const { isFetching, permissions } = useOrganizationDetails();
  const disableUserInteractions = !permissions.manageInvitations;

  return (
    <Section
      title="Pending Invites"
      description="Your team members playing hard to get, still to accept their invites!"
    >
      {isFetching && (
        <LoaderWrapper>
          <UpdateLoader min />
        </LoaderWrapper>
      )}
      {!isFetching && <InvitationsTable disableUserInteractions={disableUserInteractions} />}
    </Section>
  );
};
