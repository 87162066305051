import styled from 'styled-components';

const Container = styled.div`
  border-radius: 8px;
  background: var(--Conditional-pop-over, #fff);

  /* drop-shadow/0.12+0.8+0.5 */
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`;

const Item = styled.div<{ clickable: boolean; disabled: boolean }>`
  display: flex;
  > * {
    color: ${props => (props.disabled ? 'grey' : 'inherit')};
  }
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 5px 12px;
  cursor: ${props => (props.clickable && !props.disabled ? 'pointer' : 'default')};
`;

const Label = styled.span<{ disabled: boolean }>`
  color: ${props => (props.disabled ? 'grey' : 'var(--character-title-85, rgba(0, 0, 0, 0.85))')};
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: var(--Cool-Gray-100, #e7eaeb);
`;

export default {
  Container,
  Item,
  Label,
  Divider,
};
