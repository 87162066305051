import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0px 8px 10px 8px;
  position: relative;
  height: 100%;
  overflow: auto;
`;

const MessageContainer = styled.div<{ isUpdateAI: boolean }>`
  display: flex;
  gap: 12px;
  justify-content: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
`;

const Message = styled.div<{ isUpdateAI: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isUpdateAI }) => (isUpdateAI ? 'row' : 'row-reverse')};
  gap: 8px;
`;

const Timestamp = styled.span<{ isUpdateAI: boolean }>`
  text-align: right;
  color: #0d283b;
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
  color: ${({ isUpdateAI }) => (isUpdateAI ? '#0D283B' : '#FF6C54')};
`;

const MessageBuble = styled.div<{ isUpdateAI: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
  padding: 8px;
  gap: 8px;
  width: calc(100% - 40px);

  border-radius: ${({ isUpdateAI }) => (isUpdateAI ? '0px 8px 8px 8px' : '8px 0px 8px 8px')};

  /* border: 1px solid ${colors.coolGray[100]}; */
  box-shadow: 2px 2px 12px 0px rgba(158, 169, 177, 0.25);

  color: ${({ isUpdateAI }) => (isUpdateAI ? '#0d283b' : '#FF6C54')};
  background-color: ${({ isUpdateAI }) => (isUpdateAI ? 'white' : 'rgba(255, 108, 84, 0.05)')};
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    color: ${colors.primary[500]};
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 14px !important;
    font-weight: 500;
    margin: 0px;
  }

  h2 {
    font-size: 13px !important;
    font-weight: 300;
    margin: 0px;
  }

  h3 {
    font-size: 11px !important;
    font-weight: 200;
    margin: 0px;
    margin-top: -7px;
  }

  h4 {
    font-size: 12px !important;
    font-weight: 200;
    margin: 0px;

    a {
      color: ${colors.primary[500]};
    }
  }
`;

const Pulse = keyframes`
0% {
  transform: scale(0.95);
  opacity: 0.7;
}
50% {
  transform: scale(1);
  opacity: 1;
}
100% {
  transform: scale(0.95);
  opacity: 0.7;
}
`;

const PulsingDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.primary['400']};
  border-radius: 50%;
  animation: ${Pulse} 1s infinite ease-in-out;
`;

export default {
  Container,
  MessageContainer,
  Message,
  Timestamp,
  MessageBuble,
  TooltipContent,
  PulsingDot,
};
