import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

const NoFrameworks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Cool-Gray-400, #566976);
  font-size: 16px;

  a {
    color: ${colors.primary[500]};
  }
`;

export default { Container, NoFrameworks };
