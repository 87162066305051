import styled from 'styled-components';
import { colors } from '../../../../../theme/colors';

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.gray['200']};
  background: var(--base-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  width: 282px;
`;

const Contents = styled.div`
  padding: 4px 0px;
`;

const Option = styled.div<{ isSelected: boolean }>`
  padding: ${props => (props.isSelected ? '4px 10px' : '4px 10px 4px 36px')};
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray['100']};
  }
`;

const OptionHeader = styled.div`
  display: flex;
  gap: 8px;
`;

const RoleName = styled.span`
  color: ${colors.gray['700']};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const RoleDescription = styled.span<{ isSelected: boolean }>`
  color: ${colors.gray['300']};
  padding-left: ${props => (props.isSelected ? '26px' : '0px')};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export default {
  Container,
  Contents,
  Option,
  RoleName,
  RoleDescription,
  OptionHeader,
};
