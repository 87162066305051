import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SearchAccountItem, SearchResult } from '../types';
import { coreAPI } from '../../../../API/core';

export const useMeetingAccountSearch = () => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);
  const [searchResult, setSearchResult] = useState<SearchResult>({ error: null, items: [] });
  const [isSearching, setIsSearching] = useState(false);

  const searchAccountOrEmailDomain = () => {
    setIsSearching(true);
    setSearchResult({ error: null, items: [] });

    const api = new coreAPI();
    api
      .fetchUserAccounts(search)
      .then(data => {
        const result: SearchAccountItem[] = data.accounts.map(account => ({
          providerId: account.id,
          name: account.name,
          provider: account.provider,
        }));
        setSearchResult({ error: null, items: result });
      })
      .catch(error => {
        setSearchResult({ error: 'Error searching accounts', items: [] });
      })
      .finally(() => setIsSearching(false));
  };

  useEffect(() => {
    if (search !== '' && search.length >= 3) searchAccountOrEmailDomain();
  }, [debouncedSearch]);

  return {
    accounts: searchResult.items,
    isSearching,
    searchTerm: search,
    changeSearchTerm: setSearch,
  };
};
