import React from 'react';

import Step from './Step';
import { Container, Footer, Steps, TestAgainButton } from './style';
import { ZoomTestResults } from '../../../../../../API/types';

interface Props {
  testResults: ZoomTestResults | undefined;
  loading: boolean;
  onTest: () => void;
}

const AdvancedSettings: React.FC<Props> = ({ testResults, loading, onTest }) => {
  return (
    <Container>
      <Steps>
        <Step
          success={testResults?.isLicensed}
          description={'You must be a licensed user on a Pro, Business, or Enterprise account.'}
          title="Account license"
          testHasRan={!!testResults}
          onIconClickHref={
            'https://support.zoom.us/hc/en-us/articles/203741855-Starting-a-cloud-recording'
          }
        />
        <Step
          success={testResults?.cloudRecording}
          title="Cloud Recording setting"
          testHasRan={!!testResults}
          onIconClickHref={'https://support.zoom.us/hc/en-us/articles/360060231472'}
        />
        <Step
          success={testResults?.hasParticipantAudioFilesEnabled}
          title="Record a separate audio file"
          testHasRan={!!testResults}
          onIconClickHref={
            'https://help.update.ai/en/articles/7043320-i-get-an-error-message-stating-zoom-participant-audio-files-have-not-been-enabled-or-that-my-recording-is-not-available'
          }
        />
      </Steps>
      <Footer>
        <TestAgainButton onClick={onTest} loading={loading}>
          {!!testResults ? 'Test again' : 'Run test'}
        </TestAgainButton>
      </Footer>
    </Container>
  );
};

export default AdvancedSettings;
