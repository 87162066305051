import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SVGComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M8.60999 2.62425C8.64171 2.54687 8.69573 2.48068 8.76518 2.4341C8.83463 2.38751 8.91636 2.36263 8.99999 2.36263C9.08362 2.36263 9.16535 2.38751 9.2348 2.4341C9.30425 2.48068 9.35827 2.54687 9.38999 2.62425L10.9837 6.4575C11.0136 6.52923 11.0626 6.59133 11.1255 6.63699C11.1883 6.68264 11.2626 6.71007 11.34 6.71625L15.4785 7.04775C15.8527 7.07775 16.0042 7.545 15.7192 7.78875L12.5662 10.4903C12.5073 10.5406 12.4634 10.6063 12.4394 10.68C12.4153 10.7537 12.412 10.8325 12.4297 10.908L13.3935 14.9468C13.4129 15.0278 13.4078 15.1127 13.3789 15.1909C13.35 15.269 13.2986 15.3369 13.2312 15.3858C13.1638 15.4348 13.0834 15.4626 13.0001 15.4659C12.9169 15.4692 12.8345 15.4477 12.7635 15.4043L9.21974 13.2405C9.15354 13.2002 9.07751 13.1788 8.99999 13.1788C8.92247 13.1788 8.84644 13.2002 8.78024 13.2405L5.23649 15.405C5.16543 15.4485 5.08308 15.47 4.99983 15.4667C4.91659 15.4634 4.83618 15.4355 4.76877 15.3866C4.70136 15.3376 4.64996 15.2698 4.62108 15.1916C4.5922 15.1135 4.58712 15.0285 4.60649 14.9475L5.57024 10.908C5.58811 10.8325 5.58483 10.7536 5.56075 10.6799C5.53666 10.6062 5.49272 10.5406 5.43374 10.4903L2.28074 7.78875C2.2176 7.73446 2.17192 7.66271 2.14945 7.58253C2.12698 7.50234 2.12872 7.41731 2.15445 7.33811C2.18018 7.25891 2.22876 7.18909 2.29407 7.13743C2.35937 7.08576 2.4385 7.05456 2.52149 7.04775L6.65999 6.71625C6.73743 6.71007 6.81166 6.68264 6.87451 6.63699C6.93737 6.59133 6.98641 6.52923 7.01624 6.4575L8.60999 2.62425Z"
        stroke="#9EA9B1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Unstar: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={SVGComponent} {...props} />;
};
