import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const CardFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 335px !important;
  height: 250px !important;

  border-radius: 8px;
  border: solid 1px ${colors.coolGray[200]};
  gap: 24px;

  :hover {
    border: 1px solid ${colors.primary[500]};
  }

  cursor: pointer;
`;

const CardTitle = styled.p`
  font-family: Cera Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
`;

const CardDescription = styled.p`
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
  letter-spacing: 0em;
  width: 250px;
  text-align: center;
  margin: 0;
`;

export { Header, Cards, CardFrame, CardContainer, CardTitle, CardDescription };
