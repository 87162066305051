import { Category } from '../types';
import { create } from 'zustand';

interface InsightCategoriesStore {
  categories: Category[];
  isFetching: boolean;
  type: string;
}

const insightCategoriesStore = create<InsightCategoriesStore>(() => {
  return {
    categories: [],
    isFetching: false,
    type: 'Risks',
  };
});

const fetchStatusChanged = (isFetching: boolean) => {
  insightCategoriesStore.setState(() => ({ isFetching }));
};

const categoriesFetched = (categories: Category[]) => {
  insightCategoriesStore.setState(() => ({ categories }));
};

const typeChanged = (type: string) => {
  insightCategoriesStore.setState(() => ({ type }));
};

const reducers = {
  fetchStatusChanged,
  categoriesFetched,
  typeChanged,
};

export { reducers, insightCategoriesStore };
