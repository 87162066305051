import React, { useState } from 'react';
import { Container } from './style';
import { Select } from 'antd';

interface Props {
  userType?: string;
  onChange: (e: string) => void;
  disableUserInteractions?: boolean;
}

const UserTypeCell: React.FC<Props> = ({ userType, onChange, disableUserInteractions }) => {
  const [value, setValue] = useState(userType);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Container>
      <Select
        defaultValue={userType}
        style={{ width: 100 }}
        disabled={disableUserInteractions}
        onChange={handleChange}
        value={value}
        options={[
          { value: 'PAID', label: 'Paid' },
          { value: 'FREE', label: 'Free' },
        ]}
      />
    </Container>
  );
};

export default UserTypeCell;
