import { create } from 'zustand';
import { ProspectAccount } from '../types';

interface AccountSelectionModalState {
  prospectAccounts: ProspectAccount[];
  show: boolean;
  close: () => void;
  eventId: string;
  open: (eventId: string, prospectAccounts: ProspectAccount[]) => void;
}

export const accountSelectionStore = create<AccountSelectionModalState>(set => {
  return {
    prospectAccounts: [],
    show: false,
    eventId: '',
    close: () => set({ show: false, prospectAccounts: [] }),
    open: (eventId, prospectAccounts) => set({ show: true, prospectAccounts, eventId }),
  };
});
