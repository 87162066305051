import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 8px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
`;
export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  color: #6a6a6a;
  white-space: pre-wrap;
`;
export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;
