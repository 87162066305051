import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 700px;
  overflow-y: auto;
`;

export default {
  Container,
};
