import React from 'react';
import Styles from './styles';
import { useToast } from '../../../../../../hooks/useToast';
import { Button, Input } from 'antd';
import { coreService } from '../../../../../../services/core/core-service';

const { Label, Container } = Styles;

interface Props {
  onSuccess: (args: { id: string; secretKey: string }) => void;
}

export const AddCredentials: React.FC<Props> = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [id, setId] = React.useState('');
  const [key, setKey] = React.useState('');

  const { success, error } = useToast();

  const addKey = async () => {
    setIsLoading(true);
    coreService
      .addGongCredentials(id, key)
      .then(() => {
        success('Gong keys added successfully');
        onSuccess({ id, secretKey: key });
        setId('');
        setKey('');
      })
      .catch(() => {
        error('Failed to add Gong keys');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container>
      <div style={{ width: '100%' }}>
        <Label>Access Key</Label>
        <Input onChange={i => setId(i.target.value)} disabled={isLoading} value={id} />
      </div>

      <div style={{ width: '100%', marginTop: '-150px' }}>
        <Label>Access Key Secret</Label>
        <Input onChange={i => setKey(i.target.value)} disabled={isLoading} value={key} />
      </div>

      <Button
        type="primary"
        style={{ marginTop: '50px', width: '200px' }}
        disabled={id === '' || key === '' || isLoading}
        loading={isLoading}
        onClick={addKey}
      >
        Save
      </Button>
    </Container>
  );
};
