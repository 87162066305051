import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux/typings/store';

const useDelighthed = () => {
  const user = useSelector((store: Store) => store.session.user);

  useEffect(() => {
    if (user && window.delighted) {
      const organization = user.membership ? user.membership.organization.name : null;
      window.delighted.survey({
        email: user?.email,
        name: user?.firstName,
        properties: {
          plan: user?.userSubscription.type,
          organization: organization,
        },
      });
    }
  }, [user]);
};

export default useDelighthed;
