import styled from 'styled-components';

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 8px 4px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? '#EDFCF5' : 'none')};
`;

const AccountContainer = styled.div`
  display: flex;
  gap: 1px;
  flex: 1;
`;

export default {
  Container,
  AccountContainer,
};
