import React from 'react';
import Styles from './styles';
import { Check } from '../../../../shared/components/Icons/Check';
import { colors } from '../../../../../theme/colors';
import { useMemberRoles } from '../../../hooks/useMemberRoles';
import { useSession } from '../../../../../hooks/useSession';

const { Container, Contents, Option, OptionHeader, RoleDescription, RoleName } = Styles;

interface Props {
  role: string;
  onRoleChange: (roleId: string) => unknown;
  onRemove: () => unknown;
}

const Panel: React.FC<Props> = ({ onRemove, onRoleChange, role }) => {
  const { user } = useSession();
  const { roles } = useMemberRoles(user?.membership.role || 'MEMBER');

  return (
    <Container>
      <Contents>
        {roles.map(({ id, name, description }) => {
          const isSelected = id === role;
          return (
            <Option key={id} isSelected={isSelected} onClick={() => onRoleChange(id)}>
              <OptionHeader>
                {isSelected && (
                  <Check width={20} height={20} style={{ width: '20px', height: '20px' }} />
                )}
                <RoleName>{name}</RoleName>
              </OptionHeader>
              <RoleDescription isSelected={isSelected}>{description}</RoleDescription>
            </Option>
          );
        })}
        <Option isSelected={false} onClick={onRemove}>
          <OptionHeader>
            <RoleName style={{ color: colors.red['500'] }}>{'Remove'}</RoleName>
          </OptionHeader>
          <RoleDescription style={{ color: colors.red['300'] }} isSelected={false}>
            {'Will be removed from your workspace'}
          </RoleDescription>
        </Option>
      </Contents>
    </Container>
  );
};

export default Panel;
