import React from 'react';
import Styles from './styles';
import { RightOutlined } from '@ant-design/icons';

const { Container, Label, Value } = Styles;

interface Props {
  panelId: string;
  label: string;
  value: string;
  onClick?: () => unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PanelLink: React.FC<Props> = ({ panelId, label, value, onClick }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Value>{value}</Value>
      <RightOutlined onClick={onClick} />
    </Container>
  );
};

PanelLink.displayName = 'PanelLink';
