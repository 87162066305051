import styled from 'styled-components';
import { Card } from '../../../shared/components/Card/Card';

export const Container = styled(Card)`
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  flex: 1;
`;
