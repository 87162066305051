import React, { useState } from 'react';
import { Container, ResendInviteButton, RevokeButton} from './style';

interface Props {
  onResend?: () => {};
  onRevoke?: () => void;
  loadingRevoke?: boolean;
  setLoadingRevoke?: () => {};
  setLoadingResend?: () => {};
  loadingResend?: boolean;
  disableUserInteractions?: boolean;
}

const ActionCell: React.FC<Props> = ({onResend, onRevoke, loadingRevoke, loadingResend, disableUserInteractions}) => {

  const [loading, setLoading] = useState(false)
 return (
    <Container>
      <ResendInviteButton onClick={()=> {if (onResend) onResend(); setLoading(true)}} loading={loadingResend || loading} disabled={disableUserInteractions}>Resend Invite</ResendInviteButton>
      <RevokeButton onClick={()=>{if (onRevoke) onRevoke(); setLoading(true)}} loading={loadingRevoke || loading} disabled={disableUserInteractions}>Revoke</RevokeButton>
    </Container>
 );
}

export default ActionCell;