interface ColorScale {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  950: string;
}

interface ThemeColors {
  primary: ColorScale;
  blue: ColorScale;
  violet: ColorScale;
  elephant: ColorScale;
  green: ColorScale;
  red: ColorScale;
  yellow: ColorScale;
  coolGray: ColorScale;
  gray: ColorScale;
  white: string;
  black: string;
}

export const colors: ThemeColors = {
  primary: {
    50: '#FFF8F6',
    100: '#FFF0EE',
    200: '#FFDAD4',
    300: '#FFC4BB',
    400: '#FF9887',
    500: '#FF6C54',
    600: '#E6614C',
    700: '#BF513F',
    800: '#994132',
    900: '#7D3529',
    950: '#60190D',
  },
  blue: {
    50: '#EDF9FF',
    100: '#D6F1FF',
    200: '#B6E8FF',
    300: '#84DBFF',
    400: '#4BC5FF',
    500: '#21A6FF',
    600: '#0988FF',
    700: '#0675FC',
    800: '#0A58C3',
    900: '#0F4D99',
    950: '#0E2F5D',
  },
  violet: {
    50: '#F3F2FF',
    100: '#EBE7FF',
    200: '#D8D2FF',
    300: '#BCAEFF',
    400: '#9B80FF',
    500: '#7C4DFF',
    600: '#7230FF',
    700: '#5E16EB',
    800: '#4F12C5',
    900: '#4F12C5',
    950: '#26076E',
  },
  elephant: {
    50: '#F2F9FD',
    100: '#E3F1FB',
    200: '#C1E3F6',
    300: '#8ACDEF',
    400: '#4CB3E4',
    500: '#249BD3',
    600: '#167CB3',
    700: '#136291',
    800: '#145478',
    900: '#164664',
    950: '#0D283B',
  },
  green: {
    50: '#EDFCF5',
    100: '#D2F9E5',
    200: '#AAF0D1',
    300: '#72E3B7',
    400: '#2FBB89',
    500: '#16B380',
    600: '#0A9168',
    700: '#087456',
    800: '#095C46',
    900: '#094B3B',
    950: '#032B21',
  },
  red: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FDCBCB',
    300: '#FBA6A6',
    400: '#F77272',
    500: '#EE4E4E',
    600: '#DA2828',
    700: '#B71E1E',
    800: '#981C1C',
    900: '#7E1E1E',
    950: '#440B0B',
  },
  yellow: {
    50: '#FFFCEB',
    100: '#FFF6C6',
    200: '#FFEC88',
    300: '#FFDC4A',
    400: '#FFCA20',
    500: '#F9AA0B',
    600: '#DD8002',
    700: '#B75A06',
    800: '#94440C',
    900: '#7A390D',
    950: '#461D02',
  },
  coolGray: {
    50: '#F3F4F5',
    100: '#E7EAEB',
    200: '#C3C9CE',
    300: '#9EA9B1',
    400: '#566976',
    500: '#0D283B',
    600: '#0C2435',
    700: '#0A1E2C',
    800: '#081823',
    900: '#06141D',
    950: '#030C11',
  },
  gray: {
    50: '#F8F8F8',
    100: '#F0F0F0',
    200: '#E4E4E4',
    300: '#D1D1D1',
    400: '#B4B4B4',
    500: '#A6A6A6',
    600: '#818181',
    700: '#6A6A6A',
    800: '#5A5A5A',
    900: '#4E4E4E',
    950: '#282828',
  },
  white: '#FFFFFF',
  black: '#000000',
};
