import { createContext } from 'react';
import { PropertyMappingStore, createPropertyMappingStore } from './property-mapping.store';

interface PropertyMappingContext {
  store: PropertyMappingStore;
}

export const propertyMappingContext = createContext<PropertyMappingContext>({
  store: createPropertyMappingStore(),
});
