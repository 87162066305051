import React from 'react';
import { Badge, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

interface Props {
  onClick?: () => unknown;
  count: number;
  label: string;
  className?: string;
}

const FilterButton: React.FC<Props> = ({ onClick, label, count, className }) => {
  return (
    <Badge count={count}>
      <Button className={className} onClick={onClick} icon={<FilterOutlined />}>
        <span>{label}</span>
      </Button>
    </Badge>
  );
};

export { FilterButton };
