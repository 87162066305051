import styled from 'styled-components';

const LibraryHeaderActionsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export default {
  LibraryHeaderActionsContainer,
};
