import styled from 'styled-components';

const Label = styled.div`
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const URL = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 2.5px 3px;
  border-radius: 2px;
  background: var(--N-200, #eff2f7);
  width: fit-content;
`;

export default { Label, URL, Section, Tags };
