import React, { useEffect, useState } from 'react';
import { MeetingTypeSelector } from '../../tabs/UpcomingsTab/components/MeetingTypeSelector';
import { Button } from 'antd';

interface Props {
  initialType: string | null;
  showTitle?: boolean;
  flex?: boolean;
  size?: 'small' | 'middle' | 'large';
  style?: React.CSSProperties;
  showAll?: boolean;
  onChange: (type: string | null) => void;
}

export const MeetingTypeFilter: React.FC<Props> = ({
  initialType,
  showAll,
  showTitle = true,
  flex = true,
  size = 'small',
  style = {},
  onChange,
}) => {
  const [type, setType] = useState<string | null>(initialType);

  useEffect(() => {
    setType(initialType);
  }, [initialType]);

  return (
    <div
      style={{
        display: 'flex',
        width: flex ? '100%' : 'auto',
        justifyContent: 'space-between',
        ...style,
      }}
    >
      {showTitle && <div>Type</div>}

      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
        <MeetingTypeSelector
          meetingType={type}
          onChange={onChange}
          size={size}
          showAll={showAll}
          eventId={''}
        />

        {initialType && (
          <Button
            type="primary"
            shape="circle"
            size="small"
            onClick={() => {
              onChange(null);
              setType(null);
            }}
          >
            X
          </Button>
        )}
      </div>
    </div>
  );
};
