import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingStatus } from '../../types/commonTypes';
import { Onboarding } from '../typings/onboarding';

export const initialState: Onboarding = {
  isLoading: false,
  error: undefined,
  currentStep: OnboardingStatus.Validation,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialState,
  reducers: {
    setCurrentStep(state) {
      // backend purposes only.
      state.isLoading = false;
      state.error = undefined;
    },
    setLoader(state, action: PayloadAction<{ value: boolean; error?: string }>) {
      state.isLoading = action.payload.value;
      state.error = action.payload.error;
    },
    restoreInitialState: state => {
      state.isLoading = false;
      state.error = undefined;
    },
  },
});

export const { setCurrentStep, setLoader } = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
