// Main colors
export const alert = 'rgb(255,84,84)';
export const black = 'rgb(24, 30, 36)';
export const blackLight = 'rgb(80, 89, 98)';
export const blackLighter = 'rgb(113, 121, 130)';
export const blue = 'rgb(6, 117, 252)';
export const blueLight = 'rgb(194, 222, 255)';
export const blueLighter = 'rgb(239, 246, 255)';
export const blueLightest = 'rgba(6, 117, 252, 0.5)';
export const cyanDark = 'rgb(81, 177, 188)';
export const cyanLight = 'rgb(244, 255, 252)';
export const cyanLightest = 'rgb(22, 187, 224)';
export const deepPink = 'rgb(207, 44, 109)';
export const error = 'rgb(255, 84, 84)';
export const gray = 'rgb(180, 180, 180)';
export const grayDark = 'rgb(184, 190, 199)';
export const grayDarker = 'rgb(163, 172, 181)';
export const grayDisabled = 'rgb(239, 242, 244)';
export const grayLight = 'rgb(205, 211, 219)';
export const grayLighter = 'rgb(223, 226, 230)';
export const green = 'rgb(60, 179, 113)';
export const obligatoryFieldColor = 'rgb(255, 108, 84)';
export const orangeLighter = 'rgb(255, 230, 226)';
export const orangePrimary = 'rgb(255, 108, 84)';
export const orangePrimaryLight = 'rgb(255, 244, 242)';
export const orangeSecondary = 'rgb(207,82,61)';
export const pink = 'rgb(253, 243, 248)';
export const primaryColor = 'rgb(50, 59, 67)';
export const violet = 'rgb(114, 48, 255)';
export const violetLight = 'rgba(114, 48, 255, 0.5)';
export const violetLighter = 'rgb(244, 239, 255)';
export const purple = 'rgb(134, 106, 215)';
export const purpleLight = 'rgb(230, 221, 255)';
export const red = 'rgb(255, 0, 0)';
export const redLight = 'rgb(212, 86, 86)';
export const secondColor = 'rgb(113, 121, 130)';
export const shadowColor = 'rgb(230, 230, 230)';
export const shadowLightColor = 'rgba(15, 33, 46, 0.06)';
export const success = 'rgb(82, 229, 106)';
export const successDark = '#068D95';
export const successBackground = '#BCF9FC';
export const white = 'rgb(255, 255, 255)';
export const whiteDark = 'rgb(250, 251, 253)';
export const whiteDarker = 'rgb(239, 242, 244)';
export const whiteDarkest = 'rgb(230, 233, 235)';
