import styled from 'styled-components';
import { colors } from '../../../../../../../../theme/colors';

const ChangeLabel = styled.span`
  cursor: pointer;
  color: ${colors.primary[500]};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-decoration-line: underline;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Visibility = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${colors.gray[950]};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
`;

export default { ChangeLabel, Visibility, Container };
