import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { TabsBarContainer } from './styled';
import { Tab } from './Tab';

export interface TabDefinition {
  key: string;
  label: string | React.ReactElement;
  children: React.ReactElement;
}

interface Props {
  defaultKey?: string;
  items: TabDefinition[];
}

export const TabsBar: React.FC<PropsWithChildren<Props>> = ({
  defaultKey,
  items,
  children: propsChildren,
}) => {
  const [activeKey, setActiveKey] = useState(defaultKey);

  useEffect(() => {
    const existingTab = items.find(tab => tab.key === activeKey);
    if (existingTab) {
      setActiveKey(defaultKey);
    } else {
      setActiveKey(items[0].key);
    }
  }, []);

  const children = useMemo(() => {
    const currentTabs = items.find(tab => tab.key === activeKey);
    return currentTabs?.children;
  }, [activeKey]);

  return (
    <>
      <TabsBarContainer>
        {items.map(item => {
          return (
            <Tab
              key={item.key}
              onClick={() => setActiveKey(item.key)}
              label={item.label}
              active={activeKey === item.key}
            ></Tab>
          );
        })}
        <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>{propsChildren}</div>
      </TabsBarContainer>
      {children}
    </>
  );
};
