import React from 'react';
import { TopTopics } from '../../../../features/topics/components/TopTopics';
import { Flex } from 'antd';

export const TopTopicsByTypeSection: React.FC<{
  filters: {
    from: string;
    to: string;
    accountId?: string;
    crmFilters?: Record<string, string[]>;
  };
  onTopicSelected: (topic: { id: string; name: string }) => unknown;
}> = ({ filters, onTopicSelected }) => {
  return (
    <>
      <Flex gap={'16px'}>
        <TopTopics insightType="Risks" filters={filters} onTopicSelected={onTopicSelected} />

        <TopTopics
          insightType="Product Feedback"
          filters={filters}
          onTopicSelected={onTopicSelected}
        />
      </Flex>

      <Flex gap={'16px'}>
        <TopTopics insightType="Advocacy" filters={filters} onTopicSelected={onTopicSelected} />

        <TopTopics
          insightType="Growth Opportunities"
          filters={filters}
          onTopicSelected={onTopicSelected}
        />
      </Flex>
    </>
  );
};
