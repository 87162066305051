import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Label = styled.span`
  color: ${colors.coolGray[400]};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CopyKey = styled.div`
  display: flex;
  gap: 5px;
  color: ${colors.primary[500]};

  font-size: 12px;
  cursor: pointer;

  :hover {
    font-weight: 700;
  }

  transition: all 0.2s ease-in-out;
`;

export default {
  Label,
  Container,
  Data,
  CopyKey,
};
