import { UpgradeToPro } from '../../../../../componentsV2/UpgradeToPro';
import { MessagesList } from '../AskMeAnythingModal/MessagesList';
import { Button } from 'antd';
import { ClearOutlined, CopyFilled, SendOutlined } from '@ant-design/icons';
import { SuggestedQuestions } from '../SuggestedQuestions';
import { useState } from 'react';
import { useToast } from '../../../../../hooks/useToast';
import { useAskMeAnything } from '../../../../askMeAnything/hooks/useAskMeAnything';
import { ChatItem } from '../../../../../services/types';
import useFeatures from '../../../../../hooks/useFeatures';

import Styles from './styles';
const { ChatContainer, ChatInput, ActionButtonsContainenr, ChatActionsContainer, BottomContainer } =
  Styles;

interface Props {
  askFunction: (chatMessages: ChatItem[]) => Promise<ReadableStream<Uint8Array> | null>;
  location: string;
}

export const AskMeAnythingChat: React.FC<Props> = ({ askFunction, location }) => {
  const { success } = useToast();
  const {
    messages,
    isAsking,
    suggestedQuestions,
    defaultCategory,
    suggestedQuestionsDisplayDropdown,
    ask,
    clearMessages,
    copyAll,
  } = useAskMeAnything();

  const buttonLabel = isAsking ? 'Waiting for response' : 'Submit';
  const noMessages = messages.length === 0;

  const features = useFeatures();
  const availableFeature = features.ama;

  const [question, setQuestion] = useState('');

  const sendMessage = () => {
    if (!isAsking) {
      ask({ question: question, askFunction: askFunction, location: location });
      setQuestion('');
    }
  };

  const handleCopyAll = () => {
    copyAll();
    success('Messages copied to clipboard');
  };

  return (
    <UpgradeToPro plan="pro" display={!availableFeature}>
      <>
        <MessagesList messages={messages} />
        <ChatActionsContainer>
          <Button
            disabled={noMessages}
            icon={<CopyFilled />}
            onClick={handleCopyAll}
            style={{ borderRadius: '8px' }}
          >
            Copy All
          </Button>

          <Button
            disabled={noMessages}
            icon={<ClearOutlined />}
            onClick={clearMessages}
            style={{ borderRadius: '8px' }}
          >
            Clear Chat
          </Button>
        </ChatActionsContainer>
        <BottomContainer>
          <SuggestedQuestions
            send={m => {
              ask({
                question: m,
                askFunction: askFunction,
                suggestedQuestion: true,
                location: location,
              });
            }}
            display={suggestedQuestionsDisplayDropdown}
            disabled={isAsking}
            context={suggestedQuestions!}
            defaultCategory={defaultCategory!}
          />

          <ChatContainer>
            <ChatInput
              value={question}
              onKeyDown={ev => {
                if (ev.key === 'Enter' && !ev.shiftKey) {
                  ev.stopPropagation();
                  ev.preventDefault();
                  sendMessage();
                }
              }}
              onChange={ev => {
                setQuestion(ev.target.value);
              }}
              placeholder="Enter your prompt..."
            />

            <ActionButtonsContainenr>
              <Button
                onClick={sendMessage}
                icon={<SendOutlined />}
                style={{ width: '200px', height: '40px', borderRadius: '8px' }}
                disabled={!question || isAsking}
                type="primary"
                loading={isAsking}
              >
                {buttonLabel}
              </Button>
            </ActionButtonsContainenr>
          </ChatContainer>
        </BottomContainer>
      </>
    </UpgradeToPro>
  );
};
