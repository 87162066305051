import styled from 'styled-components';
import { colors } from '../../../../../theme/colors';

const InvitationsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 370px;
  overflow-y: auto;
`;

const CreateOrganizationLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  width: 100%;
  text-decoration-line: underline;
  color: ${colors.primary[500]};
  margin-bottom: 35px;
  cursor: pointer;
`;

export default { InvitationsList, CreateOrganizationLink };
