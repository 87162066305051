import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.span`
  color: var(--Cool-Gray-500, #0d283b);
  font-family: 'Cera Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SummaryContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Cool-Gray-50, #f3f4f5);
`;

const Summary = styled.p`
  white-space: pre-wrap;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const SummaryTextActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default {
  Container,
  SummaryContainer,
  Title,
  Summary,
  ActionsContainer,
  SummaryTextActionsContainer,
};
