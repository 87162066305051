import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-block-start: 0px;
  margin-block-end: 0px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  text-align: center;
`;

const ConnectLabel = styled.span`
  color: #ff6c54;
  font-size: 14px;
  line-height: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Action = styled(Space)`
  cursor: pointer;
`;

const PlusIcon = styled(PlusOutlined)`
  color: #ff6c54;
`;

export default {
  Title,
  Subtitle,
  Container,
  Contents,
  MessageContainer,
  ConnectLabel,
  ActionContainer,
  PlusIcon,
  Action,
};
