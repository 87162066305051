import React, { useEffect, useState } from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { extractError } from '../../../../../../utils/api';
import { useToast } from '../../../../../../hooks/useToast';
import IconSvg from '../../../../../../components/common/IconSvg';
import { Button } from 'antd';

const { DescriptionText, Label, StyledInput, Title, Buttons, Container, Link } = Styled;

export const MyOrganizationSection: React.FC<{}> = () => {
  const { name, changeName, permissions } = useOrganizationDetails();

  const { success, error } = useToast();

  const [organizationNameInput, setOrganizationNameInput] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOrganizationNameInput(name);
  }, [name]);

  const disableUserInteractions = !permissions.manageOrganizationDetails;

  const handleSaveOrganizationName = async () => {
    try {
      setLoading(true);
      await changeName(organizationNameInput);
      success('Workspace updated successfully');
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    }
    setLoading(false);
  };

  const handleOrganizationNameInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationNameInput(e.target.value);
  };

  const handleCancel = () => {
    setOrganizationNameInput(name);
  };

  const nameHasChanged = organizationNameInput !== name;

  return (
    <Container>
      <Section
        title="My Workspace"
        description="Presenting the ins and outs of your workspace account!"
        learnMore="https://help.update.ai/en/articles/8047684-what-is-an-organization-in-updateai"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 16px 0px 16px',
            gap: '12px',
          }}
        >
          {!disableUserInteractions ? (
            <>
              <StyledInput
                label="Workspace name"
                inputValue={organizationNameInput}
                onChange={handleOrganizationNameInputChanged}
                id="organizationNameInput"
              />
              <DescriptionText>
                Enter your company name. Or use your own name if you're keeping this space to
                yourself.
              </DescriptionText>
            </>
          ) : (
            <>
              <Label>Workspace Name</Label>
              <Title>
                <IconSvg name="organization" size="mdl" color="transparent"></IconSvg>
                {organizationNameInput}
              </Title>
            </>
          )}

          <Buttons>
            <Button
              type="primary"
              loading={loading}
              disabled={disableUserInteractions || !nameHasChanged}
              onClick={handleSaveOrganizationName}
            >
              Save Changes
            </Button>

            <Button disabled={!nameHasChanged} onClick={handleCancel}>
              Cancel
            </Button>
          </Buttons>

          <Link
            href="https://help.update.ai/en/articles/8096616-understanding-organizations-in-updateai-collaboration-privacy-and-workspace-functionality"
            target="_blank"
          >
            Create a new workspace
          </Link>
        </div>
      </Section>
    </Container>
  );
};
