import { produce } from 'immer';
import { DateRangeIntervals, KeyToFilterConfig, KeyToFilterStore } from './types';
import { create } from 'zustand';
import { filtersConfigToStore } from './dashboard-filters.utils';
import { ProviderIntegration } from '../../../redux/typings/store';

interface DashboardFiltersState {
  filters: KeyToFilterStore;
  dateFilterChanged: (filterId: string, intervals: DateRangeIntervals) => void;
  listFilterSelectionChanged: (filterId: string, optionIds: string[]) => void;
  dateFilterSelectionChanged: (filterId: string, dates: [Date, Date]) => void;
  accountFilterSelectionChanged: (filterId: string, selected: string | undefined) => void;
  numberFilterValueChanged: (filterId: string, value: number | null) => void;
  rangeNumberFilterValueChanged: (filterId: string, value: [number, number]) => void;
  selectFilterSelectionChanged: (filterId: string, selected: number | string) => void;
  meetingTypeChanged: (filterId: string, selected: string | null) => void;
  filtersChanged: (filters: KeyToFilterConfig) => void;
}

export type DashboardFiltersStore = ReturnType<typeof createStore>;

type CreateStoreArgs = {
  filters: KeyToFilterConfig;
};

export const createStore = (args: CreateStoreArgs) => {
  const filters = filtersConfigToStore(args.filters);

  return create<DashboardFiltersState>(set => {
    return {
      filters,
      dateFilterChanged: (filterId, intervals) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'dateRange') {
              filter.value.interval = intervals;
            }
          }),
        );
      },
      rangeNumberFilterValueChanged: (filterId, intervals) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'numberRange') {
              filter.value.interval = intervals;
            }
          }),
        );
      },
      filtersChanged: filters => {
        set({ filters: filtersConfigToStore(filters) });
      },
      listFilterSelectionChanged: (filterId, optionIds) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'list') {
              filter.value.options = filter.value.options.map(option => {
                option.selected = optionIds.includes(option.id);
                return option;
              });
            }
          }),
        );
      },
      accountFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'account') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      dateFilterSelectionChanged: (filterId, dates) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'dateRange') {
              filter.value.interval = dates;
            }
          }),
        );
      },
      numberFilterValueChanged: (filterId, value) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'number') {
              filter.value.value = value;
            }
          }),
        );
      },
      selectFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'select') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      meetingTypeChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'meetingType') {
              filter.value.selected = selected;
            }
          }),
        );
      },
    };
  });
};
