import styled from 'styled-components';

const EllipsisButton = styled.span`
  color: var(--P-500, #ff6c54);
  font-variant-numeric: stacked-fractions;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border-radius: 2px;
  background: var(--P-200, #ffe6e2);
  padding: 2px;
`;

export default {
  EllipsisButton,
};
