import { call, put, retry, select, takeLatest } from 'redux-saga/effects';
import {
  fetchRecap as fetchRecapAction,
  fetchRecapSuccess as fetchRecapSuccessAction,
  fetchRecapError as fetchRecapErrorAction,
  updateNote,
  updateType,
  updateActionItem,
  removeActionItemBullet,
  removeSummaryItem,
  editSummaryItem,
  removeMoment,
  editMoment,
  addSummaryItem,
  replaceSummaryItemId,
  addMoment,
  replaceMomentId,
  addActionItem,
  replaceActionItemId,
} from '../slices/recapSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { Event } from '../typings/recap';
import { coreAPI } from '../../API/core';
import {
  UpdateEventTypeAction,
  UpdateEventNoteAction,
  UpdateActionItemAction,
  RemoveActionItemAction,
  RemoveSummaryItemAction,
  EditSummaryItemAction,
  RemoveMomentAction,
  EditMomentAction,
} from '../actions/recap';
import { v4 } from 'uuid';
import { Store } from '../typings/store';

const RETRY_TIMES = 3;
const INTERVAL_HALF_SECOND = 500;

export type FetchEventsType = {
  page: number;
  search: string;
  limit: number;
  showAllStatus: boolean;
  hostOnly: boolean;
  account?: string;
  domain?: string;
  from?: string;
  to?: string;
};

function* fetchRecap(action: PayloadAction<{ id: string; secret: string | null }>) {
  try {
    const API = new coreAPI();

    const event: Event = yield retry(
      RETRY_TIMES,
      INTERVAL_HALF_SECOND,
      API.fetchRecap,
      action.payload.id,
      action.payload.secret,
    );
    yield put(fetchRecapSuccessAction({ eventData: event }));
  } catch (error) {
    yield put(
      fetchRecapErrorAction({
        error: `Error while fetching meeting:\n${action.payload.id}.`,
      }),
    );
  }
}

function* updateNoteSaga(action: UpdateEventNoteAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(updateNote(action.payload));
    yield call([api, api.updateEventNote], eventId, action.payload.note);
  } catch {}
}

function* updateTypeSaga(action: UpdateEventTypeAction) {
  const previousType: string = yield select((store: Store) => store.recap.event.type);
  try {
    const api = new coreAPI();

    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(updateType(action.payload));
    yield call([api, api.updateMeetingType], eventId, action.payload.type);
  } catch {
    yield put(updateType({ type: previousType }));
  }
}

function* updateActionItemSaga(action: UpdateActionItemAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(updateActionItem(action.payload));
    yield call(
      [api, api.editActionItem],
      eventId,
      action.payload.actionItemId,
      action.payload.text,
    );
  } catch {}
}

function* removeActionItemSaga(action: RemoveActionItemAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(removeActionItemBullet(action.payload));
    yield call([api, api.removeActionItemBullet], eventId, action.payload.actionItemId);
  } catch {}
}

function* removeSummaryItemSaga(action: RemoveSummaryItemAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(removeSummaryItem(action.payload));
    yield call([api, api.removeSummaryItem], eventId, action.payload.summaryItemId);
  } catch {}
}

function* createSummaryItemSaga() {
  try {
    const api = new coreAPI();
    const tempId = v4();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(addSummaryItem({ id: tempId, text: 'Add an item of your own' }));
    const id: string = yield call([api, api.addSummaryItem], eventId);
    yield put(replaceSummaryItemId({ oldId: tempId, newId: id }));
  } catch {}
}

function* createMomentSaga(action: PayloadAction<{ category: string }>) {
  const { category } = action.payload;
  try {
    const api = new coreAPI();
    const tempId = v4();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(addMoment({ id: tempId, text: 'Add an item of your own', category }));
    const id: string = yield call([api, api.addMoment], eventId, category);
    yield put(replaceMomentId({ oldId: tempId, newId: id }));
  } catch {}
}

function* createActionItemSaga() {
  try {
    const api = new coreAPI();
    const tempId = v4();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(addActionItem({ id: tempId, text: 'Add an item of your own' }));
    const id: string = yield call([api, api.addActionItem], eventId);
    yield put(replaceActionItemId({ oldId: tempId, newId: id }));
  } catch {}
}

function* removeMomentSaga(action: RemoveMomentAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(removeMoment(action.payload));
    yield call([api, api.removeMoment], eventId, action.payload.momentId);
  } catch {}
}

function* editMomentSaga(action: EditMomentAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(editMoment(action.payload));
    yield call(
      [api, api.editMoment],
      eventId,
      action.payload.momentId,
      action.payload.category,
      action.payload.text,
    );
  } catch {}
}

function* editSummaryItemSaga(action: EditSummaryItemAction) {
  try {
    const api = new coreAPI();
    const eventId: string = yield select((store: Store) => store.recap.event.id);
    yield put(editSummaryItem(action.payload));
    yield call(
      [api, api.editSummaryItem],
      eventId,
      action.payload.summaryItemId,
      action.payload.text,
    );
  } catch {}
}

export function* meetingsSaga() {
  yield takeLatest(fetchRecapAction.type, fetchRecap);
  yield takeLatest('UPDATE_EVENT_NOTE_REQUEST', updateNoteSaga);
  yield takeLatest('UPDATE_EVENT_TYPE_REQUEST', updateTypeSaga);
  yield takeLatest('UPDATE_ACTION_ITEM', updateActionItemSaga);
  yield takeLatest('REMOVE_ACTION_ITEM_REQUEST', removeActionItemSaga);
  yield takeLatest('REMOVE_SUMMARY_ITEM_REQUEST', removeSummaryItemSaga);
  yield takeLatest('EDIT_SUMMARY_ITEM_REQUEST', editSummaryItemSaga);
  yield takeLatest('REMOVE_MOMENT_REQUEST', removeMomentSaga);
  yield takeLatest('EDIT_MOMENT_REQUEST', editMomentSaga);
  yield takeLatest('ADD_SUMMARY_ITEM_REQUEST', createSummaryItemSaga);
  yield takeLatest('ADD_ACTION_ITEM_REQUEST', createActionItemSaga);
  yield takeLatest('ADD_MOMENT_REQUEST', createMomentSaga);
}
