import { useLocation } from 'react-router-dom';
import { useAnalytics } from './useAnalytics';
import { useEffect } from 'react';

export const useLocationChange = () => {
  const { page } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    if (location) page.viewed(location.pathname.split('/')[1]);
  }, []);
};
