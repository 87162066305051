import React, { useMemo } from 'react';
import { Button } from 'antd';
import { colors } from '../../theme/colors';
import Style from './style';

interface Props {
  plan: 'pro' | 'startup' | 'business' | 'enterprise';
  display: boolean;
  children?: React.ReactElement | null;
}

const Card: React.FC<Props> = ({ plan }) => {
  const { CardContainer } = Style;

  const planContent = useMemo(() => {
    switch (plan) {
      case 'pro':
        return (
          <>
            <h1>Upgrade to Pro to Save More Time</h1>
            <h2>
              The UpdateAI Pro subscription transforms your workflow through even more AI automation
            </h2>
            <ul>
              <li>Unlimited meeting recaps with AI insights</li>
              <li>Answer any question about any meeting through our ‘Ask Me Anything’ engine</li>
              <li>Receive a meeting prep cheatsheet before the beginning of each day</li>
              <li>Fill out the MEDDICC framework from your meeting notes automatically</li>
              <li>Share your insights through our Slack integration</li>
              <li>Get prioritized email support</li>
            </ul>
          </>
        );
      case 'startup':
        return (
          <>
            <h1>Upgrade to a Team Plan</h1>
            <h2>
              Our team plans are built to remove the gruntwork of customer calls and eliminate
              blindspots
            </h2>
            <ul>
              <li>Historical AI knowledge bases of each customer account</li>
              <li>AI-generated profiles of each client stakeholder</li>
              <li>Push meeting notes to your CRM</li>
            </ul>
            <Button
              type="primary"
              onClick={() => window.open('https://www.update.ai/book-a-demo/paywall', '_self')}
            >
              Learn more
            </Button>
          </>
        );
      case 'business':
        return (
          <>
            <h1>Upgrade to a Business or Enterprise Plan</h1>
            <h2>Analyze and act on the trends and patterns of what your customers are saying</h2>
            <ul>
              <li>
                Get a bird's-eye view into the trends in Product Feedback, Risk, and Opportunity
                across all meetings
              </li>
              <li>Push information to anywhere (e.g. Zapier, API)</li>
              <li>Create custom insight categories</li>
              <li>SSO / SCIM integration</li>
              <li>Dedicated Customer Success Manager</li>
            </ul>
            <Button
              type="primary"
              onClick={() => window.open('https://www.update.ai/book-a-demo/paywall', '_self')}
            >
              Learn more
            </Button>
          </>
        );
    }
  }, [plan]);

  return (
    <CardContainer>
      {planContent}
      {/* <Button type="primary" onClick={() => window.open('/billing')}>
        Upgrade to PRO!
      </Button> */}
    </CardContainer>
  );
};

export const UpgradeToProBadge: React.FC = () => {
  const { Badge } = Style;
  return <Badge>Upgrade</Badge>;
};

export const SubscribeToProPlanLink: React.FC = () => {
  return (
    <div>
      <a
        style={{ color: colors.primary[500] }}
        href="/settings?tab=plan-and-billing"
        target="_self"
      >
        Upgrade to a Pro plan
      </a>{' '}
      for access or{' '}
      <a
        style={{ color: colors.primary[500] }}
        href="https://www.update.ai/book-a-demo/paywall"
        target="_self"
      >
        learn about our team plans.
      </a>
    </div>
  );
};

export const SubscribeToTeamsPlanLink: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div>
      {title ? title : 'Paid team plan required.'}
      <br></br>
      <a style={{ color: colors.primary[500] }} href="https://www.update.ai/book-a-demo/paywall">
        Learn more.
      </a>
    </div>
  );
};

export const UpgradeToPro: React.FC<Props> = ({ children, plan, display }) => {
  if (display) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          padding: '0px',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card plan={plan} display={true} />
        </div>

        {children && (
          <div
            style={{
              position: 'relative',
              filter: 'blur(5px)',
              pointerEvents: 'none',
              padding: '10px',
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
