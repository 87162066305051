/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

export interface IInsightsAnalytics {
  portfolio: {
    filtered: (field: string) => void;
  };
  summary: {
    regenerated: (type: any) => void;
    playlist: {
      viewed: (type: any) => void;
    };
  };
}

export const useInsightsAnalytics = () => {
  const { sendEvent } = useAnalytics();

  return {
    portfolio: {
      filtered: (field: string) => {
        sendEvent('Portfolio Filtered', {
          event: {
            'field filtered': field,
          },
        });
      },
    },
    summary: {
      regenerated: (type: any) => {
        sendEvent('AI Summary Regenerated', {
          event: {
            location: 'insights',
            ...type,
          },
        });
      },
      playlist: {
        viewed: (type: any) => {
          sendEvent('Playlist Viewed', {
            event: {
              location: 'insights',
              ...type,
            },
          });
        },
      },
    },
  } as IInsightsAnalytics;
};
