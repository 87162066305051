import { useContext } from 'react';
import { automaticPlayContext } from './automatic-play.context';
import { useStore } from 'zustand';

export const useRecapInsightAutomaticPlay = () => {
  const { store } = useContext(automaticPlayContext);
  const itemPlayed = useStore(store, store => store.itemPlayed);
  const played = useStore(store, store => store.played);

  return {
    played,
    itemPlayed,
  };
};
