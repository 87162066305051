import { DatePicker } from 'antd';
import React from 'react';
import { addDays, isValid } from 'date-fns';
import dayjs from 'dayjs';
import { setToZeroSeconds } from '../../../../utils/dateUtils';

interface Props {
  pastOnly?: boolean;
  futureOnly?: boolean;
  className?: string;
  handleCalendarChange: (range: [Date, Date] | [null, null]) => void;
  defaultFrom?: Date | null;
  defaultTo?: Date | null;
  bordered?: boolean;
  style?: React.CSSProperties;
}

const { RangePicker } = DatePicker;

export const DateSelect: React.FC<Props> = ({
  pastOnly = false,
  futureOnly = false,
  handleCalendarChange,
  defaultFrom,
  className,
  defaultTo,
  bordered,
  style,
}) => {
  const DAY_MILISECONDS = 86400000;

  return (
    <RangePicker
      bordered={bordered}
      style={style}
      className={className}
      disabledDate={d =>
        !d ||
        (pastOnly && d.isAfter(setToZeroSeconds(addDays(Date.now(), 1)))) ||
        (futureOnly && d.isBefore(Date.now() - DAY_MILISECONDS))
      }
      // eslint-disable-next-line
      onCalendarChange={(e: any) => {
        if (e?.length === 2) {
          const start = new Date(new Date(e[0]?.$d).setUTCHours(0, 0, 0, 0));
          const end = new Date(new Date(e[1]?.$d).setUTCHours(23, 59, 59, 999));
          if (isValid(start) && isValid(end)) handleCalendarChange([start, end]);
        }
      }}
      defaultValue={defaultFrom && defaultTo ? [dayjs(defaultFrom), dayjs(defaultTo)] : undefined}
    />
  );
};
