import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
`;

export const AlertLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  min-width: 50px;
  max-width: 50px;
  min-height: 45px;
  max-height: 45px;
  max-width: 50px;
  max-height: 50px;
  background: #fee2e2;
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 8px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
`;
export const Detail = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  white-space: pre-wrap;
`;
export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;

export const CancelButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 13px;

  width: 82px;
  height: 38px;

  background: #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
`;

export const ConfirmDeleteButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;

  width: 153px;
  height: 38px;
  background: #ee4e4e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;
