import { put, retry, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { coreAPI } from '../../API/core';
import {
  acceptInvitationSuccess,
  createOrganization as createOrganizationAction,
  createOrganizationError,
  createOrganizationSuccess,
  fetchInvitations,
  fetchInvitationsError,
  fetchInvitationsSuccess,
  inviteMember as inviteMemberAction,
  acceptInvitation as acceptInvitationAction,
  declineInvitation as declineInvitationAction,
  inviteMemberError,
  inviteMemberSuccess,
  setPrimary as setPrimaryAction,
  setPrimaryError,
  setPrimarySuccess,
  declineInvitationSuccess,
  declineInvitationError,
  fetchOrganizationData,
  fetchOrganizationDataSuccess,
  fetchOrganizationDataError,
  deleteOrganizationSuccess,
  deleteOrganizationError,
  deleteOrganization,
  inviteMemberClear,
  updateOrganization,
  updateOrganizationSuccess,
  removeMemberSuccess,
  removeMember,
  removeMemberError,
} from '../slices/organizationsSlice';
import { Organization, OrganizationInvite, OrganizationMember } from '../typings/session';
import { fetchUser } from '../slices/sessionSlice';
import { AxiosError } from 'axios';

const RETRY_TIMES = 3;
const INTERVAL_HALF_A_SECOND = 500;

function* getOrganizationData(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();
    const organization: Organization = yield retry(
      RETRY_TIMES,
      INTERVAL_HALF_A_SECOND,
      API.getOrganization,
      action.payload,
    );
    yield put(fetchOrganizationDataSuccess(organization));
  } catch (error) {
    yield put(fetchOrganizationDataError());
  }
}

// Create organization.
function* createOrganization(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();
    const organization: Organization = yield retry(
      RETRY_TIMES,
      INTERVAL_HALF_A_SECOND,
      API.createOrganization,
      action.payload,
    );
    yield put(createOrganizationSuccess(organization));
    yield put(fetchUser());
  } catch (error) {
    yield put(createOrganizationError());
  }
}

function* removeOrganization(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();
    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.deleteOrganization, action.payload);
    yield put(deleteOrganizationSuccess());
    yield put(fetchUser());
  } catch (error) {
    yield put(deleteOrganizationError());
  }
}

function* patchOrganization(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();
    const updatedName = action.payload.name;
    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.patchOrganization, action.payload);
    yield put(updateOrganizationSuccess({ name: updatedName }));
    yield put(fetchUser());
  } catch (error) {
    yield put(deleteOrganizationError());
  }
}

function* inviteMember(action: PayloadAction<OrganizationInvite>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.inviteUserToOrganization, action.payload);
    yield put(inviteMemberSuccess());
    yield put(inviteMemberClear());
    yield put(fetchOrganizationData());
  } catch (error) {
    const err = error as AxiosError;
    yield put(inviteMemberError({ error: err.response?.data?.message }));
  }
}

function* acceptInvitation(action: PayloadAction<OrganizationInvite>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.acceptInvitation, action.payload);
    yield put(acceptInvitationSuccess());
    yield put(fetchUser());
    yield put(fetchInvitations());
  } catch (error) {
    yield put(inviteMemberError({ error: 'Invalid email or password.' }));
  }
}

function* declineInvitation(action: PayloadAction<OrganizationInvite>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.declineInvitation, action.payload);
    yield put(declineInvitationSuccess());
    yield put(fetchInvitations());
  } catch (error) {
    yield put(declineInvitationError());
  }
}

function* deleteMember(action: PayloadAction<OrganizationMember>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.removeMember, action.payload);
    yield put(removeMemberSuccess());
    yield put(fetchOrganizationData());
  } catch (error) {
    yield put(removeMemberError({ error: 'Cannot remove member' }));
  }
}

function* setPrimary(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();

    yield retry(RETRY_TIMES, INTERVAL_HALF_A_SECOND, API.setPrimaryOrganization, action.payload);
    yield put(setPrimarySuccess());
    yield put(fetchUser());
  } catch (error) {
    yield put(setPrimaryError());
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getUserInvitations(action: PayloadAction<Organization>) {
  try {
    const API = new coreAPI();

    const { invitations } = yield retry(
      RETRY_TIMES,
      INTERVAL_HALF_A_SECOND,
      API.getUserInvitations,
    );
    yield put(fetchInvitationsSuccess({ invitations }));
    // yield put(fetchUser());
  } catch (error) {
    yield put(fetchInvitationsError());
  }
}

export function* organizationsSaga() {
  yield takeLatest(fetchOrganizationData.type, getOrganizationData);
  yield takeLatest(createOrganizationAction.type, createOrganization);
  yield takeLatest(inviteMemberAction.type, inviteMember);
  yield takeLatest(setPrimaryAction.type, setPrimary);
  yield takeLatest(fetchInvitations.type, getUserInvitations);
  yield takeLatest(acceptInvitationAction.type, acceptInvitation);
  yield takeLatest(declineInvitationAction.type, declineInvitation);
  yield takeLatest(deleteOrganization.type, removeOrganization);
  yield takeLatest(updateOrganization.type, patchOrganization);
  yield takeLatest(removeMember.type, deleteMember);
}
