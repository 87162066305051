import React from 'react';
import { Container, DateText} from './style';

interface Props {
  date: string;
}

const DateCell: React.FC<Props> = ({date}) => {

 return (
    <Container>
      <DateText>{new Date(date).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})}</DateText>
    </Container>
 );
}

export default DateCell;