import React from 'react';
import { Hubspot } from '../../../shared/components/Icons/Hubspot';
import { Salesforce } from '../../../shared/components/Icons/Salesforce';
import { Email } from '../../../shared/components/Icons/Email';

interface Props {
  provider: string;
  styles?: React.CSSProperties;
}

export const AccountIcon: React.FC<Props> = ({ provider, styles = {} }) => {
  if (provider === 'hubspot') {
    return <Hubspot style={{ display: 'flex', justifyContent: 'center', ...styles }} />;
  }

  if (provider === 'salesforce') {
    return <Salesforce style={{ display: 'flex', justifyContent: 'center', ...styles }} />;
  }

  return <Email style={{ ...styles }} />;
};
