import { create } from 'zustand';
import {
  AccountFavouritesInitialState,
  AccountFavouritesStore as FavouritesStoreType,
} from './account-favourites-types';

export const createAccountFavouritesStore = () => {
  return create<FavouritesStoreType>(set => ({
    ...AccountFavouritesInitialState,
    dataLoaded: (store: FavouritesStoreType) => set({ ...store }),
    removeFavourite: (id: string) =>
      set(state => {
        const accounts = state.accounts.filter(account => account.id !== id);
        return { accounts };
      }),
    setRecord: (id: string, value: boolean) =>
      set(state => {
        const accounts = state.accounts.map(account => {
          if (account.nextMeeting?.id === id) {
            account.nextMeeting.record.value = value;
          }
          return account;
        });
        return { accounts };
      }),
    setRegenerating: (id: string) =>
      set(state => {
        const accounts = state.accounts.map(account => {
          if (account.id === id) {
            account.storyIsGenerating = true;
          }
          return account;
        });
        return { accounts };
      }),
  }));
};
