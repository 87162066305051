import { PropsWithChildren, createContext, useRef } from 'react';
import { AccountStoryStore, createAccountStoryStore } from '../store';

interface AccountStoryContext {
  store: AccountStoryStore;
}

export const accountStoryContext = createContext<AccountStoryContext>({
  store: createAccountStoryStore(null),
});

interface Props {
  accountId: string | null;
}

export const AccountStoryProvider: React.FC<PropsWithChildren<Props>> = ({
  accountId,
  children,
}) => {
  const account = useRef(createAccountStoryStore(accountId)).current;

  return (
    <accountStoryContext.Provider value={{ store: account }}>
      {children}
    </accountStoryContext.Provider>
  );
};
