import { Select, Spin } from 'antd';
import { Card } from '../../../../components/Card';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { useCustomerIntelligenceData } from '../../../../features/insights/hooks/useCustomerIntelligenceData';
import { useEffect, useMemo, useRef } from 'react';
import { CumulativeChart } from '../CumulativeChart';
import { Summary } from '../Summary';
import { ExportDropdown } from '../ExportDropdown';
import { storeToInsightArgs } from '../../../../features/insights/context/CustomerIntelligenceProvider/utils';
import { coreService } from '../../../../services/core/core-service';
import { json2csv } from 'json-2-csv';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';

import Styles from './styles';
const { Filters, Contents, ChartContainer, SpinnerContainer } = Styles;

export const SubCategoriesCard: React.FC = () => {
  const {
    category,
    selectGroup,
    group,
    contractValueMax,
    contractValueMin,
    contractRenewalDateBefore,
    members,
    lifecycleStages,
    accounts,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    priorities,
  } = useCustomerIntelligenceFilters();
  const { summary } = useInsightsAnalytics();
  const { groups, isFetching } = useCustomerIntelligenceData();
  const chartRef = useRef<HTMLDivElement>(null);

  const clearDependencies = useMemo(() => {
    return [
      category,
      contractValueMax,
      contractValueMin,
      contractRenewalDateBefore,
      members,
      lifecycleStages,
      accounts,
      dateRange,
      industries,
      meetingsImInvitedOnly,
      priorities,
      group,
    ];
  }, [
    category,
    contractValueMax,
    contractValueMin,
    contractRenewalDateBefore,
    members,
    lifecycleStages,
    accounts,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    priorities,
    group,
  ]);

  useEffect(() => {
    if (groups.length) {
      if (group) {
        const existingGroup = groups.find(g => g.name === group);
        if (!existingGroup) {
          selectGroup(groups[0].name);
        }
      }
      if (!group) {
        selectGroup(groups[0].name);
      }
    }
  }, [groups]);

  const { cumulativeSeries } = useMemo(() => {
    const existingGroup = groups.find(gr => gr.name === group);
    if (!existingGroup) {
      return {
        cumulativeSeries: [],
      };
    }

    const cumulativeSeries = existingGroup.subGroups.map(gr => {
      return {
        name: gr.name,
        total: gr.count,
      };
    });
    return { cumulativeSeries };
  }, [groups, group]);

  const handleSelectedGroup = (groupName: string) => {
    selectGroup(groupName);
  };

  return (
    <Card title={`${category} Subcategories`}>
      <Contents>
        <Filters>
          <span>View subcategories patterns associated with:</span>
          <Select
            value={group}
            style={{ width: '300px' }}
            onChange={value => handleSelectedGroup(value)}
            options={groups.map(group => ({ value: group.name, key: group.name }))}
          />
          <ExportDropdown
            formatCsvFn={() => json2csv(cumulativeSeries)}
            chartRef={chartRef.current}
          />
        </Filters>
      </Contents>
      <ChartContainer ref={chartRef}>
        {isFetching ? (
          <SpinnerContainer>
            <Spin />
          </SpinnerContainer>
        ) : (
          <CumulativeChart data={{ series: cumulativeSeries }} />
        )}
      </ChartContainer>
      <Summary
        clearDependencies={clearDependencies}
        trackGenerateClick={() => summary.regenerated({ category: group, type: category })}
        generateFn={async () => {
          const insightArgs = storeToInsightArgs();
          if (insightArgs) {
            return coreService.summarizeItems(insightArgs).then(response => {
              return response.summary;
            });
          }
          return '';
        }}
        watchItemsFn={async () => {
          summary.playlist.viewed({ category: group, type: category });
          const insightArgs = storeToInsightArgs();
          if (insightArgs) {
            return coreService.getInsightsItems(insightArgs).then(response => {
              return response.items.map(ri => ({
                ...ri,
              }));
            });
          }
          return [];
        }}
      />
    </Card>
  );
};
