import { useContext } from 'react';
import { inviteBotContext } from './invite-bot.context';

export const useInviteBot = () => {
  const { open, show, close } = useContext(inviteBotContext);
  return {
    open,
    show,
    close,
  };
};
