import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 24px 0px;
`;

export default { LoaderWrapper };
