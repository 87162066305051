import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 290px);
  width: 100%;
  margin-right: 10px;
  background-color: white;
  overflow-y: auto;
  border-radius: var(--4px, 8px);
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OneLiner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
`;

const LastGeneratedLabel = styled.span`
  display: flex;
  align-items: center;

  padding: 5px 10px;
  color: #0675fc;

  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--4px, 8px);

  border: 1px solid #b6e8ff;

  background: #edf9ff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const StatsTitle = styled.div`
  color: var(--Cool-Gray-400, #566976);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StatsContent = styled.div`
  color: #ff6c54;

  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default {
  Container,
  Header,
  Title,
  OneLiner,
  Stats,
  LastGeneratedLabel,
  StatsTitle,
  StatsContent,
};
