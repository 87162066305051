import React from 'react';
import DeleteModal from '../../../settings/Tabs/Organizations/components/DeleteModal';

interface Props {
  show: boolean;
  name: string;
  onConfirm: () => unknown;
  onCancel: () => unknown;
  isRemoving: boolean;
}

export const DeleteFolderModal: React.FC<Props> = ({
  show,
  onConfirm,
  name,
  onCancel,
  isRemoving,
}) => {
  return (
    <DeleteModal
      loading={isRemoving}
      heading={`Delete "${name}" folder?`}
      confirmButtonText="Yes, delete it."
      detail="All of your favorite Smart Clips™ in this folder️ will be un-starred. This action cannot be undone."
      shouldShow={show}
      onConfirm={onConfirm}
      closeModal={onCancel}
    />
  );
};
