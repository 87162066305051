import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .ant-btn {
    scale: 0.9;
  }

  .ant-btn-circle {
    scale: 0.5;
  }
`;

const Actions = styled.div`
  position: absolute;
  display: flex;
  right: 11px;
  z-index: 100;
`;

export default { Container, Actions };
