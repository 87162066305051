import { PropsWithChildren, useEffect, useState } from 'react';
import { AskMeAnythingContext } from './AskMeAnythingContext';
import { AskMeAnythingMessage } from '../types';
import { SuggestedQuestionsType } from '../questions';

interface Props {
  amaKey: string;
  suggestedQuestions: { data: SuggestedQuestionsType; category?: string; displayDropdown: boolean };
}

export const AskMeAnythingProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  amaKey,
  suggestedQuestions,
}) => {
  const [messages, setMessages] = useState<AskMeAnythingMessage[]>([]);
  const [isAsking, setIsAsking] = useState(false);

  const key = 'ama-local-storage-key-' + amaKey;

  useEffect(() => {
    const storedMessages = localStorage.getItem(key);
    const messages = storedMessages ? JSON.parse(storedMessages) : [];
    setMessages(messages);
  }, [key]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(messages));
  }, [messages]);

  const addMessage = (message: AskMeAnythingMessage): AskMeAnythingMessage[] => {
    setMessages(msgs => [...msgs, message]);
    return messages.concat(message);
  };

  const removeLastMessage = () => {
    setMessages(messages => {
      const updatedMessages = messages;
      updatedMessages.splice(-1);
      return [...updatedMessages];
    });
  };

  const clearMessages = () => {
    setMessages([]);
  };

  const askingStarted = () => {
    setIsAsking(true);
  };

  const askingFinished = () => {
    setIsAsking(false);
  };

  const addWordToMessage = (index: number, content: string) => {
    setMessages(pastMessages => {
      const newMessages = [...pastMessages].map((message, messageIndex) => {
        if (messageIndex === index) {
          message.content += content;
          if (message.content.trim()) {
            message.isWaiting = false;
          }
        }
        return message;
      });
      return newMessages;
    });
  };

  return (
    <AskMeAnythingContext.Provider
      value={{
        key,
        messages,
        addWordToMessage,
        isAsking,
        suggestedQuestions: suggestedQuestions.data,
        suggestedQuestionsDefaultCategory: suggestedQuestions.category || '',
        suggestedQuestionsDisplayDropdown: suggestedQuestions.displayDropdown,
        askingStarted,
        askingFinished,
        addMessage,
        removeLastMessage,
        clearMessages,
      }}
    >
      {children}
    </AskMeAnythingContext.Provider>
  );
};
