import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const CopySVG = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 10.6667V12.5334C10.6667 13.2801 10.6667 13.6535 10.5214 13.9387C10.3936 14.1896 10.1896 14.3936 9.93869 14.5214C9.65348 14.6667 9.28011 14.6667 8.53337 14.6667H3.46671C2.71997 14.6667 2.3466 14.6667 2.06139 14.5214C1.8105 14.3936 1.60653 14.1896 1.4787 13.9387C1.33337 13.6535 1.33337 13.2801 1.33337 12.5334V7.46671C1.33337 6.71997 1.33337 6.3466 1.4787 6.06139C1.60653 5.8105 1.8105 5.60653 2.06139 5.4787C2.3466 5.33337 2.71997 5.33337 3.46671 5.33337H5.33337M7.46671 10.6667H12.5334C13.2801 10.6667 13.6535 10.6667 13.9387 10.5214C14.1896 10.3936 14.3936 10.1896 14.5214 9.93869C14.6667 9.65348 14.6667 9.28011 14.6667 8.53337V3.46671C14.6667 2.71997 14.6667 2.3466 14.5214 2.06139C14.3936 1.8105 14.1896 1.60653 13.9387 1.4787C13.6535 1.33337 13.2801 1.33337 12.5334 1.33337H7.46671C6.71997 1.33337 6.3466 1.33337 6.06139 1.4787C5.8105 1.60653 5.60653 1.8105 5.4787 2.06139C5.33337 2.3466 5.33337 2.71997 5.33337 3.46671V8.53337C5.33337 9.28011 5.33337 9.65348 5.4787 9.93869C5.60653 10.1896 5.8105 10.3936 6.06139 10.5214C6.3466 10.6667 6.71997 10.6667 7.46671 10.6667Z"
        stroke="#596370"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Copy: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={CopySVG} {...props} />;
};
