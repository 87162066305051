import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SVGComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99 2.67499C9.36333 1.77749 10.6367 1.77749 11.01 2.67499L12.745 6.84749L17.2483 7.20832C18.2183 7.28582 18.6117 8.49582 17.8725 9.12915L14.4417 12.0683L15.4892 16.4625C15.715 17.4092 14.6858 18.1567 13.8558 17.65L10 15.295L6.14417 17.65C5.31417 18.1567 4.285 17.4083 4.51083 16.4625L5.55833 12.0683L2.1275 9.12915C1.38833 8.49582 1.78167 7.28582 2.75167 7.20832L7.255 6.84749L8.99 2.67499Z"
        fill="#566976"
      />
    </svg>
  );
};

export const StarredFolder: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon component={SVGComponent} {...props} />;
};
