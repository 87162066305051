import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(300px, 60%);
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  height: calc(100vh - 300px);
  width: 100%;
`;

const ContactGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px 10px;
  gap: 16px;
  height: calc(100vh - 300px);
  overflow-y: auto;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
`;

export default {
  Container,
  ContactGroup,
  EmptyState,
};
