import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default {
  Buttons,
  Container,
};
