import React from 'react';
import Styles from './styles';

const { Label } = Styles;

interface Props {
  count: string;
}

export const MentionCount: React.FC<Props> = ({ count }) => {
  return <Label>({count})</Label>;
};
