import React, { useState } from 'react';
import Styles from './styles';
import { ProviderIntegration } from '../../../../redux/typings/store';
import { ResolveAccountLabel } from './ResolveAccountLabel';
import { AccountModal } from '../../../recap/components/AccountSelector/AccountSelector/AccountModal';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { Tooltip } from 'antd';

const { Container } = Styles;
interface Props {
  account: ProviderIntegration;
  prospectedAccounts?: ProviderIntegration[] | null;
  styles?: React.CSSProperties;
  onClick?: () => unknown;
  onAccountSet?: (account: ProviderIntegration) => unknown;
}

export const AccountLabel: React.FC<Props> = ({
  account,
  prospectedAccounts,
  styles = {},
  onClick,
  onAccountSet,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<ProviderIntegration | null>(account);

  const hasProspectedAccounts = prospectedAccounts && prospectedAccounts.length > 1;

  const onSelect = async () => {
    if (selectedAccount) {
      setIsVisible(false);
      if (onAccountSet) onAccountSet(selectedAccount);
    }
  };

  const accountComponent = (
    <OptionalTooltipWrapper
      display={!!account?.id}
      value={onClick ? `Click to filter by ${account.name}` : account.name}
    >
      <Container
        onClick={ev => {
          ev.preventDefault();
          ev.stopPropagation();
          if (onClick) onClick();
          if (hasProspectedAccounts) setIsVisible(true);
        }}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        height={styles?.height}
      >
        <ResolveAccountLabel account={account} prospectedAccounts={prospectedAccounts} />
      </Container>
    </OptionalTooltipWrapper>
  );

  return (
    <div onClick={onClick}>
      {accountComponent}

      {hasProspectedAccounts && (
        <div onClick={e => e.stopPropagation()}>
          <AccountModal
            onAccountSelection={onSelect}
            onCancel={() => {
              setIsVisible(false);
            }}
            onResultSelect={a => setSelectedAccount(a)}
            open={isVisible}
            selectedAccount={selectedAccount}
            prospectedAccounts={prospectedAccounts}
            centered
            destroyOnClose
            title={false}
            closable={false}
            footer={false}
          />
        </div>
      )}
    </div>
  );
};
