import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { Modal } from 'antd';

const AntModal = styled(Modal)`
  .ant-modal-content {
    padding: 0px;
    max-height: 90vh;
    overflow-y: auto;
  }
`;

const Header = styled.div`
  padding: 24px;
  display: flex;
  gap: 16px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  align-items: center;
`;

const Title = styled.span`
  color: #212121;
  font-family: 'Cera Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  flex: 1;
`;

const Footer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
`;

const Contents = styled.div`
  padding: 24px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const HelpArticleLabel = styled.span`
  color: ${colors.gray['700']};
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  cursor: pointer;
`;

const HelpArticleContainer = styled.div`
  display: flex;
  height: 30px;
  padding: 7px 11px 7px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export default {
  Header,
  Contents,
  Footer,
  Title,
  HelpArticleContainer,
  HelpArticleLabel,
  Actions,
  AntModal,
};
