import styled from 'styled-components';
import { Card } from '../../../shared/components/Card/Card';

const SectionCardContainer = styled(Card)`
  padding: 12px 16px;
  position: relative;
`;

const SectionCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
`;

const SectionCardTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export default { ButtonGroup, SectionCardContainer, SectionCardHeader, SectionCardTitle };
