import React from 'react';
import Chart from 'react-apexcharts';
import { colors } from '../../../../theme/colors';

const seriesColors = [
  colors.red[500],
  colors.blue[500],
  colors.yellow[500],
  colors.green[300],
  colors.elephant[700],
  colors.violet[500],
  colors.yellow[600],
  colors.blue[700],
  colors.green[500],
  colors.yellow[300],
];

interface Props {
  data: {
    series: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[];
  };
}

export const TimelineChart: React.FC<Props> = ({ data }) => {
  const series = data.series.map((ov, i) => {
    return {
      name: ov.name,
      data: ov.data.map(point => ({ x: point.x, y: point.y })),
      color: seriesColors[i],
    };
  });

  return (
    <Chart
      key={'line'}
      height={'320px'}
      series={series}
      options={{
        chart: {
          fontFamily: 'Cera Pro',
          type: 'line',
          toolbar: { show: false },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            opacity: 0.1,
          },
          column: {
            opacity: 0.1,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        legend: {
          position: 'right',
          show: true,
          fontSize: '14px',
          itemMargin: { horizontal: 10, vertical: 5 },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        yaxis: {
          show: true,
          title: {
            text: 'Number of Mentions',
            style: {
              fontSize: '12px',
              fontWeight: 400,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
        tooltip: {
          enabled: true,
          followCursor: true,
        },
      }}
    />
  );
};
