/* eslint-disable @typescript-eslint/no-explicit-any */
import Styles from './style';
import { Button, Radio, Space, Tooltip } from 'antd';
import { usePastMeetings } from '../../../../features/meetings/past-meetings/past-meetings.hook';
import { differenceInMinutes, format, subDays } from 'date-fns';
import { ProfileAvatar } from '../../../../componentsV2/ProfileAvatar';
import { useEffect } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Table } from '../../../../componentsV2/Table';
import { coreService } from '../../../../services/core/core-service';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { useProfile } from '../../../../features/profile/profile.hook';
import { useToast } from '../../../../hooks/useToast';
import { colors } from '../../../../theme/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { Participants } from '../../../../features/shared/components/Icons/Participants';

export const HistoryPage: React.FC = () => {
  const { Container, PaginationFooter, PaginationFooterBold, Filters } = Styles;
  const { profile } = useProfile();
  const { success } = useToast();

  const {
    page,
    events,
    isFetching,
    paginationMetadata,
    filterContacts,
    changeDateRange,
    changeShowReadyOnly,
    changePageSize,
    changePage,
  } = usePastMeetings();

  const columns = [
    {
      title: 'Meeting Title',
      dataIndex: 'title',
      key: 'meeting-title',
      render: (_: any, record: any) => (
        <Tooltip title={record.title}>
          <Space>
            <a
              style={{
                // display: 'inline-block',
                width: '430px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textDecoration: 'ellipsis',
                color: colors.primary[500],
              }}
              href={`/recap/${record.id}`}
              target="_blank"
            >
              {record.title}
            </a>
            <FontAwesomeIcon size="sm" icon={faExternalLink} color="rgb(255, 108, 84)" />
          </Space>
        </Tooltip>
      ),
    },
    {
      title: 'Participants',
      dataIndex: 'participants',
      key: 'participants',
      render(value: { email: string }[]) {
        const emails = value.map(val => val.email).join('\n');
        return (
          <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{emails}</span>}>
            <Space>
              <Participants style={{ display: 'flex', alignItems: 'center' }} />
              {value.length}
            </Space>
          </Tooltip>
        );
        return <>{value}m</>;
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'meeting-duration',
      render(value: any) {
        return <>{value}m</>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'meeting-date',
      render(value: any) {
        return <>{value}</>;
      },
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'meeting-host',
      render(value: any) {
        return <>{value}</>;
      },
    },
  ];

  const renderPaginationText = (total: number, range: [start: number, end: number]) => {
    return (
      <PaginationFooter>
        Showing <PaginationFooterBold>{range[0]}</PaginationFooterBold> to{' '}
        <PaginationFooterBold>{range[1]}</PaginationFooterBold> of{' '}
        <PaginationFooterBold>{total}</PaginationFooterBold> results
      </PaginationFooter>
    );
  };

  const handleRegenerateBio = () => {
    coreService.generateStakeholderProfile(profile.id).then(() => {
      profile.profileProcessingRequested();
      success(`Stakeholder profile is being processed`);
    });
  };

  useEffect(() => {
    changePageSize(10);
    changeShowReadyOnly(true);
    filterContacts([profile.id]);
    changeDateRange([subDays(new Date(), 365), new Date()]);
  }, []);

  return (
    <Container>
      <Filters>
        <Radio.Group defaultValue="b" buttonStyle="solid" style={{ height: '35px' }}>
          <Radio.Button
            value="a"
            onClick={() => changeDateRange([subDays(new Date(), 30), new Date()])}
          >
            3M
          </Radio.Button>
          <Radio.Button
            value="b"
            onClick={() => changeDateRange([subDays(new Date(), 365), new Date()])}
          >
            1Y
          </Radio.Button>
          <Radio.Button value="c" onClick={() => changeDateRange([new Date(0), new Date()])}>
            ALL
          </Radio.Button>
        </Radio.Group>

        <OptionalTooltipWrapper
          display={profile.isGeneratingProfile}
          value="We’re generating a stakeholder profile for this person. Please wait a moment or check back shortly."
        >
          <Button
            onClick={handleRegenerateBio}
            disabled={profile.isGeneratingProfile}
            loading={profile.isGeneratingProfile}
          >
            Regenerate Bio
          </Button>
        </OptionalTooltipWrapper>
      </Filters>

      <Table
        pagination={{
          current: page,
          total: paginationMetadata.totalItems,
          pageSize: paginationMetadata.itemsPerPage,
          showSizeChanger: false,
          style: {
            margin: '0px',
          },
          showTotal: renderPaginationText,
          onChange: number => changePage(number),
        }}
        loading={isFetching}
        columns={columns}
        data={events.map(event => {
          return {
            ...event,
            key: event.id,
            title: event.title,
            date: format(new Date(event.scheduledStartDate), 'dd-MMM-yyyy'),
            duration: (
              <>
                <PhoneOutlined />{' '}
                {event.started && event.ended
                  ? differenceInMinutes(new Date(event.ended), new Date(event.started))
                  : 0}
              </>
            ),
            host: (
              <ProfileAvatar
                image={<Tooltip title={event.host}>{event.host.slice(0, 1).toUpperCase()}</Tooltip>}
                width={20}
                height={20}
                fontSize={10}
              />
            ),
          };
        })}
      />
    </Container>
  );
};
