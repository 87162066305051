import React, { ReactNode } from 'react';
import Styles from './styled';

const { Container } = Styles;

export const AuthenticationButton: React.FC<{
  label: string;
  onClick: () => void;
  children?: ReactNode;
}> = ({ label, onClick, children }) => {
  return (
    <Container onClick={() => onClick()}>
      {children}
      {label}
    </Container>
  );
};
