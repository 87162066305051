import timezones from 'timezones-list';
import Section from '../../_Misc/Section';
import { Button, Select } from 'antd';
import { Buttons, LeadingContent } from '../style';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../redux/selectors';
import { useState } from 'react';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';

export const TimeZone: React.FC = () => {
  const user = useSelector(selectUser);

  const tz = user && user?.timezone !== 'undefined' ? user?.timezone : null;

  const timezonesOptions = timezones.map(t => {
    return {
      label: t.label,
      value: t.tzCode,
      search: `${t.label} ${t.name} ${t.tzCode} ${t.utc}`.toLowerCase(),
    };
  });

  const [loading, setLoading] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState<string | null>(tz);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string | null>(tz);
  const [options, setOptions] = useState<any[]>(timezonesOptions);

  const { success, error } = useToast();

  const handleCancel = () => {
    setSelectedTimeZone(currentTimeZone);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      coreService.updateUser({ timezone: selectedTimeZone || undefined });
      setCurrentTimeZone(selectedTimeZone);
      success('Time zone updated');
    } catch {
      error('Failed to update time zone');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Section title="Time zone" description="Set your local time zone">
      <LeadingContent>
        {/* <Detail>Once you delete your account, you will lose all data associated with it.</Detail> */}

        <Select
          showSearch
          optionLabelProp="label"
          onChange={e => setSelectedTimeZone(e)}
          placeholder="Select a time zone"
          style={{ width: '500px', height: '35px' }}
          options={options}
          onSearch={input => {
            setOptions(timezonesOptions.filter(t => t.search.includes(input.toLowerCase())));
          }}
          value={selectedTimeZone}
          defaultValue={
            timezones.find(timezone => timezone.tzCode === selectedTimeZone)?.label || undefined
          }
        />
      </LeadingContent>

      <Buttons>
        <Button
          type="primary"
          onClick={handleSave}
          disabled={loading || currentTimeZone === selectedTimeZone}
        >
          Save Changes
        </Button>
        <Button style={{ width: '100px' }} onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
      </Buttons>
    </Section>
  );
};
