import React, { useState } from 'react';
import Styles from './styles';
import JoinScreen from '../JoinScreen/JoinScreen';
import { useToast } from '../../../../../hooks/useToast';
import { coreAPI } from '../../../../../API/core';
import { Progress } from '../../../Progress';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../../../types/commonTypes';
import { NavigationButtons, Status } from '../../style';
import { Button } from 'antd';
import { useOnboardingAnalytics } from '../../../useOnboardingAnalytics';

const { InputContainer, OrganizationNameInput, Inputs } = Styles;

const api = new coreAPI();

interface Props {
  onOrganizationCreated: () => unknown;
  onBack: () => unknown;
  showBack: boolean;
}

const CreateOrganization: React.FC<Props> = ({ onOrganizationCreated, onBack, showBack }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const { error } = useToast();
  const { organization } = useOnboardingAnalytics();

  const isValidOrganizationName = organizationName.length > 3;

  const onCreateOrganization = () => {
    setIsCreating(true);
    api
      .createOrganization({
        name: organizationName,
      })
      .then(() => {
        organization.created(organizationName);
        onOrganizationCreated();
      })
      .catch(() => {
        error(`Error creating the workspace`);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <JoinScreen
      title="Create a Workspace"
      subtitle="Want to make the most out of UpdateAI? Bring your peers into your workspace to share customer meeting summaries and insights. You can do this anytime in your settings."
    >
      <>
        <Inputs>
          <InputContainer>
            <div>Workspace Name</div>
            <OrganizationNameInput
              disabled={isCreating}
              onChange={ev => setOrganizationName(ev.target.value)}
            />
          </InputContainer>
        </Inputs>

        <Status>
          <NavigationButtons>
            {showBack ? (
              <Button onClick={() => onBack()} type="default" disabled={isCreating}>
                {'<< Back to Join a Workspace'}
              </Button>
            ) : (
              <div></div>
            )}

            <Button
              type="primary"
              loading={isCreating}
              onClick={onCreateOrganization}
              disabled={!isValidOrganizationName}
            >
              Next
            </Button>
          </NavigationButtons>

          <Progress total={ONBOARDING_TOTAL_STEPS_AMOUNT} current={2} />
        </Status>
      </>
    </JoinScreen>
  );
};

export default CreateOrganization;
