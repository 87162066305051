import styled from 'styled-components';
import { colors } from '../../../../../../../theme/colors';

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  padding: 4px 0px;

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  display: flex;
  width: 190px;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  padding: 12px 24px 12px 16px;
  align-items: flex-start;
  align-self: stretch;
  color: var(--cool-gray-400, #566976);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-bottom: 1px solid ${colors.coolGray[100]};
`;

const OptionContainer = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  align-self: stretch;

  &:hover {
    cursor: pointer;
  }
`;

const OptionContent = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0px 14px;
  gap: 8px;
  color: ${props =>
    props.selected ? 'var(--cool-gray-950, #030C11)' : 'var(--cool-gray-400, #566976)'};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const CheckContainer = styled.div`
  display: block;
  width: 24px;
  height: 24px;
`;

export default {
  Container,
  Header,
  CheckContainer,
  OptionContainer,
  OptionContent,
};
