import React from 'react';
import { Container, Content, Description, Header, LearnMore, Title } from './style';
import BetaBadge from '../../../../../features/shared/components/Badges/Beta';

interface Props {
  id?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  col?: React.ReactNode;
  childrenBackground?: boolean;
  childrenBorder?: boolean;
  learnMore?: string;
  isBeta?: boolean;
}

const Section: React.FC<Props> = ({
  id,
  title,
  description,
  col,
  children,
  childrenBackground = true,
  childrenBorder = true,
  learnMore,
  isBeta,
}) => {
  return (
    <Container id={id} key={id}>
      {!title && !description ? (
        <></>
      ) : (
        <Header>
          {title && (
            <Title>
              {title}
              {isBeta && <BetaBadge />}
            </Title>
          )}
          {description && (
            <Description>
              {description}
              {learnMore && (
                <LearnMore href={learnMore} target="_blank">
                  Learn More
                </LearnMore>
              )}
            </Description>
          )}
          {col}
        </Header>
      )}

      {children ? (
        <Content background={childrenBackground} border={childrenBorder}>
          {children}
        </Content>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Section;
