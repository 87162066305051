import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRoleName } from '../../utils';
import { useToast } from '../../../../hooks/useToast';
import { useOrganizationMembers } from '../../hooks/useOrganizationMembers';
import { extractError } from '../../../../utils/api';
import Panel from './Panel';
import RemoveMemberModal from './RemoveMemberModal';
import TransferOwnershipModal from './TransferOwnershipModal';

interface Props {
  user: {
    role: string;
    id: string;
    fullName: string;
    email: string;
  };
  isLoggedUser: boolean;
  disabled: boolean;
  onOrganizationTransfered: () => unknown;
}

export const RoleDropdown: React.FC<Props> = ({
  user,
  disabled,
  isLoggedUser,
  onOrganizationTransfered,
}) => {
  const [currentRole, setCurrentRole] = useState(getRoleName(user.role));
  const [isOpened, setIsOpened] = useState(false);
  const [userIdToRemove, setUserIdToRemove] = useState('');
  const [userToTransfer, setUserToTransfer] = useState<null | {
    id: string;
    email: string;
    fullName: string;
  }>(null);
  const { error, success } = useToast();
  const { changeMemberRole } = useOrganizationMembers();

  const isDisabled = disabled || isLoggedUser;

  const closePanel = () => {
    setIsOpened(false);
  };

  const handleTransferRequest = async () => {
    setUserToTransfer({
      id: user.id,
      email: user.email,
      fullName: user.fullName,
    });
    closePanel();
  };

  const changeCurrentRole = (roleId: string) => {
    setCurrentRole(getRoleName(roleId));
  };

  useEffect(() => {
    changeCurrentRole(user.role);
  }, [user]);

  const onDropdownClicked = () => {
    if (!isDisabled) {
      setIsOpened(true);
    }
  };

  const handleChangeRole = async (role: string) => {
    if (!isDisabled) {
      if (role === 'OWNER') {
        handleTransferRequest();
      } else {
        closePanel();
        changeCurrentRole(role);
        try {
          await changeMemberRole(user.id, role);
          success('Role changed successfully.');
        } catch (e) {
          const errorMessage = extractError(e);
          error(errorMessage);
        }
      }
    }
  };

  const handleRemoveRequest = async () => {
    closePanel();
    setUserIdToRemove(user.id);
  };

  const handleRemoveCancel = () => {
    setUserIdToRemove('');
  };

  const handleTransferCanceled = () => {
    setUserToTransfer(null);
  };

  return (
    <>
      <Dropdown
        placement="bottomRight"
        disabled={disabled}
        open={isOpened}
        onOpenChange={open => {
          if (!open) closePanel();
        }}
        dropdownRender={() => (
          <Panel role={user.role} onRemove={handleRemoveRequest} onRoleChange={handleChangeRole} />
        )}
      >
        <Button
          onClick={onDropdownClicked}
          style={{
            width: '110px',
            borderRadius: '6px',
          }}
          disabled={isDisabled}
        >
          <div
            style={{
              paddingRight: '16px',
              width: '95px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>{currentRole}</div>
            <div>
              {!isDisabled ? (
                <DownOutlined style={{ color: '#d9d9d9', width: '12px' }} />
              ) : (
                <div style={{ width: '10px' }}> </div>
              )}
            </div>
          </div>
        </Button>
      </Dropdown>

      <RemoveMemberModal
        onCancel={handleRemoveCancel}
        userId={userIdToRemove}
        isLoggedUser={isLoggedUser}
      />

      <TransferOwnershipModal
        user={userToTransfer}
        onTransferCanceled={handleTransferCanceled}
        onOrganizationTransfered={onOrganizationTransfered}
      />
    </>
  );
};
