import { PastEvent } from '../../../../../../../../API/types';
import { format } from 'date-fns';
import { Button, Collapse, ConfigProvider, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { MeetingTitleLink } from '../../../../../../../../components/common/MeetingTitleLink';
import {
  DownOutlined,
  LockOutlined,
  ProfileOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { capitalize } from 'lodash';
import { coreService } from '../../../../../../../../services/core/core-service';
import { FetchRecapResponse } from '../../../../../../../../services/types';
import { parseInsights } from '../../../../../../../../features/recapv2/recap-management/utils';
import { colors } from '../../../../../../../../theme/colors';
import { AIItem } from './AIItem';
import { secondsToTime } from '../../../../../../../../helpers/TextHelper';

import Styles from './style';
const {
  Container,
  Content,
  Actions,
  Header,
  Title,
  DateLabel,
  RowSection,
  ContentTitle,
  TooltipContent,
  CustomCheckbox,
} = Styles;

interface Props {
  event: PastEvent;
  selected: boolean;
  onSelctedChange: (selected: boolean, eventId: string) => void;
}

const eventVisibilityToLabel = (visibility: string) => {
  if (visibility === 'organization') {
    return 'Workspace';
  }
  if (visibility === 'invitees') {
    return 'Invitees';
  }

  return 'Other';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MentionsTooltip: React.FC<{ messages: any[] }> = ({ messages }) => {
  return (
    <TooltipContent>
      {messages.map(m => {
        return (
          <div key={m.id} style={{ display: 'inline-block', gap: '5px' }}>
            <strong>{m.speaker}</strong> ({secondsToTime(m.startedTime)}): {m.content}
          </div>
        );
      })}
    </TooltipContent>
  );
};

export const PastCard: React.FC<Props> = ({ event, selected, onSelctedChange }) => {
  const [checked, setChecked] = useState(selected);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FetchRecapResponse | null>(null);
  const [open, setOpen] = useState<string | null>(null);

  const tldrMessagesIds = new Set<string>();
  const insights = (data ? parseInsights(data) : []).sort((a, b) => a.id.localeCompare(b.id));
  insights
    .find(i => i.category === 'TL;DR')
    ?.chunks.forEach(itc => tldrMessagesIds.add(itc.messageId));

  const filteredMessages =
    data?.messages.filter(msg => {
      return tldrMessagesIds.has(msg.id);
    }) || [];

  useEffect(() => {
    if (!!open && !data) {
      coreService
        .fetchRecap(event.id, '')
        .then(recap => setData(recap))
        .finally(() => setLoading(false));
    }
  }, [open]);

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
          },
        },
      }}
    >
      <Collapse activeKey={open || ''} ghost style={{ width: '100%', padding: '0px' }}>
        <Collapse.Panel
          style={{ width: '100%', padding: '0px', margin: '0px' }}
          showArrow={false}
          key={event.id}
          header={
            <Container>
              <CustomCheckbox
                backgroundColor="#7230FF"
                checked={checked}
                onChange={e => {
                  onSelctedChange(e.target.checked, event.id);
                  setChecked(e.target.checked);
                }}
              />
              <Content onClick={() => (!!open ? setOpen(null) : setOpen(event.id))}>
                <Header>
                  <Title>
                    <MeetingTitleLink id={event.id} title={event.title} />
                  </Title>
                  <DateLabel>
                    {format(new Date(event.scheduledStartDate), 'eeee, MMMM dd, yyyy ')}
                    {format(new Date(event.scheduledStartDate), 'HH:MM aaa - ').toUpperCase()}
                    {format(new Date(event.scheduledEndDate), 'HH:MM aaa').toUpperCase()}
                  </DateLabel>
                </Header>

                <Actions>
                  <Button
                    onClick={() => (!!open ? setOpen(null) : setOpen(event.id))}
                    shape="circle"
                    size="small"
                    ghost
                    icon={
                      !!open ? (
                        <UpOutlined style={{ color: 'gray' }} />
                      ) : (
                        <DownOutlined style={{ color: 'gray' }} />
                      )
                    }
                  />
                  <RowSection>
                    <UserOutlined />
                    {event.participants.length}
                    <div>Participants</div>
                  </RowSection>

                  <RowSection>
                    <LockOutlined />
                    {capitalize(eventVisibilityToLabel(event.visibility))}
                  </RowSection>
                </Actions>
              </Content>
            </Container>
          }
        >
          {loading ? (
            <Spin style={{ width: '100%' }} size="small" />
          ) : (
            <>
              <ContentTitle>TL;DR</ContentTitle>
              <ul style={{ padding: '0px 0px 0px 25px' }}>
                {data &&
                  insights.map((insight, index) => {
                    if (insight.category === 'TL;DR') {
                      return (
                        <li key={index}>
                          {insight.text}{' '}
                          <Tooltip
                            title={<MentionsTooltip messages={filteredMessages} />}
                            overlayInnerStyle={{ backgroundColor: 'white' }}
                          >
                            <ProfileOutlined style={{ color: colors.primary[500] }} />
                          </Tooltip>
                        </li>
                      );
                    }
                  })}
                {data && insights.some(insight => insight.category === 'TL;DR') ? null : (
                  <div>No TL;DR Available</div>
                )}
              </ul>

              <ContentTitle>Action Items</ContentTitle>
              <ul style={{ padding: '0px 0px 0px 15px', listStyle: 'none' }}>
                {data &&
                  parseInsights(data).map((insight, index) => {
                    if (insight.category === 'Action Items') {
                      return (
                        <AIItem key={index + '-AI-ITEM'} insight={insight} eventId={event.id} />
                      );
                    }
                  })}
                {data &&
                parseInsights(data).some(insight => insight.category === 'Action Items') ? null : (
                  <div>No Action Items Available</div>
                )}
              </ul>
            </>
          )}
        </Collapse.Panel>
      </Collapse>
    </ConfigProvider>
  );
};
