import styled from 'styled-components';

const MeetingTitle = styled.span`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
`;

const MeetingDate = styled.span`
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default {
  MeetingTitle,
  MeetingDate,
};
