import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;
  flex-direction: column;
  display: flex;
  gap: 24px;
  max-width: 536px;
  justify-items: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.h1`
  color: #282828;
  font-weight: 700;
  line-height: 36px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-family: 'Cera Pro';
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormContainer = styled.div`
  max-width: 354px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #ff6c54;
`;

const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
`;

const BackButton = styled(LeftOutlined)`
  color: #ff6c54;
`;

export default {
  Container,
  Title,
  FormContainer,
  TitleContainer,
  Link,
  HeaderContainer,
  BackContainer,
  BackButton,
};
