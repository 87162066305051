import { useNavigate } from 'react-router-dom';
import { useEmail } from '../../../../../email/useEmail';

export const NoMeetingMessage: React.FC = () => {
  const navigate = useNavigate();
  const { getEmailLink } = useEmail();

  const goToIntegrations = () => {
    navigate('/settings?tab=integrations');
  };
  return (
    <div style={{ width: '550px', marginTop: '-100px' }}>
      <h1>Trouble finding your meetings?</h1>

      <p>
        Swing by your <a onClick={goToIntegrations}>integration settings</a> and give your calendar
        a quick disconnect and reconnect, and wait 2 minutes. This simple fix usually does the job.
      </p>

      <p>
        Still stuck? Send an email to
        <a
          href={getEmailLink({
            subject: 'Trouble finding my meetings',
            to: ['support@update.ai'],
            body: '',
          })}
          target="_blank"
          rel="noopener noreferrer"
          style={{ paddingLeft: '4px' }}
        >
          support@update.ai
        </a>{' '}
        and give us the title and date of at least one meeting that should be showing up.
      </p>

      <p>Sorry for the hassle – we're here to help!</p>
    </div>
  );
};
