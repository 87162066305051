import styled from 'styled-components';

const Container = styled.div`
  gap: 16px;
  width: 100%;
  padding: 12px 16px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const EmptyView = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Cool-Gray-400, #566976);
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

const LastGeneratedLabel = styled.span`
  display: flex;
  align-items: center;

  padding: 10px;
  color: #0675fc;

  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--4px, 8px);

  border: 1px solid #b6e8ff;

  background: #edf9ff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

export default { Container, EmptyView, Cards, LastGeneratedLabel };
