import { useAnalytics } from '../../features/analytics/hooks/useAnalytics';

export interface IOnboardingAnalytics {
  knowYourselfView: {
    viewed: () => void;
  };
  video: {
    selected: (selected: string) => void;
  };
  organization: {
    invitation: {
      accepted: (name: string) => void;
    };
    created: (name: string) => void;
  };
  user: {
    role: {
      set: (role: string) => void;
    };
  };
}

enum oldOnboardingEvents {
  knowYourselfFilled = 'know_yourself_filled',
  onboardingKnowYourselfView = 'onboarding_know_yourself_view',
  organizationInvitedAccepted = 'org_invite_accepted',
  organizationCreated = 'org_created',
  onboardingVideoSelection = 'onboarding_video_selection',
}

export const useOnboardingAnalytics = () => {
  const { sendEvent } = useAnalytics();

  return {
    knowYourselfView: {
      viewed: () => sendEvent(oldOnboardingEvents.onboardingKnowYourselfView),
    },
    video: {
      selected: selected =>
        sendEvent(oldOnboardingEvents.onboardingVideoSelection, {
          people: {
            onboarding_video_selection: selected,
          },
        }),
    },
    organization: {
      invitation: {
        accepted: name => {
          sendEvent(oldOnboardingEvents.organizationInvitedAccepted, {
            event: {
              organization: name,
            },
          });
        },
      },
      created: name => {
        sendEvent(oldOnboardingEvents.organizationCreated, {
          event: {
            organization: name,
          },
        });
      },
    },
    user: {
      role: {
        set: role => {
          sendEvent(oldOnboardingEvents.knowYourselfFilled, {
            people: {
              user_role: role,
              'User Function': role,
              'Role Level': role,
              // 'Primary Usage': reason,
            },
          });
        },
      },
    },
  } as IOnboardingAnalytics;
};
