import { useMemo } from 'react';
import { Crop } from '../types';

/**
 * Handles video timeline conversion. When crop is applied
 * time is adjusted according to the cropping.
 * @param cropMask Start and end of cropping
 * @param realDuration Duration of the video
 * @returns
 */
export const useVideoTimeline = (cropMask: Crop | null, realDuration: number) => {
  const duration = useMemo(() => {
    let totalSubstract = 0;
    let newDuration = realDuration;
    if (cropMask && realDuration) {
      totalSubstract = cropMask.end + cropMask.start;
      newDuration = newDuration - totalSubstract;
    }
    return newDuration;
  }, [cropMask, realDuration]);

  const userTimeToVideoTime = (userTime: number) => {
    if (cropMask) {
      if (userTime < 0) {
        return cropMask.start;
      }
      return userTime + cropMask.start;
    }
    return userTime;
  };

  const videoTimeToUserTime = (videoTime: number) => {
    if (cropMask && videoTime >= cropMask.start) {
      return videoTime - cropMask.start;
    }
    return videoTime;
  };

  return {
    userTimeToVideoTime,
    videoTimeToUserTime,
    duration,
  };
};
