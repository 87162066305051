import styled from 'styled-components';
import { Badge as AntBadge } from 'antd';

const CardContainer = styled.div<{ $min?: boolean }>`
  display: flex;
  width: 400px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  background: #fff;
  box-shadow: 0 0 60px gray;

  h1 {
    color: var(--Cool-Gray-950, #030c11);
    font-family: 'Cera Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin: 0;
  }

  h2 {
    align-self: stretch;
    color: var(--Cool-Gray-400, #566976);
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;

    a {
      color: #ff6c54;
      font-family: 'Cera Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  ul {
    padding: 16px;
    margin: 0;
  }

  li {
    color: var(--Cool-Gray-400, #566976);
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }
`;

const Badge = styled(AntBadge)`
  color: #ff6c54;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  border-radius: 4px;
  background: #fff8f6;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export default { CardContainer, Badge };
