import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const VolumeMutedSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="#F3F4F5"
        d="M11.25 3.383c0-1.113-1.347-1.67-2.133-.883l-3.75 3.75h-1.61c-.951 0-1.932.553-2.217 1.588A8.133 8.133 0 0 0 1.25 10c0 .748.1 1.473.292 2.163.284 1.033 1.265 1.587 2.216 1.587h1.608l3.75 3.75c.787.788 2.134.23 2.134-.883V3.383Zm3.567 4.3a.624.624 0 1 0-.884.884L15.367 10l-1.434 1.433a.625.625 0 0 0 .884.884l1.433-1.434 1.433 1.434a.626.626 0 1 0 .884-.884L17.133 10l1.434-1.433a.625.625 0 0 0-.884-.884L16.25 9.117l-1.433-1.434Z"
      />
    </svg>
  );
};
export const VolumeMuted: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={VolumeMutedSVG} {...props} />;
};
