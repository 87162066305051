import styled from 'styled-components';

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChartContainer = styled.div`
  height: 320px;
`;

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Filters,
  Contents,
  ChartContainer,
  SpinnerContainer,
};
