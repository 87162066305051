import { PropsWithChildren, createContext, useRef } from 'react';
import { SummarizeInsightStore, createSummarizeInsightStore } from './summarize-insights.store';
import { ShareSmartSummaryModal } from './components/ShareSmartSummaryModal';

export interface SummarizeInsightsContext {
  store: SummarizeInsightStore;
}

export const summarizeInsightsContext = createContext<SummarizeInsightsContext>({
  store: createSummarizeInsightStore(),
});

export const SummarizeInsightsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createSummarizeInsightStore()).current;

  return (
    <summarizeInsightsContext.Provider value={{ store }}>
      <ShareSmartSummaryModal />
      {children}
    </summarizeInsightsContext.Provider>
  );
};
