import styled from 'styled-components';

const TooltipContainer = styled.div`
  padding: 12px;
`;

const TooltipContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TooltipTitle = styled.span` 
color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 150% */
`;

const TooltipText = styled.span`
  color: var(--base-white, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export default { TooltipContainer, TooltipContents, TooltipTitle, TooltipText };
