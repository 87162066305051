import { Button, Drawer, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useCustomerIntelligenceData } from '../../../../features/insights/hooks/useCustomerIntelligenceData';
import Styles from './styles';
import { SmartClipItem } from './components/SmartClipItem';
import { FolderAddOutlined } from '@ant-design/icons';
import { InsightSmartClip } from '../../../../features/insights/types';
import { NewFolderModal } from '../../../libraries/components/NewFolderModal';
import { useToast } from '../../../../hooks/useToast';
import { useLibraries } from '../../../../features/libraries/hooks/useLibraries';

const {
  Heading,
  Title,
  Contents,
  WatchItemsContainer,
  HeaderActions,
  SpinnerContainer,
  Container,
} = Styles;

export const SmartClipsDrawer: React.FC = () => {
  const { showWatchItems, toggleShowWatchItems, watchItems, isFetchingItems } =
    useCustomerIntelligenceData();
  const [selectedItems, setSelectedItems] = useState<InsightSmartClip[]>([]);
  const { addItemsToFolder } = useLibraries();
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const { success } = useToast();
  const [insightIdsToSave, setInsightIdsToSave] = useState<string[]>([]);

  const handleItemCheckChange = (watchItem: InsightSmartClip, value: boolean) => {
    if (value) {
      setSelectedItems([...selectedItems, watchItem]);
    } else {
      setSelectedItems(selectedItems.filter(item => item.id !== watchItem.id));
    }
  };

  const hasItemsSelected = selectedItems.length > 0;

  const onFolderCreated = async (folderId: string) => {
    await addItemsToFolder(folderId, insightIdsToSave);
    success(`Items added succcessfuly`);
    setShowNewFolderModal(false);
  };

  const handleAddToNewFolder = (insightIds: string[]) => {
    setShowNewFolderModal(true);
    setInsightIdsToSave(insightIds);
  };

  const handleCancelAddToFolder = () => {
    setInsightIdsToSave([]);
    setShowNewFolderModal(false);
  };

  return (
    <Drawer
      closable={false}
      placement="right"
      onClose={() => toggleShowWatchItems(false)}
      open={showWatchItems}
      maskClosable={true}
      contentWrapperStyle={{ width: '700px' }}
      // bodyStyle={{ padding: '0px' }}
    >
      <Container>
        <NewFolderModal
          onFolderCreated={onFolderCreated}
          open={showNewFolderModal}
          onCancel={handleCancelAddToFolder}
        />
        <Heading>
          <Title>Library</Title>
          <HeaderActions>
            <Tooltip title="Add to new folder">
              <Button
                disabled={!hasItemsSelected}
                shape="circle"
                onClick={() => handleAddToNewFolder(selectedItems.map(item => item.id))}
                icon={<FolderAddOutlined />}
              ></Button>
            </Tooltip>
            {/*<Tooltip title="Share selected Smart Clips™️">
              <Button disabled={!hasItemsSelected} shape="circle" icon={<LinkOutlined />}></Button>
  </Tooltip>*/}
          </HeaderActions>
        </Heading>
        <Contents>
          {isFetchingItems ? (
            <SpinnerContainer>
              <Spin />
            </SpinnerContainer>
          ) : (
            <WatchItemsContainer>
              {watchItems.map(item => {
                return (
                  <SmartClipItem
                    onAddToNewFolder={() => handleAddToNewFolder([item.id])}
                    item={item}
                    key={item.id}
                    onChange={value => handleItemCheckChange(item, value)}
                  />
                );
              })}
            </WatchItemsContainer>
          )}
        </Contents>
      </Container>
    </Drawer>
  );
};
