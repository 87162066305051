import styled from 'styled-components';

const Container = styled.div`
  width: 160px;
  height: 32px;
  display: flex;
  align-items: center;
`;

const AccountLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PushAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default {
  AccountLabelContainer,
  PushAccountContainer,
  Container,
};
