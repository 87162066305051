import { useEffect, useState } from 'react';
import { Input, Title } from './styles';
import { Button, Space, Tooltip } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { useRecapManagement } from '../../../../../recapv2/recap-management/hook';
import { useToast } from '../../../../../../hooks/useToast';

export const EditableTitle = () => {
  const { changeTitle, title, isPublic, permissions } = useRecapManagement();
  const [temporalTitle, setTemporalTitle] = useState(title || 'Default title');
  const { success, error } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setTemporalTitle(title);
  }, [title]);

  const editTitleFeatureAvailable = !isPublic && permissions.canChangeMeetingTitle;

  const handleTitleChanged = (title: string) => {
    setTemporalTitle(title);
  };

  const handleSave = () => {
    setIsSaving(true);
    changeTitle(temporalTitle)
      .then(() => {
        success(`Meeting title updated`);
      })
      .catch(() => error(`There was an error updating meeting title`))
      .finally(() => {
        setIsSaving(false);
        setIsEditing(false);
      });
  };

  if (!editTitleFeatureAvailable) {
    return <Title>{title}</Title>;
  }

  return (
    <Space
      style={{
        display: 'flex',
        alignContent: 'center',
        maxWidth: '600px',
        justifyContent: 'space-between',
      }}
    >
      <Tooltip title={'Click here to edit'}>
        <Input
          placeholder={title}
          value={temporalTitle}
          bordered={false}
          onChange={e => handleTitleChanged(e.target.value)}
          onFocus={() => setIsEditing(true)}
        />
      </Tooltip>
      {isEditing && (
        <Tooltip title={'Save'}>
          <Button
            type="primary"
            shape="round"
            size="small"
            loading={isSaving}
            disabled={!isEditing}
            onClick={() => {
              handleSave();
            }}
          >
            <SaveFilled />
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};
