import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  height: calc(100vh - 300px);
  width: 100%;
  min-height: 500px;
  padding: 16px;
  overflow-y: auto;
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  width: 100%;
  height: 100%;
`;

const LastGeneratedLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #0675fc;

  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--4px, 8px);

  border: 1px solid #b6e8ff;

  background: #edf9ff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled.div`
  display: block;
  padding: 16px;
  align-self: stretch;
  border-radius: var(--4px, 8px);
  border: 1px solid #d9d9d9;
  background: var(--Global-white, #fafafa);
  height: 170px;
  overflow-y: auto;
  white-space: pre-wrap;
  font-size: 13px;
`;

export default {
  Container,
  EmptyState,
  LastGeneratedLabel,
  Text,
};
