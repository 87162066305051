import { Account } from './types';
import { createStore } from 'zustand';

interface AccountSearchState {
  accounts: Account[];
  isFetching: boolean;
  version: string;
  accountsSet: (accounts: Account[]) => void;
  fetchStatusChanged: (isFetching: boolean) => void;
}

export type AccountSearchStore = ReturnType<typeof createAccountSearchStore>;

export const createAccountSearchStore = (version: string) => {
  return createStore<AccountSearchState>(set => {
    return {
      accounts: [],
      isFetching: true,
      version: version,
      accountsSet: accounts => set({ accounts }),
      fetchStatusChanged: isFetching => set({ isFetching }),
    };
  });
};
