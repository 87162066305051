import { QuestionCircleOutlined } from '@ant-design/icons';
import { Space, Switch, Tooltip } from 'antd';
import { useToast } from '../../hooks/useToast';
import { coreAPI } from '../../API/core';
import { useState } from 'react';
import Styles from './style';

interface Props {
  id: string;
  title?: string;
  disabled?: boolean;
  checked?: boolean;
  action?: (id: string, v: boolean) => void;
}

const { Label } = Styles;

export const RecordMeetingToggle: React.FC<Props> = ({
  id,
  title,
  disabled = false,
  checked = false,
  action,
}) => {
  const { success, error } = useToast();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(checked);

  const handleRecording = (eventId: string) => {
    if (disabled) return;

    const currentValue = value;
    const newValue = !value;

    setLoading(true);
    setValue(newValue);

    const coreApi = new coreAPI();
    coreApi
      .changeSkipBehavior({ eventId, value: newValue })
      .then(() => {
        if (action) action(eventId, newValue);
        success('Recording status updated');
      })
      .catch(() => {
        if (action) action(eventId, currentValue);
        setValue(currentValue);
        error('Failed to update recording status. Try again.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip title={title}>
      <Space>
        <QuestionCircleOutlined />

        <Label>Create recording</Label>

        <Switch
          onChange={() => handleRecording(id)}
          disabled={disabled || loading}
          loading={loading}
          checked={value}
          size="small"
        />
      </Space>
    </Tooltip>
  );
};
