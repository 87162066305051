import { Modal } from '../../../../componentsV2/Modal';
import {
  TopAccountRecord,
  TopAccountsCondensedTable,
} from './components/TopAccountsCondensedTable';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { PropsWithChildren, useMemo, useState } from 'react';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
import { Button, Flex, Pagination, Select, Spin } from 'antd';
import { DashboardFilterPovider } from '../../../shared/dashboard-filters/dashboard-filters.context';
import { useDashboardFilters } from '../../../shared/dashboard-filters/dashboard-filters.hooks';
import { addDays } from 'date-fns';
import { CopyAllButton } from '../../../recap/components/CopyAll/CopyAllButton';
import { useToast } from '../../../../hooks/useToast';
import { CopyOutlined } from '@ant-design/icons';

const { Container, CardContents, SummaryContainer } = Styles;

interface Props {
  open: boolean;
  topic: {
    id: string;
    name: string;
  };
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  onClose: () => void;
}

export const Wrapper: React.FC<PropsWithChildren<Omit<Props, 'filters'>>> = ({
  topic,
  open,
  onClose,
  children,
}) => {
  const { filters, getAccountFilterOptions, getDateFilterOptions, render, changeAccountValue } =
    useDashboardFilters();

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountFilterOptions('account');

    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountId: accountValue || '',
    };
  }, [filters]);

  const [sortBy, setSortBy] = useState('mentions');
  const [page, setPage] = useState(1);
  const [summarizing, setIsSummarizing] = useState(false);
  const [summary, setSummary] = useState('');
  const [summarized, setSummarized] = useState(false);
  const { success } = useToast();

  const handleClose = () => {
    changeAccountValue('account', '');
    onClose();
    setIsSummarizing(false);
    setSummary('');
    setSummarized(false);
  };

  const { data: topAccounts, isFetching: topAccountsFetching } = useQuery({
    queryKey: [
      'topic-modal-accounts',
      topAccountsFilters.from,
      topAccountsFilters.to,
      topAccountsFilters.accountId,
      page,
      topic.id,
      sortBy,
      open,
    ],
    queryFn: async () => {
      if (!open) {
        return null;
      }

      if (!topic.id) return null;

      const options: any = [];
      if (topAccountsFilters.accountId) {
        options['accountIds'] = [topAccountsFilters.accountId];
      }

      const topAcc = await coreService.getTopAccountsByTopicId(
        topic.id,
        topAccountsFilters.from,
        topAccountsFilters.to,
        page,
        10,
        sortBy,
        options,
      );
      return topAcc;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const tableData: TopAccountRecord[] = useMemo(() => {
    if (!topAccounts) return [];

    return topAccounts.accounts.map(acc => {
      return {
        account: {
          id: acc.id,
          name: acc.name || '',
          provider: acc.provider || '',
        },
        mentions: acc.mentions || 0,
        meetings: acc.meetings || 0,
        arr: acc.arr || 0,
        renewalDate: acc.renewalDate ? new Date(acc.renewalDate) : new Date(),
      };
    });
  }, [topAccounts]);

  const accountFilter = useMemo(() => {
    return render('account');
  }, [topAccountsFilters]);

  const summarizeTopics = async () => {
    setIsSummarizing(true);
    setSummarized(false);
    coreService
      .summarizeTopics(topic.id, topAccountsFilters.from, topAccountsFilters.to)
      .then(summarization => {
        setSummary(summarization);
      })
      .finally(() => {
        setSummarized(true);
        setIsSummarizing(false);
      });
  };

  const handleChangeSort = (sortBy: string) => {
    setSortBy(sortBy);
    setPage(1);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(summary);
    success('Summary copied to clipboard');
  };

  return (
    <Modal hideFooter width={1296} open={open} title={topic.name} onClose={handleClose}>
      <Container>
        {children}
        <Flex gap={'8px'}>
          <Select
            value={sortBy}
            onChange={value => handleChangeSort(value)}
            options={[
              { value: 'mentions', label: '# of Mentions' },
              { value: 'meetings', label: '# of Meetings' },
            ]}
          />
          {render('dateRange')}
          {accountFilter}
        </Flex>
        <SummaryContainer>
          {summarized ? (
            <Flex vertical gap={'8px'}>
              {' '}
              <Flex gap={'8px'}>
                <Button type="default" onClick={summarizeTopics}>
                  Summarize this topic
                </Button>
                <Button icon={<CopyOutlined />} onClick={handleCopy} />
              </Flex>
              {summary}
            </Flex>
          ) : summarizing ? (
            <Spin size="small" tip={'Summarizing...'} />
          ) : (
            <Button type="primary" onClick={summarizeTopics}>
              Summarize this topic
            </Button>
          )}
        </SummaryContainer>
        <Card title="Accounts">
          <CardContents>
            <TopAccountsCondensedTable
              elems={tableData}
              loading={topAccountsFetching}
              filters={topAccountsFilters}
              sortBy={sortBy}
              topic={topic}
            />
            <Pagination
              style={{
                alignSelf: 'flex-end',
              }}
              onChange={value => setPage(value)}
              hideOnSinglePage
              showSizeChanger={false}
              total={topAccounts?.pagination?.items?.total || 1}
              pageSize={topAccounts?.pagination?.pages?.pageSize || 10}
              current={topAccounts?.pagination?.pages?.currentPage || 1}
            />
          </CardContents>
        </Card>
      </Container>
    </Modal>
  );
};

export const TopicDetailModal: React.FC<PropsWithChildren<Props>> = ({
  topic,
  open,
  onClose,
  filters,
  children,
}) => {
  return (
    <DashboardFilterPovider
      filters={{
        dateRange: {
          type: 'dateRange',
          config: {
            interval: [new Date(Date.parse(filters.from)), new Date(Date.parse(filters.to))],
            value: 'custom',
          },
        },
        account: {
          type: 'account',
          config: {
            selected: filters.accountId || '',
          },
        },
      }}
    >
      <Wrapper open={open} topic={topic} onClose={onClose}>
        {children}
      </Wrapper>
    </DashboardFilterPovider>
  );
};
