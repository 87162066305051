import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ParticipantsSVG = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.1998 5.30002C7.1998 6.62551 6.12529 7.70002 4.7998 7.70002C3.47432 7.70002 2.3998 6.62551 2.3998 5.30002C2.3998 3.97454 3.47432 2.90002 4.7998 2.90002C6.12529 2.90002 7.1998 3.97454 7.1998 5.30002Z"
        fill="#D1D1D1"
      />
      <path
        d="M13.5998 5.30002C13.5998 6.62551 12.5253 7.70002 11.1998 7.70002C9.87432 7.70002 8.7998 6.62551 8.7998 5.30002C8.7998 3.97454 9.87432 2.90002 11.1998 2.90002C12.5253 2.90002 13.5998 3.97454 13.5998 5.30002Z"
        fill="#D1D1D1"
      />
      <path
        d="M10.3431 14.1C10.3805 13.8387 10.3998 13.5716 10.3998 13.3C10.3998 11.9919 9.95128 10.7885 9.19961 9.83528C9.788 9.49486 10.4712 9.30002 11.1998 9.30002C13.409 9.30002 15.1998 11.0909 15.1998 13.3V14.1H10.3431Z"
        fill="#D1D1D1"
      />
      <path
        d="M4.7998 9.30002C7.00894 9.30002 8.7998 11.0909 8.7998 13.3V14.1H0.799805V13.3C0.799805 11.0909 2.59067 9.30002 4.7998 9.30002Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};
export const Participants: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={ParticipantsSVG} {...props} />;
};
