import React, { useState } from 'react';
import Styles from './styles';
import { Button, Form, Input } from 'antd';
import { coreAPI } from '../../../../API/core';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../../redux/slices/sessionSlice';
import { useToast } from '../../../../hooks/useToast';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';

const { FormInputsContainer } = Styles;

const ResetPasswordForm: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const { error } = useToast();

  const dispatch = useDispatch();

  const changePassword = () => {
    if (newPassword !== confirmPassword) {
      error('New password and confirm password must match.');
    } else {
      setIsWorking(true);
      const client = new coreAPI();
      client
        .resetPassword({
          password: confirmPassword,
        })
        .then(() => {
          dispatch(logoutUser());
        })
        .catch(clientError => {
          if (clientError.response && clientError.response.data.message) {
            error(clientError.response.data.message);
          } else {
            error('There was an error setting the new password.');
          }
        })
        .finally(() => {
          setIsWorking(false);
        });
    }
  };

  return (
    <AuthenticationForm title="Reset password" description="Set a new password">
      <FormInputsContainer>
        <Form layout="vertical">
          <Form.Item style={{ marginBottom: '16px', fontSize: 'Inter' }} label="New password">
            <Input
              onChange={e => setNewPassword(e.target.value)}
              disabled={isWorking}
              type="password"
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '16px', fontSize: 'Inter' }}
            label="Confirm new password"
          >
            <Input
              onChange={e => setConfirmPassword(e.target.value)}
              disabled={isWorking}
              type="password"
            />
          </Form.Item>
        </Form>
        <Button
          onClick={changePassword}
          disabled={isWorking}
          type="primary"
          style={{ width: '100%' }}
        >
          Set password
        </Button>
      </FormInputsContainer>
    </AuthenticationForm>
  );
};

export default ResetPasswordForm;
