import React, { PropsWithChildren } from 'react';
import Styles from './style';

const { Container, Error } = Styles;

interface Props {
  message?: string;
}

export const ErrorView: React.FC<PropsWithChildren<Props>> = ({
  message = 'Error 404',
  children,
}) => <Container>{children || <Error>{message}</Error>}</Container>;
