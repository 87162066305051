import { useDispatch, useSelector } from 'react-redux';
import { coreAPI } from '../API/core';
import { selectRecapAccount, selectRecapProspectedAccounts } from '../redux/selectors';
import { ProviderIntegration, Store } from '../redux/typings/store';
import { addRecapAccount, removeRecapAccount } from '../redux/slices/recapSlice';
import { useState } from 'react';
import { useToast } from './useToast';

export const useRecapAccount = (): {
  recapAccount: ProviderIntegration | null;
  setAccount: (account: ProviderIntegration) => unknown;
  removeAccount: () => unknown;
  isWorking: boolean;
  prospectedAccounts: ProviderIntegration[] | null;
} => {
  const [isWorking, setIsWorking] = useState(false);
  const dispatch = useDispatch();
  const eventId = useSelector((store: Store) => store.recap.event.id);
  const eventDomain = useSelector((store: Store) => store.recap.event.domain);
  const recapAccount = useSelector(selectRecapAccount);
  const prospectedAccounts = useSelector(selectRecapProspectedAccounts);

  const { error } = useToast();

  const removeAccount = () => {
    if (recapAccount?.id === eventDomain) {
      return;
    }

    setIsWorking(true);
    const api = new coreAPI();
    api
      .removeRecapAccount(eventId)
      .then(() => {
        dispatch(removeRecapAccount());
      })
      .catch(() => {
        error('There was an error removing the account.');
      })
      .finally(() => {
        setIsWorking(false);
        dispatch(addRecapAccount({ id: eventDomain, name: eventDomain, provider: 'email' }));
      });
  };

  const setAccount = (account: ProviderIntegration) => {
    setIsWorking(true);
    const api = new coreAPI();
    api
      .setRecapAccount(eventId, account.id, account.provider, account.name)
      .then(() => {
        dispatch(addRecapAccount(account));
      })
      .catch(() => {
        error('There was an error setting the account.');
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  return {
    isWorking,
    recapAccount,
    setAccount,
    removeAccount,
    prospectedAccounts,
  };
};
