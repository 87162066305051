import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Space, Tabs } from 'antd';
import { AlertConsumer } from '../../componentsV2/Alert/AlertConsumer';
import { AlertProvider } from '../../componentsV2/Alert/context/AlertProvider';
import { RecapsTab } from '../../features/home/tabs/RecapsTab';
import { UpcomingsTab } from '../../features/home/tabs/UpcomingsTab';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useOrganizationDetails } from '../../features/organizations/hooks/useOrganizationDetails';
import { ReportMissingMeeting } from './components/ReportMissingMeeting';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';
import { updateFirstTimeUser } from '../../redux/slices/sessionSlice';
import { PastMeetingsProvider } from '../../features/meetings/past-meetings/past-meetings.provider';
import { UpcomingMeetingsProvider } from '../../features/meetings/upcoming-meetings/upcoming-meetings.provider';

export const Home: React.FC = () => {
  const { name } = useOrganizationDetails();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('upcomings');
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLocationChange();

  useEffect(() => {
    if (!['/meetings/upcoming', '/meetings/recaps'].includes(location.pathname)) {
      return navigate('/meetings/recaps');
    }
    const currentTab = location.pathname === '/meetings/upcoming' ? 'upcomings' : 'recaps';
    setSelectedTab(currentTab);
  }, [location]);

  useEffect(() => {
    if (user?.firstTimeUser) {
      dispatch(updateFirstTimeUser());
    }
  }, []);

  const handleTabChange = (tabId: string) => {
    if (tabId === 'recaps') {
      navigate('/meetings/recaps');
    } else {
      navigate('/meetings/upcoming');
    }
    setSelectedTab(tabId);
  };

  return (
    <AlertProvider>
      <TitledVerticalFlexLayout title={(name ? name + ' ' : '') + 'Meetings'}>
        <AlertConsumer />

        <Tabs
          tabBarExtraContent={
            <Space>
              <ReportMissingMeeting />
            </Space>
          }
          items={[
            {
              key: 'upcomings',
              label: 'Upcoming Meetings',
              children: (
                <UpcomingMeetingsProvider>
                  <UpcomingsTab />
                </UpcomingMeetingsProvider>
              ),
            },
            {
              key: 'recaps',
              label: 'Past Meetings',
              children: (
                <PastMeetingsProvider>
                  <RecapsTab />
                </PastMeetingsProvider>
              ),
            },
          ]}
          style={{ width: '100%' }}
          activeKey={selectedTab}
          onChange={handleTabChange}
        />
      </TitledVerticalFlexLayout>
    </AlertProvider>
  );
};
