import React, { ReactNode } from 'react';
import Styles from './styles';

interface Props {
  children: ReactNode[] | ReactNode;
  title?: string;
}

const { Container, Contents, Divider, Title, Header } = Styles;

export const FilterSection: React.FC<Props> = ({ children, title }) => {
  return (
    <Container>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      <Divider />
      <Contents>{children}</Contents>
    </Container>
  );
};

FilterSection.displayName = 'FilterSection';
