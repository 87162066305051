import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 100%;
  gap: 5px;

  h1 {
    color: #212121;
    font-family: 'Cera Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    margin: 0;
    padding: 0;
  }

  h2 {
    color: #6a6a6a;
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07px;
    margin: 0;
    padding: 0;
  }
`;

export default { Container };
