import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  > {
    flex: 1;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ShowLabel = styled.span`
  color: #d2d2d2;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: pointer;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PoweredBy = styled.span`
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SummaryTextContainer = styled.div`
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
`;

export default {
  Container,
  Indicators,
  ShowLabel,
  Footer,
  SummaryContainer,
  SummaryTextContainer,
  PoweredBy,
};
