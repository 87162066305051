import styled from 'styled-components';
import { colors } from '../../../theme/colors';

export const Container = styled.div<{ collapsed?: boolean }>`
  ${props => (props.collapsed ? '' : 'width: 100%;')}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px;
  isolation: isolate;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    border-radius: 4px;
    background: ${colors.coolGray[100]};
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
`;

export const AvatarAndLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  justify-content: flex-start;
`;

export const AvatarImage = styled.div`
  border-radius: 20px;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  line-height: 40px;
  & > span {
    color: white;
    font-size: 20px;
    width: 30px;
    height: 30px;
    color: black;
  }
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #00489f;
`;

export const NameAndEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
`;
export const Name = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a6a6a6;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 132px;
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
