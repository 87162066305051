import React from 'react';
import { PastEvent, UpcomingEvent } from '../../../../../API/types';
import { TimeBox } from './components/TimeBox';
import { MeetingCardContent } from './components/MeetingCardContent';
import { ProviderIntegration } from '../../../../../redux/typings/store';

import Styles from './styles';
const { Container } = Styles;

export type Event = Partial<PastEvent> &
  Partial<UpcomingEvent> & {
    id: string;
    scheduledStartDate: string;
    scheduledEndDate: string;
    title: string;
    account: ProviderIntegration | null;
  };

interface Props {
  event: Event;
  secondaryActions: React.ReactNode[];
  tertiaryActions: React.ReactNode[];
  joinMeetingAction?: React.ReactNode;
  isReady?: boolean;
  onAccountRemoved: (eventId: string) => void;
  onAccountClicked: (accounts: ProviderIntegration[]) => void;
  onInsightToggleChanged?: (checked: boolean) => void;
}

export const MeetingCard: React.FC<Props> = ({
  event,
  secondaryActions,
  tertiaryActions,
  joinMeetingAction,
  isReady = false,
  onAccountRemoved,
  onAccountClicked,
  onInsightToggleChanged,
}) => {
  const canClick = event?.status === 'READY';

  return (
    <Container key={event.id + '-event-id'} canClick={canClick}>
      <TimeBox
        start={event?.started || event.scheduledStartDate}
        end={event?.ended || event.scheduledEndDate}
      />

      <MeetingCardContent
        event={{ ...event }}
        secondaryActions={secondaryActions}
        joinMeetingAction={joinMeetingAction}
        isReady={isReady}
        onAccountRemoved={onAccountRemoved}
        onAccountClicked={onAccountClicked}
        onInsightToggleChanged={onInsightToggleChanged}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          gap: '10px',
          minWidth: '170px',
        }}
      >
        {tertiaryActions}
      </div>
    </Container>
  );
};
