import styled from 'styled-components';
import Label from '../../../../features/shared/components/Label';

const FormContainer = styled.div`
  max-width: 354px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #ff6c54;
`;

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

const TermsLink = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
`;

const TermsLabel = styled(Label)`
  font-size: 12px;
`;

const LoginLink = styled(Link)`
  margin-left: 4px;
  font-size: 14px;
`;

const SecurityLink = styled(Link)`
  margin-left: 4px;
  font-size: 12px;
`;

const GoogleWorkspaceContainer = styled.div`
  padding-bottom: 12px;
  display: flex;
`;

const TermsContainer = styled.div`
  padding-top: 12px;
  border-top: 1px solid #d1d1d1;
`;

const SignInContainer = styled.div`
  display: flex;
  padding-top: 12px;
  align-self: center;
`;

const GoogleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8pxM;
`;

const SocContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 354px;
  width: 100%;
  font-size: 12px;
  padding-top: 12px;
`;

const SocLogo = styled.img`
  width: 36px;
  height: 36px;
`;

const ListItem = styled.li`
  margin: 4px 0px 0px 0px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
`;

export default {
  FormContainer,
  LoginLink,
  TermsContainer,
  ActionsContainer,
  SocContainer,
  SocLogo,
  TermsLabel,
  TermsLink,
  SecurityLink,
  ListItem,
  ListContainer,
  SignInContainer,
  GoogleWorkspaceContainer,
  GoogleContainer,
  LoginOptions,
};
