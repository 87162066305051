import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.span`
  color: var(--cool-gray-500, #0d283b);
  text-align: center;
  /* Text Styles/Heading 6 */
  font-family: Cera Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Subtitle = styled.span`
  color: var(--cool-gray-400, #566976);
  text-align: center;
  /* Text sm/Regular */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export default {
  Container,
  Title,
  Subtitle,
  Contents,
};
