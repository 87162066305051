import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { coreAPI } from '../../../../API/core';
import { FullLoadingView } from '../../../../componentsV2/FullLoadingView';
import { loginSuccess, logoutUser } from '../../../../redux/slices/sessionSlice';

interface Props {
  children: React.ReactElement;
}

export const UserResolver: React.FC<Props> = ({ children }) => {
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const dispatch = useDispatch();

  const returnedComponent = useMemo(() => {
    if (isFetchingUser) {
      return <FullLoadingView />;
    }

    return children;
  }, [isFetchingUser]);

  useEffect(() => {
    const api = new coreAPI();
    api
      .fetchUser()
      .then(user => {
        dispatch(loginSuccess({ user }));
      })
      .catch(() => {
        dispatch(logoutUser());
      })
      .finally(() => {
        setIsFetchingUser(false);
      });
  }, []);

  return returnedComponent;
};
