import { useContext } from 'react';
import { insightCategoriesStore } from '../stores/insights-categories';
import { insightCategoriesContext } from '../context/InsightCategoriesProvider';

export const useInsightCategories = () => {
  const { isFetching, categories, type } = insightCategoriesStore();
  const { refetch } = useContext(insightCategoriesContext);

  return {
    isFetching,
    refetch,
    categories,
    type,
  };
};
