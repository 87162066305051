import { useEffect, useState } from 'react';
import { Table } from '../../../../../../componentsV2/Table';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { format } from 'date-fns';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MentionsContainer } from '../MentionsContainer';
import { ro } from 'date-fns/locale';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  arr: number | null;
  renewalDate: Date | null;
}

export interface Props {
  sortBy: string;
  topic: {
    id: string;
    name: string;
  };
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  elems?: TopAccountRecord[];
  loading?: boolean;
}

export const TopAccountsCondensedTable: React.FC<Props> = ({
  elems: data,
  topic,
  sortBy,
  loading = false,
  filters,
}) => {
  const [expandRow, setExpandRow] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      setExpandRow([]);
    };
  }, [data]);

  return (
    <Table
      pagination={false}
      loading={loading}
      rowKey={row => row.account.id}
      columns={[
        {
          title: 'Account',
          key: 'accounts',
          dataIndex: 'account',
          render: value => {
            return <AccountLabel account={value} />;
          },
        },
        {
          title: '# of Mentions',
          key: 'mentions',
          dataIndex: 'mentions',
          render: value => `${value}`,
        },
        {
          title: '# of Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          render: value => `${value}`,
        },
      ]}
      data={data || []}
      props={{
        expandable: {
          expandedRowKeys: expandRow,
          expandIcon: (row: any) => (
            <Button
              icon={row.expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
              onClick={() => {
                if (expandRow.includes(row.record.account.id)) {
                  setExpandRow(expandRow.filter(id => id !== row.record.account.id));
                } else {
                  setExpandRow([...expandRow, row.record.account.id]);
                }
              }}
            />
          ),
          expandedRowRender: (record: any) => {
            return (
              <MentionsContainer
                sortBy={sortBy}
                topic={topic}
                filters={{ ...filters, accountId: record.account.id }}
                accountId={[record.id, record.name]}
              />
            );
          },
        },
      }}
    />
  );
};
