import React from 'react';

interface IRecapPlayerContext {
  status: 'playing' | 'paused' | 'stopped';
  isPlaying: boolean;
  isPaused: boolean;
  isMuted: boolean;
  play: (time?: number) => unknown;
  pause: () => unknown;
  skip: (amount: number) => unknown;
  changeVolume: (value: number) => unknown;
  changePlaybackRate: (rate: number) => unknown;
  seek: (value: number) => unknown;
  mute: () => unknown;
  unmute: () => unknown;
  containerRects: {
    height: number;
  };
  volume: number;
  duration: number;
  currentTime: number;
  playbackRate: number;
  isScrollingEnabled: boolean;
  changeScrollEnabled: (value: boolean) => unknown;
  setVideoPlayerRef: (ref: HTMLVideoElement | HTMLAudioElement | null) => unknown;
  setContainerRef: (ref: HTMLElement | null) => unknown;
}

const VideoRecapPlayerContext = React.createContext<IRecapPlayerContext>({
  isPaused: false,
  isPlaying: false,
  status: 'stopped',
  volume: 0,
  duration: 0,
  currentTime: 0,
  isMuted: false,
  play: () => null,
  pause: () => null,
  skip: () => null,
  seek: () => null,
  playbackRate: 0,
  changePlaybackRate: () => null,
  changeVolume: () => null,
  mute: () => null,
  unmute: () => null,
  setVideoPlayerRef: () => null,
  setContainerRef: () => null,
  isScrollingEnabled: false,
  changeScrollEnabled: () => null,
  containerRects: {
    height: 0,
  },
});

export default VideoRecapPlayerContext;
