import { Modal } from 'antd';
import React from 'react';
import {
  AlertLogo,
  CancelButton,
  ConfirmDeleteButton,
  Container,
  Content,
  Detail,
  Footer,
  Heading,
  TextContainer,
} from './style';
import IconSvg from '../../../../../../components/common/IconSvg';

interface Props {
  shouldShow?: boolean;
  closeModal: () => void;
  onConfirm?: () => void;
  heading?: string;
  detail?: string;
  loading?: boolean;
  confirmButtonText?: string;
}

const DeleteModal: React.FC<Props> = ({
  shouldShow,
  closeModal,
  onConfirm,
  heading,
  detail,
  loading,
  confirmButtonText,
}) => {
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal title={false} open={shouldShow} closable={false} destroyOnClose footer={false} centered>
      <Container>
        <Content>
          <AlertLogo>
            <IconSvg name="alertTriangle" color="transparent" size="md" />
          </AlertLogo>

          <TextContainer>
            <Heading>{heading}</Heading>
            <Detail>{detail}</Detail>
          </TextContainer>
        </Content>
        <Footer>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ConfirmDeleteButton
            onClick={handleConfirm}
            loading={loading}
            style={{ width: 'fit-content' }}
          >
            {confirmButtonText ? confirmButtonText : 'Yes, remove them now'}
          </ConfirmDeleteButton>
        </Footer>
      </Container>
    </Modal>
  );
};

export default DeleteModal;
