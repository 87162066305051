import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const CellLabel = styled.span`
  overflow: hidden;
  color: ${colors.gray['700']};
  text-overflow: ellipsis;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  aling-items: center;
`;

export default {
  CellLabel,
  ActionsContainer,
};
