import React, { useEffect } from 'react';
import Styles from './styles';
import { Spin } from 'antd';
import { ResultItem } from './ResultItem/ResultItem';
import { ProspectAccount } from '../../../types';

const { Container, ResultContainer, SpinnerContainer } = Styles;

interface Props {
  accounts: ProspectAccount[];
  loading: boolean;
  onSelect: (value: ProspectAccount) => void;
  selected: ProspectAccount | null;
  setSelected: (ev: ProspectAccount | null) => unknown;
}

const Results: React.FC<Props> = ({ accounts, loading, onSelect, selected, setSelected }) => {
  useEffect(() => {
    setSelected(null);
  }, [loading]);

  return (
    <Container>
      {loading ? (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      ) : (
        <ResultContainer>
          {accounts.map(acc => (
            <ResultItem
              key={acc.providerId}
              onClick={() => {
                onSelect(acc);
                setSelected(acc);
              }}
              name={acc.name}
              provider={acc.provider}
              selected={acc.providerId === selected?.providerId}
            />
          ))}
        </ResultContainer>
      )}
    </Container>
  );
};

export { Results };
