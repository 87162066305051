import {
  customerIntelligenceFiltersStore,
  reducers,
} from '../stores/customer-intelligence-filters';

const {
  accountsSelected,
  categoryChanged,
  contractValuesChanged,
  contractRenewalDateBeforeChanged,
  dateRangeChanged,
  groupSelected,
  memberSelectionToggled,
  allMembersToggled,
  industriesToggled,
  lifecycleStagesToggled,
  prioritiesToggled,
  subGroupSelected,
  meetingsImInvitedOnlyToggled,
  filtersReset,
} = reducers;

export const useCustomerIntelligenceFilters = () => {
  const {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateRange,
    group,
    industries,
    lifecycleStages,
    meetingsImInvitedOnly,
    members,
    priorities,
    subGroup,
  } = customerIntelligenceFiltersStore();

  return {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateRange,
    group,
    industries,
    lifecycleStages,
    meetingsImInvitedOnly,
    members,
    priorities,
    subGroup,
    toggleAllMembers: allMembersToggled,
    selectAccounts: accountsSelected,
    changeCategory: categoryChanged,
    changeContractValues: contractValuesChanged,
    changeContractRenewalDate: contractRenewalDateBeforeChanged,
    changeDateRange: dateRangeChanged,
    selectGroup: groupSelected,
    toggleIndustries: industriesToggled,
    toggleLifecycleStages: lifecycleStagesToggled,
    toggleMember: memberSelectionToggled,
    togglePriorities: prioritiesToggled,
    selectSubGroup: subGroupSelected,
    toggleMeetingsImInvitedOnly: meetingsImInvitedOnlyToggled,
    resetFilters: filtersReset,
  };
};
