import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  display: flex;
  padding: 16px 16px 24px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #d2d2d2;
`;

const HeaderActions = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.span`
  color: var(--Cool-Gray-500, #0d283b);
  flex: 1;
  font-family: 'Cera Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Contents = styled.div`
  padding: 16px;
  flex: 1;
  display: flex;
`;

const WatchItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export default {
  Title,
  Heading,
  Contents,
  WatchItemsContainer,
  HeaderActions,
  Container,
  SpinnerContainer,
};
