import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Actions = styled.div`
  display: flex;
  gap: 24px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  gap: 24px;
`;

const Title = styled.h1`
  color: ${colors.gray[950]};
  font-family: Cera Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Subtitle = styled.span`
  color: ${colors.gray[600]};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MeetingsVisibilitySelectors = styled.div`
  display: flex;
  gap: 16px;
`;

const MeetingsVisibilitySelector = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const VisibilityLabel = styled.span`
  color: ${colors.coolGray[950]};
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const LockContainer = styled.div`
  gap: 12px;
  display: flex;
`;

export default {
  Container,
  Actions,
  Contents,
  MainContent,
  Title,
  Subtitle,
  MeetingsVisibilitySelectors,
  MeetingsVisibilitySelector,
  VisibilityLabel,
  LockContainer,
};
