import { useContext } from 'react';
import { learningCenterContext } from './learning-center.context';
import { useLearningCenterAnalytics } from './hooks/useLearningCenterAnalytics';

export const useLearningCenter = () => {
  const { show, open, close } = useContext(learningCenterContext);
  const { home } = useLearningCenterAnalytics();

  const handleOpen = () => {
    home.viewed();
    open();
  };

  return {
    show,
    open: handleOpen,
    close,
  };
};
