import styled from 'styled-components';

const Container = styled.div<{ fullscreen: boolean }>`
  background-color: black;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 100;

  ${props =>
    props.fullscreen
      ? `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  > video {
    height: 100vh;
  }
  `
      : `position: relative;
  max-height: 260px;
  width: 100%;
  border-radius: 16px;
  > video {
    height: 260px;
    max-height: 260px;
    border-radius: 16px;
    width: 100%;
  }
`}

  &:hover {
    #controls-container {
      visibility: visible;
      opacity: 1;
    }
  }

  &:not(:hover) {
    #controls-container {
      visibility: hidden;
      opacity: 0;
    }
  }
`;

const Video = styled.video``;

export default {
  Container,
  Video,
};
