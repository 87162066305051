import { useContext } from 'react';
import { videoPlayerContext } from '../contexts/VideoPlayer';

export const useVideoPlayer = () => {
  const {
    changePlaybackRate,
    changeVolume,
    currentTime,
    duration,
    isMuted,
    isPaused,
    isPlaying,
    mute,
    pause,
    play,
    playbackRate,
    seek,
    skip,
    unmute,
    volume,
    setVideoPlayerRef,
    cropping,
    setCropping,
    maskedDuration,
    fullscreen,
    toggleFullscreen,
    download,
    isEditable,
    setIsEditable,
    markers,
    setCropCallback,
    changeCropping,
    setContainerRef,
    isScrollingEnabled,
    changeScrollEnabled,
    togglePlay,
    ready,
  } = useContext(videoPlayerContext);

  return {
    ready,
    changePlaybackRate,
    setContainerRef,
    download,
    changeVolume,
    currentTime,
    duration,
    isMuted,
    isPaused,
    isPlaying,
    mute,
    pause,
    play,
    changeCropping,
    playbackRate,
    isScrollingEnabled,
    changeScrollEnabled,
    seek,
    skip,
    unmute,
    volume,
    setVideoPlayerRef,
    cropping,
    setCropping,
    maskedDuration,
    fullscreen,
    toggleFullscreen,
    isEditable,
    setIsEditable,
    markers,
    setCropCallback,
    togglePlay,
  };
};
