import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { coreService } from '../../../services/core/core-service';
import { useToast } from '../../../hooks/useToast';
import { StarFilled, StarOutlined } from '@ant-design/icons';

export const useFavouriteAccount = (
  accountId: string,
  isPreviouslyFavourited: boolean,
  onFavourite?: (val: boolean) => void,
) => {
  const { success, error } = useToast();
  const [isFavourited, setIsFavourited] = useState(false);

  const handleFavourite = async () => {
    const previousStatus = isFavourited;

    if (onFavourite) onFavourite(!previousStatus);
    setIsFavourited(!isFavourited);

    try {
      if (isFavourited) {
        await coreService.removeFromFavouriteCohort(accountId);
        success('Account removed from Favorites');
      } else {
        await coreService.addToFavouriteCohort(accountId);
        success('Account added to Favorites');
      }
    } catch (e) {
      if (onFavourite) onFavourite(previousStatus);
      setIsFavourited(previousStatus);
      error('Failed to favorite account');
    }
  };

  const button = (
    <Tooltip title={isFavourited ? 'Remove from favorites' : 'Add to favorites'}>
      <Button
        onClick={handleFavourite}
        type="default"
        style={{ padding: '4px' }}
        icon={isFavourited ? <StarFilled style={{ color: '#ffe81c' }} /> : <StarOutlined />}
      />
    </Tooltip>
  );

  useEffect(() => {
    setIsFavourited(isPreviouslyFavourited);
  }, [isPreviouslyFavourited]);

  return { favouriteButton: button };
};
