import React, { useState } from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useIntegrationRequest } from '../../../../../features/integrations/hooks/useIntegrationRequest';
import { Store } from '../../../../../redux/typings/store';
import { useSelector } from 'react-redux';
import { selectZoomIntegration } from '../../../../../redux/selectors';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { Content } from '../style';
import { IntegrationModal } from '../Components/IntegrationModal';
import AdvancedSettings from './AdvancedSettings';
import { useZoomTest } from './hooks/useZoomTest';
import plug from '../../../../../assets/svg/actions/plug.svg';
import unplug from '../../../../../assets/svg/actions/unplug.svg';

const selectZoomIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.zoom.id;

export const ZoomIntegrationCard: React.FC = () => {
  const { isIntegrating, requestIntegration, hasIntegration, removeIntegration, isRemoving } =
    useIntegrationRequest({ source: 'ZOOM', id: selectZoomIntegrationId });
  const [showModal, setShowModal] = useState(false);
  const integrationData = useSelector(selectZoomIntegration);
  const { lastSync, test, loading, testResults } = useZoomTest();
  const logo = 'https://assets.update.ai/zoom_logo.png';

  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Disconnect',
          icon: <img src={plug} />,
          onClick: () => removeIntegration(),
          buttonProps: {
            loading: isRemoving,
            style: {
              width: '110px',
              height: '34px',
            },
          },
        },
      ]
    : [
        {
          type: 'button',
          label: 'Connect',
          icon: <img src={unplug} />,
          onClick: () => requestIntegration(),
          buttonProps: {
            loading: isIntegrating,
            type: 'primary',
            style: {
              width: '110px',
              height: '34px',
            },
          },
        },
      ];

  return (
    <IntegrationCard
      title="Zoom"
      description="Connect your zoom account to start receiving Smart Summaries from your meetings recaps."
      logoSrc={logo}
      actions={actions}
      // learnMore="https://help.update.ai/en/articles/6279111-connecting-your-zoom-account"
    >
      <Content>
        {hasIntegration && (
          <SuccessConnectionDataLabel label="Connected: " value={integrationData?.email || ''} />
        )}
        <a style={{ alignSelf: 'end', color: '#FF6C54' }} onClick={() => setShowModal(true)}>
          {'Setup Requirements >'}
        </a>
      </Content>

      <IntegrationModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        icon={logo}
        title="Zoom setup requirements"
        description={lastSync}
      >
        <AdvancedSettings onTest={test} loading={loading} testResults={testResults} />
      </IntegrationModal>
    </IntegrationCard>
  );
};
