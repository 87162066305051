import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const SkipForwardSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99966 18.4824C8.94049 18.4824 7.94777 18.2812 7.02149 17.8789C6.0952 17.4766 5.28881 16.9319 4.60233 16.2449C3.91583 15.5578 3.37217 14.7517 2.97135 13.8266C2.57053 12.9015 2.37012 11.91 2.37012 10.8522C2.37012 9.79434 2.57136 8.80226 2.97385 7.87593C3.37632 6.94961 3.92101 6.14383 4.60791 5.45859C5.2948 4.77336 6.10133 4.2295 7.02751 3.82701C7.95369 3.42454 8.94631 3.2233 10.0053 3.2233H10.4838L9.37014 2.10964C9.2391 1.98108 9.17621 1.81858 9.18149 1.62214C9.18678 1.42569 9.25495 1.26195 9.38599 1.13091C9.51415 1.00268 9.67737 0.939532 9.87568 0.941449C10.074 0.943365 10.237 1.00818 10.3647 1.13589L12.607 3.39405C12.7486 3.53898 12.8194 3.7064 12.8194 3.8963C12.8194 4.08622 12.7486 4.25198 12.607 4.39359L10.3647 6.63091C10.2264 6.77252 10.0588 6.84416 9.86197 6.84582C9.66512 6.84748 9.50118 6.7775 9.37014 6.63591C9.22853 6.49385 9.16119 6.32798 9.16814 6.13828C9.17508 5.94859 9.24936 5.78127 9.39097 5.63634L10.3846 4.64768H9.90047C8.19069 4.64768 6.74559 5.25086 5.56516 6.45724C4.38471 7.66363 3.79449 9.12853 3.79449 10.8519C3.79449 12.5754 4.39777 14.0406 5.60433 15.2475C6.81088 16.4545 8.27599 17.058 9.99966 17.058C11.586 17.058 12.9707 16.5303 14.154 15.4748C15.3373 14.4193 16.0123 13.1053 16.179 11.5326C16.2101 11.3355 16.2947 11.1729 16.4326 11.0449C16.5706 10.9169 16.7324 10.8528 16.9181 10.8528C17.1243 10.8528 17.2943 10.9212 17.4283 11.0578C17.5622 11.1944 17.6223 11.3543 17.6084 11.5376C17.4403 13.5235 16.6255 15.1774 15.1641 16.4994C13.7027 17.8214 11.9812 18.4824 9.99966 18.4824Z"
        fill="#F3F4F5"
      />
    </svg>
  );
};
export const SkipForward: React.FC<
  Partial<CustomIconComponentProps> & { onClick?: () => unknown }
> = props => {
  return <Icon onClick={props.onClick} component={SkipForwardSvg} {...props} />;
};
