import React, { useEffect, useState } from 'react';
// import useMixPanel from '../../../../features/analytics/hooks/useMixPanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/selectors';
import { setCurrentStep } from '../../../../redux/slices/onboardingSlice';
import { KnowYourself } from './KnowYourself';
import { PrimaryReason } from './PrimaryReason';
import { useOnboardingAnalytics } from '../../useOnboardingAnalytics';
import { useAnalytics } from '../../../../features/analytics/hooks/useAnalytics';

export const Validation: React.FC = () => {
  const [currentInternalStep, setCurrentInternalStep] = useState(1);
  const [role, setRole] = useState('Leader');
  // const [reason, setReason] = useState('Save Time');

  const dispatch = useDispatch();
  // const mixpanel = useMixPanel();
  const user = useSelector(selectUser);
  const { user: userAnalytics } = useOnboardingAnalytics();
  const { alias } = useAnalytics();

  const onNext = () => {
    userAnalytics.role.set(role);
    dispatch(setCurrentStep());
  };

  const onBack = () => {
    setCurrentInternalStep(currentInternalStep - 1);
  };

  useEffect(() => {
    alias(user!.id);
  }, []);

  return (
    <>
      {currentInternalStep === 1 ? (
        <KnowYourself
          currentInternalStep={currentInternalStep}
          // onNext={() => setCurrentInternalStep(currentInternalStep + 1)}
          onNext={() => onNext()}
          setRole={s => setRole(s)}
        />
      ) : (
        <PrimaryReason
          currentInternalStep={currentInternalStep}
          onBack={() => onBack()}
          onNext={() => onNext()}
          // setReason={s => setReason(s)}
          setReason={() => null}
        />
      )}
    </>
  );
};
