import styled from 'styled-components';

const TableContainer = styled.div`
  max-height: 340px;
  overflow-y: auto;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default {
  TableContainer,
  Contents,
};
