import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux/typings/store';
import Hotjar from '@hotjar/browser';

const useHotjar = () => {
  const user = useSelector((store: Store) => store.session.user);

  useEffect(() => {
    if (user) {
      Hotjar.identify(user.id, {
        email: user.email,
      });
    }
  }, [user]);
};

export default useHotjar;
