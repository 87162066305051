import { produce } from 'immer';
import { create } from 'zustand';

export interface LibraryItem {
  id: string;
  name: string;
  itemId: string;
  duration: string;
  type: string;
  thumbnail: string;
  eventId: string;
  starredBy: string;
  daysSinceMeeting: string;
  category: string;
  folder: {
    id: string;
    name: string;
    libraryId: string;
  };
}

export interface Library {
  id: string;
  type: 'organization' | 'user';
  folders: {
    id: string;
    name: string;
    isStarred: boolean;
    itemsCount: number;
    items: LibraryItem[];
  }[];
}

interface LibrariesStore {
  selectedLibraryId: string | null;
  selectedFolderId: string;
  libraries: Library[];
  isFetchingItems: boolean;
  isFetchingLibraries: boolean;
  insightSelected: {
    isFetching: boolean;
    insight: {
      id: string;
      type: string;
      folders: {
        id: string;
        name: string;
      }[];
    } | null;
  };
}

export const librariesStore = create<LibrariesStore>(() => {
  return {
    selectedLibraryId: null,
    isFetchingItems: false,
    isFetchingLibraries: false,
    libraries: [],
    selectedFolderId: '',
    insightSelected: {
      isFetching: false,
      insight: null,
    },
  };
});

export const folderItemsLoaded = (
  libraryId: string,
  folderId: string,
  items: {
    id: string;
    name: string;
    duration: string;
    thumbnail: string;
    starredBy: string;
    type: string;
    daysSinceMeeting: string;
    eventId: string;
    itemId: string;
    category: string;
    folder: {
      id: string;
      name: string;
      libraryId: string;
    };
  }[],
) => {
  librariesStore.setState(state => {
    return produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        const folder = library.folders.find(folder => folder.id === folderId);
        if (folder) {
          folder.items = items;
        }
      }
    });
  });
};

export const fetchStarted = () => {
  librariesStore.setState(() => ({ isFetchingLibraries: true }));
};

export const fetchEnded = () => {
  librariesStore.setState(() => ({ isFetchingLibraries: false }));
};

export const fetchItemsStarted = () => {
  librariesStore.setState(() => ({ isFetchingItems: true }));
};

export const fetchItemsEnded = () => {
  librariesStore.setState(() => ({ isFetchingItems: false }));
};

export const librariesFetched = (libraries: Library[]) => {
  librariesStore.setState(() => ({ libraries }));
};

export const removeItem = (libraryId: string, folderId: string, itemId: string) => {
  librariesStore.setState(state => {
    return produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        const folder = library.folders.find(folder => folder.id === folderId);
        if (folder) {
          folder.items = folder.items.filter(item => item.id !== itemId);
        }
      }
    });
  });
};

export const folderRemoved = (libraryId: string, folderId: string) => {
  librariesStore.setState(state => {
    return produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        library.folders = library.folders.filter(folder => folder.id !== folderId);
      }
    });
  });
};

export const setSelectedLibraryId = (libraryId: string) => {
  librariesStore.setState(() => ({ selectedLibraryId: libraryId }));
};

export const folderIdSelected = (folderId: string) => {
  librariesStore.setState(() => ({ selectedFolderId: folderId }));
};

export const folderAdded = (folderId: string, folderName: string, libraryId: string) => {
  librariesStore.setState(state =>
    produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        library.folders.push({
          itemsCount: 0,
          id: folderId,
          name: folderName,
          isStarred: false,
          items: [],
        });
      }
    }),
  );
};

export const folderRenamed = (folderId: string, folderName: string, libraryId: string) => {
  librariesStore.setState(state =>
    produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        const folder = library.folders.find(folder => folder.id === folderId);
        if (folder) {
          folder.name = folderName;
        }
      }
    }),
  );
};

export const folderItemRemoved = (libraryId: string, folderId: string, itemId: string) => {
  librariesStore.setState(state =>
    produce(state, draft => {
      const library = draft.libraries.find(library => library.id === libraryId);
      if (library) {
        const folder = library.folders.find(folder => folder.id === folderId);
        if (folder) {
          folder.items = folder.items.filter(item => item.id !== itemId);
        }
      }
    }),
  );
};

export const insightToManageSelected = (id: string, type: string) => {
  librariesStore.setState(() => ({
    insightSelected: { isFetching: true, insight: { id, type, folders: [] } },
  }));
};

export const insightSelectedFetchEnded = (folders: { id: string; name: string }[]) => {
  const {
    insightSelected: { insight },
  } = librariesStore.getState();
  if (!insight) {
    return;
  }

  librariesStore.setState(() => ({
    insightSelected: {
      isFetching: false,
      insight: { id: insight.id, type: insight.type, folders },
    },
  }));
};

export const insightToManageDeselected = () => {
  librariesStore.setState(() => ({
    insightSelected: {
      isFetching: false,
      insight: null,
    },
  }));
};
