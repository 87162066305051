import styled from 'styled-components';
import { keyframes } from 'styled-components';

const leftToRightAnimation = keyframes`
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: #ff0000;
  color: white;
  width: 100%;
  height: 12px;
  z-index: 9001;
  top: 0px;
  left: 0px;
  font-size: 10px;
  font-weight: 600;
`;
export const Text = styled.span`
  position: absolute;
`;

export const AnimatedText = styled.span`
  width: 100%;
  /* animation properties */
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  -moz-animation: ${leftToRightAnimation} 120s linear infinite;
  -webkit-animation: ${leftToRightAnimation} 120s linear infinite;
  animation: ${leftToRightAnimation} 120s linear infinite;
`;
