import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { useRecapManagement } from '../../recap-management/hook';

export interface IRecapAnalytics {
  viewed: () => void;
  deleted: () => void;
  meetingLevelInsightExclusionToggled: (included: boolean) => void;
  insightExclusionToggled: (included: boolean) => void;
  video: {
    played: () => void;
  };
  transcript: {
    linkCopied: (id: string) => void;
  };
  insight: {
    created: (category: string) => void;
    deleted: (category: string) => void;
  };
  smartClip: {
    navigated: () => void;
    copied: () => void;
    favorited: () => void;

    favoriteFolder: {
      created: () => void;
      updated: () => void;
      removed: () => void;
    };
  };
  shareLink: {
    copied: () => void;
  };
  dynamicContent: {
    copied: () => void;
  };
  followUp: {
    copied: () => void;
  };
  TLDR: {
    copied: () => void;
  };
  smartSummary: {
    copied: () => void;
    public: {
      copied: () => void;
    };
  };
}

enum oldRecapEvents {
  webMeetingRecapView = 'web_meeting_recap_view',
  copySmartSummary = 'copy_smart_summary_internal',
  copySmartSummaryPublic = 'copy_smart_summary_external',
  copyTLDR = 'copy_TLDR',
  copyShareLink = 'copy_share_link',
  copySmartClip = 'copy_smart_clip',
  copyFollowUp = 'copy_follow_up',
  // bigPicturedSaved = 'big_pictured_saved',
  recapDeleted = 'recap_deleted',
  copyDynamicContent = 'copy_dynamic_content',
  newInsight = 'insight_added',
  deletedInsight = 'insight_deleted',
  playerPlay = 'recap_video_played',
  smartClipNavigated = 'smart_clip_navigated',
}

// const OBJECT = 'Recap';

export const useRecapAnalytics = () => {
  const { sendEvent } = useAnalytics();
  const { id, isDemo } = useRecapManagement();

  return {
    meetingLevelInsightExclusionToggled: (included: boolean) => {
      sendEvent('Toggled Meeting Level Insight Exclusion', {
        event: {
          included: String(included),
        },
      });
    },
    insightExclusionToggled: (included: boolean) => {
      sendEvent('Toggled Insight Exclusion', {
        event: {
          included: String(included),
        },
      });
    },
    viewed: () => {
      if (id) {
        sendEvent(oldRecapEvents.webMeetingRecapView, {
          people: {
            viewed_meeting_recap: 'true',
          },
          event: {
            is_demo: String(isDemo),
            recap_ID: id,
          },
        });
      }
    },
    transcript: {
      linkCopied: (id: string) => {
        sendEvent('Transcript Link Copied', {
          event: {
            recap_ID: id,
            transcript_id: id,
          },
        });
      },
    },
    deleted: () => {
      sendEvent(oldRecapEvents.recapDeleted, {
        event: {
          recap_ID: id,
        },
      });
    },
    insight: {
      created: (category: string) => {
        sendEvent(oldRecapEvents.newInsight, {
          event: {
            category: category,
          },
        });
      },
      deleted: (category: string) => {
        sendEvent(oldRecapEvents.deletedInsight, {
          event: {
            category: category,
          },
        });
      },
    },
    video: {
      played: () => sendEvent(oldRecapEvents.playerPlay),
    },
    smartClip: {
      navigated: () => sendEvent(oldRecapEvents.smartClipNavigated),
      copied: () => sendEvent(oldRecapEvents.copySmartClip),
      favorited: () => sendEvent('Smart Clip Favorited'),

      favoriteFolder: {
        created: () => sendEvent('Favorite Folder Created'),
        updated: () => sendEvent('Favorite Folder Updated'),
        removed: () => sendEvent('Favorite Folder Removed'),
      },
    },
    shareLink: {
      copied: () => sendEvent(oldRecapEvents.copyShareLink),
    },
    dynamicContent: {
      copied: () => sendEvent(oldRecapEvents.copyDynamicContent),
    },
    followUp: {
      copied: () => sendEvent(oldRecapEvents.copyFollowUp),
    },
    TLDR: {
      copied: () => sendEvent(oldRecapEvents.copyTLDR),
    },
    smartSummary: {
      copied: () => sendEvent(oldRecapEvents.copySmartSummary),

      public: {
        copied: () => sendEvent(oldRecapEvents.copySmartSummaryPublic),
      },
    },
  } as IRecapAnalytics;
};
