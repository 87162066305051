import styled from 'styled-components';

const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export default {
  DropdownOption,
};
