import { useState } from 'react';
import { Modal } from '../../../../../componentsV2/Modal';
import TabV2 from '../../../../shared/components/TabV2';
import { useLearningCenter } from '../../learning-center.hook';
import Styles from './styles';
import { ArticleSection } from './components/ArticleSection';
import { articles } from './data';

const { TabItem, Tab } = TabV2;
const { Contents, ArticleContainer } = Styles;

export const LearningCenter: React.FC = () => {
  const { show, close } = useLearningCenter();
  const [currentTab, setCurrentTab] = useState('team-contributor');

  return (
    <Modal
      onClose={() => close()}
      open={show}
      title="Learning Center"
      primaryAction={{
        label: 'Go to help center',
        onClick: () => window.open('https://help.update.ai', '_blank'),
      }}
    >
      <Contents>
        <Tab
          activeKey={currentTab}
          items={[
            {
              children: (
                <ArticleContainer>
                  <ArticleSection section={articles['team-contributor']} />
                </ArticleContainer>
              ),
              key: 'team-contributor',
              label: (
                <TabItem isActive={currentTab === 'team-contributor'} label="Team Contributor" />
              ),
            },
            {
              children: (
                <ArticleContainer>
                  <ArticleSection section={articles['team-leader']} />
                </ArticleContainer>
              ),
              key: 'team-leader',
              label: <TabItem isActive={currentTab === 'team-leader'} label="Team Leader" />,
            },
          ]}
          onChange={key => setCurrentTab(key)}
        />
      </Contents>
    </Modal>
  );
};
