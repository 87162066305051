import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Styles from './styles';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const { Container, Header, HeaderContents, Contents } = Styles;

interface Props {
  header: ReactElement;
  open?: boolean;
}

export const AccordionCard: React.FC<PropsWithChildren<Props>> = ({ header, children, open }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof open !== 'undefined') {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <Container>
      <Header isOpen={isOpen}>
        <HeaderContents>{header}</HeaderContents>
        {isOpen ? (
          <UpOutlined onClick={() => setIsOpen(false)} />
        ) : (
          <DownOutlined onClick={() => setIsOpen(true)} />
        )}
      </Header>
      {isOpen && <Contents>{children}</Contents>}
    </Container>
  );
};
