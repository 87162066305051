import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DateText = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
`;
