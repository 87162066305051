import React from 'react';
import Style from './style';
import { Avatar, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const { Container, Title, Content, Data, Item } = Style;

interface Props {
  onJoin: () => void;
  name: string;
  owner: string;
  invitedBy?: string;
  members: number;
  invited: boolean;
  loading: boolean;
  disabled: boolean;
  selected: boolean;
  label: string;
}

export const Invitation: React.FC<Props> = ({
  onJoin,
  members,
  owner,
  invitedBy,
  invited = false,
  loading = false,
  disabled = false,
  selected = false,
  name,
  label,
}) => {
  const organizationName = name.charAt(0).toUpperCase();

  return (
    <Container>
      <Content>
        <Avatar size="large">{organizationName}</Avatar>

        <Data>
          <Title>{name}</Title>
          <Item>{members === 1 ? '1 Member' : `${members} Members`}</Item>
          <Item>Owner: {owner}</Item>
          {invited && <Item>You were invited to this workspace by {invitedBy}</Item>}
          {!invited && <Item>Your email domain matches this workspace</Item>}
        </Data>
      </Content>

      {selected ? (
        <div
          style={{ width: '85px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <CheckCircleFilled style={{ color: '#16B380' }} />
        </div>
      ) : (
        <Button type="primary" loading={loading} onClick={onJoin} disabled={disabled}>
          {label}
        </Button>
      )}
    </Container>
  );
};
