import { useMemo, useState } from 'react';
import { coreService } from '../../../services/core/core-service';
import {
  organizationMembersStore,
  meetingsVisibilityModified,
  meetingsVisibilityLockModified,
} from '../../../stores/organization-members';

export const useMemberMeetingVisibility = (userId: string) => {
  const { members } = organizationMembersStore();
  const [isModifyingLock, setIsModifyingLock] = useState(false);
  const [isModifyingSettings, setIsModifyingSettings] = useState(false);

  const member = useMemo(() => {
    return members.find(mem => mem.user.id === userId);
  }, [members]);

  const meetingsVisibility = useMemo(() => {
    if (member) {
      return {
        internalMeetingsVisibility: member.meetingsVisibility.internalMeetingsVisibility,
        externalMeetingsVisibility: member.meetingsVisibility.externalMeetingsVisibility,
      };
    }
    return null;
  }, [member]);

  const meetingVisibilityLocked = useMemo(() => {
    if (member) {
      return member.meetingVisibilityLocked;
    }
    return null;
  }, [member]);

  const isMember = Boolean(member);

  const modifyMemberMeetingsVisibilityLock = async (locked: boolean) => {
    setIsModifyingLock(true);
    await coreService.modifyMemberMeetingsVisibilityLock(userId, locked);
    meetingsVisibilityLockModified(userId, locked);
    setIsModifyingLock(false);
  };

  const modifyMemberMeetingsVisibility = async (args: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  }) => {
    setIsModifyingSettings(true);
    await coreService.modifyMemberMeetingsVisibility(userId, args);
    meetingsVisibilityModified(userId, args);
    setIsModifyingSettings(false);
  };

  const isAllowedToModifyLock = member?.permissions.manageMeetingsVisibilityLock;
  const isAllowedToModifyVisibility = member?.permissions.manageMeetingsVisibility;

  return {
    isMember,
    isModifyingLock,
    isAllowedToModifyLock,
    isAllowedToModifyVisibility,
    modifyMemberMeetingsVisibility,
    modifyMemberMeetingsVisibilityLock,
    isModifyingSettings,
    meetingsVisibility,
    meetingVisibilityLocked,
  };
};
