import { Store } from '../typings/store';

// Session.
export const selectSession = (store: Store) => store.session;
export const selectSessionStatus = (store: Store) => store.session.status;
export const selectCalendarStatus = (store: Store) => {
  if (store.session.user?.configuration?.integration?.google?.id) {
    return { ...store.session.calendar, ...{ provider: 'GOOGLE' } };
  } else if (store.session.user?.configuration?.integration?.microsoft?.id) {
    return { ...store.session.calendar, ...{ provider: 'MICROSOFT' } };
  }

  return store.session.calendar;
};
export const selectZoomStatus = (store: Store) => store.session.zoom;
export const selectZoomLoadingStatus = (store: Store) => store.session.zoom;
export const selectSlackStatus = (store: Store) => store.session.slack;
export const selectSalesforceStatus = (store: Store) => store.session.salesforce;
export const selectHubspotStatus = (store: Store) => store.session.hubspot;

// User.
export const selectUser = (store: Store) => store.session.user;
export const selectSettings = (store: Store) => store.session.user?.configuration.settings;
export const selectOnboardingStatus = (store: Store) => store.session.user?.onboardingStatus;
export const selectDemoEventId = (store: Store) => store.session.user?.demoEventId;
export const selectUserFirstTimeUse = (store: Store) => store.session.user?.firstTimeUser;
export const selectUserHasBotEnabled = (store: Store) => store.session.user?.hasBotEnabled;
export const selectSubscription = (store: Store) =>
  store.session.user?.membership.organization.subscription;
export const selectUserSubscription = (store: Store) => store.session.user?.userSubscription;
export const selectUserShareEventSettings = (store: Store) =>
  store.session.user?.configuration.settings.shareEventSettings;
export const selectUserAvailableFeatures = (store: Store) => store.session.user?.features;

// Integrations.
export const selectIntegrations = (store: Store) => store.session.user?.configuration.integration;
export const selectCalendarIntegration = (store: Store) => {
  let response = undefined;
  if (store.session.user?.configuration?.integration?.google?.id) {
    response = {
      ...store.session.user?.configuration.integration.google,
      ...{ provider: 'GOOGLE' },
    };
  } else if (store.session.user?.configuration?.integration?.microsoft?.id) {
    response = {
      ...store.session.user?.configuration.integration.microsoft,
      ...{ provider: 'MICROSOFT' },
    };
  }

  return (
    response ||
    store.session.user?.configuration?.integration?.google ||
    store.session.user?.configuration?.integration?.microsoft
  );
};
export const selectZoomIntegration = (store: Store) =>
  store.session.user?.configuration.integration.zoom;
export const selectSlackIntegration = (store: Store) =>
  store.session.user?.configuration.integration.slack;
export const selectSalesforceIntegration = (store: Store) =>
  store.session.user?.configuration.integration.salesforce;
export const selectHubspotIntegration = (store: Store) =>
  store.session.user?.configuration.integration.hubspot;
export const selectCrmProfile = (store: Store) => store.session.user?.crmProfile;

// Home.
export const selectRecaps = (store: Store) => store.home.recaps;
export const selectRecapsCurrentPage = (store: Store) => store.home.recaps.metadata.page;
export const selectRecapsLastSearch = (store: Store) => store.home.recaps.filter.lastSearch;
export const selectRecapsFilter = (store: Store) => store.home.recaps.filter;
export const selectRecapsFilterShowAllStatus = (store: Store) =>
  store.home.recaps.filter.showAllStatus;
export const selectRecapsFilterProviders = (store: Store) => store.home.recaps.filter.providers;

export const selectEvents = (store: Store) => store.home.events;
export const selectEventsFilter = (store: Store) => store.home.events.filter;
export const selectEventsFilterProviders = (store: Store) => store.home.events.filter.providers;
export const selectEventsCurrentPage = (store: Store) => store.home.events.metadata.page;
export const selectEventsLastSearch = (store: Store) => store.home.events.filter.lastSearch;

export const currentTab = (store: Store) => store.home.selectedTab;

// Recap.
export const selectRecapEventData = (state: Store) => state.recap.event;
export const selectRecapAccount = (state: Store) => state.recap.event.account;
export const selectRecapProspectedAccounts = (state: Store) => state.recap.event.prospectedAccounts;
export const selectRecapFetching = (store: Store) => store.recap.status.isFetching;
export const selectKeyMoments = (state: Store) => state.recap.event.keyMoments;
export const selectRecapError = (store: Store) => store.recap.status.error;
export const selectRecapExternalResource = (store: Store) => store.recap.event.externalResource;
export const selectRecapStatus = (store: Store) => store.recap.event.status;
export const selectRecapId = (store: Store) => store.recap.event.id;
export const selectRecapStarted = (store: Store) => store.recap.event.started;
export const selectRecapEnded = (store: Store) => store.recap.event.ended;
export const selectRecapDemoFlag = (store: Store) => store.recap.event.isDemo;
export const selectRecapEventMessages = (store: Store) => store.recap.event.messages;

// Onboarding
export const selectOnboardingLoading = (store: Store) => store.onboarding.isLoading;
export const selectOnboardingError = (store: Store) => store.onboarding.error;

// Settings
export const selectUserOrganizations = (store: Store) => store.session.user?.organizations;
export const selectUserOrganizationsIsCreating = (store: Store) => store.organizations.isCreating;
export const selectUserOrganizationsIsUpdating = (store: Store) => store.organizations.isUpdating;
export const selectUserMembership = (store: Store) => store.session.user?.membership;
