import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const ChevronUpDownSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.25 15L12 18.75L15.75 15M8.25 9L12 5.25L15.75 9"
        stroke="#9EA9B1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronUpDown: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={ChevronUpDownSVG} {...props} />;
};
