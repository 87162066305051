import styled from 'styled-components';

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default { Loader, TwoColumns };
