import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: solid 2px ${colors.coolGray[100]};
  gap: 16px;
  font-family: Cera Pro;

  transition: height 0.15s ease-out;

  &:hover {
    cursor: pointer;
    background-color: #f7f9fa;
  }
`;

const Title = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  width: 100%;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
`;

const Img = styled.img`
  height: 24px;
  width: 24px;
  align-self: center;
`;

export default { Container, Title, Img, Description, Header };
