import React, { PropsWithChildren, useMemo } from 'react';
import Styled from './styles';
import { Input, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchAccountOrEmailDomain } from '../../../../hooks/useSearchAccountsOrEmailDomain';
import { Results } from './Results/Results';
import { ProviderIntegration } from '../../../../redux/typings/store';
import { NoCRMConnectionMessage } from '../../../shared/components/CRMConnection';

const { AccountsSelectedContainer, Contents } = Styled;

interface Props {
  selectedAccounts: ProviderIntegration[];
  placeholder: string;
  filter: (values: ProviderIntegration[]) => ProviderIntegration[];
  onAccountsSelectionChange: (accounts: ProviderIntegration[]) => void;
}

export const AccountFilterPanel: React.FC<PropsWithChildren<Props>> = ({
  onAccountsSelectionChange,
  selectedAccounts,
  placeholder,
  filter,
}) => {
  const { setSearch, search, isSearching, accounts } = useSearchAccountOrEmailDomain();

  const filteredAccounts = useMemo(() => {
    return filter(accounts);
  }, [accounts, filter]);

  const accountsToShow: { item: ProviderIntegration; checked: boolean }[] = useMemo(() => {
    return filteredAccounts.map(acc => {
      return {
        item: acc,
        checked: Boolean(selectedAccounts.find(as => as.id === acc.id)),
      };
    });
  }, [selectedAccounts, filteredAccounts]);

  const addAccount = (provider: ProviderIntegration) => {
    const newAccounts = [...selectedAccounts];
    newAccounts.push(provider);
    onAccountsSelectionChange(newAccounts);
  };

  const removeAccount = (provider: ProviderIntegration) => {
    const newAccounts = selectedAccounts.filter(p => p.id !== provider.id);
    onAccountsSelectionChange(newAccounts);
  };

  return (
    <Contents>
      <AccountsSelectedContainer>
        {selectedAccounts.map(acc => (
          <Tag key={acc.id} closable onClose={() => removeAccount(acc)}>
            {acc.name}
          </Tag>
        ))}
      </AccountsSelectedContainer>
      <Input
        onChange={ev => setSearch(ev.target.value)}
        prefix={<SearchOutlined />}
        allowClear
        placeholder={placeholder}
      />
      {search ? (
        <Results
          loading={isSearching}
          accounts={accountsToShow}
          onCheck={addAccount}
          onUncheck={removeAccount}
        />
      ) : (
        <NoCRMConnectionMessage />
      )}
    </Contents>
  );
};
