import style from './style';
import { Radio, Space, Tooltip } from 'antd';
import {
  AlignLeftOutlined,
  ClockCircleFilled,
  CopyFilled,
  ReloadOutlined,
} from '@ant-design/icons';
// import { FilterFavoriteClip } from '../../../features/shared/components/Icons/FilterFavoriteClip';
import { OptionalTooltipWrapper } from '../../OptionalTooltipWrapper';
import { format } from 'date-fns';

const { Container, Buttons, Button, LastGeneratedLabel } = style;

interface Props {
  defaultValue: string;
  disableGenerate: boolean;
  isGenerating?: boolean;
  lastGeneratedAt: Date | null;
  onClick: (v: string) => void;
  onGenerate: () => void;
  handleCopyAll: () => void;
  onFilter?: () => void;
}

export const ActionBar: React.FC<Props> = ({
  defaultValue,
  disableGenerate,
  isGenerating,
  lastGeneratedAt,
  handleCopyAll,
  onClick,
  onGenerate,
  // onFilter,
}) => {
  const wasGenerated = Boolean(lastGeneratedAt);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {lastGeneratedAt && (
        <LastGeneratedLabel>
          Last generated at{' '}
          {lastGeneratedAt ? format(lastGeneratedAt, 'MM/dd/yyy hh:mm aa') : 'Not generated yet'}
        </LastGeneratedLabel>
      )}
      <Container>
        <Buttons>
          <Radio.Group
            defaultValue={defaultValue}
            disabled={!wasGenerated}
            size="small"
            onChange={t => onClick(t.target.value)}
            style={{ display: 'flex' }}
          >
            <Radio.Button value="timeline" data-testid="account-story-timeline-button">
              <Space style={{ fontSize: '14px' }}>
                <ClockCircleFilled />
                Timeline View
              </Space>
            </Radio.Button>
            <Radio.Button value="narrative" data-testid="account-story-narrative-button">
              <Space style={{ fontSize: '14px' }}>
                <AlignLeftOutlined />
                Narrative View
              </Space>
            </Radio.Button>
          </Radio.Group>
        </Buttons>

        <div style={{ display: 'flex', gap: '6px', width: 'auto' }}>
          <OptionalTooltipWrapper
            display={disableGenerate}
            value="Account story is being generated"
          >
            <Tooltip title="Copy All">
              <Button
                onClick={handleCopyAll}
                type="default"
                size="small"
                icon={<CopyFilled />}
                disabled={!lastGeneratedAt || isGenerating}
              />
            </Tooltip>

            <Tooltip title="Regenerate Account Story">
              <Button
                type="default"
                icon={<ReloadOutlined />}
                size="small"
                onClick={onGenerate}
                disabled={disableGenerate}
                loading={isGenerating}
              />
            </Tooltip>
          </OptionalTooltipWrapper>
        </div>
      </Container>
    </div>
  );
};

/* {onFilter ? (
  <Button
    size="middle"
    style={{ justifyContent: 'center', alignContent: 'center' }}
    onClick={onFilter}
    data-testid="account-story-filter-button"
    disabled={defaultValue === 'narrative'}
  >
    <FilterFavoriteClip style={{ marginTop: '2px', marginLeft: '3px' }} />
  </Button>
) : (
  <></>
)} */
