import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 0px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
`;

const SearchContainer = styled.div`
  padding-top: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
`;

const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 12px;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 24px;
  gap: 16px;
`;

const SectionTitle = styled.span`
  color: var(--cool-gray-300, #9ea9b1);
  padding: var(--spacing-md, 8px) 0px 4px 0px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const SectionContainer = styled.div`
  padding: 0px 24px;
`;

const FoldersList = styled.div`
  padding-top: 4px;
  max-height: 200px;
  overflow-y: auto;
`;

const NewFolder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  cursor: pointer;
`;

const Folder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  cursor: pointer;

  &:hover {
    background-color: var(--cool-gray-100, #f5f7f9);
  }
`;

const FolderName = styled.div`
  color: var(--cool-gray-700, #0a1e2c);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
`;

const NewFolderLabel = styled.span`
  color: var(--primary-500, #ff6c54);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export default {
  Footer,
  Container,
  Content,
  Heading,
  Header,
  SearchContainer,
  Modal,
  NewFolder,
  Icon,
  SectionTitle,
  SectionContainer,
  FoldersList,
  FolderName,
  NewFolderLabel,
  Folder,
};
