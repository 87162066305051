import React from 'react';
import Styles from './styles';
import { secondsToTime } from '../../../../../../helpers/TextHelper';
import { useVideoPlayer } from '../../../../hooks/useVideoPlayer';

const { Container } = Styles;

export const Time: React.FC = () => {
  const { currentTime, maskedDuration } = useVideoPlayer();
  return (
    <Container>
      {secondsToTime(currentTime)} / {secondsToTime(maskedDuration)}
    </Container>
  );
};
