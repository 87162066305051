import styled from 'styled-components';
import { colors } from '../../../../../../../../theme/colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.white};
  padding: 12px;
  border-radius: var(--4px, 8px);
  border: 1px solid #e7eaeb;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  border-left: 2px solid #e7eaeb;
  padding-left: 12px;
  gap: 7px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 50%;
`;

const Title = styled.div`
  overflow: hidden;
  color: var(--Cool-Gray-700, #0a1e2c);
  text-overflow: ellipsis;
  font-family: 'Cera Pro Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
`;

const DateLabel = styled.div`
  overflow: hidden;
  color: ${colors.gray[400]};
  text-overflow: ellipsis;
  font-family: 'Cera Pro';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;

export default {
  Container,
  Actions,
  Header,
  Title,
  DateLabel,
};
