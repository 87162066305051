import React from 'react';
import Styles from './styles';
import { Button, Spin } from 'antd';
import { getTexts } from './utils';
import { useNavigate } from 'react-router-dom';

const { Container, Title, Subtitle, IndicatorContainer } = Styles;

interface Props {
  status: 'error' | 'loading' | 'success' | 'warning';
}

export const InvitationCard: React.FC<Props> = ({ status }) => {
  const isLoading = status === 'loading';
  const isWarning = status === 'warning';
  const { title, subtitle, image: InvitationImage } = getTexts(status);
  const navigate = useNavigate();

  const goTo = (to: string) => {
    navigate(to);
  };

  return (
    <Container>
      <IndicatorContainer>
        {isLoading ? <Spin spinning={isLoading} /> : InvitationImage && <InvitationImage />}
      </IndicatorContainer>
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>

      <div>
        {!isLoading && !isWarning && (
          <Button type="primary" size="large" onClick={() => goTo('/')}>
            Go to my homepage
          </Button>
        )}
      </div>

      <div>
        {isWarning && (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              type="primary"
              size="large"
              onClick={() => goTo('/signup')}
              style={{ width: '200px' }}
            >
              Create my account
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => goTo('/signin')}
              style={{ width: '200px' }}
            >
              Sign in
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};
