import React from 'react';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';

export const NoDetectionMessage: React.FC<{
  isInternal: boolean;
  isExternalCategory: boolean;
  category: string;
}> = props => {
  const { isExternalCategory, isInternal } = props;
  const onlyToExternal = isInternal && isExternalCategory;
  const message = onlyToExternal ? 'Applies only to external meetings' : 'No insights detected';
  return (
    <OptionalTooltipWrapper
      display={!onlyToExternal}
      overlayInnerStyle={{ width: '320px' }}
      value={`Our AI analyzes ${props.category} in your meetings based on training patterns and meeting context, while filtering out irrelevant chatter. Note: Some scattered or unclear insights may be missed.`}
    >
      <p style={{ textAlign: 'center', color: 'gray' }}>{message}</p>
    </OptionalTooltipWrapper>
  );
};
