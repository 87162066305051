import { create } from 'zustand';
import { EventDetails, Insight } from './types';
import { produce } from 'immer';

interface SummarizeInsightState {
  isSummarizing: boolean;
  show: boolean;
  additionalNote: string;
  insights: Insight[];
  details: EventDetails | null;
  result: {
    summary: string;
    references: string[];
  } | null;
  isSummarizingStatusChanged: (value: boolean) => void;
  resultGenerated: (result: { summary: string; references: string[] }) => void;
  reset: () => void;
  openModal: () => void;
  closeModal: () => void;
  setDetails: (details: EventDetails) => void;
  addInsight: (insight: Insight) => void;
  additionalNoteModified: (note: string) => void;
  removeInsight: (insightId: string) => void;
}

export type SummarizeInsightStore = ReturnType<typeof createSummarizeInsightStore>;

export const createSummarizeInsightStore = () => {
  return create<SummarizeInsightState>(set => {
    return {
      isSummarizing: false,
      additionalNote: '',
      show: false,
      result: null,
      details: null,
      additionalNoteModified: note => set({ additionalNote: note }),
      isSummarizingStatusChanged: value => set({ isSummarizing: value }),
      resultGenerated: result => set({ result: result }),
      reset: () =>
        set({ isSummarizing: false, result: null, show: false, details: null, additionalNote: '' }),
      openModal: () => set({ show: true }),
      closeModal: () => set({ show: false }),
      setDetails: details => set({ details }),
      insights: [],
      addInsight: insight => {
        set(store => {
          return produce(store, draft => {
            draft.insights.push(insight);
          });
        });
      },
      removeInsight: insightId => {
        set(store => {
          return produce(store, draft => {
            draft.insights = draft.insights.filter(insight => insight.id !== insightId);
          });
        });
      },
    };
  });
};
