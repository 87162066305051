import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0px 24px 0px 24px;
  position: relative;
  height: 290px;
  overflow: auto;
`;

const MessageContainer = styled.div<{ isUpdateAI: boolean }>`
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
`;

const Message = styled.div<{ isUpdateAI: boolean }>`
  align-items: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 550px;
`;

const Sender = styled.span`
  color: ${colors.coolGray[500]};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Timestamp = styled.span`
  color: ${colors.coolGray[300]};
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MessageBuble = styled.div<{ isUpdateAI: boolean }>`
  display: flex;
  justify-content: ${({ isUpdateAI }) => (isUpdateAI ? 'start' : 'end')};
  border-radius: ${({ isUpdateAI }) => (isUpdateAI ? '0px 14px 14px 14px' : '14px 0px 14px 14px')};
  border: 1px solid ${colors.coolGray[100]};
  padding: 16px 24px;
  align-items: center;
  background: ${colors.white};
  filter: drop-shadow(2px 2px 12px rgba(156, 169, 178, 0.25));
  min-width: 30%;

  a {
    color: ${colors.primary[500]};
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 14px !important;
    font-weight: 500;
    margin: 0px;
  }

  h2 {
    font-size: 13px !important;
    font-weight: 300;
    margin: 0px;
  }

  h3 {
    font-size: 11px !important;
    font-weight: 200;
    margin: 0px;
    margin-top: -7px;
  }

  h4 {
    font-size: 12px !important;
    font-weight: 200;
    margin: 0px;

    a {
      color: ${colors.primary[500]};
    }
  }
`;

const Pulse = keyframes`
0% {
  transform: scale(0.95);
  opacity: 0.7;
}
50% {
  transform: scale(1);
  opacity: 1;
}
100% {
  transform: scale(0.95);
  opacity: 0.7;
}
`;

const PulsingDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.primary['400']};
  border-radius: 50%;
  animation: ${Pulse} 1s infinite ease-in-out;
`;

export default {
  Container,
  MessageContainer,
  Message,
  Sender,
  Timestamp,
  MessageBuble,
  TooltipContent,
  PulsingDot,
};
