import { customerIntelligenceDataStore, reducers } from '../stores/customer-intelligence-data';
import { InsightSmartClip } from '../types';

export const useCustomerIntelligenceData = () => {
  const { groups, isFetchingItems, isFetching, showWatchItems, watchItems, meetingsCount } =
    customerIntelligenceDataStore();

  const fetchItems = (fetchFn: () => Promise<InsightSmartClip[]>) => {
    reducers.watchItemsShowToggled(true);
    reducers.watchItemsFetchStarted();
    fetchFn()
      .then(items => reducers.watchItemsFetched(items))
      .finally(() => reducers.watchItemsFetchEnded());
  };

  return {
    groups,
    isFetching,
    isFetchingItems,
    showWatchItems,
    watchItems,
    meetingsCount,
    toggleShowWatchItems: reducers.watchItemsShowToggled,
    fetchItems,
  };
};
