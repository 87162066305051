import styled from 'styled-components';

const Contents = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;

const OptionsContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  max-height: 260px;
  overflow-y: auto;
`;

const SelectAllHeader = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 8px;
`;

const OptionLabel = styled.span`
  cursor: pointer;
`;

export default {
  Contents,
  OptionsContainer,
  SelectAllHeader,
  OptionLabel,
};
