import styled from 'styled-components';

export const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: 100%;
`;
