import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/selectors';
import { useNavigate } from 'react-router-dom';
import { Recap } from '../pages/recap';
import { RecapManagementProvider } from '../features/recapv2/recap-management/context';

export const RecapDemoResolver: React.FC = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  if (!user?.demoEventId) {
    navigate('/');
    return null;
  }

  return (
    <RecapManagementProvider id={user.demoEventId} secret="">
      <Recap id={user.demoEventId} shareCode="" />
    </RecapManagementProvider>
  );
};
