import styled from 'styled-components';

const Container = styled.span`
  display: block;
  color: #212121;
  font-variant-numeric: stacked-fractions;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 24px;
  cursor: pointer;
`;

const Highlight = styled.div<{ color: string }>`
  border-radius: 2px;
  background: ${props => props.color};
  padding: 2px;
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline;
`;

const SpeakerTimestamp = styled.span`
  overflow: hidden;
  color: #212121;
  font-variant-numeric: stacked-fractions;
  text-overflow: ellipsis;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export default {
  Container,
  Highlight,
  SpeakerTimestamp,
};
