import { put, putResolve, retry, take, takeLatest } from 'redux-saga/effects';
import { coreAPI } from '../../API/core';
import { setCurrentStep as setCurrentStepAction, setLoader } from '../slices/onboardingSlice';
import { silentFetchUser as silentFetchUserAction, fetchUserSuccess } from '../slices/sessionSlice';

const RETRY_TIMES = 3;
const INTERVAL_1_SECOND = 1000;

function* updateUserOnboardingStatus() {
  yield put(setLoader({ value: true }));
  const API = new coreAPI();

  try {
    yield retry(RETRY_TIMES, INTERVAL_1_SECOND, API.nextOnboardingStep);
    yield putResolve(silentFetchUserAction());
    yield take(fetchUserSuccess);
    yield put(setLoader({ value: false }));
  } catch {
    yield put(
      setLoader({
        value: false,
        error: 'Error while updating onboarding status. Please retry later.',
      }),
    );
  }
}

export function* onboardingSaga() {
  yield takeLatest(setCurrentStepAction.type, updateUserOnboardingStatus);
}
