import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #bcaeff;
  position: relative;
  background: linear-gradient(132deg, #7230ff 0%, #ff6c54 100%);
  color: white;
`;

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  right: 16px;
  top: 16px;
  stroke: white;
  fill: white;
  color: white;
`;

const Contents = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const HighlightLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const RegularLabel = styled.span`
  font-size: 14px;
  color: white;
`;

const UnderlineLabel = styled(RegularLabel)`
  text-decoration: underline;
`;

const JoshFace = styled.img`
  width: 28px;
  height: 28px;
`;

export default {
  Container,
  Contents,
  TextContents,
  HighlightLabel,
  RegularLabel,
  UnderlineLabel,
  CloseButton,
  JoshFace,
};
