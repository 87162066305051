import { PropsWithChildren, createContext, useState } from 'react';

interface LearningCenterContext {
  show: boolean;
  open: () => unknown;
  close: () => unknown;
}

export const learningCenterContext = createContext<LearningCenterContext>({
  show: false,
  open: () => null,
  close: () => null,
});

export const LearningCenterProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [show, setShow] = useState(false);

  const open = () => {
    setShow(true);
  };

  const close = () => setShow(false);

  return (
    <learningCenterContext.Provider value={{ show, open, close }}>
      {children}
    </learningCenterContext.Provider>
  );
};
