import { create } from 'zustand';

interface RecapPlaybackState {
  messageIdPlaying: string;
  messagePlayingChanged: (messageId: string) => void;
  autoScroll: boolean;
  autoScrollingChanged: (autoScroll: boolean) => void;
}

export const recapPlaybackStore = create<RecapPlaybackState>(set => {
  return {
    messageIdPlaying: '',
    messagePlayingChanged: messageId => set({ messageIdPlaying: messageId }),
    autoScroll: true,
    autoScrollingChanged: autoScroll => set({ autoScroll }),
  };
});
