interface ArticleCollection {
  howDoI: Array<{ title: string; link: string }>;
  howCanUpdateAI: Array<{ title: string; link: string }>;
}

export interface ArticleType {
  gettingStarted: ArticleCollection;
  beyond: ArticleCollection;
}

export const articles: { [k: string]: ArticleType } = {
  'team-contributor': {
    gettingStarted: {
      howDoI: [
        {
          title: 'Make sure the right meetings are being recorded?',
          link: 'https://help.update.ai/en/articles/8940704-how-do-i-make-sure-the-right-meetings-are-being-recorded',
        },
        {
          title: 'Address concerns or questions about my meeting being recorded?',
          link: 'https://help.update.ai/en/articles/8940746-how-do-i-address-concerns-or-questions-about-my-meetings-being-recorded',
        },
      ],

      howCanUpdateAI: [
        {
          title: 'Help me pull the most important information out of every customer meeting?',
          link: 'https://help.update.ai/en/articles/8930940-how-to-pull-the-most-important-information-out-of-every-customer-meeting',
        },
        {
          title: 'Help me share key moments of a meeting with my team/manager/internal partner?',
          link: 'https://help.update.ai/en/articles/8293320-how-to-use-smart-clips',
        },
        {
          title: 'Help me share key moments of a meeting with my customer?',
          link: 'https://help.update.ai/en/articles/6296112-how-to-create-a-publicly-shareable-view-of-your-meeting-recap',
        },
        {
          title: 'Help me add my meeting notes to my CRM?',
          link: 'https://help.update.ai/en/articles/7211777-how-to-integrate-salesforce-or-hubspot-crm-into-updateai',
        },
      ],
    },
    beyond: {
      howDoI: [
        {
          title: 'Keep sensitive conversations private?',
          link: 'https://help.update.ai/en/articles/8946223-how-do-i-keep-sensitive-conversations-private',
        },
        {
          title: 'Manage native integrations with UpdateAI?',
          link: 'https://help.update.ai/en/articles/8946349-how-do-i-manage-native-integrations-with-updateai',
        },
      ],
      howCanUpdateAI: [
        {
          title: 'Enhance CSM to CSM account transition?',
          link: 'https://help.update.ai/en/articles/9028903-how-can-updateai-enhance-csm-to-csm-account-transition',
        },
        {
          title: 'Help me send a follow-up to my customers that they will actually read?',
          link: 'https://help.update.ai/en/articles/9028939-how-can-updateai-help-me-send-a-follow-up-to-my-customers-that-they-will-actually-read',
        },
      ],
    },
  },
  'team-leader': {
    gettingStarted: {
      howCanUpdateAI: [
        {
          title: 'Help me find the most prominent trends to share and act on with other leaders?',
          link: 'https://help.update.ai/en/articles/8996894-identify-trends-and-portfolio-insights',
        },
      ],
      howDoI: [
        {
          title: 'Manage the privacy settings of my workspace?',
          link: 'https://help.update.ai/en/articles/8096685-setting-visibility-for-your-meeting-recaps-in-updateai',
        },
        {
          title: 'Prevent a specific client account from being recorded?',
          link: 'https://help.update.ai/en/articles/9012073-how-do-i-prevent-a-specific-client-account-from-being-recorded',
        },
        {
          title: 'How do I make sure every meeting in my workspace is pushed to our CRM?',
          link: 'https://help.update.ai/en/articles/9056845-how-do-i-make-sure-every-meeting-in-my-workspace-is-pushed-to-our-crm',
        },
      ],
    },
    beyond: {
      howCanUpdateAI: [
        {
          title: 'Help me understand commitments my team has made?',
          link: 'https://help.update.ai/en/articles/9028991-how-can-updateai-help-me-understand-commitments-my-team-has-made',
        },
      ],
      howDoI: [
        {
          title: 'Invite additional team members to my UpdateAI workspace?',
          link: 'https://help.update.ai/en/articles/8097274-understanding-user-roles-and-permissions-in-updateai-owners-admins-and-members',
        },
        {
          title: 'Map my CRM properties to UpdateAI account properties?',
          link: 'https://help.update.ai/en/articles/8996898-mapping-crm-fields-and-properties',
        },
        {
          title: 'Customize AI insights for my workspace?',
          link: 'https://help.update.ai/en/articles/9058485-how-do-i-customize-ai-insights-for-my-workspace',
        },
      ],
    },
  },
};

export const articleSections = [
  { label: 'Team Leader', key: 'team-leader' },
  { label: 'Team Contributor', key: 'team-contributor' },
];
