import React, { useState } from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { createZapierApiKey } from '../../../../../features/integrations/integrations.service';
import { extractError } from '../../../../../utils/api';
import { useToast } from '../../../../../hooks/useToast';
import { useIntegrationRequest } from '../../../../../features/integrations/hooks/useIntegrationRequest';
import { Store } from '../../../../../redux/typings/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../../../redux/slices/sessionSlice';
import { IntegrationModal } from '../Components/IntegrationModal';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { MoreInfo } from './MoreInfo';
import Styles from './styles';
import { CreateZapierApiKeyResult } from '../../../../../services/types';
import useFeatures from '../../../../../hooks/useFeatures';
import { SubscribeToTeamsPlanLink } from '../../../../../componentsV2/UpgradeToPro';

const { Content } = Styles;

export const ZapierIntegrationCard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState<CreateZapierApiKeyResult>({ id: '', secretKey: '***' });

  const features = useFeatures();
  const availableFeature = features.zapierIntegration;

  const { hasIntegration, isRemoving, removeIntegration } = useIntegrationRequest({
    source: 'ZAPIER',
    id: (store: Store) => store.session.user?.configuration.integration.zapier?.id,
  });
  const integrationData = useSelector(
    (store: Store) => store.session.user?.configuration.integration.zapier,
  );
  const dispatch = useDispatch();
  const { error: errorToast } = useToast();

  const logo = 'https://assets.update.ai/zapier.png';

  const revokeKey = async () => {
    await removeIntegration();
    setResult({ id: '', secretKey: '***' });
  };

  const createZapierIntegration = async () => {
    setIsLoading(true);
    try {
      const result = await createZapierApiKey();
      setResult(result);
      dispatch(fetchUser());
      setShowModal(true);
    } catch (error) {
      const errorMessage = extractError(error);
      errorToast(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const tooltipMessage = (
    <SubscribeToTeamsPlanLink title="Upgrade to a Business plan or higher to integrate with Zapier." />
  );
  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Revoke Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'default',
            loading: isRemoving,
            disabled: isRemoving || isLoading || !availableFeature,
          },
          onClick: () => {
            if (availableFeature) revokeKey();
          },
        },
      ]
    : [
        {
          type: 'button',
          label: 'Generate Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'primary',
            loading: isLoading,
            disabled: isLoading || !availableFeature,
          },
          onClick: () => {
            if (availableFeature) createZapierIntegration();
          },
        },
      ];

  return (
    <IntegrationCard
      title="Zapier"
      description="Build custom workflows. Automate the busywork, so you can focus on your job, not your tools."
      actions={actions}
      logoSrc={logo}
    >
      <Content>
        {hasIntegration && (
          <>
            <SuccessConnectionDataLabel
              label="Id: "
              value={result.id || integrationData?.apiKeyId || ''}
            />
            <a style={{ alignSelf: 'end', color: '#FF6C54' }} onClick={() => setShowModal(true)}>
              {'More Info >'}
            </a>
          </>
        )}
      </Content>

      <IntegrationModal
        show={showModal}
        onCancel={() => {
          setShowModal(false);
          setResult({ id: result.id, secretKey: '***' });
        }}
        icon={logo}
        title="Zapier advanced settings"
      >
        <MoreInfo secret={result.secretKey} id={result.id || integrationData?.apiKeyId!} />
      </IntegrationModal>
    </IntegrationCard>
  );
};
