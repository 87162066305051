import { Button } from 'antd';
import styled from 'styled-components';
import LabeledInput from '../_Misc/LabeledInput';
import { colors } from '../../../../theme/colors';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: calc(100vh - 180px);
  padding-bottom: 20px;
  padding-right: 10px;
`;

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #818181;
  align-self: stretch;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 24px 32px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const UploadLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 4px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;
export const UploadLogoFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 24px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const UploadImageButtonFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const UploadImageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;

  width: 75px;
  height: 75px;
  border: 2px dashed #d1d1d1;
  border-radius: 6px;
`;

export const HelpText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #818181;
  white-space: pre-line;
`;

export const UploadButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 11px;
  width: 94px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5a5a5a;
`;

export const StyledInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 0px 9px 13px;
  width: 375px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;

  width: 100%;
  height: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d1d1d1;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ToggleCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Separator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;

  width: 100%;
  height: 1px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

export const LargeTitle = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 20px;
  line-height: 24px;
  color: #282828;
  flex: none;
  flex-grow: 0;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 0px 16px;
  margin-bottom: 15px;
`;

export const AlertContainer = styled.div`
  display: flex;
  padding: 24px;
`;

export const AlertNotice = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const BotSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
`;

export const BotSwitchLabel = styled.span`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.coolGray['700']};
  align-self: stretch;
`;

export const BotGreetingSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
