import { Tooltip } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';

interface Props {
  display?: boolean;
  value?: string | ReactNode;
  overlayInnerStyle?: React.CSSProperties;
}

export const OptionalTooltipWrapper: React.FC<PropsWithChildren<Props>> = ({
  display = false,
  value,
  children,
  overlayInnerStyle,
}) => {
  if (display && value) {
    return (
      <Tooltip overlayInnerStyle={overlayInnerStyle && { zIndex: 1000 }} title={value}>
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};
