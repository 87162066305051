import React, { useState } from 'react';
import AuthenticationLayout from '../../componentsV2/Layouts/AuthenticationLayout';
import SigninForm from './components/SigninForm';
import LoginForm from './components/LoginForm';

const SigninPage: React.FC = () => {
  const [currentForm, setCurrentForm] = useState<'signin' | 'login'>('signin');

  const formToDisplay =
    currentForm === 'signin' ? (
      <SigninForm onStandardLogin={() => setCurrentForm('login')} />
    ) : (
      <LoginForm onBack={() => setCurrentForm('signin')} />
    );

  return <AuthenticationLayout leftSide={formToDisplay} rightSide={<p></p>} />;
};

export default SigninPage;
