import React, { useMemo } from 'react';
import { Container } from './style';
import { Helmet } from 'react-helmet';

interface Props {
  maxWidth?: number;
}

export const GoogleButton: React.FC<Props> = ({ maxWidth = 380 }) => {
  const isDevelopment = process.env.REACT_APP_PORTAL_ENV === 'development';
  const referrer = isDevelopment ? 'no-referrer-when-downgrade' : '';
  //const baseURL = isDevelopment ? 'http://localhost:4000' : process.env.REACT_APP_API_CORE;
  const baseURL = process.env.REACT_APP_API_CORE;

  const button = useMemo(() => {
    return (
      <>
        <div
          id="g_id_onload"
          data-client_id="1064401347505-5gpsbufkddjtqd7g3eaaggr3k21kqcac.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="redirect"
          data-login_uri={baseURL + '/users/google/authenticate'}
          data-auto_prompt="false"
        />

        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="center"
          data-width={maxWidth}
        />
      </>
    );
  }, [maxWidth]);

  return (
    <Container>
      {button}
      <Helmet>
        <script src="https://accounts.google.com/gsi/client" referrerPolicy={referrer} />
      </Helmet>
    </Container>
  );
};
