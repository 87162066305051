import styled from 'styled-components';

const Container = styled.span<{ open: boolean }>`
  border-radius: 6px;
  width: 48px;
  border: 1px solid
    ${props => (props.open ? 'var(--primary-200, #FFDAD4)' : 'var(--cool-gray-400, #566976)')};
  background: ${props => (props.open ? 'var(--primary-500, #FF6C54)' : 'transparent')};
  display: flex;
  padding: 3px 6px;
  align-items: flex-end;
  justify-content: center;

  & > span {
    color: var(--cool-gray-50, #f3f4f5);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
  }
`;

export default {
  Container,
};
