import { coreService } from '../../services/core/core-service';
import {
  saveSettingsEnded,
  saveSettingsStarted,
  externalRecordingSettingsUpdated,
  internalRecordingSettingsUpdated,
  userSettingsStore,
  updateSettings as updateSettingsStore,
  botAnnouncementUpdated,
} from '../../stores/user-settings';
import { Settings } from './types';

const updateOrRestoreSettings = async (
  update: () => Promise<void>,
  restore: () => Promise<void>,
) => {
  try {
    saveSettingsStarted();
    await update();
  } catch {
    await restore();
  } finally {
    saveSettingsEnded();
  }
};

export const updateInternalMeetingRecordingSetting = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings.internalMeetingsRecording;

  updateOrRestoreSettings(
    async () => {
      internalRecordingSettingsUpdated({
        record: value,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });

      await coreService.updateSettings({
        internalMeetingsRecording: {
          record: value,
          sendBotIfNoHost: currentSettings.sendBotIfNoHost,
        },
      });
    },
    async () => {
      internalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });
    },
  );
};

export const updateSettings = async (settings: Partial<Settings>) => {
  const currentSettings = userSettingsStore.getState().settings;

  updateOrRestoreSettings(
    async () => {
      updateSettingsStore(settings);

      await coreService.updateSettings(settings);
    },
    async () => {
      updateSettingsStore(currentSettings);
    },
  );
};

export const updateExternalMeetingRecordingSetting = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings.externalMeetingsRecording;

  updateOrRestoreSettings(
    async () => {
      externalRecordingSettingsUpdated({
        record: value,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });

      await coreService.updateSettings({
        externalMeetingsRecording: {
          record: value,
          sendBotIfNoHost: currentSettings.sendBotIfNoHost,
        },
      });
    },
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });
    },
  );
};

export const updateMeetingRecordingSetting = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings;

  updateOrRestoreSettings(
    async () => {
      externalRecordingSettingsUpdated({
        record: value,
        sendBotIfNoHost: currentSettings.externalMeetingsRecording.sendBotIfNoHost,
      });
      internalRecordingSettingsUpdated({
        record: value,
        sendBotIfNoHost: currentSettings.internalMeetingsRecording.sendBotIfNoHost,
      });

      await coreService.updateSettings({
        externalMeetingsRecording: {
          record: value,
          sendBotIfNoHost: currentSettings.externalMeetingsRecording.sendBotIfNoHost,
        },
        internalMeetingsRecording: {
          record: value,
          sendBotIfNoHost: currentSettings.internalMeetingsRecording.sendBotIfNoHost,
        },
      });
    },
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.externalMeetingsRecording.record,
        sendBotIfNoHost: currentSettings.externalMeetingsRecording.sendBotIfNoHost,
      });
      internalRecordingSettingsUpdated({
        record: currentSettings.internalMeetingsRecording.record,
        sendBotIfNoHost: currentSettings.internalMeetingsRecording.sendBotIfNoHost,
      });
    },
  );
};

export const updateInternalSendBot = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings.internalMeetingsRecording;

  updateOrRestoreSettings(
    async () => {
      internalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: value,
      });
      await coreService.updateSettings({
        internalMeetingsRecording: {
          record: currentSettings.record,
          sendBotIfNoHost: value,
        },
      });
    },
    async () => {
      internalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });
    },
  );
};

export const updateExternalSendBot = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings.externalMeetingsRecording;

  updateOrRestoreSettings(
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: value,
      });
      await coreService.updateSettings({
        externalMeetingsRecording: {
          record: currentSettings.record,
          sendBotIfNoHost: value,
        },
      });
    },
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.record,
        sendBotIfNoHost: currentSettings.sendBotIfNoHost,
      });
    },
  );
};

export const updateSendBot = async (value: boolean) => {
  const currentSettings = userSettingsStore.getState().settings;

  updateOrRestoreSettings(
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.externalMeetingsRecording.record,
        sendBotIfNoHost: value,
      });
      internalRecordingSettingsUpdated({
        record: currentSettings.internalMeetingsRecording.record,
        sendBotIfNoHost: value,
      });

      await coreService.updateSettings({
        externalMeetingsRecording: {
          record: currentSettings.externalMeetingsRecording.record,
          sendBotIfNoHost: value,
        },
        internalMeetingsRecording: {
          record: currentSettings.internalMeetingsRecording.record,
          sendBotIfNoHost: value,
        },
      });
    },
    async () => {
      externalRecordingSettingsUpdated({
        record: currentSettings.externalMeetingsRecording.record,
        sendBotIfNoHost: currentSettings.externalMeetingsRecording.sendBotIfNoHost,
      });
      internalRecordingSettingsUpdated({
        record: currentSettings.internalMeetingsRecording.record,
        sendBotIfNoHost: currentSettings.internalMeetingsRecording.sendBotIfNoHost,
      });
    },
  );
};
