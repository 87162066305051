import React, { useState } from 'react';
import { Modal } from '../../../../../componentsV2/Modal';
import { Alert } from '../../../../../componentsV2/Alert/Alert';
import { useInviteBot } from '../../invite-bot.hook';
import Styles from './styles';
import { Input, Select, Space, Tooltip } from 'antd';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';
import { extractError } from '../../../../../utils/api';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Container, FormItem, Label } = Styles;

export const InviteBotModal: React.FC = () => {
  const { open, close } = useInviteBot();
  const [conferencingUrl, setConferencingUrl] = useState('');
  const [meetingTitle, setMeetingTitle] = useState('');
  const [participantsType, setParticipantsType] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const { success, error } = useToast();

  const areArgsValid = Boolean(conferencingUrl) && Boolean(participantsType);

  const handleExit = () => {
    setConferencingUrl('');
    setMeetingTitle('');
    setParticipantsType('');
    close();
  };

  const handleSubmission = () => {
    setIsInviting(true);
    coreService
      .inviteBot({
        conferencingUrl,
        title: meetingTitle || undefined,
        participantsType,
      })
      .then(() => {
        success(`Bot invited successfully.`);
      })
      .catch(err => {
        const message = extractError(err);
        error(message);
      })
      .finally(() => {
        setIsInviting(false);
        handleExit();
      });
  };

  return (
    <Modal
      width={600}
      onClose={handleExit}
      open={open}
      primaryAction={{
        label: 'Invite bot',
        loading: isInviting,
        disabled: !areArgsValid || isInviting,
        onClick: handleSubmission,
      }}
      secondaryAction={{
        label: 'Cancel',
        disabled: isInviting,
        onClick: handleExit,
      }}
      title="Invite bot to new meeting"
    >
      <Container>
        <Alert
          title=""
          variant="info"
          message="We support links from Google Meet, Microsoft Teams, Webex and Zoom. Please note that after successful creation, the bot may take a minute or two to join."
        />
        <FormItem>
          <Label>Meeting Title (Recommended)</Label>
          <Input
            placeholder="Defaults to meeting link"
            onChange={ev => setMeetingTitle(ev.target.value)}
            value={meetingTitle}
          />
        </FormItem>
        <FormItem>
          <Label>Meeting Link</Label>
          <Input
            placeholder="e.g. https://meet.google.com/aaa-bbbb-ccc"
            onChange={ev => setConferencingUrl(ev.target.value)}
            value={conferencingUrl}
          />
        </FormItem>
        <FormItem>
          <Space>
            <Label>Participants Type</Label>
            <Tooltip title="Click to open help article">
              <QuestionCircleOutlined
                onClick={() =>
                  window.open(
                    'https://help.update.ai/en/articles/8024488-how-does-updateai-know-whether-a-meeting-is-internal-or-external',
                    '_blank',
                  )
                }
              />
            </Tooltip>
          </Space>
          <Select
            value={participantsType}
            onChange={value => setParticipantsType(value)}
            options={[
              {
                label: 'Internal',
                value: 'internal',
              },
              {
                label: 'External',
                value: 'external',
              },
            ]}
          ></Select>
        </FormItem>
      </Container>
    </Modal>
  );
};
