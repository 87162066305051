import React from 'react';
import { Crop, CropCallback } from '../../types';

interface IVideoPlayerContext {
  status: 'playing' | 'paused' | 'stopped';
  isPlaying: boolean;
  isPaused: boolean;
  isMuted: boolean;
  play: (time?: number) => unknown;
  pause: () => unknown;
  skip: (amount: number) => unknown;
  changeVolume: (value: number) => unknown;
  changePlaybackRate: (rate: number) => unknown;
  seek: (value: number) => unknown;
  mute: () => unknown;
  unmute: () => unknown;
  containerRects: {
    height: number;
  };
  volume: number;
  duration: number;
  maskedDuration: number;
  currentTime: number;
  playbackRate: number;
  isScrollingEnabled: boolean;
  changeScrollEnabled: (value: boolean) => unknown;
  setVideoPlayerRef: (ref: HTMLVideoElement | HTMLAudioElement | null) => unknown;
  setContainerRef: (ref: HTMLElement | null) => unknown;
  cropping: Crop | null;
  setCropping: (args: Crop) => unknown;
  changeCropping: (args: Crop) => unknown;
  fullscreen: boolean;
  toggleFullscreen: (value: boolean) => unknown;
  download: () => unknown;
  isEditable: boolean;
  setIsEditable: (value: boolean) => unknown;
  markers: [number, number];
  setCropCallback: (arg: CropCallback) => unknown;
  togglePlay: () => unknown;
  ready: boolean;
}

export const videoPlayerContext = React.createContext<IVideoPlayerContext>({
  isPaused: false,
  isPlaying: false,
  ready: false,
  status: 'stopped',
  volume: 0,
  duration: 0,
  currentTime: 0,
  isMuted: false,
  play: () => null,
  pause: () => null,
  skip: () => null,
  seek: () => null,
  playbackRate: 0,
  changePlaybackRate: () => null,
  changeVolume: () => null,
  mute: () => null,
  unmute: () => null,
  setVideoPlayerRef: () => null,
  setContainerRef: () => null,
  isScrollingEnabled: false,
  changeScrollEnabled: () => null,
  containerRects: {
    height: 0,
  },
  setCropping: () => null,
  cropping: null,
  maskedDuration: 0,
  fullscreen: false,
  toggleFullscreen: () => null,
  download: () => null,
  isEditable: false,
  setIsEditable: () => null,
  markers: [0, 0],
  setCropCallback: () => null,
  changeCropping: () => null,
  togglePlay: () => null,
});
