import ToggleCard from '../ToggleCard';
// import { InputNumber } from 'antd';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import { useEffect, useState } from 'react';
// import { coreService } from '../../../../../services/core/core-service';
// import { SaveButton } from '../../Account/style';
import { useToast } from '../../../../../hooks/useToast';
import {
  SubscribeToProPlanLink,
  UpgradeToProBadge,
} from '../../../../../componentsV2/UpgradeToPro';
// import Style from './style';
import useFeatures from '../../../../../hooks/useFeatures';
import { useUserSettings } from '../../../../../features/user/hooks/useUserSettings';

/*
const INITIAL_STATE = {
  scheduleHour: 19,
  send: false,
};

interface State {
  scheduleHour: number;
  send: boolean;
}

*/
export const CheatSheet = () => {
  // const { Description } = Style;

  const features = useFeatures();
  const availableFeature = features.clientMeetingCheatsheet;

  /*
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [currentCheatSheet, setCurrentCheatSheet] = useState<State>(INITIAL_STATE);
  const [cheatSheet, setCheatSheet] = useState<State>(INITIAL_STATE);

  */
  const { settings, updateSettings } = useUserSettings();

  const { success, error: errorToast } = useToast();

  /*
  const updateCheatSheet = async (cheatSheet: State) => {
    setLoading(true);
    setDisable(true);
    try {
      await coreService.updateCheatSheet(cheatSheet);
      setCurrentCheatSheet(cheatSheet);
      setCheatSheet(cheatSheet);
      success('CheatSheet configuration updated successfully');
    } catch {
      error('Failed to update CheatSheet configuration');
      setCheatSheet(currentCheatSheet);
    } finally {
      setLoading(false);
      setDisable(false);
    }
  };
  */

  /*
  useEffect(() => {
    coreService.fetchCheatSheet().then(data => {
      setCheatSheet({
        scheduleHour: data.scheduleHour,
        send: data.send,
      });
      setCurrentCheatSheet({
        scheduleHour: data.scheduleHour,
        send: data.send,
      });
    });
  }, []);

  useEffect(() => {
    if (!availableFeature && cheatSheet.send) {
      updateCheatSheet(INITIAL_STATE);
    } else {
      setDisable(false);
    }
  }, [cheatSheet.send]);
  */

  const handleToggleChanged = (value: boolean) => {
    updateSettings({ sendDailyCheatSheet: value })
      .then(() => success('Cheatsheet setting updated'))
      .catch(() => errorToast('There was an error updating Cheatsheet setting'));
  };

  return (
    <ToggleCard
      description={
        <div style={{ display: 'flex', gap: '10px' }}>
          Receive daily 'Client Meeting Cheatsheet' email{' '}
          {!availableFeature && <UpgradeToProBadge />}
        </div>
      }
      extraDescription="Every evening, we send you notes to help you prepare for tomorrow's external calls. It will be sent at 7PM your local time."
      checked={settings.sendDailyCheatSheet}
      onChange={e => handleToggleChanged(e.valueOf())}
      recommendedSetting={'ON'}
      learnMore="https://product.update.ai/announcements/cheatsheets-the-future-of-meeting-prep"
      disabled={!availableFeature}
      tooltipTitle={!availableFeature ? <SubscribeToProPlanLink /> : <></>}
    >
      {/*
      <Description>Receive this notification the day before my calls at:</Description>

      <div style={{ display: 'flex', gap: '10px' }}>
        <InputNumber
          min={1}
          max={23}
          style={{ maxWidth: '150px' }}
          value={cheatSheet.scheduleHour}
          addonBefore={<ClockCircleOutlined />}
          disabled={disable || loading || !cheatSheet.send || !availableFeature}
          onChange={e => setCheatSheet(cs => ({ ...cs, scheduleHour: e || 19 }))}
          formatter={value =>
            `${value! % 12 === 0 ? 12 : value! % 12}:00 ${value! >= 12 ? 'PM' : 'AM'}`
          }
        />

        <SaveButton
          type="default"
          style={{ width: 'auto' }}
          onClick={() => updateCheatSheet(cheatSheet)}
          loading={loading}
          disabled={
            loading ||
            disable ||
            !cheatSheet.send ||
            (currentCheatSheet.scheduleHour === cheatSheet.scheduleHour &&
              currentCheatSheet.send === cheatSheet.send) ||
            !availableFeature
          }
        >
          Save
        </SaveButton>
      </div>

      <Description style={{ marginTop: '20px' }}>
        You may set your local time zone <a href="/settings?tab=account">here</a>
      </Description>
        */}
    </ToggleCard>
  );
};
