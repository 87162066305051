import { create } from 'zustand';
import { produce } from 'immer';
import { Member } from '../features/organizations/types';
interface OrganizationMembersStore {
  members: Member[];
}

const organizationMembersStore = create<OrganizationMembersStore>(() => {
  return {
    members: [],
  };
});

const memberRoleChanged = (userId: string, role: string) => {
  organizationMembersStore.setState(state => {
    return produce(state, draft => {
      const member = draft.members.find(member => member.user.id === userId);
      if (member) {
        member.role = role;
      }
    });
  });
};

const memberTypeChanged = (userId: string, type: string) => {
  organizationMembersStore.setState(state => {
    return produce(state, draft => {
      const member = draft.members.find(member => member.user.id === userId);
      if (member) {
        member.type = type;
      }
    });
  });
};

const memberRemoved = (userId: string) => {
  organizationMembersStore.setState(state => {
    const members = [...state.members].filter(member => member.user.id !== userId);
    return { members };
  });
};

const meetingsVisibilityLockModified = (userId: string, locked: boolean) => {
  organizationMembersStore.setState(state => {
    const newMembers = [...state.members].map(member => {
      if (member.user.id === userId) {
        const newMember = Object.assign({}, member);
        newMember.meetingVisibilityLocked = locked;
        return newMember;
      }
      return member;
    });
    return { members: newMembers };
  });
};

const meetingsVisibilityModified = (
  userId: string,
  args: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  },
) => {
  organizationMembersStore.setState(state => {
    const newMembers = [...state.members].map(member => {
      if (member.user.id === userId) {
        const newMember = Object.assign({}, member);
        newMember.meetingsVisibility = {
          externalMeetingsVisibility: args.externalMeetingsVisibility,
          internalMeetingsVisibility: args.internalMeetingsVisibility,
        };
        return newMember;
      }
      return member;
    });
    return { members: newMembers };
  });
};

const membersFetched = (members: Member[]) => {
  organizationMembersStore.setState(() => ({ members }));
};

export {
  organizationMembersStore,
  memberRemoved,
  memberRoleChanged,
  memberTypeChanged,
  meetingsVisibilityLockModified,
  meetingsVisibilityModified,
  membersFetched,
};
