import { PropsWithChildren, useEffect, useMemo } from 'react';
import { customerIntelligenceFiltersStore } from '../../stores/customer-intelligence-filters';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { reducers } from '../../stores/customer-intelligence-data';
import { reducers as filtersReducers } from '../../stores/customer-intelligence-filters';

import { extractGroupsFromAggregatedData, storeToGetInsightAggregatedDataArgs } from './utils';

export const CustomerIntelligenceProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const filtersStore = customerIntelligenceFiltersStore();

  const {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    lifecycleStages,
    members,
    priorities,
  } = filtersStore;

  const aggregatedDataArgs = useMemo(() => {
    return storeToGetInsightAggregatedDataArgs();
  }, [
    accounts,
    dateRange,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    industries,
    meetingsImInvitedOnly,
    lifecycleStages,
    members,
    priorities,
  ]);

  const { data: aggregateData, isFetching: isFetchingAggregatedData } = useQuery({
    queryKey: ['insights-aggregate', aggregatedDataArgs],
    queryFn: async () => {
      if (!aggregatedDataArgs) {
        return null;
      }

      const result = await coreService.getInsightsAggregatedData(aggregatedDataArgs);
      return result;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    reducers.fetchingStatusChanged(isFetchingAggregatedData);
  }, [isFetchingAggregatedData]);

  useEffect(() => {
    if (aggregateData) {
      const groups = extractGroupsFromAggregatedData(aggregateData);

      reducers.dataFetched({
        groups,
        meetingsCount: aggregateData.eventsCount,
      });

      filtersReducers.filtersLoaded(
        aggregateData.filters.industry,
        aggregateData.filters.lifecycleStage,
        aggregateData.filters.prioriy,
        aggregateData.filters.members,
      );
    }
  }, [aggregateData]);

  return <>{children}</>;
};
