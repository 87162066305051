import { Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Styles from './style';
import { NewFolder } from '../../../../features/shared/components/Icons/NewFolder';
import { useLibraries } from '../../../../features/libraries/hooks/useLibraries';
import { useToast } from '../../../../hooks/useToast';
import { extractError } from '../../../../utils/api';

const {
  CancelButton,
  ConfirmDeleteButton,
  Container,
  Content,
  Footer,
  Heading,
  Header,
  MainContent,
  Column,
  InputLabel,
} = Styles;

interface Props {
  open?: boolean;
  onFolderCreated: (folderId: string) => void;
  onCancel: () => void;
  loading?: boolean;
  location?: string;
}

export const NewFolderModal: React.FC<Props> = ({ open, onFolderCreated, onCancel, location }) => {
  const { userLibrary, organizationLibrary, createFolder } = useLibraries();
  const [locationOption, setLocationOption] = useState<string>(location || 'mine');
  const [folderName, setFolderName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const { error: errorToast } = useToast();

  useEffect(() => {
    if (location) setLocationOption(location);
  }, [location]);

  const handleConfirm = async () => {
    try {
      setIsCreating(true);
      const libraryId = locationOption === 'mine' ? userLibrary?.id : organizationLibrary?.id;
      const folderId = await createFolder(folderName, libraryId || '');
      setFolderName('');
      onFolderCreated(folderId);
    } catch (error) {
      const message = extractError(error);
      errorToast(message);
    } finally {
      setIsCreating(false);
    }
  };

  const handleClose = () => {
    setLocationOption('mine');
    setFolderName('');
    onCancel();
  };

  return (
    <Modal title={false} open={open} closable={false} destroyOnClose footer={false} centered>
      <Container>
        <Content>
          <Header>
            <NewFolder />
            <Heading>New folder</Heading>
          </Header>
        </Content>
        <MainContent>
          <Column>
            <InputLabel>Folder name</InputLabel>
            <Input value={folderName} onChange={ev => setFolderName(ev.target.value)} />
          </Column>
          <Column>
            <InputLabel>Location</InputLabel>
            <Select
              value={locationOption}
              onChange={value => setLocationOption(value)}
              options={[
                { label: 'My Library', value: 'mine' },
                { label: 'My Workspace', value: 'my-organization' },
              ]}
            />
          </Column>
        </MainContent>
        <Footer>
          <CancelButton onClick={handleClose} disabled={isCreating}>
            Cancel
          </CancelButton>
          <ConfirmDeleteButton
            disabled={isCreating}
            onClick={handleConfirm}
            loading={isCreating}
            style={{ width: 'fit-content' }}
          >
            Create
          </ConfirmDeleteButton>
        </Footer>
      </Container>
    </Modal>
  );
};
