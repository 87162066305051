import React from 'react';
import { ArticleType } from '../../data';
import Styles from './styles';
import { Divider } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

const {
  ArticleContainer,
  ArticleListContainer,
  ArticleTitle,
  Container,
  ContentRow,
  ContentRowHeader,
  SectionTitle,
  SubSectionTitle,
  Contents,
  ContentColumn,
  SectionTitleContainer,
} = Styles;

interface Props {
  section: ArticleType;
}

export const ArticleSection: React.FC<Props> = ({ section }) => {
  const { beyond, gettingStarted } = section;

  return (
    <Contents>
      <Container>
        <ContentRow>
          <ContentColumn>
            <ContentRowHeader>
              <SectionTitleContainer>
                <SectionTitle>Getting started</SectionTitle>
              </SectionTitleContainer>
              <SubSectionTitle>How do I...</SubSectionTitle>
            </ContentRowHeader>
            <ArticleListContainer>
              {gettingStarted.howDoI.map((article, i) => {
                return (
                  <ArticleContainer key={`article-container-left-${i}`}>
                    <LinkOutlined style={{ color: '#00489F' }} />
                    <ArticleTitle href={article.link} target="_blank">
                      {article.title}
                    </ArticleTitle>
                  </ArticleContainer>
                );
              })}
            </ArticleListContainer>
          </ContentColumn>

          <ContentColumn>
            <ContentRowHeader>
              <SectionTitleContainer></SectionTitleContainer>
              <SubSectionTitle>How can UpdateAI...</SubSectionTitle>
            </ContentRowHeader>
            <ArticleListContainer>
              {gettingStarted.howCanUpdateAI.map((article, i) => {
                return (
                  <ArticleContainer key={`article-container-right-${i}`}>
                    <LinkOutlined style={{ color: '#00489F' }} />
                    <ArticleTitle href={article.link} target="_blank">
                      {article.title}
                    </ArticleTitle>
                  </ArticleContainer>
                );
              })}
            </ArticleListContainer>
          </ContentColumn>
        </ContentRow>
        <Divider style={{ margin: '0px' }} />
        <ContentRow>
          <ContentColumn>
            <ContentRowHeader>
              <SectionTitleContainer>
                <SectionTitle>Beyond</SectionTitle>
              </SectionTitleContainer>

              <SubSectionTitle>How do I...</SubSectionTitle>
            </ContentRowHeader>
            <ArticleListContainer>
              {beyond.howDoI.map(article => {
                return (
                  <ArticleContainer>
                    <LinkOutlined style={{ color: '#00489F' }} />
                    <ArticleTitle href={article.link} target="_blank">
                      {article.title}
                    </ArticleTitle>
                  </ArticleContainer>
                );
              })}
            </ArticleListContainer>
          </ContentColumn>

          <ContentColumn>
            <ContentRowHeader>
              <SectionTitleContainer></SectionTitleContainer>
              <SubSectionTitle>How can UpdateAI...</SubSectionTitle>
            </ContentRowHeader>
            <ArticleListContainer>
              {beyond.howCanUpdateAI.map(article => {
                return (
                  <ArticleContainer>
                    <LinkOutlined style={{ color: '#00489F' }} />
                    <ArticleTitle href={article.link} target="_blank">
                      {article.title}
                    </ArticleTitle>
                  </ArticleContainer>
                );
              })}
            </ArticleListContainer>
          </ContentColumn>
        </ContentRow>
      </Container>
    </Contents>
  );
};
