import { Input } from 'antd';
import styled from 'styled-components';

const OrganizationNameInput = styled(Input)`
  width: 450px;
  align-self: start;
`;

const Inputs = styled.div`
  display: flex;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 450px;
`;

const InputLabel = styled.span`
  color: #445260;
  font-weight: 400;
`;

export default { OrganizationNameInput, InputContainer, InputLabel, Inputs };
