import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  width: 100%;
  margin-top: 7px;
`;

export const ReadySwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const FilterSpacer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Shadow = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const ConnectToCrmContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 36px;
  align-items: center;
  gap: 10px;
`;

export const ConnectToCrm = styled.div`
  font-family: 'Cera Pro', sans-serif, Arial;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  box-sizing: border-box;
  align-items: center;
  padding: 8px 12px;
  width: 154px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dbdbe0;
  border-radius: 6px;
  cursor: pointer;
`;

export const ConnectToCrmText = styled.p`
  margin: 0px;
  height: 20px;

  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #00489f;
`;
