import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Store } from '../redux/typings/store';

export const LoggedInUser: React.FC = () => {
  const user = useSelector((store: Store) => store.session.user);
  if (!user) {
    return <Navigate to={'/'} />;
  } else {
    return <Outlet />;
  }
};
