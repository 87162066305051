import React from 'react';
import { UpdateLoader } from '../UpdateLoader';
import Styles from './style';

const { Container } = Styles;

export const FullLoadingView: React.FC = () => {
  return (
    <Container>
      <UpdateLoader></UpdateLoader>
    </Container>
  );
};
