import React, { useState } from 'react';
import Styles from './styles';
import { Dropdown } from 'antd';
import { SpeedList } from './SpeedList';
import { useVideoPlayer } from '../../../../hooks';

const { Container } = Styles;

export const Speed: React.FC = () => {
  const { playbackRate, changePlaybackRate } = useVideoPlayer();
  const [open, setIsOpen] = useState(false);

  const onSpeedChanged = (speed: number) => {
    changePlaybackRate(speed);
    setIsOpen(false);
  };

  return (
    <Dropdown
      open={open}
      trigger={['click']}
      onOpenChange={setIsOpen}
      dropdownRender={() => (
        <SpeedList speed={playbackRate} onSpeedChange={speed => onSpeedChanged(speed)} />
      )}
    >
      <Container open={open}>
        <span>{playbackRate}x</span>
      </Container>
    </Dropdown>
  );
};
