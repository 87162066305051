import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Container, StepDescription, StepTitle, TitleAndDescription } from './style';

interface Props {
  testHasRan?: boolean;
  success?: boolean;
  description?: string;
  title?: string;
  onIconClickHref?: string;
}

function determineTestResult(success: boolean, testHasRan: boolean) {
  if (!testHasRan) return <QuestionCircleTwoTone twoToneColor="#0a58c3" />;
  if (success) {
    return <CheckCircleTwoTone twoToneColor="#16B380" />;
  } else {
    return <CloseCircleTwoTone twoToneColor="red" />;
  }
}

const Step: React.FC<Props> = ({
  success,
  description,
  testHasRan,
  title,
  onIconClickHref = '',
}) => {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Container>
      <TitleAndDescription>
        <StepTitle style={{ color: !!testHasRan ? (success ? '#16B380' : 'red') : '#000' }}>
          <Tooltip title={!testHasRan ? 'Run the test to check status of these items' : undefined}>
            {determineTestResult(!!success, !!testHasRan)}
          </Tooltip>
          {title} <InfoCircleOutlined onClick={() => openInNewTab(onIconClickHref)} />
        </StepTitle>
        <StepDescription>{description}</StepDescription>
      </TitleAndDescription>
    </Container>
  );
};

export default Step;
