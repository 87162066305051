import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const DraggerSVG = () => {
  return (
    <svg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L2 20" stroke="#F3F4F5" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
};
export const Dragger: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={DraggerSVG} {...props} />;
};
