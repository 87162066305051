import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const EarthSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.53667 5.24667L4.53 2.24C5.58334 1.54 6.84334 1.13333 8.20334 1.13333C11.8833 1.13333 14.87 4.12 14.87 7.8C14.87 9.16 14.4633 10.42 13.7633 11.4733L12.79 10.5C13.2633 9.71333 13.5367 8.78666 13.5367 7.8C13.5367 5.56666 12.1567 3.65333 10.2033 2.86V3.13333C10.2033 3.86666 9.60334 4.46666 8.87 4.46666H7.53667V5.24667ZM14.33 13.9267L13.39 14.8667L11.8767 13.3533C10.8233 14.06 9.56334 14.4667 8.20334 14.4667C4.52334 14.4667 1.53667 11.48 1.53667 7.8C1.53667 6.44 1.94334 5.18 2.64334 4.12666L1.13 2.61333L2.07 1.67333L14.33 13.9267ZM7.53667 11.8C6.80334 11.8 6.20334 11.2 6.20334 10.4667V9.8L3.01 6.60666C2.92334 6.99333 2.87 7.38666 2.87 7.8C2.87 10.52 4.90334 12.76 7.53667 13.0867V11.8Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};
export const EarthCrossed: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={EarthSVG} {...props} />;
};
