import React from 'react';
import { format } from 'date-fns';
import Styles from './styles';

const { Container } = Styles;

interface Props {
  start: string;
  end: string;
}

export const TimeBox: React.FC<Props> = ({ start, end }) => {
  return (
    <Container>
      <h1>{format(new Date(start), 'hh:mm aa')}</h1>
      <h2>{format(new Date(end), 'hh:mm aa')}</h2>
    </Container>
  );
};
