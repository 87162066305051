import React, { ReactElement } from 'react';

interface AlertContext {
  alerts: ReactElement[];
  index: number;
  addAlert: null | ((node: ReactElement) => unknown);
}

export const alertContext = React.createContext<AlertContext>({
  alerts: [],
  addAlert: null,
  index: 0,
});
