import { create } from 'zustand';
import { BotAnnouncement, ParticipantTypeRecordingSetting, Settings } from '../features/user/types';
import { produce } from 'immer';

interface UserSettingsStore {
  settings: Settings;
  isFetching: boolean;
  isSaving: boolean;
}

export const userSettingsStore = create<UserSettingsStore>(() => {
  return {
    isFetching: false,
    isSaving: false,
    settings: {
      cloudRecordingEmail: false,
      deleteAfterFetching: false,
      receiveRecapsOnEmail: false,
      receiveRecapsOnSlack: false,
      sendRecapUpdateUsers: false,
      externalMeetingsRecording: {
        record: false,
        sendBotIfNoHost: false,
      },
      internalMeetingsRecording: {
        record: false,
        sendBotIfNoHost: false,
      },
      recordInstantMeetings: false,
      syncRecapAutomaticallyCrm: false,
      sendRecapToInvitees: false,
      sendDailyCheatSheet: false,
      sendLimitedDialogueEmail: false,
      sendBotGreetingEmail: true,
      botAnnouncement: {
        text: false,
        voice: false,
      },
    },
  };
});

const fetchSettingsStarted = () => {
  userSettingsStore.setState(() => ({ isFetching: true }));
};

const fetchSettingsEnded = () => {
  userSettingsStore.setState(() => ({ isFetching: false }));
};

const saveSettingsStarted = () => {
  userSettingsStore.setState(() => ({ isSaving: true }));
};

const saveSettingsEnded = () => {
  userSettingsStore.setState(() => ({ isSaving: true }));
};

const internalRecordingSettingsUpdated = (setting: ParticipantTypeRecordingSetting) => {
  userSettingsStore.setState(state =>
    produce(state, draft => {
      draft.settings.internalMeetingsRecording = setting;
    }),
  );
};

const externalRecordingSettingsUpdated = (setting: ParticipantTypeRecordingSetting) => {
  userSettingsStore.setState(state =>
    produce(state, draft => {
      draft.settings.externalMeetingsRecording = setting;
    }),
  );
};

const botAnnouncementUpdated = (setting: BotAnnouncement) => {
  userSettingsStore.setState(state =>
    produce(state, draft => {
      draft.settings.botAnnouncement = setting;
    }),
  );
};

const updateSettings = (
  settings: Partial<
    Omit<Settings, 'externalMeetingsRecordingSetting' | 'internalMeetingsRecordingSetting'>
  >,
) => {
  userSettingsStore.setState(state =>
    produce(state, draft => {
      draft.settings = { ...state.settings, ...settings };
    }),
  );
};

const settingsLoaded = (settings: Settings) => {
  userSettingsStore.setState(() => ({ settings }));
};

export {
  fetchSettingsStarted,
  fetchSettingsEnded,
  saveSettingsStarted,
  saveSettingsEnded,
  internalRecordingSettingsUpdated,
  externalRecordingSettingsUpdated,
  updateSettings,
  settingsLoaded,
  botAnnouncementUpdated,
};
