export const categoriesMetadata: {
  [k: string]: { [k: string]: { description: string; customContext: string } };
} = {
  Risks: {
    'Client Business Changes': {
      description:
        "This category identifies mentions of significant shifts or transformations in a client's business. It covers aspects like changes in management, strategic direction, market focus, or operational adjustments. Understanding these changes can be crucial for anticipating potential churn risks and adapting your approach to meet evolving client needs.",
      customContext:
        "Please share insights about your clients' main industry focus, including any significant projects they're undertaking. Details about their regulatory landscape or specific instances of business changes that might lead to instability or increased churn risk are also highly valuable.",
    },
    'Pricing and Value Concerns': {
      description:
        'This category identifies discussions where clients express concerns or raise questions about the pricing structure or the perceived value of your product or service. It includes mentions of cost-related issues, comparisons with competitors’ pricing, or doubts about the return on investment. Keeping track of such conversations is crucial for addressing potential dissatisfaction and preventing churn related to pricing or value perception',
      customContext:
        'Enhance detection of pricing/value concerns by including context on specific products, competitor pricing, discounts, client segments, market trends, and historical pricing changes, to tailor and improve client-specific insights.',
    },
    'Product Dissatisfaction': {
      description:
        'This category detects expressions of discontent or issues related to the product, including functionality, performance, or reliability. It helps in identifying areas needing improvement and mitigating churn risks by addressing client concerns promptly.',
      customContext:
        'Tell us about specific features of your product or service, client usage patterns and areas of your product that are more susceptible to troubleshooting.',
    },
    'Service and Support Issues': {
      description:
        'Identifies client mentions of challenges or dissatisfaction with customer service and support, highlighting areas for improvement in service delivery and client care to mitigate churn risks.',
      customContext:
        'Enhance service issue detection with context on SLAs, support history, client-interaction protocols, survey and feedback channels, and response/resolution rates.',
    },
    'Unmet Expectations': {
      description:
        'Highlights instances where client expectations differ from their experience with your product or service, aiding in quickly addressing discrepancies to reduce churn risk.',
      customContext:
        'Refine unmet expectations detection with context on common sales promises, onboarding experiences, product roadmap, client goals and OKRs, previous feedback, and industry benchmarks.',
    },
  },
  'Product Feedback': {
    'Performance Issues': {
      description:
        'Identifies mentions of any difficulties or shortcomings in product functionality, speed, or reliability, as reported by customers. Helps you quickly pinpoint areas for improvement.',
      customContext:
        'Enhance detection by adding context like industry jargon, specific product details, historical issues, customer segments, competitor benchmarks, and feedback trends to pinpoint precise performance issues.',
    },
    'Data Management': {
      description:
        'Identifies feedback related to the handling, storage, and accessibility of data within your product. Pinpoints user concerns and suggestions about data efficiency and security.',
      customContext:
        'Include specifics like types of data managed (e.g., customer data, transaction records), common data-related challenges in your industry, historical data breaches or losses, and how data management may compare with competitors.',
    },
    'Feature Requests': {
      description:
        'Highlights customer suggestions for new features or improvements to existing ones. Useful for innovation and prioritizing development efforts.',
      customContext:
        'Boost detection by sharing your key product functionalities and use cases, common past requests, competitor strengths, and market trends to identify key feature requests.',
    },
    Integrations: {
      description:
        'Detects feedback on the compatibility and efficiency of third-party integrations in your product.',
      customContext:
        'Improve detection by sharing names of specific integration partners, past challenges, and customer use cases and data types that may require integrations.',
    },
    Reporting: {
      description:
        'Focuses on feedback related to reporting capabilities, such as data visualization, report generation, and analytics accuracy.',
      customContext:
        'Share common types of reports used, specific user requirements for reporting, historical issues with reporting accuracy, and how your reporting tools stack up against competitors.',
    },
    'Training and Support': {
      description:
        'Captures feedback related to customer education and support services, including training materials, helpdesk efficiency, and support response times.',
      customContext:
        'List specific training content, past support challenges, your customer service model and your communication framework with customers.',
    },
    'UI / UX': {
      description:
        'Identifies user feedback on interface design and usability, focusing on ease of use, aesthetic appeal, and navigation efficiency.',
      customContext:
        'Boost detection with details on design specifics, usability issues, user preferences, and competitor UI/UX benchmarks.',
    },
  },
  Advocacy: {
    Testimonials: {
      description: '',
      customContext: '',
    },
    'Customer Championship': {
      description: '',
      customContext: '',
    },
    'Value Creation': {
      description: '',
      customContext: '',
    },
    'Acquisition Channel': {
      description: '',
      customContext: '',
    },
  },
};
