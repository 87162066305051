import styled from 'styled-components';

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

const ContentRowHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const ArticleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ArticleContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

const SectionTitleContainer = styled.div`
  height: 16px;
  padding: 4px 4px 4px 0px;
`;

const SectionTitle = styled.span`
  border-radius: 2px;
  color: var(--Secondary-sBlue500, #0675fc);
  background: var(--secondary-bgc-2-deff, #c2deff);
  padding: 4px;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 16px;
`;

const SubSectionTitle = styled.span`
  color: #212121;
  font-family: 'Cera Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 1;
`;

const ArticleTitle = styled.a`
  color: var(--secondary-default-0675-fc, #0675fc);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
`;

export default {
  Contents,
  Container,
  ContentRow,
  ContentRowHeader,
  ArticleListContainer,
  ArticleContainer,
  SectionTitle,
  SubSectionTitle,
  ArticleTitle,
  ContentColumn,
  SectionTitleContainer,
};
