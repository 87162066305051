import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Store } from '../redux/typings/store';
import { useRedirectNavigation } from '../features/redirectNavigation/useRedirectNavigation';

interface Props {
  redirectPath: string;
}

export const ProtectedRoute: React.FC<Props> = ({ redirectPath }) => {
  const user = useSelector((store: Store) => store.session.user);
  const { hasRedirect, redirect } = useRedirectNavigation();

  if (user && user.id) {
    if (user.requestPasswordReset) return <Navigate to={'/password/reset'} />;
    if (hasRedirect()) return <Navigate to={redirect()} />;

    return <Outlet />;
  } else {
    return <Navigate to={redirectPath} />;
  }
};
