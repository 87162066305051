import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, createContext, useEffect } from 'react';
import { coreService } from '../../../services/core/core-service';
import { AccountFavouritesContext } from './account-favourites-types';
import { createAccountFavouritesStore } from './account-favourites.store';
import { useStore } from 'zustand';

export const accountFavouritesContext = createContext<AccountFavouritesContext>(
  createAccountFavouritesStore(),
);

export const AccountFavouritesProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = createAccountFavouritesStore();
  const dataLoaded = useStore(store, store => store.dataLoaded);

  const { data, isFetching } = useQuery({
    queryKey: ['favourites-initial-fetch'],
    queryFn: async () => {
      const favs = await coreService.fetchFavourites();
      return favs;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (data) dataLoaded({ ...data, loading: isFetching });
  }, [data, isFetching]);

  useEffect(() => {
    if (data) dataLoaded({ ...data });
  }, []);

  return (
    <accountFavouritesContext.Provider value={store}>{children}</accountFavouritesContext.Provider>
  );
};
