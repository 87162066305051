import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Error, FrameContainer } from './style';

interface Props {
  error: string | null;
  width?: string;
  height?: string;
}

export const Frame: React.FC<PropsWithChildren<Props>> = ({ error, children, width, height }) => {
  const DISPLAY_TIME = 8000;
  const [displayError, setDisplayError] = useState<string | null>(null);

  useMemo(() => {
    setTimeout(() => {
      setDisplayError(null);
    }, DISPLAY_TIME);

    if (error) {
      setDisplayError(error);
    }
  }, [error]);

  return (
    <FrameContainer style={{ width: width ? width : '900px', height: height ? height : '650px' }}>
      {children}
      {displayError ? <Error animationTime={DISPLAY_TIME + 100}>{displayError}</Error> : <></>}
    </FrameContainer>
  );
};
