import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider } from 'antd';
import { OptionalTooltipWrapper } from '../../../../../../../componentsV2/OptionalTooltipWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { coreService } from '../../../../../../../services/core/core-service';
import { useAccountOverview } from '../../../../../../../features/account/account-overview/account-overview.hook';
import { useToast } from '../../../../../../../hooks/useToast';

interface Props {
  id: string;
  meetings: string[];
  summarizeDisabled: boolean;
  onSuccess: () => void;
}

export const SummarizeButton: React.FC<Props> = ({
  id,
  meetings,
  summarizeDisabled = false,
  onSuccess,
}) => {
  // const [summarizations, setSummarizations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [_id, setId] = useState<string>(id);
  const [_meetings, setMeetings] = useState<string[]>(meetings);

  const { summarizationRequested } = useAccountOverview();
  const { success } = useToast();

  const handleSummarize = async () => {
    setLoading(true);
    await coreService
      .summarizeMeetings(_id, _meetings)
      .then(() => {
        onSuccess();
        summarizationRequested();
        success('Summarization requested successfully');
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setId(id);
    setMeetings(meetings);
  }, [id, meetings]);

  return (
    <OptionalTooltipWrapper
      display={false}
      value="A summary of the selected meetings with this account is being generated."
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7230FF',
          },
        }}
      >
        <Button
          disabled={summarizeDisabled || loading}
          onClick={handleSummarize}
          type="primary"
          loading={loading}
          style={{
            gap: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          icon={
            !false ? (
              <FontAwesomeIcon
                icon={faMagicWandSparkles}
                color={summarizeDisabled || false ? '#E0E6EE' : 'white'}
              />
            ) : (
              <></>
            )
          }
        >
          <span>Summarize Meetings</span>
        </Button>
      </ConfigProvider>
    </OptionalTooltipWrapper>
  );
};
