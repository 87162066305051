import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';

const Container = styled.div`
  display: flex;
  height: 55px;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--N-900, #0d283b);
`;

const Contents = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Switch = styled(AntSwitch)`
  background-color: red;
  &[aria-checked='false'] {
    background-color: #d2d2d2;
    &:hover {
      background-color: #d2d2d2;
    }
  }
`;

const Text = styled.span`
  color: var(--Global-white, #fafafa);
  text-align: center;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const Link = styled.a`
  color: var(--Global-white, #fafafa);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  text-decoration-line: underline;
`;

export default {
  Container,
  Contents,
  Switch,
  Text,
  Link,
};
