import React from 'react';
import { Folder } from '../Folder';
import { FolderBack } from '../../../../features/shared/components/Icons/FolderBack';

interface Props {
  onClick: () => unknown;
}

export const BackFolder: React.FC<Props> = ({ onClick }) => {
  return (
    <Folder
      onClick={onClick}
      icon={<FolderBack />}
      name="Go back"
      showMenu={false}
      onRemove={() => null}
      onRename={() => null}
    />
  );
};
