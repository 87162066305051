import { Button, Input, InputNumber, Tag } from 'antd';
import { ILink } from '../../../../../services/types';
import { useEffect, useState } from 'react';
import { authToLogo } from '../shareBoxUtils';
import { IAuthorizationMechanism } from '../types';
import { useCopyLink } from '../../../../../hooks/useRecapCopy';
import { formatLinkTime } from '../../../../../utils/dateUtils';
import { CopyOutlined } from '@ant-design/icons';
import { addDays } from 'date-fns';
import { coreService } from '../../../../../services/core/core-service';
import { useToast } from '../../../../../hooks/useToast';

import Styles from '../styles';
const { Title, Footer, Content } = Styles;

import EditShareLinkStyles from './styles';
import { ManageLinksComponent } from '../components/ManageLinksComponent';
const { Label, URL, Section, Tags } = EditShareLinkStyles;

interface Props {
  link: (ILink & { edit: boolean }) | null;
  toManageLinks: () => void;
  updateLink: (link: ILink & { edit: boolean }) => void;
}

export const EditShareLink: React.FC<Props> = ({ link, toManageLinks, updateLink }) => {
  const [editable, setEditable] = useState(link?.edit);
  const [editedFields, setEditedFields] = useState({ expiration: false, description: false });
  const [isLoading, setIsLoading] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(link?.description);
  const [updatedExpiration, setupdatedExpiration] = useState(
    link ? new Date(link?.expirationDate) : new Date(),
  );
  const [days, setDays] = useState(1);

  const copy = useCopyLink();
  const { error, success } = useToast();

  const description = editable
    ? updatedDescription
    : link && link.description !== ''
    ? link.description
    : '-';

  const expiration = editable
    ? formatLinkTime(updatedExpiration)
    : link && formatLinkTime(new Date(link.expirationDate));

  const handleUpdateDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setUpdatedDescription(e?.target?.value || '');
    setEditedFields(f => {
      return { ...f, description: true };
    });
  };

  const handleUpdateExpiration = (e: number | null) => {
    setDays(e || 1);
    setEditedFields(f => {
      return { ...f, expiration: true };
    });
  };

  const stopEditable = () => {
    setEditedFields({ expiration: false, description: false });
    setEditable(false);
  };

  const startEditable = () => {
    setDays(1);
    setupdatedExpiration(addDays(new Date(), 1));
    setUpdatedDescription(link?.description);
    setEditable(true);
  };

  const handleUpdate = async () => {
    if (link) {
      try {
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedArgs: any = {};
        if (editedFields.description) updatedArgs['description'] = updatedDescription;
        if (editedFields.expiration) updatedArgs['expirationDate'] = days;

        await coreService.updateShareLink(link?.id, updatedArgs);

        if (editedFields.expiration) updatedArgs['expirationDate'] = updatedExpiration;
        updateLink({
          ...link,
          ...updatedArgs,
        });

        success('Link updated successfully.');
        stopEditable();
      } catch {
        error('Failed to update link. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setupdatedExpiration(addDays(new Date(), days));
  }, [days]);

  return (
    <>
      <Title>Share Links Details</Title>

      <Content>
        <Section
          onClick={() => {
            if (link) copy(link.url);
          }}
        >
          <Label>Shared URL</Label>
          <URL>
            <Tags>
              {link?.url} <CopyOutlined />
            </Tags>
          </URL>
        </Section>

        <Section>
          <Label>Type</Label>
          {link && authToLogo(link.type as IAuthorizationMechanism)}
        </Section>

        {link?.type === IAuthorizationMechanism.authorized_only ? (
          <Section>
            <Label>Invited Users</Label>
            <Tags>
              {link?.emails?.map((e: string) => (
                <Tag key={e}>{e}</Tag>
              ))}
            </Tags>
          </Section>
        ) : (
          <></>
        )}

        <Section>
          <Label>Expiration Date</Label>
          {expiration}
        </Section>

        {!editable && (
          <Section>
            <Label>Description</Label>
            {description}
          </Section>
        )}

        {editable && (
          <>
            <Section>
              Expiration (Days from now)*
              <InputNumber
                min={1}
                max={365}
                defaultValue={1}
                style={{ width: '50%' }}
                disabled={isLoading}
                // formatter={value => `${value} ${value! > 1 ? 'days' : 'day'}`}
                onChange={e => handleUpdateExpiration(e)}
              />
            </Section>

            <Section>
              Shared Link Description (Optional)
              <Input
                allowClear
                onChange={e => {
                  handleUpdateDescription(e);
                }}
                style={{ width: '50%' }}
                placeholder="Describe the use of the link"
                value={updatedDescription}
                disabled={isLoading}
              />
            </Section>
          </>
        )}

        <Section>
          {!editable ? (
            <Button
              disabled={isLoading}
              onClick={() => startEditable()}
              style={{ width: 'fit-content' }}
            >
              Edit Details
            </Button>
          ) : (
            <Tags>
              <Button
                onClick={() => stopEditable()}
                style={{ width: 'fit-content' }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleUpdate();
                }}
                style={{ width: 'fit-content' }}
                disabled={!(editedFields.description || editedFields.expiration)}
                loading={isLoading}
              >
                Apply Changes
              </Button>
            </Tags>
          )}
        </Section>
      </Content>

      <Footer>
        <ManageLinksComponent />
        <Button disabled={isLoading} onClick={toManageLinks}>
          Go to Manage Links
        </Button>
      </Footer>
    </>
  );
};
