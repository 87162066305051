import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #212121;
  font-variant-numeric: stacked-fractions;
  font-family: 'Cera Pro';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled.div`
  padding-left: 8px;
  color: #212121;
  font-variant-numeric: stacked-fractions;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  border-left: 2px solid #ff6c54;
`;

export default {
  Container,
  Text,
};
