import React from 'react';
import { ScissorsActive } from '../../../../../shared/components/Icons/ScissorsActive';
import { Scissors as ScissorsIcon } from '../../../../../shared/components/Icons/Scissors';
import Styled from './styles';
import { Tooltip } from 'antd';

const { TooltipContainer, TooltipContents, TooltipTitle, TooltipText } = Styled;

interface Props {
  isEditing: boolean;
  onEditionStarted: () => unknown;
}

export const Scissors: React.FC<Props> = ({ isEditing, onEditionStarted }) => {
  return (
    <Tooltip
      overlayInnerStyle={{ background: 'var(--gray-950, #282828)' }}
      title={
        <TooltipContainer>
          <TooltipContents>
            <TooltipTitle>Bookend Cropping</TooltipTitle>
            <TooltipText>
              Trim the beginning and end of your meeting video. As the host, you'll have access to
              the entire footage, but attendees will only view the edited sections.
            </TooltipText>
          </TooltipContents>
        </TooltipContainer>
      }
    >
      {isEditing ? <ScissorsActive /> : <ScissorsIcon onClick={() => onEditionStarted()} />}
    </Tooltip>
  );
};
