import Styles from './styles';
import React, { ReactNode, useMemo } from 'react';
import { Ellipsis } from '../Ellipsis';
import { GenerativeAIItem } from '../../../../redux/typings/recap';
import { capitalize, secondsToTime } from '../../../../helpers/TextHelper';
import { UtteranceSegment } from '../../../../features/recapv2/recap-management/types';
import { useNavigate } from 'react-router-dom';

const { Container, Highlight, SpeakerTimestamp } = Styles;

interface Props {
  recapId?: string;
  message: {
    id: string;
    content: string;
    mapping: UtteranceSegment[];
    startedTime: string;
    speaker: string;
  };
  openInsightSamePage?: boolean;
  insight: GenerativeAIItem;
  highlightColor: string;
  play: (time: number) => void;
}

export const Message: React.FC<Props> = ({
  message,
  insight,
  highlightColor,
  recapId,
  openInsightSamePage,
  play,
}) => {
  const navigate = useNavigate();

  const navigateToInsight = () => {
    if (!recapId) return;

    const url = `/recap/${recapId}?messageId=${message.id}`;
    if (openInsightSamePage) {
      navigate(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const messageComponents = useMemo(() => {
    const components: ReactNode[] = [];
    const content = capitalize(message.content);

    let partialEllipsisText: string = '';
    message?.mapping?.forEach(mappingPart => {
      const text = content.slice(mappingPart.from, mappingPart.to);
      if (mappingPart.itemIds.includes(insight.id)) {
        if (partialEllipsisText) {
          components.push(<Ellipsis text={partialEllipsisText} />);
          partialEllipsisText = '';
        }
        components.push(
          <Highlight color={highlightColor} onClick={() => navigateToInsight()}>
            {text}
          </Highlight>,
        );
      } else {
        partialEllipsisText += text;
      }
    });

    if (partialEllipsisText) {
      components.push(
        <Highlight onClick={() => navigateToInsight()} color={highlightColor}>
          {partialEllipsisText}
        </Highlight>,
      );
    }
    return components;
  }, [insight, message]);

  const startTime = parseInt(message.startedTime);
  return (
    <Container
      onClick={() => {
        play(startTime);
      }}
    >
      <SpeakerTimestamp>
        {message.speaker} ({secondsToTime(startTime)}):
      </SpeakerTimestamp>
      {` `}
      {messageComponents}
    </Container>
  );
};
