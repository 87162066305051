import { TabsProps } from 'antd';
import { colors } from '../../../../theme/colors';
import Styles from './style';
import { ReactNode } from 'react';

interface Props {
  items: {
    key: string;
    label: JSX.Element;
    children: JSX.Element;
  }[];
  activeKey: string;
  extraContent?: ReactNode;
  onChange: (tab: string) => void;
}

const { TabRawItem, StyledTabs } = Styles;

const Tab: React.FC<Props> = ({ items, activeKey, onChange, extraContent }) => {
  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <DefaultTabBar
      {...props}
      extra={{
        right: extraContent,
      }}
      style={{
        background: `${colors.coolGray[100]}`,
        border: `1px solid ${colors.coolGray[100]}`,
        borderRadius: '10px',
        display: 'flex',
        height: '44px',
        width: '100%',
      }}
    />
  );

  return (
    <StyledTabs
      items={items}
      activeKey={activeKey}
      onChange={onChange}
      renderTabBar={renderTabBar}
      style={{
        width: '100%',
      }}
    />
  );
};

const TabItem: React.FC<{ isActive: boolean; label: string }> = ({ isActive, label }) => {
  return (
    <TabRawItem
      isActive={isActive}
      whileTap={{
        scale: 0.9,
        borderRadius: '50%',
      }}
    >
      {label}
    </TabRawItem>
  );
};

export default { TabItem, Tab };
