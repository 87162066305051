import styled from 'styled-components';
import { colors } from '../../../../../theme/colors';

const Containter = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-bottom: 5px;
  font-family: 'Cera Pro';
  font-style: normal;
  color: ${colors.coolGray[200]};
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  grid-gap: 8px;
`;

const CardContainter = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-gap: 8px;
`;

const HideButton = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  cursor: pointer;
  font-family: 'Cera Pro';
  font-style: normal;
  color: ${colors.primary[400]};
  background-color: ${colors.white};
  padding: 4px 10px;
  border-radius: 8px;
  gap: 8px;
  font-weight: 400;

  :hover {
    font-weight: 600;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-family: 'Cera Pro';
  cursor: pointer;
  padding: 8px;
  gap: 2px;
  border-radius: 8px;
  background-color: ${colors.white};

  h1 {
    color: ${colors.gray[700]};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }

  h2 {
    color: ${colors.gray[400]};
    font-family: 'Cera Pro';
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }

  :hover {
    outline: 1px solid ${colors.primary[400]};
    background: ${colors.primary[100]};
    h1 {
      color: ${colors.primary[400]};
    }
    h2 {
      color: ${colors.primary[400]};
    }
  }
`;

export default {
  Card,
  Containter,
  CardContainter,
  ActionContainer,
  HideButton,
};
