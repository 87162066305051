import { PropsWithChildren, createContext, useState } from 'react';

interface InviteBotContext {
  open: boolean;
  show: () => unknown;
  close: () => unknown;
}

export const inviteBotContext = createContext<InviteBotContext>({
  open: false,
  show: () => null,
  close: () => null,
});

export const InviteBotProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [open, setIsOpen] = useState(false);

  const show = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <inviteBotContext.Provider value={{ open, show, close }}>{children}</inviteBotContext.Provider>
  );
};
