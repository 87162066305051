import React, { useState } from 'react';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { useToast } from '../../../../../hooks/useToast';
import { useOrganizationMembers } from '../../../hooks/useOrganizationMembers';
import { extractError } from '../../../../../utils/api';

interface Props {
  isLoggedUser: boolean;
  userId: string;
  onCancel: () => unknown;
}

const RemoveMemberModal: React.FC<Props> = ({ isLoggedUser, userId, onCancel }) => {
  const { removeMember } = useOrganizationMembers();
  const [isRemoving, setIsRemoving] = useState(false);
  const { error, success } = useToast();

  const handleRemoveConfirm = async () => {
    try {
      setIsRemoving(true);
      await removeMember(userId);
      success('User removed successfully.');
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    } finally {
      setIsRemoving(false);
    }
  };

  const handleRemoveCancel = async () => {
    onCancel();
  };

  const open = Boolean(userId);

  const confirmationText = isLoggedUser ? "Yes, i'm sure." : 'Yes, remove member.';
  const description = isLoggedUser
    ? "If you want to hop back in, you'll need an invite from an admin. We'll whip up a new personal workspace for you immediately.\n\nYour meeting recaps and all privacy settings will transfer with you."
    : 'Are you sure you want to remove this member from the workspace?.\nYou will have to reinvite them again.\n\n';
  const title = isLoggedUser
    ? 'Are you sure you want to leave this workspace?'
    : 'Remove member from workspace';
  return (
    <ConfirmationModal
      confirmButtonText={confirmationText}
      onCloseModal={handleRemoveCancel}
      onConfirm={handleRemoveConfirm}
      loading={isRemoving}
      open={open}
      description={description}
      title={title}
    />
  );
};

export default RemoveMemberModal;
