import { Mapping, Property } from './types';
import { create } from 'zustand';

interface PropertyMappingState {
  isFetching: boolean;
  properties: Property[];
  mapping: Mapping[];
  fetchStatusChanged: (isFetching: boolean) => void;
  propertiesLoaded: (properties: Property[], mapping: Mapping[]) => void;
  mappingChanged: (mapping: Mapping[]) => void;
}

export type PropertyMappingStore = ReturnType<typeof createPropertyMappingStore>;

export const createPropertyMappingStore = () => {
  return create<PropertyMappingState>(set => {
    return {
      isFetching: false,
      properties: [],
      mapping: [],
      fetchStatusChanged: isFetching => set({ isFetching }),
      propertiesLoaded: (properties, mapping) => set({ properties, mapping }),
      mappingChanged: mapping => set({ mapping }),
    };
  });
};
