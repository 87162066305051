import { Radio } from 'antd';
import React from 'react';

interface Props {
  value: string;
  onParticipantTypeChanged: (value: string) => unknown;
}

export const ParticipantsTypeFilter: React.FC<Props> = ({ value, onParticipantTypeChanged }) => {
  return (
    <Radio.Group
      optionType="button"
      style={{ width: '100%' }}
      value={value}
      onChange={e => onParticipantTypeChanged(e.target.value)}
      options={[
        {
          label: 'Internal',
          value: 'internal',
          style: { width: '33.3%', textAlign: 'center' },
        },
        {
          label: 'External',
          value: 'external',
          style: { width: '33.3%', textAlign: 'center' },
        },
        { label: 'All', value: 'all', style: { width: '33.3%', textAlign: 'center' } },
      ]}
    />
  );
};
