import React, { ReactNode, useState } from 'react';
import { tokens } from './config';
import Styles from './styles';
import { AlertVariant } from './types';
import { Close } from '../../features/shared/components/Icons/Close';

const {
  Container,
  Message,
  MessageContainer,
  PrimaryAction,
  ActionsContainer,
  Title,
  Action,
  CloseContainer,
} = Styles;

interface Props {
  title: ReactNode;
  emoji?: string;
  customIcon?: ReactNode;
  message: ReactNode;
  closable?: boolean;
  variant: AlertVariant;
  primaryAction?: {
    label: string;
    onClick: () => unknown;
  };
  secondaryAction?: {
    label: string;
    onClick: () => unknown;
  };
  actions?: ReactNode;
  padding?: string;
  width?: string;
  height?: string;
  iconSize?: string;
}

export const Alert: React.FC<Props> = ({
  variant,
  title,
  emoji,
  message,
  primaryAction,
  secondaryAction,
  actions,
  customIcon,
  padding,
  closable,
  width,
  height,
  iconSize,
}) => {
  const { icon: Icon } = tokens[variant];
  const iconPositioned = (
    <div style={{ height: '100%', justifyContent: 'center', display: 'flex' }}>
      {emoji ||
        customIcon ||
        React.cloneElement(Icon, {
          style: {
            fontSize: iconSize,
            height: iconSize,
            width: iconSize,
          },
        })}
    </div>
  );

  const [shouldShow, setShouldShow] = useState(true);

  if (!shouldShow) return <></>;

  return (
    <Container variant={variant} padding={padding} width={width} height={height}>
      <MessageContainer>
        {title && (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {iconPositioned}
            <Title className="alert-title">
              {title}
              {closable && (
                <CloseContainer onClick={() => setShouldShow(false)}>
                  <Close />
                </CloseContainer>
              )}
            </Title>
          </div>
        )}
        {message && <Message className="alert-message">{message}</Message>}
      </MessageContainer>

      {(actions || primaryAction || secondaryAction) && (
        <div>
          {actions || (
            <>
              <ActionsContainer>
                {secondaryAction && (
                  <Action
                    onClick={() => secondaryAction.onClick()}
                    className="alert-secondary-action"
                  >
                    {secondaryAction.label}
                  </Action>
                )}
                {primaryAction && (
                  <PrimaryAction
                    onClick={() => primaryAction.onClick()}
                    className="alert-primary-action"
                  >
                    {primaryAction.label}
                  </PrimaryAction>
                )}
              </ActionsContainer>
            </>
          )}
        </div>
      )}
    </Container>
  );
};
