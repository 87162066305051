import React from 'react';
import { Modal, Button, Select, Checkbox } from 'antd';
import { useMemberMeetingVisibility } from '../../../../../../features/organizations/hooks/useMemberMeetingVisibility';
import Styles from './styles';
import { EventVisibility } from '../../../../../../features/recapv2/types';

const {
  Container,
  Contents,
  MainContent,
  Actions,
  Title,
  Subtitle,
  MeetingsVisibilitySelector,
  MeetingsVisibilitySelectors,
  VisibilityLabel,
  LockContainer,
} = Styles;

const options = [
  { value: EventVisibility.Invitees, label: 'Invitees Only' },
  { value: EventVisibility.Organization, label: 'Workspace' },
];

const getOptionLabel = (value: string): string => {
  return options.find(v => v.value === value)?.label || '';
};

interface Props {
  userId: string;
  open: boolean;
  onCancel: () => unknown;
  onDone: () => unknown;
}

export const MeetingsVisibilityModal: React.FC<Props> = ({ userId, open, onCancel, onDone }) => {
  const {
    meetingsVisibility,
    meetingVisibilityLocked,
    modifyMemberMeetingsVisibility,
    modifyMemberMeetingsVisibilityLock,
    isModifyingLock,
    isModifyingSettings,
    isAllowedToModifyLock,
    isAllowedToModifyVisibility,
  } = useMemberMeetingVisibility(userId);

  const handleInternalChanged = (internalMeetingsVisibility: string) => {
    if (meetingsVisibility) {
      modifyMemberMeetingsVisibility({
        externalMeetingsVisibility: meetingsVisibility?.externalMeetingsVisibility,
        internalMeetingsVisibility,
      });
    }
  };

  const handleExternalChanged = (externalMeetingsVisibility: string) => {
    if (meetingsVisibility) {
      modifyMemberMeetingsVisibility({
        externalMeetingsVisibility,
        internalMeetingsVisibility: meetingsVisibility.internalMeetingsVisibility,
      });
    }
  };

  const disableCheck = !isAllowedToModifyLock || isModifyingLock || isModifyingSettings;
  const disableInputs = !isAllowedToModifyVisibility || isModifyingLock || isModifyingSettings;

  return (
    <Modal width={460} style={{ width: '460px' }} open={open} footer={null} closable={false}>
      <Container>
        <Contents>
          <div>
            <Title>Meeting Visibility Settings</Title>
            <Subtitle>
              Adjust the default settings for this user's future meetings. (Past and already
              scheduled meetings will not be changed.)
            </Subtitle>
          </div>
          <MainContent>
            <MeetingsVisibilitySelectors>
              <MeetingsVisibilitySelector>
                <VisibilityLabel>Internal meetings</VisibilityLabel>
                {meetingsVisibility && (
                  <Select
                    disabled={disableInputs}
                    value={getOptionLabel(meetingsVisibility.internalMeetingsVisibility)}
                    style={{ width: '150px' }}
                    onChange={v => handleInternalChanged(v)}
                    options={options}
                  />
                )}
              </MeetingsVisibilitySelector>
              <MeetingsVisibilitySelector>
                <VisibilityLabel>External meetings</VisibilityLabel>
                {meetingsVisibility && (
                  <Select
                    disabled={disableInputs}
                    value={getOptionLabel(meetingsVisibility.externalMeetingsVisibility)}
                    style={{ width: '150px' }}
                    onChange={v => handleExternalChanged(v)}
                    options={options}
                  />
                )}
              </MeetingsVisibilitySelector>
            </MeetingsVisibilitySelectors>
            <LockContainer>
              <Checkbox
                disabled={disableCheck}
                checked={meetingVisibilityLocked === null ? false : meetingVisibilityLocked}
                onChange={ev => modifyMemberMeetingsVisibilityLock(ev.target.checked)}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <VisibilityLabel>
                  Prevent user from changing these settings on their own.
                </VisibilityLabel>
              </div>
            </LockContainer>
          </MainContent>
        </Contents>
        <Actions>
          <Button type="default" style={{ flex: 1 }} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" style={{ flex: 1 }} onClick={onDone}>
            Done
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};
