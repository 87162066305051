import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/selectors';
import { Spin } from 'antd';
import { FrameworkProvider } from '../../../../features/frameworks/framework.provider';
import FrameworkComponent from './Framework';

const Framework: React.FC = ({}) => {
  const user = useSelector(selectUser);

  const { data, isFetching } = useQuery({
    queryKey: ['frameworks', user?.id],
    queryFn: async () => {
      if (!user?.membership.organization.id) return [];
      const d = await coreService.fetchFrameworksByWorkspaceId(user?.membership.organization.id);
      return d;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return isFetching && data ? (
    <Spin size="small" />
  ) : (
    <FrameworkProvider>
      <FrameworkComponent frameworks={data} lding={isFetching} />
    </FrameworkProvider>
  );
};

export default Framework;
