import styled from 'styled-components';

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 200px);
  color: #6a6a6a;
`;

const NextMeeting = styled.div`
  display: flex;
  flex-direction: column;
  color: #6a6a6a;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
`;

const NoMeeting = styled.div`
  color: #6a6a6a;
  font-size: 14px;
  width: 100%;
`;

const Container = styled.div`
  .ant-table-row-expand-icon-cell {
    display: none;
  }
`;

const NoWrap = styled.div`
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: ellipsis;
`;

const Title = styled.div`
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: -10px;
`;

export default {
  CenteredContent,
  NextMeeting,
  NoMeeting,
  NoWrap,
  Container,
  Title,
};
