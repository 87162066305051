import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { OnboardingStatus } from '../types/commonTypes';
import { selectUser } from '../redux/selectors';

export const OnboardingRedirect: React.FC = () => {
  const user = useSelector(selectUser);

  return user && user.onboardingStatus === OnboardingStatus.Completed ? (
    <Outlet />
  ) : (
    <Navigate to={'/onboarding'} />
  );
};
