import { useAnalytics } from '../../analytics/hooks/useAnalytics';

export interface IAMAAnalytics {
  submitted: (location: string) => void;

  suggestedQuestion: {
    submitted: (location: string) => void;
  };
}

const OBJECT = 'AMA';
const QUESTION_OBJECT = 'AMA Suggested Question';

export const useAskMeAnythingAnalytics = () => {
  const { sendEvent } = useAnalytics();

  return {
    submitted: (location: string) => {
      sendEvent(`${OBJECT} Submitted`, {
        event: {
          location: location,
        },
      });
    },
    suggestedQuestion: {
      submitted: (location: string) => {
        sendEvent(`${QUESTION_OBJECT} Submitted`, {
          event: {
            location: location,
          },
        });
      },
    },
  } as IAMAAnalytics;
};
