import { Button } from 'antd';
import { colors } from '../../../../../../../theme/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 16px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
`;

const Bold = styled.span`
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;
const Text = styled.span`
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const LearnMore = styled.a`
  color: var(--P-500, #ff6c54);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  padding-left: 4px;
`;

const ActionButton = styled(Button)`
  display: flex;
  padding: 4px 12px 4px 4px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CheckBoxInfo = styled.span`
  color: var(--Cool-Gray-500, #0d283b);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CRMConnectedStatus = styled.span<{ connected: boolean }>`
  color: ${props => (props.connected ? colors['green'][500] : colors['red'][500])};
`;

const SuccessLabel = styled.span`
  color: ${colors['green'][500]};
`;

const WorkingLabel = styled.span`
  color: ${colors['yellow'][500]};
`;

const Spacing = styled.div`
  display: flex;
  gap: 4px;
`;

export default {
  Container,
  Header,
  Title,
  Bold,
  Text,
  LearnMore,
  ActionButton,
  Footer,
  CheckBoxInfo,
  CRMConnectedStatus,
  SuccessLabel,
  WorkingLabel,
  Spacing,
};
