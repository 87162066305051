import React from 'react';
import { PublicRecap } from '../../../shared/components/Icons/PublicRecap';
import Styles from './styles';

const { Container, Text } = Styles;

export const PublicRecapBadge: React.FC = ({}) => {
  return (
    <Container>
      <PublicRecap />
      <Text>Public View</Text>
    </Container>
  );
};
