import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const EarthSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 1.33331C4.31999 1.33331 1.33333 4.31998 1.33333 7.99998C1.33333 11.68 4.31999 14.6666 8 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8 1.33331ZM2.66666 7.99998C2.66666 7.59331 2.72 7.19331 2.80666 6.81331L5.99333 9.99998V10.6666C5.99333 11.4 6.59333 12 7.32666 12V13.2866C4.70666 12.9533 2.66666 10.7133 2.66666 7.99998ZM11.9267 11.6C11.7533 11.06 11.26 10.6666 10.66 10.6666H9.99333V8.66665C9.99333 8.29998 9.69333 7.99998 9.32666 7.99998H5.32666V6.66665H6.66C7.02666 6.66665 7.32666 6.36665 7.32666 5.99998V4.66665H8.66C9.39333 4.66665 9.99333 4.06665 9.99333 3.33331V3.05998C11.9467 3.84665 13.3333 5.76665 13.3333 7.99998C13.3333 9.38665 12.7933 10.6533 11.9267 11.6Z"
        fill="#9EA9B1"
      />
    </svg>
  );
};
export const Earth: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={EarthSVG} {...props} />;
};
