import React, { useEffect, useMemo, useState } from 'react';
import Styled from './style';
import { Connect } from '../../../../../../../features/shared/components/Icons/Connect';
import { Disconnect } from '../../../../../../../features/shared/components/Icons/Disconnect';
import { Alert } from '../../../../../../../componentsV2/Alert/Alert';
import { useToast } from '../../../../../../../hooks/useToast';
import { OptionalTooltipWrapper } from '../../../../../../../componentsV2/OptionalTooltipWrapper';
import { ConnectCRMModal } from './ConnectCRMModal';
import { DisconnectCRMModal } from './DisconnectCRMModal';
import { SubscribeToTeamsPlanLink } from '../../../../../../../componentsV2/UpgradeToPro';
import useFeatures from '../../../../../../../hooks/useFeatures';
import { useCrmIntegration } from '../../../../../../../features/crm-integration/hooks/use-crm-integration';
import { paragon, SDK_EVENT } from '@useparagon/connect';
import { coreService } from '../../../../../../../services/core/core-service';
import {
  SelectedProperty,
  WorkspaceCrmProperty,
} from '../../../../../../../features/crm-integration/crm-integration.types';
import { Button, Flex, Select } from 'antd';
import Link from 'antd/es/typography/Link';
import { ref } from 'yup';

const { Container, Title, ActionButton, CRMConnectedStatus } = Styled;

export interface Props {
  disableUserInteractions: boolean;
}

export const CRMOrganizationSection: React.FC<Props> = ({ disableUserInteractions }) => {
  const {
    disconnect,
    connect,
    integration,
    isConnectedEnabled,
    isDisconnectEnabled,
    workspaceCrmIntegration,
    refetchCrmIntegration,
    isFetching,
  } = useCrmIntegration();
  const [shouldShowConnectModal, setShouldShowConnectModal] = useState(false);
  const [shouldShowDisconnectModal, setShouldShowDisconnectModal] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<SelectedProperty[]>([]);
  const [filterProperties, setFilterProperties] = useState<SelectedProperty[]>([]);
  const [availableProperties, setAvailableProperties] = useState<WorkspaceCrmProperty[]>([]);

  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, () => {
      coreService.removeCrmIntegration().then(() => {
        refetchCrmIntegration();
        setSelectedProperties([]);
        setAvailableProperties([]);
        setFilterProperties([]);
      });
    });
  }, []);

  const { error, success } = useToast();

  const features = useFeatures();
  const [isSavingProperties, setIsSavingProperties] = useState(false);
  const availableFeature = features.hubspotIntegration || features.salesforceIntegration;

  const hasCrmConnected = integration !== null;

  const [isLoading, setIsLoading] = useState(false);

  const ConnectPrimaryProfileButton = (
    <ActionButton
      onClick={() => setShouldShowConnectModal(true)}
      disabled={
        disableUserInteractions || integration !== null || !isConnectedEnabled || !availableFeature
      }
      loading={isLoading}
    >
      <Connect /> Connect CRM{' '}
    </ActionButton>
  );

  const DisconnectPrimaryProfileButton = (
    <ActionButton
      onClick={() => setShouldShowDisconnectModal(true)}
      disabled={
        disableUserInteractions || !availableFeature || integration === null || !isDisconnectEnabled
      }
      loading={isLoading}
    >
      <Disconnect /> Disconnect CRM{' '}
    </ActionButton>
  );

  const handleConnectToCRM = async (selectedCRM: string) => {
    setIsLoading(true);
    try {
      if (selectedCRM === 'SALESFORCE') {
        connect('salesforce');
      }
      if (selectedCRM === 'HUBSPOT') {
        connect('hubspot');
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
      setShouldShowConnectModal(false);
    }
  };

  const handleDisconnectToCRM = async () => {
    setIsLoading(true);
    try {
      await disconnect();
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
      setShouldShowDisconnectModal(false);
    }
  };

  const status = useMemo(() => {
    if (isFetching) {
      return {
        connected: false,
        status: 'Reading integration status',
      };
    }

    if (workspaceCrmIntegration?.workspace) {
      if (!hasCrmConnected || integration.status === 'INVALID') {
        return {
          connected: true,
          status: 'IMPORTANT: Reconnect CRM. Credentials expired.',
        };
      }

      return {
        connected: true,
        status: 'Connected',
      };
    } else {
      if (hasCrmConnected) {
        return {
          connected: true,
          status: 'Synchronizing CRM data',
        };
      }
      return {
        connected: false,
        status: 'Not connected',
      };
    }
  }, [hasCrmConnected, integration, workspaceCrmIntegration, isFetching]);

  useEffect(() => {
    if (workspaceCrmIntegration && workspaceCrmIntegration.workspace) {
      setSelectedProperties(workspaceCrmIntegration.workspace.selectedProperties);
      setAvailableProperties(workspaceCrmIntegration.workspace.properties);
      setFilterProperties(workspaceCrmIntegration.workspace.filterProperties);
    }
  }, [workspaceCrmIntegration]);

  const propertiesOptions = useMemo(() => {
    if (!hasCrmConnected) return [];
    return availableProperties.map(p => ({ label: p.label, value: p.id }));
  }, [availableProperties, hasCrmConnected]);

  const handleOptionClicked = (values: string[]) => {
    const validValues = values.filter(v => availableProperties.find(p => p.id === v) !== undefined);
    setSelectedProperties(validValues);
  };

  const handleFilterOptionClicked = (values: string[]) => {
    const validValues = values.filter(v => availableProperties.find(p => p.id === v) !== undefined);
    setFilterProperties(validValues);
  };

  const handleSave = () => {
    setIsSavingProperties(true);
    coreService
      .updateWorkspaceCrmIntegrationSelectedProperties({
        displayProperties: selectedProperties,
        filterProperties,
      })
      .then(() => {
        success('Account Properties saved successfully');
      })
      .finally(() => setIsSavingProperties(false));
  };

  const isPullingProperties = isFetching || propertiesOptions.length === 0;

  return (
    <Container>
      <ConnectCRMModal
        isLoading={isLoading}
        isOpen={shouldShowConnectModal}
        onCancel={() => setShouldShowConnectModal(false)}
        onClose={() => setShouldShowConnectModal(false)}
        onConfirm={handleConnectToCRM}
      />

      <DisconnectCRMModal
        isLoading={isLoading}
        isOpen={shouldShowDisconnectModal}
        onCancel={() => setShouldShowDisconnectModal(false)}
        onClose={() => setShouldShowDisconnectModal(false)}
        onConfirm={handleDisconnectToCRM}
      />

      <Title>
        CRM Integration Status:{' '}
        <CRMConnectedStatus connected={status.connected}>
          {status.status}{' '}
          {status.status === 'Synchronizing CRM data' ? (
            <Link onClick={() => refetchCrmIntegration()}>Refresh status</Link>
          ) : (
            <></>
          )}
        </CRMConnectedStatus>
      </Title>

      <OptionalTooltipWrapper
        display={disableUserInteractions || !availableFeature}
        value={
          !availableFeature ? (
            <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to your CRM." />
          ) : (
            'Only the Owner and Admins of the organization can change this setting.'
          )
        }
      >
        <div>{!integration ? ConnectPrimaryProfileButton : DisconnectPrimaryProfileButton}</div>
      </OptionalTooltipWrapper>
      <Flex vertical gap={8}>
        <Flex vertical gap={8}>
          <Title>
            {isPullingProperties && hasCrmConnected
              ? `Account properties list is being pulled from the CRM. Please refresh the page to update the status.`
              : 'Account properties to display'}
          </Title>

          <Select
            mode="tags"
            loading={isPullingProperties}
            disabled={!hasCrmConnected || isPullingProperties}
            onChange={value => handleOptionClicked(value)}
            autoClearSearchValue={true}
            value={selectedProperties}
            options={propertiesOptions}
            style={{ width: '50%' }}
          />
        </Flex>

        <Flex vertical gap={8}>
          <Title>Account properties to use as filter</Title>

          <Select
            mode="tags"
            loading={isPullingProperties}
            disabled={!hasCrmConnected || isPullingProperties}
            onChange={value => handleFilterOptionClicked(value)}
            autoClearSearchValue={true}
            value={filterProperties}
            options={propertiesOptions}
            style={{ width: '50%' }}
          />
        </Flex>

        <Flex>
          <Button
            disabled={!hasCrmConnected || isFetching || isSavingProperties}
            onClick={handleSave}
            type="primary"
          >
            Save Properties
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};
