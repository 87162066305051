import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CardContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
  padding: 16px;
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  border-radius: var(--4px, 8px);
  border: 1px solid #d9d9d9;
  background: var(--Global-white, #fafafa);
`;

export default {
  Container,
  CardContents,
  SummaryContainer,
};
