import React, { PropsWithChildren, ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import { colors } from '../theme/colors';

export const AntProvider: React.FC<PropsWithChildren<ReactNode>> = ({ children }) => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Cera Pro',
        paddingContentVertical: 0,
        paddingContentHorizontal: 0,
        colorPrimary: colors.primary[500],
      },
      components: {
        DatePicker: {
          fontFamily: 'Cera Pro',
        },
        Button: {
          borderRadius: 8,
          borderRadiusLG: 4,
          colorBorder: '#E0E6EE',
          paddingContentHorizontal: 16,
          paddingContentVertical: 8,
        },
        Tabs: {
          colorText: 'black',
          colorTextBase: 'black',
        },
        Typography: {
          paddingContentVertical: 0,
        },
        Switch: {
          colorPrimary: '#2FBB89',
          colorPrimaryHover: '#2FBB89',
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
);
