import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { alertContext } from './AlertContext';

const { Provider } = alertContext;

export const AlertProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<ReactElement[]>([]);
  const [index, setIndex] = useState(0);

  const changeIndexFunction = useRef<() => unknown | null>();

  useEffect(() => {
    changeIndexFunction.current = () => {
      const nextIndex = index + 1;
      if (nextIndex > alerts.length - 1) {
        setIndex(0);
      } else {
        setIndex(nextIndex);
      }
    };
  }, [index, alerts]);

  const addAlert = (node: ReactElement) => {
    setAlerts(prevAlerts => {
      const alertsCopy = [...prevAlerts];
      alertsCopy.push(node);
      return alertsCopy;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (changeIndexFunction.current) {
        changeIndexFunction.current();
      }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Provider value={{ alerts, addAlert, index }}>{children}</Provider>;
};
