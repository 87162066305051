import { Skeleton, Space } from 'antd';
import Styled from './style';

interface Props {
  image: React.ReactElement;
  title?: string;
  titlewidth?: number;
  subtitle?: React.ReactElement | string;
  prefix?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  loading?: boolean;
}

export const ProfileAvatar: React.FC<Props> = ({
  image,
  title,
  titlewidth,
  subtitle,
  prefix,
  width,
  height,
  fontSize,
  loading,
}) => {
  const { Avatar, Title } = Styled;

  const size = {
    width: (width || 40) + 'px',
    height: (height || 40) + 'px',
    fontSize: (fontSize || 16) + 'px',
    titlewidth: (titlewidth || 240) + 'px',
  };

  if (loading)
    return (
      <Space>
        <Skeleton.Avatar active shape="square" size="large" />
        <Skeleton.Input active size="large" />
      </Space>
    );

  return (
    <div style={{ display: 'flex', gap: '15px', height: '100%' }}>
      <Avatar style={{ height: size.height, width: size.width, fontSize: size.fontSize }}>
        {image}
      </Avatar>

      <Title titleWidth={size.titlewidth}>
        {title && <h1>{title}</h1>}

        {subtitle && (
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {prefix && <span>{prefix}</span>}
            <h2>{subtitle}</h2>
          </div>
        )}
      </Title>
    </div>
  );
};
