import styled from 'styled-components';

interface Props {
  minusHeight: number;
  sider?: boolean;
}

export const LayoutStyled = styled.div<Props>`
  height: 100vh;
  width: ${({ sider }) => (sider ? 'calc(100% - 80px)' : '100%')};
  margin-left: ${({ sider }) => (sider ? '90px' : '')};
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
`;
