import { useEffect, useState } from 'react';
import { StoredFilterType } from '../../../pages/insights/components/StoredFilters/types';
import { useToast } from '../../../hooks/useToast';
import { coreService } from '../../../services/core/core-service';
import { KeyToFilterStore } from '../../shared/dashboard-filters/types';

export const useStoreFilter = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<StoredFilterType[]>([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<StoredFilterType | undefined>(undefined);

  const { success, error } = useToast();

  const createFilter = (filter: Omit<StoredFilterType, 'id'>) => {
    setLoading(true);
    coreService
      .createInsightsFilter(filter)
      .then(f => {
        setData([...data, f.data]);
        success('View saved successfully');
      })
      .catch(() => {
        error('Error saving View');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeFilter = (id: string) => {
    setLoading(true);
    coreService
      .removeInsightsFilter(id)
      .then(() => {
        setData(data.filter(d => d.id !== id));
        success('View removed successfully');
      })
      .catch(() => {
        error('Error removing View');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFilter = (
    id: string,
    args: { name?: string; config?: Partial<KeyToFilterStore> },
  ) => {
    setLoading(true);
    coreService
      .updateInsightsFilter(id, args)
      .then(() => {
        const updated = data.find(d => d.id === id) as StoredFilterType;
        if (updated) {
          updated.name = args.name || updated.name;
          updated.config = { ...updated.config, ...args.config };

          setData([...data.filter(d => d.id !== id), updated]);
        }
        success('View updated successfully');
      })
      .catch(() => {
        error('Error updating View');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    coreService
      .getInsightsFilters()
      .then(r => {
        setData(r.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // TODO
    // onStoreFilter(selectedFilter?.config || {});
  }, [selectedFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    data,
    manageModal: showManageModal,
    createModal: showCreateModal,
    showManageModal: () => setShowManageModal(true),
    hideManageModal: () => setShowManageModal(false),
    showCreateModal: () => setShowCreateModal(true),
    hideCreateModal: () => setShowCreateModal(false),
    selectFilter: (f: StoredFilterType) => setSelectedFilter(f),
    removeFilter,
    createFilter,
    updateFilter,
  };
};
