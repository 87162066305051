import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Title = styled.div`
  font-family: Cera Pro;
  font-size: 32px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;

  span {
    font-weight: 900;
  }
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.coolGray[400]};
`;

const Line = styled.div`
  height: 0px;
  width: 100%;
  border: 1px solid #e0e6ee;
  margin-top: 26px;
  margin-bottom: 26px;
`;

const OrgInputInfo = styled.div`
  width: 354px;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #596370;
`;

const NewOrg = styled.div`
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #282828;
  align-self: flex-start;
`;

const LinkButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ff6c54;
  cursor: pointer;
`;

const Contents = styled.div`
  flex: 1;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
`;

export default {
  Title,
  ContainerHeader,
  SubTitle,
  Line,
  OrgInputInfo,
  NewOrg,
  LinkButton,
  Contents,
};
