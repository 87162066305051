import { combineReducers, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { recapReducers, initialState as recapInitialState } from './slices/recapSlice';
import { sessionReducers, initialState as sessionInitialState } from './slices/sessionSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import {
  onboardingReducer,
  initialState as onboardingInitialState,
} from './slices/onboardingSlice';
import {
  organizationsReducer,
  initialState as organizationsInitialState,
} from './slices/organizationsSlice';

export const initStore = (): Store => {
  const sagaMiddleware = createSagaMiddleware();

  const store: Store = configureStore({
    middleware: [sagaMiddleware],
    preloadedState: {
      session: { ...sessionInitialState },
      recap: recapInitialState,
      onboarding: onboardingInitialState,
      organizations: organizationsInitialState,
    },
    reducer: combineReducers({
      session: sessionReducers,
      recap: recapReducers,
      onboarding: onboardingReducer,
      organizations: organizationsReducer,
    }),
    devTools: process.env.NODE_ENV === 'development',
  });

  sagaMiddleware.run(rootSaga);
  return store;
};
