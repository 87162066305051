import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    :hover {
      text-decoration: underline;
    }
  }
`;

export default {
  Container,
};
