import React from 'react';
import { SectionCard } from '../SectionCard/SectionCard';
import { ParticipantTalkData } from '../ParticipantTalkData/ParticipantTalkData';
import { FollowUpEmailContainer, FollowUpHeader, ParicipantTalkRatioList } from './styles';
import { TalkRatio } from '../../../shared/components/Icons/TalkRatio';
import { useCopyFollowUp, useCopyMeetingDetailsAndTR } from '../../../../hooks/useRecapCopy';
import { FollowUp } from '../../../shared/components/Icons/FollowUp';
import { SendOutlined } from '@ant-design/icons';
import { useEmail } from '../../../email/useEmail';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';

export const TalkRatioCard: React.FC = () => {
  const { speakerAnalytics, followUp, participants, publicVisibility, changePublicVisibility } =
    useRecapManagement();
  const { getEmailLink } = useEmail();

  const copyTalkRatio = useCopyMeetingDetailsAndTR();
  const copyFollowUp = useCopyFollowUp();

  return (
    <>
      <SectionCard
        icon={TalkRatio}
        onCopy={copyTalkRatio}
        color="#5704C0"
        showAdd={false}
        label="Talk Ratio"
        isPubliclyVisible={publicVisibility.showMeetingDetails}
        visibility={{
          disabled: false,
          onClick: () =>
            changePublicVisibility({
              ...publicVisibility,
              showMeetingDetails: !publicVisibility.showMeetingDetails,
            }),
        }}
      >
        <ParicipantTalkRatioList>
          {speakerAnalytics.speakers.map(speakerData => {
            return (
              <ParticipantTalkData
                ratio={speakerData.talkRatio}
                speaker={speakerData.name}
                key={'id-' + speakerData.name}
              />
            );
          })}
        </ParicipantTalkRatioList>
      </SectionCard>
      {followUp && (
        <SectionCard
          icon={FollowUp}
          onCopy={copyFollowUp}
          color="#0D283B"
          showAdd={false}
          label="Client Follow Up"
          isPubliclyVisible={false}
          visibility={{
            disabled: true,
          }}
        >
          <FollowUpHeader
            href={getEmailLink({
              subject: 'Follow-up from our call',
              to: participants.map(p => p.email),
              body: followUp,
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SendOutlined
              size={14}
              style={{
                transform: 'rotate(-45deg)',
                alignItems: 'center',
                paddingLeft: '7px',
                marginLeft: '1px',
                height: '14px',
                display: 'flex',
              }}
            />
            Send by email
          </FollowUpHeader>
          <FollowUpEmailContainer>{followUp}</FollowUpEmailContainer>
        </SectionCard>
      )}
    </>
  );
};
