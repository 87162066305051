import React, { useMemo } from 'react';
import { colors } from '../../../../theme/colors';
import * as Style from './style';

interface Props {
  title: string;
  image?: JSX.Element;
  selected?: boolean;
  description?: string;
  onClick?: () => void;
}

const { CardContainer, CardTitle, CardDescription } = Style;

export const Card: React.FC<Props> = ({ title, image, selected = false, onClick, description }) => {
  const style = useMemo(() => {
    return {
      backgroundColor: selected ? colors.primary[500] : '',
      color: selected ? colors.white : colors.black,
    };
  }, [image, selected]);

  return (
    <CardContainer
      style={{ ...style }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {image ? image : <></>}
      <div>
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </div>
    </CardContainer>
  );
};
