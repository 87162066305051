import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import Icon from '@ant-design/icons';

const DraggerMarkSVG = () => {
  return (
    <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0.113249L0.113249 3L3 5.88675L5.88675 3L3 0.113249ZM2.5 3.9C2.5 4.17614 2.72386 4.4 3 4.4C3.27614 4.4 3.5 4.17614 3.5 3.9L2.5 3.9ZM3.5 5.7C3.5 5.42386 3.27614 5.2 3 5.2C2.72386 5.2 2.5 5.42386 2.5 5.7L3.5 5.7ZM2.5 7.5C2.5 7.77614 2.72386 8 3 8C3.27614 8 3.5 7.77614 3.5 7.5L2.5 7.5ZM3.5 9.3C3.5 9.02386 3.27614 8.8 3 8.8C2.72386 8.8 2.5 9.02386 2.5 9.3L3.5 9.3ZM2.5 11.1C2.5 11.3761 2.72386 11.6 3 11.6C3.27614 11.6 3.5 11.3761 3.5 11.1L2.5 11.1ZM3.5 12.9C3.5 12.6239 3.27614 12.4 3 12.4C2.72386 12.4 2.5 12.6239 2.5 12.9L3.5 12.9ZM2.5 14.7C2.5 14.9761 2.72386 15.2 3 15.2C3.27614 15.2 3.5 14.9761 3.5 14.7L2.5 14.7ZM3.5 16.5C3.5 16.2239 3.27614 16 3 16C2.72386 16 2.5 16.2239 2.5 16.5L3.5 16.5ZM2.5 18.3C2.5 18.5761 2.72386 18.8 3 18.8C3.27614 18.8 3.5 18.5761 3.5 18.3L2.5 18.3ZM3.5 20.1C3.5 19.8239 3.27614 19.6 3 19.6C2.72386 19.6 2.5 19.8239 2.5 20.1L3.5 20.1ZM2.5 3L2.5 3.9L3.5 3.9L3.5 3L2.5 3ZM2.5 5.7L2.5 7.5L3.5 7.5L3.5 5.7L2.5 5.7ZM2.5 9.3L2.5 11.1L3.5 11.1L3.5 9.3L2.5 9.3ZM2.5 12.9L2.5 14.7L3.5 14.7L3.5 12.9L2.5 12.9ZM2.5 16.5L2.5 18.3L3.5 18.3L3.5 16.5L2.5 16.5ZM2.5 20.1L2.5 21L3.5 21L3.5 20.1L2.5 20.1Z"
        fill="#21A6FF"
      />
    </svg>
  );
};
export const DraggerMark: React.FC<Partial<CustomIconComponentProps>> = props => {
  return <Icon component={DraggerMarkSVG} {...props} />;
};
