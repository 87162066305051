import { recapFormatDate } from '@updateai/ui';
import { useToast } from './useToast';
import { useRecapAnalytics } from '../features/recapv2/recap-analytics/hooks/useRecapAnalytics';
import { useRecapManagement } from '../features/recapv2/recap-management/hook';
import { getInsightTypeConfiguration } from '../features/insights/insight-type-configuration/insight-type-configuration.service';
import { useCallback, useMemo } from 'react';

export const listItems = (title: string, items: string[]) => {
  let text = `${title.toUpperCase()}\n`;
  items.forEach(each => {
    text = text + '  • ' + each.trim() + '\n';
  });
  return text;
};

const useCopyWithHeaderDetails = () => {
  const { host, started, ended, title } = useRecapManagement();
  const details = useMemo(() => {
    return {
      host,
      started: (started || new Date()).toISOString(),
      ended: (ended || new Date()).toISOString(),
      title,
    };
  }, [host, started, ended, title]);

  const copyWithDetails = useCallback(
    (text: string) => {
      let copyText = `🪄 This Smart Summary™ was magically created by UpdateAI\n\n`;
      copyText += `${details.title}\n`;
      copyText += `${recapFormatDate(details.started, details.ended)} - Host: ${details.host}`;
      copyText += '\n\n' + text;
      navigator.clipboard.writeText(copyText);
    },
    [details],
  );

  return copyWithDetails;
};

const append = (
  text: string,
  condition: string | number | null,
  value: string | number,
): string => {
  if (
    (typeof condition === 'string' && condition !== '') ||
    (typeof condition === 'number' && condition > 0)
  ) {
    return text + value.toString();
  }

  return text;
};

export const useCopyLink = () => {
  const { success } = useToast();
  const { shareLink } = useRecapAnalytics();

  return (link: string) => {
    shareLink.copied();
    navigator.clipboard.writeText(link);
    success('Recap link copied to clipboard.');
  };
};

export const useCopySummaryPublicly = () => {
  const {
    categories,
    insights,
    publicVisibility: visibilityConfig,
    participants,
    speakerAnalytics,
  } = useRecapManagement();
  const copyWithHeaderDetails = useCopyWithHeaderDetails();
  const { neutral } = useToast();
  const { smartSummary } = useRecapAnalytics();

  return () => {
    const Invitees =
      listItems(
        `Invitees (${participants.length})`,
        participants.map(each => {
          return each.email;
        }),
      ) + '\n';

    const TR = listItems(
      `Talk Ratio`,
      speakerAnalytics.speakers.map(each => {
        return `${each.name} - ${each.talkRatio}%`;
      }),
    );

    const extraCategories = Object.keys(categories)
      .map(categoryName => {
        const config = getInsightTypeConfiguration(categoryName);
        if (visibilityConfig[config.visibilityKey || '']) {
          const items = insights.filter(insight => insight.category === categoryName);
          return {
            count: items.length,
            text:
              listItems(
                `${categoryName} (${items.length})`,
                items.map(each => each.text),
              ) + '\n',
          };
        } else {
          return null;
        }
      })
      .filter(e => e !== null);

    let text = '';

    extraCategories.forEach(c => {
      if (c) {
        text = append(text, c.count, c.text);
      }
    });

    if (visibilityConfig.showMeetingDetails) {
      text = append(text, participants?.length, Invitees);
      text = append(text, speakerAnalytics?.speakers?.length, TR);
    }

    smartSummary.copied();

    copyWithHeaderDetails(text);
    neutral('Smart Summary™ public copied to clipboard.');
  };
};

export const useCopySummary = () => {
  const { categories, insights, participants, speakerAnalytics, host, started, ended, title } =
    useRecapManagement();
  const { neutral } = useToast();
  const { smartSummary } = useRecapAnalytics();
  const copyWithHeader = useCopyWithHeaderDetails();

  const copyFunction = useCallback(() => {
    const Invitees =
      listItems(
        `Invitees (${participants.length})`,
        participants.map(each => {
          return each.email;
        }),
      ) + '\n';

    const TR = listItems(
      `Talk Ratio`,
      speakerAnalytics.speakers.map(each => {
        return `${each.name} - ${each.talkRatio}%`;
      }),
    );

    let text = '';

    Object.keys(categories).forEach(categoryName => {
      const items = insights.filter(insight => insight.category === categoryName);
      const bullets = items.map(e => e.text);
      const title = categoryName.replaceAll('_', ' ');
      text = append(text, bullets.length, listItems(`${title} (${items.length})`, bullets) + '\n');
    });

    text = append(text, participants?.length, Invitees);
    text = append(text, speakerAnalytics?.speakers?.length, TR);

    smartSummary.public.copied();
    copyWithHeader(text);
    neutral('Smart Summary™ copied to clipboard.');
  }, [categories, insights, participants, speakerAnalytics, host, started, ended, title]);

  return copyFunction;
};

export const useCopyMeetingDetailsAndTR = () => {
  const { dynamicContent } = useRecapAnalytics();
  const { started, ended, host, title, participants, speakerAnalytics } = useRecapManagement();
  const copyWithHeader = useCopyWithHeaderDetails();
  const { neutral } = useToast();

  return () => {
    const invitees =
      listItems(
        `Invitees (${participants.length})`,
        participants.map(each => {
          return each.email;
        }),
      ) + '\n';
    const TR =
      '\n' +
      listItems(
        `Talk Ratio`,
        speakerAnalytics.speakers.map(each => {
          return `${each.name} - ${Math.round(Number(each.talkRatio))}%`;
        }),
      ) +
      '\n';

    const text = append(invitees, speakerAnalytics.speakers.length, TR);
    dynamicContent.copied();
    copyWithHeader(text);
    neutral('Meeting details and Talk Ratio copied to clipboard.');
  };
};

export const useCopyFollowUp = () => {
  const { followUp } = useRecapAnalytics();
  const { followUp: followUpText } = useRecapManagement();
  const { neutral } = useToast();

  return () => {
    navigator.clipboard.writeText(followUpText || '');
    followUp.copied();
    neutral('Client Follow Up copied to clipboard.');
  };
};

export const useCopyInsightType = (category: string) => {
  const { dynamicContent } = useRecapAnalytics();
  const copyWithHeader = useCopyWithHeaderDetails();
  const { insights, categories } = useRecapManagement();
  const { neutral } = useToast();

  const copyCallback = useCallback(() => {
    const items = insights.filter(insight => insight.category === category);
    const itemsCount = categories[category].count;
    const text =
      listItems(
        `${category} (${itemsCount})`,
        items.map(each => {
          return each.text;
        }),
      ) + '\n';

    dynamicContent.copied();

    copyWithHeader(text);
    neutral(`${category} copied to clipboard.`);
  }, [category, insights]);

  return copyCallback;
};

export const useCopySmartClip = (id: string, category: string) => {
  const { smartClip } = useRecapAnalytics();
  const { neutral } = useToast();

  const url = new URLSearchParams();
  url.append('insightId', id);

  return () => {
    smartClip.copied();
    navigator.clipboard.writeText(`${url.toString()}`);
    neutral('Smart Clip™ copied to clipboard.');
  };
};
