import styled from 'styled-components';
import { Magic } from '../../Icons/Magic';
import { Modal as AntModal } from 'antd';

const MagicIconHeader = styled(Magic)`
  fill: var(--cool-gray-300, #9ca9b2);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Modal = styled(AntModal)`
  padding: 0px;

  .ant-modal-content {
    padding: 0px;
  }
`;

const Description = styled.span`
  font-size: 14px;
  color: rgb(106, 106, 106);
`;

export default {
  MagicIconHeader,
  Header,
  Modal,
  Description,
  HeaderContainer,
};
