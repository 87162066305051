import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.css';
import { Dragger } from '../../../../../shared/components/Icons/Dragger';
import { DraggerMark } from '../../../../../shared/components/Icons/DraggerMark';
import Styles from './styles';
import { useVideoPlayer } from '../../../../hooks';

const { Container } = Styles;

interface Props {
  marks: [number, number] | null;
}

export const PlayBar: React.FC<Props> = ({ marks }) => {
  const { currentTime, maskedDuration, seek } = useVideoPlayer();

  const handleTimeChanged = (time: number | number[]) => {
    if (!Array.isArray(time)) {
      seek(time);
    }
  };

  const marksToShow = marks
    ? marks.map((markValue, markIndex) => {
        const percentageTotal = (markValue / maskedDuration) * 100 - 0.5;
        return (
          <DraggerMark
            key={markIndex}
            style={{ position: 'absolute', left: `${percentageTotal}%`, top: '-12px' }}
          />
        );
      })
    : [];

  return (
    <Container id="play-bar-container">
      <Slider
        key="play-bar"
        min={0}
        max={maskedDuration}
        value={currentTime}
        draggableTrack
        dots={false}
        railStyle={{ backgroundColor: 'var(--cool-gray-400, #566976)' }}
        trackStyle={{ backgroundColor: 'var(--cool-gray-200, #C3C9CE)' }}
        onChange={handleTimeChanged}
        handleRender={props => {
          return <Dragger {...props.props} />;
        }}
      />
      {marksToShow}
    </Container>
  );
};
