import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  position: relative;
  gap: 5px;
  width: 100%;
  border-radius: 8px;
  height: auto;
  display: flex;
  flex-direction: column;

  h1 {
    color: #212121;
    font-family: 'Cera Pro Medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
`;

const Content = styled.textarea`
  padding: 16px;
  border-radius: var(--4px, 8px);
  height: 150px;
  border: 1px solid #d9d9d9;
  background: ${colors.gray[50]};
  resize: none;
  color: #6a6a6a;
  font-family: 'Cera Pro';
`;

const Buttons = styled.div<{ $visible: boolean }>`
  position: absolute;
  gap: 3px;
  right: 10px;
  bottom: 10px;
  display: flex;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
`;

export default { Container, Content, Buttons };
