import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { capitalize } from '../../../../helpers/TextHelper';

import Styles from './styles';
const { Container, Content, Buttons } = Styles;

interface Props {
  id: string;
  title: string;
  value: string;
  loading: boolean;
  onSave: (args: { id: string; value: string }) => void;
}

export const FrameworkCard: React.FC<Props> = ({ id, title, value, loading, onSave }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const [updatedValue, setUpdatedValue] = useState(value);

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  return (
    <Container>
      <h1>
        {title
          .replace('_', ' ')
          .split(' ')
          .map(w => capitalize(w))
          .join(' ')}
      </h1>

      <Content
        value={updatedValue}
        onInput={t => setUpdatedValue(t.currentTarget.value)}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        disabled={loading}
      />

      <Buttons $visible={showButtons} onMouseEnter={() => setShowButtons(true)}>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setUpdatedValue(initialValue);
            setShowButtons(false);
          }}
          style={{ borderRadius: '8px' }}
          disabled={loading || initialValue === updatedValue}
          loading={loading}
        >
          Cancel
        </Button>

        <Button
          size="small"
          type="primary"
          onClick={() => onSave({ id, value: updatedValue })}
          style={{ borderRadius: '8px' }}
          disabled={loading || initialValue === updatedValue}
          loading={loading}
        >
          Save
        </Button>
      </Buttons>
    </Container>
  );
};
