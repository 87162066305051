import { useSelector } from 'react-redux';
import { selectUser, selectUserMembership } from '../../../redux/selectors';
import { useEffect } from 'react';

const useAppcues = () => {
  const user = useSelector(selectUser);
  const membership = useSelector(selectUserMembership);

  useEffect(() => {
    if (user && membership && window.Appcues) {
      window.Appcues.identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        firstTimeUser: user.firstTimeUser,
        email: user.email,
        userId: user.id,
        companyName: membership.organization?.name,
        isPaid: true,
      });
    }
  }, [user, membership]);
};

export default useAppcues;
