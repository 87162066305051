import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 1280px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
`;

export const Content = styled.div<{ background: boolean; border: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 15px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 950;
  font-size: 18px;
  line-height: 24px;
  color: #282828;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
  align-self: stretch;
  width: 100%;
`;

export const LearnMore = styled.a`
  font-style: normal;
  text-decoration: underline;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e6614c;
  flex: none;
  flex-grow: 0;
  padding-left: 4px;
`;
