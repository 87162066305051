import React from 'react';
import { AuthenticationButton } from '../AuthenticationButton';

export const MicrosoftButton: React.FC<{
  label: string;
  handleClick: () => void;
}> = ({ label, handleClick }) => {
  return (
    <AuthenticationButton label={label} onClick={() => handleClick()}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 100 100">
        <title>MS-SymbolLockup</title>
        <rect x="0" y="0%" width="48%" height="48%" fill="#f25022" />
        <rect x="0" y="52%" width="49%" height="49%" fill="#00a4ef" />
        <rect x="52%" y="0%" width="48%" height="48%" fill="#7fba00" />
        <rect x="52%" y="52%" width="48%" height="48%" fill="#ffb900" />
      </svg>
    </AuthenticationButton>
  );
};
