import { v4 } from 'uuid';
import { Question } from './type';

export const MEDDICQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Metrics',
      context: 'Quantifiable measures of success that your solution can help the customer achieve',
    },
    {
      id: v4(),
      position: '2',
      value: 'Economic Buyer',
      context:
        'positionentifying the key decision-maker who has the financial authority to approve the purchase',
    },
    {
      id: v4(),
      position: '3',
      value: 'Decision Criteria',
      context:
        'Standards or requirements that the customer will use to evaluate and choose among different solutions',
    },
    {
      id: v4(),
      position: '4',
      value: 'Decision Process',
      context: 'Steps and processes that the organization follows to make a purchase decision',
    },
    {
      id: v4(),
      position: '5',
      value: 'Positionentify Pain',
      context:
        "Uncovering the customer's pain points or challenges that your product or service can address",
    },
    {
      id: v4(),
      position: '6',
      value: 'champion',
      context:
        "An internal advocate within the customer's organization who believes in the value of your solution and can influence others",
    },
    {
      id: v4(),
      position: '7',
      value: 'Competition',
      context: "Awareness of competitors that are also vying for the customer's business",
    },
  ] as unknown as Question[];
};

export const BANTQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Budget',
      context: 'Determining if the prospect has allocated funds for the purchase',
    },
    {
      id: v4(),
      position: '2',
      value: 'Authority',
      context: 'Identifying the person who has the decision-making power to approve the purchase',
    },
    {
      id: v4(),
      position: '3',
      value: 'Need',
      context:
        'Assessing whether the prospect has a genuine requirement for your product or service',
    },
    {
      id: v4(),
      position: '4',
      value: 'Timeline',
      context:
        'Understanding the timeframe in which the prospect intends to make a purchase decision',
    },
  ] as unknown as Question[];
};

export const REACHQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Research',
      context: 'Gathering detailed information about the prospect and their industry',
    },
    {
      id: v4(),
      position: '2',
      value: 'Engage',
      context: 'Initiating meaningful conversations to build relationships with the prospect',
    },
    {
      id: v4(),
      position: '3',
      value: 'Assess',
      context: "Evaluating the prospect's needs, challenges, and potential fit for your solution",
    },
    {
      id: v4(),
      position: '4',
      value: 'Collaborate',
      context: 'Working together with the prospect to develop tailored solutions',
    },
    {
      id: v4(),
      position: '5',
      value: 'Help',
      context: 'Providing support and demonstrating how your solution addresses their needs',
    },
  ] as unknown as Question[];
};
