import styled from 'styled-components';

const GeneralOptions = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  color: var(--Grey-900, #111b2b);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.154px;
`;

export default { GeneralOptions, Item };
