import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';

enum SignUpMethod {
  Standard = 'STANDARD',
  Microsoft = 'MICROSOFT',
  Google = 'GOOGLE',
}

export const useEmail = () => {
  const user = useSelector(selectUser);

  const getEmailLink = (args: { subject?: string; body?: string; to: string[] }): string => {
    const { subject, body, to } = args;

    const sub = encodeURIComponent(subject || '');
    const bod = encodeURIComponent(body || '');
    const emails = encodeURIComponent(to.filter(p => p !== user?.email).join(','));

    let link = `mailto:${emails}?subject=${sub}&body=${bod}`;

    switch (user?.signUpMethod || '') {
      case SignUpMethod.Microsoft: {
        link = `https://outlook.live.com/mail/0/deeplink/compose?popoutv2=1&to=${emails}&subject=${sub}&body=${bod}`;
        break;
      }

      case SignUpMethod.Google: {
        link = `https://mail.google.com/mail/?view=cm&fs=1&to=${emails}&su=${sub}&body=${bod}`;
        break;
      }
    }

    return link;
  };

  return {
    getEmailLink,
  };
};
