import React, { ChangeEvent } from 'react';
import { Container, Label, DescriptionText } from './style';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

interface Props {
  label: string;
  inputWidth?: string;
  inputPlaceholder?: string;
  className?: string;
  disabled?: boolean;
  descriptionText?: string;
  inputValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  status?: '' | 'warning' | 'error';
  showPassword?: boolean;
  setShouldShowPassword?: (e: boolean) => void;
  type?: string;
  id?: string;
}

const LabeledInput: React.FC<Props> = ({
  label,
  inputPlaceholder,
  type,
  className,
  disabled = false,
  descriptionText,
  inputValue,
  setShouldShowPassword,
  showPassword,
  onChange,
  status = '',
  id,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Input
        placeholder={inputPlaceholder}
        className={className}
        disabled={disabled}
        value={inputValue}
        type={showPassword ? 'password' : ''}
        onChange={onChange}
        status={status}
        suffix={
          type === 'password' ? (
            showPassword ? (
              <EyeTwoTone
                onClick={() => {
                  if (setShouldShowPassword) setShouldShowPassword(false);
                }}
              />
            ) : (
              <EyeInvisibleOutlined
                onClick={() => {
                  if (setShouldShowPassword) setShouldShowPassword(true);
                }}
              />
            )
          ) : (
            ''
          )
        }
        id={id}
      />
      {descriptionText && <DescriptionText>{descriptionText}</DescriptionText>}
    </Container>
  );
};

export default LabeledInput;
