import { PastMeetingsProvider } from '../../../../features/meetings/past-meetings/past-meetings.provider';
import { HistoryPage } from './page';

export const History: React.FC = () => {
  return (
    <PastMeetingsProvider>
      <HistoryPage />
    </PastMeetingsProvider>
  );
};
