import React, { useEffect, useState } from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useToast } from '../../../../../hooks/useToast';
import { IntegrationModal } from '../Components/IntegrationModal';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { SubscribeToTeamsPlanLink } from '../../../../../componentsV2/UpgradeToPro';
import { coreService } from '../../../../../services/core/core-service';
import { AddCredentials } from './AddCredentials';
import { Calls } from './Calls';
import { OnlyOneConnected } from '../Components/OnlyOneConnected';
import Styles from './styles';
import DeleteModal from '../../Organizations/components/DeleteModal';
// import useFeatures from '../../../../../hooks/useFeatures';

const { Content } = Styles;

export const GongIntegrationCard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddCredentialsModal, setShowAddCredentialsModal] = useState(false);
  const [result, setResult] = useState<{
    id: string;
    secretKey: string;
  }>({ id: '', secretKey: '***' });

  // const features = useFeatures();
  const availableFeature = true;

  const { success, error } = useToast();

  const logo = 'https://www.gong.io/wp-content/uploads/2023/02/gong-logo.png';
  const hasIntegration = result.id !== '';

  const revokeKey = async () => {
    setIsLoading(true);
    coreService
      .removeGongCredentials()
      .then(() => {
        success('Gong key revoked successfully');
        setResult({ id: '', secretKey: '***' });
      })
      .catch(() => {
        error('Failed to revoke Gong key');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    coreService
      .getGongCredentials()
      .then(res => {
        setResult({ id: res, secretKey: result.secretKey });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const tooltipMessage = (
    <SubscribeToTeamsPlanLink title="Upgrade to a Business plan or higher to integrate with Gong." />
  );
  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Revoke Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'default',
            loading: isLoading,
            disabled: isLoading || !availableFeature,
          },
          onClick: () => {
            if (availableFeature) setShowDeleteModal(true);
          },
        },
      ]
    : [
        {
          type: 'button',
          label: 'Add Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'primary',
            loading: isLoading,
            disabled: isLoading || !availableFeature,
          },
          onClick: () => {
            setShowAddCredentialsModal(true);
          },
        },
      ];

  return (
    <IntegrationCard
      title="Gong"
      description="Import calls from Gong, including all calls from the last 6 months."
      actions={actions}
      logoSrc={logo}
    >
      <Content>
        {!hasIntegration && (
          <OnlyOneConnected
            elem={
              <a href="https://help.gong.io/docs/receive-access-to-the-api" target="_blank">
                How to get my gong credentials?
              </a>
            }
          />
        )}
        {hasIntegration && (
          <>
            <SuccessConnectionDataLabel label="Id: " value={result.id} />
            <a style={{ alignSelf: 'end', color: '#FF6C54' }} onClick={() => setShowModal(true)}>
              {'Import >'}
            </a>
          </>
        )}
      </Content>

      <IntegrationModal
        show={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        icon={logo}
        title="Gong Calls"
        showButton={false}
        width={750}
      >
        <Calls showModal={() => setShowModal(true)} hideModal={() => setShowModal(false)} />
      </IntegrationModal>

      <IntegrationModal
        show={showAddCredentialsModal}
        onCancel={() => {
          setShowAddCredentialsModal(false);
        }}
        icon={logo}
        title="Gong Credentials"
        buttonTitle="Close"
        showButton={false}
      >
        <AddCredentials
          onSuccess={r => {
            setResult(r);
            setShowAddCredentialsModal(false);
          }}
        />
      </IntegrationModal>

      <DeleteModal
        shouldShow={showDeleteModal}
        loading={isLoading}
        closeModal={() => setShowDeleteModal(false)}
        heading="Revoke your Gong credentials"
        detail={`Ensure all your processing is done before revoking your credentials otherwise will fail. \n\nYour imported data will not be deleted.`}
        confirmButtonText="Yes, I'm sure."
        onConfirm={() => {
          revokeKey();
          setShowDeleteModal(false);
        }}
      />
    </IntegrationCard>
  );
};
